import { customizeError, formatDNI } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import { getPublicUserAuthInfo } from '../../services/auth'

export const GET_PUBLIC_USER_AUTH_INFO = 'GET_PUBLIC_USER_AUTH_INFO'
export const GET_PUBLIC_USER_AUTH_INFO_ERROR = 'GET_PUBLIC_USER_AUTH_INFO_ERROR'
export const GET_PUBLIC_USER_AUTH_INFO_SUCCESS = 'GET_PUBLIC_USER_AUTH_INFO_SUCCESS'
export const onGetPublicUserAuthInfo = makeActionCreator(GET_PUBLIC_USER_AUTH_INFO)
export const onGetPublicUserAuthInfoError = makeActionCreator(GET_PUBLIC_USER_AUTH_INFO_ERROR, 'payload')
export const onGetPublicUserAuthInfoSuccess = makeActionCreator(GET_PUBLIC_USER_AUTH_INFO_SUCCESS, 'payload')
export const onGetPublicUserAuthInfoThunk = payload => async dispatch => {
  const { token } = payload || {}

  dispatch(onGetPublicUserAuthInfo())

  try {
    const { data } = await getPublicUserAuthInfo({ token })

    const authInfo = {
      dni: formatDNI(`${data.payload?.preferred_username}${data.payload?.dijito}`, 2) || '',
      name: `${data.payload?.nombre} ${data.payload?.apellido}` || '',
      token
    }

    return dispatch(onGetPublicUserAuthInfoSuccess(authInfo))
  } catch (error) {
    return dispatch(
      onGetPublicUserAuthInfoError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'UNAUTHORIZED_PUBLIC_USER_ERROR'
        }).toObject()
      })
    )
  }
}

import { v4 } from 'uuid'
import {
  ADD_QUESTION_FILE_TEMP_SUCCESS,
  DELETE_MEETING,
  DELETE_MEETING_ERROR,
  DELETE_MEETING_SUCCESS,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_QUESTIONS_AND_ANSWERS,
  DOWNLOAD_QUESTIONS_AND_ANSWERS_ERROR,
  DOWNLOAD_QUESTIONS_AND_ANSWERS_SUCCESS,
  GET_REQUEST_DETAIL_INFO,
  GET_REQUEST_DETAIL_INFO_ERROR,
  GET_REQUEST_DETAIL_INFO_SUCCESS,
  REMOVE_QUESTION_FILE_TEMP_SUCCESS,
  RESET_DETAIL,
  SEND_RESPONSE,
  SEND_RESPONSE_ERROR,
  SEND_RESPONSE_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_MEETING,
  UPLOAD_MEETING_ERROR,
  UPLOAD_MEETING_SUCCESS
} from './RequestDetailInfo.actions'

const requestDetailState = {
  additionalInfo: false,
  additionalInfoUrls: [],
  antecedents: '',
  attachedFiles: [],
  code: '',
  description: '',
  descriptionProblem: '',
  endDate: new Date(),
  entCode: { id: '', name: '' },
  error: undefined,
  errorAttachedFiles: undefined,
  errorDownloading: undefined,
  errorMeetings: undefined,
  histories: [],
  historyProblem: '',
  id: '',
  isActive: false,
  isDownloading: false,
  isInnovation: false,
  isInvestigated: false,
  loading: true,
  loadingAttachedFiles: false,
  loadingMeetings: false,
  locationProblem: '',
  meetings: [],
  name: '',
  orgCode: { id: '', name: '' },
  peopleAffected: '',
  products: [],
  publicationDate: new Date(),
  questions: [],
  reason: '',
  registerDate: new Date(),
  status: '',
  usrCode: { id: '', name: '' },
  willBePublic: false,
  willBeRequiredConcept: false
}

/**
 * The RequestDetailInfo's reducer.
 */
const requestDetailInfoReducer = (state = requestDetailState, { payload, type }) => {
  switch (type) {
    case ADD_QUESTION_FILE_TEMP_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                tempResponseAttachedFiles: question.tempResponseAttachedFiles.concat({
                  file: payload.file,
                  fileName: payload.fileName,
                  id: v4()
                })
              }
        )
      }
    }
    case DELETE_MEETING: {
      return {
        ...state,
        meetings: state.meetings.map(meeting =>
          meeting.id !== payload.id ? meeting : { ...meeting, error: undefined, loading: true }
        )
      }
    }
    case DELETE_MEETING_ERROR: {
      return {
        ...state,
        meetings: state.meetings.map(meeting =>
          meeting.id !== payload.id ? meeting : { ...meeting, error: payload.error, loading: false }
        )
      }
    }
    case DELETE_MEETING_SUCCESS: {
      return {
        ...state,
        meetings: state.meetings.map(meeting =>
          meeting.id !== payload.id ? meeting : { ...meeting, error: undefined, loading: false }
        )
      }
    }
    case DOWNLOAD_FILE: {
      return {
        ...state,
        attachedFiles: state.attachedFiles.map(file =>
          file.id !== payload.id ? file : { ...file, error: undefined, loading: true }
        )
      }
    }
    case DOWNLOAD_FILE_ERROR: {
      return {
        ...state,
        attachedFiles: state.attachedFiles.map(file =>
          file.id !== payload.id ? file : { ...file, error: payload.error, loading: false }
        )
      }
    }
    case DOWNLOAD_FILE_SUCCESS: {
      return {
        ...state,
        attachedFiles: state.attachedFiles.map(file =>
          file.id !== payload.id ? file : { ...file, error: undefined, loading: false }
        )
      }
    }
    case DOWNLOAD_QUESTIONS_AND_ANSWERS: {
      return { ...state, errorDownloading: undefined, isDownloading: true }
    }
    case DOWNLOAD_QUESTIONS_AND_ANSWERS_ERROR: {
      return { ...state, errorDownloading: payload.error, isDownloading: false }
    }
    case DOWNLOAD_QUESTIONS_AND_ANSWERS_SUCCESS: {
      return { ...state, errorDownloading: undefined, isDownloading: false }
    }
    case GET_REQUEST_DETAIL_INFO: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_REQUEST_DETAIL_INFO_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_REQUEST_DETAIL_INFO_SUCCESS: {
      return { ...state, ...payload, error: undefined, loading: false }
    }
    case REMOVE_QUESTION_FILE_TEMP_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                tempResponseAttachedFiles: question.tempResponseAttachedFiles.filter(file => file.id !== payload.fileId)
              }
        )
      }
    }
    case RESET_DETAIL: {
      return { ...state, ...requestDetailState }
    }
    case SEND_RESPONSE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, error: undefined, loading: true }
        )
      }
    }
    case SEND_RESPONSE_ERROR: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, error: payload.error, loading: false }
        )
      }
    }
    case SEND_RESPONSE_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, error: undefined, loading: false }
        )
      }
    }
    case UPLOAD_FILE: {
      return { ...state, errorAttachedFiles: undefined, loadingAttachedFiles: true }
    }
    case UPLOAD_FILE_ERROR: {
      return { ...state, errorAttachedFiles: payload.error, loadingAttachedFiles: false }
    }
    case UPLOAD_FILE_SUCCESS: {
      return { ...state, errorAttachedFiles: undefined, loadingAttachedFiles: false }
    }
    case UPLOAD_MEETING: {
      return { ...state, errorMeetings: undefined, loadingMeetings: true }
    }
    case UPLOAD_MEETING_ERROR: {
      return { ...state, errorMeetings: payload.error, loadingMeetings: false }
    }
    case UPLOAD_MEETING_SUCCESS: {
      return { ...state, errorMeetings: undefined, loadingMeetings: false }
    }
    default: {
      return state
    }
  }
}

export default requestDetailInfoReducer

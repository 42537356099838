import { customizeError } from '@chilecompra/react-kit/errors'
import { differenceInDays, differenceInHours } from 'date-fns'
import { ADDITIONAL_INFO_DICTIONARY } from '../../configs/settings/constants'
import { URL_CHECK } from '../../configs/settings/regexes'

/**
 * Checks some common errors.
 */
export const checkErrors = ({
  additionalInfo,
  additionalInfoURL,
  dateTime,
  description,
  isInnovation,
  name,
  products,
  questions,
  willBePublic
}) => {
  const errors = []
  const minOneDay = differenceInDays(dateTime, new Date()) === 0 || differenceInHours(dateTime, new Date()) < 24

  if (name === '') errors.push('NAME_UNSET_ERROR')

  if (name.length > 100) errors.push('NAME_MAX_LENGTH_ERROR')

  if (description === '') errors.push('DESCRIPTION_UNSET_ERROR')

  if (description.length > 500) errors.push('DESCRIPTION_MAX_LENGTH_ERROR')

  if (!dateTime) errors.push('DATE_TIME_UNSET_ERROR')

  if (minOneDay) errors.push('MIN_ONE_DAY_ERROR')

  if (products.length === 0) errors.push('PRODUCTS_UNSET_ERROR')

  if (!isInnovation) {
    if (questions.length === 0) errors.push('QUESTIONS_UNSET_ERROR')

    if (questions.some(({ message }) => message === '')) errors.push('QUESTION_EMPTY_MESSAGE_ERROR')

    if (questions.some(({ message }) => message.length > 300)) errors.push('QUESTION_MAX_LENGTH_ERROR')

    if (Array.from(new Set([...questions].map(({ message }) => message))).length < questions.length) {
      errors.push('DUPLICATED_QUESTION_ERROR')
    }
  }

  if (!additionalInfo) errors.push('ADDITIONAL_INFO_UNSET_ERROR')

  if (!willBePublic) errors.push('WILL_BE_PUBLIC_UNSET_ERROR')

  if (additionalInfo === ADDITIONAL_INFO_DICTIONARY.YES) {
    if (additionalInfoURL === '') errors.push('ADDITIONAL_INFO_URL_UNSET_ERROR')

    if (additionalInfoURL !== '' && !URL_CHECK.test(additionalInfoURL)) {
      errors.push('ADDITIONAL_INFO_URL_DONT_MATCH_ERROR')
    }

    if (additionalInfoURL.length > 300) errors.push('ADDITIONAL_INFO_URL_MAX_LENGTH_ERROR')
  }

  if (errors.length === 0) return

  throw customizeError({ reason: errors })
}

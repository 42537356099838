import { factoryService, getFileRequest, postFormRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../configs/settings/environment'

/**
 * Get download file.
 */
export const dowloadFile = factoryService(({ fileId }) => {
  return getFileRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/descargar/${fileId}`)
})

/**
 * Download questions and answers data.
 */
export const downloadQuestionsAndAnswers = factoryService(({ requestId }) => {
  return getFileRequest(
    `${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/preguntas/excel?consultaId=${requestId}`
  )
})

/**
 * Download requets informations data.
 */
export const downloadRequests = factoryService(
  ({ dateFrom, dateTo, isItems, isParticipated, keyword, order, orgBuyer, page, status }) => {
    const params = new URLSearchParams({
      ...(isItems && { misRubros: isItems }),
      ...(isParticipated && { misConsultas: isParticipated }),
      ...(keyword && { palabraClave: keyword }),
      ...(orgBuyer && { organismoComprador: orgBuyer }),
      desde: dateFrom,
      estado: status,
      hasta: dateTo,
      ordenarPor: order,
      pagina: page
    }).toString()

    return getFileRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/excel?${params}`)
  }
)

/**
 * Post send the request attached files.
 */
export const postAttachedFiles = factoryService(({ attachedFiles, requestId }) => {
  const body = { file: attachedFiles.map(attachedFile => attachedFile.file) }

  return postFormRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/adjuntos`, { body })
})

/**
 * Post send the request attached files to response of a question.
 */
export const postAttachedFilesToResponse = factoryService(({ attachedFiles, questionId, requestId, responseId }) => {
  const body = { file: attachedFiles.map(attachedFile => attachedFile.file) }

  return postFormRequest(
    `${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/preguntas/${questionId}/respuestas/${responseId}/adjuntos`,
    { body }
  )
})

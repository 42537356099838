import { batch } from 'react-redux'
import { makeActionCreator } from '../../configs/store/utils'
import { checkErrors } from './RequestCreateInnovationForm.utils'

export const ADD_QUESTION_INNOVATION_SUCCESS = 'ADD_QUESTION_INNOVATION_SUCCESS'
export const onAddQuestionInnovationSuccess = makeActionCreator(ADD_QUESTION_INNOVATION_SUCCESS, 'payload')

export const ADD_QUESTION_LIBRARY_SUCCESS = 'ADD_QUESTION_LIBRARY_SUCCESS'
export const onAddQuestionLibrarySuccess = makeActionCreator(ADD_QUESTION_LIBRARY_SUCCESS, 'payload')

export const ADD_QUESTION_MULTIPLE_CHOICE_SUCCESS = 'ADD_QUESTION_MULTIPLE_CHOICE_SUCCESS'
export const onAddQuestionMultipleChoiceSuccess = makeActionCreator(ADD_QUESTION_MULTIPLE_CHOICE_SUCCESS, 'payload')

export const INITIAL_QUESTIONS_MULTIPLE_CHOICE = 'INITIAL_QUESTIONS_MULTIPLE_CHOICE'
export const onInitialQuestionsMultipleChoice = makeActionCreator(INITIAL_QUESTIONS_MULTIPLE_CHOICE, 'payload')

export const REMOVE_QUESTION_INNOVATION_SUCCESS = 'REMOVE_QUESTION_INNOVATION_SUCCESS'
export const onRemoveQuestionInnovationSuccess = makeActionCreator(REMOVE_QUESTION_INNOVATION_SUCCESS, 'payload')

export const REMOVE_QUESTION_MULTIPLE_CHOICE_SUCCESS = 'REMOVE_QUESTION_MULTIPLE_CHOICE_SUCCESS'
export const onRemoveQuestionMultipleChoiceSuccess = makeActionCreator(
  REMOVE_QUESTION_MULTIPLE_CHOICE_SUCCESS,
  'payload'
)

export const RESET_CREATE_INNOVATION_FORM = 'RESET_CREATE_INNOVATION_FORM'
export const onResetCreateInnovationForm = makeActionCreator(RESET_CREATE_INNOVATION_FORM, 'payload')

export const RESET_QUESTIONS_MULTIPLE_CHOICE = 'RESET_QUESTIONS_MULTIPLE_CHOICE'
export const onResetQuestionsMultipleChoice = makeActionCreator(RESET_QUESTIONS_MULTIPLE_CHOICE, 'payload')

export const UPDATE_CREATE_INNOVATION_FORM_VALUE = 'UPDATE_CREATE_INNOVATION_FORM_VALUE'
export const onUpdateCreateInnovationFormValue = makeActionCreator(UPDATE_CREATE_INNOVATION_FORM_VALUE, 'payload')

export const UPDATE_DUPLICATED_QUESTION_INNOVATION_SUCCESS = 'UPDATE_DUPLICATED_QUESTION_INNOVATION_SUCCESS'
export const onUpdateDuplicatedQuestionInnovationSuccess = makeActionCreator(
  UPDATE_DUPLICATED_QUESTION_INNOVATION_SUCCESS,
  'payload'
)
export const onUpdateDuplicatedQuestionInnovationThunk = payload => async (dispatch, getState) => {
  const { id } = payload || {}
  const { questions } = getState().requestCreateInnovationForm

  const updated = questions.find(question => question.id === id)
  const duplicated = questions.some(question => question.id !== updated.id && question.message === updated.message)

  return dispatch(onUpdateDuplicatedQuestionInnovationSuccess({ ...payload, key: 'duplicated', value: duplicated }))
}

export const UPDATE_DUPLICATED_QUESTION_MULTIPLE_CHOICE_SUCCESS = 'UPDATE_DUPLICATED_QUESTION_MULTIPLE_CHOICE_SUCCESS'
export const onUpdateDuplicatedQuestionMultipleChoiceSuccess = makeActionCreator(
  UPDATE_DUPLICATED_QUESTION_MULTIPLE_CHOICE_SUCCESS,
  'payload'
)
export const onUpdateDuplicatedQuestionMultipleChoiceThunk = payload => async (dispatch, getState) => {
  const { id, idOption } = payload || {}

  const { questions } = getState().requestCreateInnovationForm

  const question = questions.find(question => question.id === id)
  const updated = question.multipleChoiceOptions.find(option => option.id === idOption)

  const duplicated = question.multipleChoiceOptions.some(option => {
    return option.id !== updated.id && option.message === updated.message
  })

  return dispatch(onUpdateDuplicatedQuestionMultipleChoiceSuccess({ ...payload, key: 'duplicated', value: duplicated }))
}

export const UPDATE_QUESTION_INNOVATION_VALUE = 'UPDATE_QUESTION_INNOVATION_VALUE'
export const onUpdateQuestionInnovationValue = makeActionCreator(UPDATE_QUESTION_INNOVATION_VALUE, 'payload')
export const onUpdateQuestionInnovationValueThunk = payload => dispatch => {
  const { id } = payload || {}

  return batch(() => {
    dispatch(onUpdateQuestionInnovationValue(payload))
    dispatch(onUpdateDuplicatedQuestionInnovationThunk({ id }))
  })
}

export const UPDATE_QUESTION_MULTIPLE_CHOICE_VALUE = 'UPDATE_QUESTION_MULTIPLE_CHOICE_VALUE'
export const onUpdateQuestionMultipleChoiceValue = makeActionCreator(UPDATE_QUESTION_MULTIPLE_CHOICE_VALUE, 'payload')
export const onUpdateQuestionMultipleChoiceValueThunk = payload => dispatch => {
  const { id, idOption } = payload || {}

  return batch(() => {
    dispatch(onUpdateQuestionMultipleChoiceValue(payload))
    dispatch(onUpdateDuplicatedQuestionMultipleChoiceThunk({ id, idOption }))
  })
}

export const VALIDATE_CREATE_INNOVATION_FORM = 'VALIDATE_CREATE_INNOVATION_FORM'
export const VALIDATE_CREATE_INNOVATION_FORM_ERROR = 'VALIDATE_CREATE_INNOVATION_FORM_ERROR'
export const onValidateCreateInnovationForm = makeActionCreator(VALIDATE_CREATE_INNOVATION_FORM)
export const onValidateCreateInnovationFormError = makeActionCreator(VALIDATE_CREATE_INNOVATION_FORM_ERROR, 'payload')
export const onValidateCreateInnovationFormThunk = payload => async (dispatch, getState) => {
  const { showFeedbackConfirmDialogCallbak = () => undefined } = payload || {}

  dispatch(onValidateCreateInnovationForm())

  try {
    const {
      antecedents,
      descriptionProblem,
      historyProblem,
      isInvestigated,
      locationProblem,
      peopleAffected,
      questions,
      willBeRequiredConcept
    } = getState().requestCreateInnovationForm

    checkErrors({
      antecedents,
      descriptionProblem,
      historyProblem,
      isInvestigated,
      locationProblem,
      peopleAffected,
      questions,
      willBeRequiredConcept
    })

    return showFeedbackConfirmDialogCallbak()
  } catch (error) {
    return dispatch(onValidateCreateInnovationFormError({ error }))
  }
}

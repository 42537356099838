import { customizeError, downloadFile, formatDate, generateBlob } from '@chilecompra/react-kit'
import { STATUS_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import { makeActionCreator } from '../../configs/store/utils'
import { downloadRequests } from '../../services/files'
import { getRequests } from '../../services/request'

export const DOWNLOAD_REQUESTS = 'DOWNLOAD_REQUESTS'
export const DOWNLOAD_REQUESTS_ERROR = 'DOWNLOAD_REQUESTS_ERROR'
export const DOWNLOAD_REQUESTS_SUCCESS = 'DOWNLOAD_REQUESTS_SUCCESS'
export const onDownloadRequests = makeActionCreator(DOWNLOAD_REQUESTS)
export const onDownloadRequestsError = makeActionCreator(DOWNLOAD_REQUESTS_ERROR, 'payload')
export const onDownloadRequestsSuccess = makeActionCreator(DOWNLOAD_REQUESTS_SUCCESS, 'payload')
export const onDownloadRequestsThunk = () => async (dispatch, getState) => {
  dispatch(onDownloadRequests())

  try {
    const { filters } = getState().requestList

    const { data, headers } = await downloadRequests({
      ...filters,
      dateFrom: formatDate(filters.dateFrom, 'yyyy-MM-dd HH:mm:ss'),
      dateTo: formatDate(filters.dateTo, 'yyyy-MM-dd HH:mm:ss'),
      status: Number(filters.status) !== STATUS_OPTIONS_DICTIONARY.ALL_STATUS ? Number(filters.status) : 0
    })

    const blob = generateBlob(data, headers['content-type'])

    downloadFile(blob, 'consultas-al-mercado')

    return dispatch(onDownloadRequestsSuccess())
  } catch (error) {
    return dispatch(
      onDownloadRequestsError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUESTS_ERROR = 'GET_REQUESTS_ERROR'
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS'
export const onGetRequests = makeActionCreator(GET_REQUESTS, 'payload')
export const onGetRequestsError = makeActionCreator(GET_REQUESTS_ERROR, 'payload')
export const onGetRequestsSuccess = makeActionCreator(GET_REQUESTS_SUCCESS, 'payload')
export const onGetRequestsThunk = payload => async dispatch => {
  const { filters, loading, searching, searchingByGeneral } = payload || {}

  const currentFilters = {
    dateFrom: filters.dateFrom,
    dateTo: filters.dateTo,
    isItems: !!(filters.isItems === true || filters.isItems === 'true'),
    isParticipated: !!(filters.isParticipated === true || filters.isParticipated === 'true'),
    keyword: filters.keyword.toLowerCase().trim(),
    order: Number(filters.order),
    orgBuyer: filters.orgBuyer.toLowerCase().trim(),
    page: Number(filters.page),
    status: Number(filters.status)
  }

  dispatch(onGetRequests({ filters: currentFilters, loading, searching, searchingByGeneral }))

  try {
    const { data } = await getRequests({
      ...currentFilters,
      dateFrom: formatDate(filters.dateFrom, 'yyyy-MM-dd HH:mm:ss'),
      dateTo: formatDate(filters.dateTo, 'yyyy-MM-dd HH:mm:ss'),
      status: Number(filters.status) !== STATUS_OPTIONS_DICTIONARY.ALL_STATUS ? Number(filters.status) : 0
    })

    return dispatch(
      onGetRequestsSuccess({
        data: data.payload?.resultados?.map(request => ({
          code: request.codigoConsulta,
          description: request.descripcion,
          endDate: request.fechaCierre,
          entCode: { id: request.codigoInstitucion, name: request.nombreInstitucion },
          id: request.id,
          name: request.nombre,
          orgCode: { id: request.codigoUnidadCompra, name: request.nombreUnidadCompra },
          publicationDate: request.fechaPublicacion,
          reason: request.motivo,
          status: request.estado,
          usrCode: { id: request.codigoUsuario, name: request.nombreUsuario }
        })),
        pageCount: data.payload?.pageCount,
        summaryCount: data.payload?.resultCount
      })
    )
  } catch (error) {
    if (error.originalError.code === 'ERR_CANCELED') return

    return dispatch(
      onGetRequestsError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

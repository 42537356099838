import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useEffectOnce, useSecondOrderCallback, useQuery, useViewport } from '@chilecompra/react-kit'
import { Button, Typography } from '@chilecompra/react-kit/components'
import {
  CREATE_SECTION_DICTIONARY,
  CREATE_SECTION_OPTIONS,
  MEETING_ACTION,
  REASON_OPTIONS_DICTIONARY
} from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { onResetCreateInnovationForm } from '../RequestCreateInnovationForm/RequestCreateInnovationForm.actions'
import { onShowFeedbackConfirmDialog } from '../RequestFeedbackConfirmDialog/RequestFeedbackConfirmDialog.actions'
import { onResetMeetingForm, onShowMeetingDialog } from '../RequestMeetingDialog/RequestMeetingDialog.actions'
import { onResetProductForm, onShowProductDialog } from '../RequestProductDialog/RequestProductDialog.actions'
import { useAuthProvider } from './../AuthProvider/AuthProvider.hook'
import {
  onAddFileThunk,
  onAddQuestionSuccess,
  onRemoveFileSuccess,
  onRemoveMeetingSuccess,
  onRemoveProductSuccess,
  onRemoveQuestionSuccess,
  onUpdateCreateFormValue,
  onUpdateQuestionValueThunk,
  onValidateCreateFormThunk
} from './RequestCreateForm.actions'
import SectionExtraForm from './RequestCreateForm.extraForm'
import SectionsForm from './RequestCreateForm.sections'
import { Container, Item, WrapperGrid } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's container.
 */
const RequestCreateForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()
  const { size } = useViewport()

  const {
    additionalInfo,
    additionalInfoURL,
    attachedFiles,
    dateTime,
    description,
    meetings,
    name,
    products,
    questions,
    submitError,
    willBePublic
  } = useSelector(state => state.requestCreateForm)
  const { filters } = useSelector(state => state.requestList)
  const { loading } = useSelector(state => state.requestFeedbackConfirmDialog)
  const { otherReason, reason } = useSelector(state => state.requestReasonDialog)

  const isInnovation = reason === REASON_OPTIONS_DICTIONARY.REQUEST_INNOVATION

  const handleAddFile = useCallback(file => dispatch(onAddFileThunk({ file })), [])
  const handleAddQuestion = useCallback(() => dispatch(onAddQuestionSuccess()), [])
  const handleRemoveFile = useSecondOrderCallback((_, id) => dispatch(onRemoveFileSuccess({ id })))
  const handleRemoveMeeting = useSecondOrderCallback((_, id) => dispatch(onRemoveMeetingSuccess({ id })))
  const handleRemoveProduct = useSecondOrderCallback((_, id) => dispatch(onRemoveProductSuccess({ id })))
  const handleRemoveQuestion = useSecondOrderCallback((_, id) => dispatch(onRemoveQuestionSuccess({ id })))
  const handleUpdateCreateFormValue = useCallback(entry => dispatch(onUpdateCreateFormValue({ ...entry })), [])

  const handleUpdateQuestion = useSecondOrderCallback((entry, id) => {
    dispatch(onUpdateQuestionValueThunk({ ...entry, id }))
  })

  const handleGoHomeWithParams = () => {
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  const handleShowMeetingDialog = useCallback(() => {
    batch(() => {
      dispatch(onResetMeetingForm())
      dispatch(onShowMeetingDialog({ action: MEETING_ACTION.CREATE_FORM }))
    })
  }, [])

  const handleShowProductsDialog = useCallback(() => {
    dispatch(onResetProductForm())
    dispatch(onShowProductDialog())
  }, [])

  const handleValidateCreateForm = () => {
    dispatch(
      onValidateCreateFormThunk({
        goToCreateStepTwoCallback: () => {
          dispatch(onResetCreateInnovationForm())
          navigate({ pathname: routes.createStepTwo }, { replace: true })
        },
        showFeedbackConfirmDialogCallbak: () => dispatch(onShowFeedbackConfirmDialog())
      })
    )
  }

  const createSectionOptions = useMemo(() => {
    if (isInnovation) {
      return CREATE_SECTION_OPTIONS.filter(section => section.name !== CREATE_SECTION_DICTIONARY.QUESTIONS)
    }

    return CREATE_SECTION_OPTIONS
  }, [CREATE_SECTION_OPTIONS, isInnovation])

  useEffectOnce(() => {
    if (!otherReason && !reason) return handleGoHomeWithParams()
  })

  if (!otherReason && !reason) return

  return (
    <Container padding="16px 0" spacing={4}>
      <Item md={9} xs={12}>
        {createSectionOptions.map(section => (
          <WrapperGrid key={section.name} padding="32px 0">
            <Item>
              <Typography fontWeight="bold" margin="0 0 8px" variant="body1">
                {section.title}
              </Typography>

              <Typography variant="subtitle">{section.description}</Typography>
            </Item>

            <SectionsForm
              attachedFiles={attachedFiles}
              dateTime={dateTime}
              description={description}
              isDisabled={loading}
              isInnovation={isInnovation}
              meetings={meetings}
              name={name}
              onAddFile={handleAddFile}
              onAddQuestion={handleAddQuestion}
              onRemoveFile={handleRemoveFile}
              onRemoveMeeting={handleRemoveMeeting}
              onRemoveProduct={handleRemoveProduct}
              onRemoveQuestion={handleRemoveQuestion}
              onShowMeetingDialog={handleShowMeetingDialog}
              onShowProductDialog={handleShowProductsDialog}
              onUpdate={handleUpdateCreateFormValue}
              onUpdateQuestion={handleUpdateQuestion}
              products={products}
              questions={questions}
              sectionName={section.name}
              submitError={submitError?.reason}
            />
          </WrapperGrid>
        ))}

        <SectionExtraForm
          additionalInfo={additionalInfo}
          additionalInfoURL={additionalInfoURL}
          isDisabled={loading}
          onUpdate={handleUpdateCreateFormValue}
          submitError={submitError?.reason}
          willBePublic={willBePublic}
        />
      </Item>

      <Item md={9} xs={12}>
        <Container borderTop justifyContent={size.isTiny ? 'center' : 'space-between'} padding="32px 0">
          <Button
            disabled={loading}
            margin={size.isTiny ? '0 0 16px' : undefined}
            onClick={handleGoHomeWithParams}
            variant="outlined"
          >
            Volver
          </Button>

          <Button color="primary" disabled={loading} onClick={handleValidateCreateForm} variant="contained">
            {isInnovation ? 'Siguiente' : 'Publicar Consulta al Mercado'}
          </Button>
        </Container>
      </Item>
    </Container>
  )
}

export default RequestCreateForm

import React from 'react'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../../configs/styles/BaseGrid'

export const Container = styled(BaseContainer)``

export const Item = styled(({ order, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: flex;
    order: ${props => props.order};
  }
`

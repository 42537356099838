import React from 'react'
import { Grid as BaseGrid } from '@material-ui/core'
import styled from 'styled-components'

export const BaseContainer = styled(React.forwardRef((props, ref) => <BaseGrid container ref={ref} {...props} />))`
  &.MuiGrid-container {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const BaseItem = styled(React.forwardRef((props, ref) => <BaseGrid item ref={ref} {...props} />))`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const BaseLayout = styled(props => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    margin: 0 auto;
    padding: 0 16px;

    ${props => props.theme.breakpoints.only('xl')} {
      max-width: 1320px;
    }

    ${props => props.theme.breakpoints.only('lg')} {
      max-width: 1140px;
    }

    ${props => props.theme.breakpoints.only('md')} {
      max-width: 960px;
    }

    ${props => props.theme.breakpoints.only('sm')} {
      max-width: 720px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      max-width: 540px;
    }
  }
`

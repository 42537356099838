import {
  DOWNLOAD_REQUESTS,
  DOWNLOAD_REQUESTS_ERROR,
  DOWNLOAD_REQUESTS_SUCCESS,
  GET_REQUESTS,
  GET_REQUESTS_ERROR,
  GET_REQUESTS_SUCCESS
} from './RequestList.actions'

const requestListState = {
  data: [],
  error: undefined,
  errorDownloading: undefined,
  filters: {
    dateFrom: undefined,
    dateTo: undefined,
    isItems: false,
    isParticipated: false,
    keyword: '',
    order: '',
    orgBuyer: '',
    page: 1,
    status: ''
  },
  isDownloading: false,
  loading: true,
  pageCount: 0,
  searching: false,
  searchingByGeneral: false,
  summaryCount: 0
}

/**
 * The RequestList's reducer.
 */
const requestListReducer = (state = requestListState, { payload, type }) => {
  switch (type) {
    case DOWNLOAD_REQUESTS: {
      return { ...state, errorDownloading: undefined, isDownloading: true }
    }
    case DOWNLOAD_REQUESTS_ERROR: {
      return { ...state, errorDownloading: payload.error, isDownloading: false }
    }
    case DOWNLOAD_REQUESTS_SUCCESS: {
      return { ...state, errorDownloading: undefined, isDownloading: false }
    }
    case GET_REQUESTS: {
      return {
        ...state,
        error: undefined,
        errorDownloading: undefined,
        filters: { ...payload.filters },
        loading: payload.loading ?? state.loading,
        searching: payload.searching ?? state.searching,
        searchingByGeneral: payload.searchingByGeneral ?? state.searchingByGeneral
      }
    }
    case GET_REQUESTS_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false,
        searching: false,
        searchingByGeneral: false
      }
    }
    case GET_REQUESTS_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        error: undefined,
        loading: false,
        pageCount: payload.pageCount,
        searching: false,
        searchingByGeneral: false,
        summaryCount: payload.summaryCount
      }
    }
    default: {
      return state
    }
  }
}

export default requestListReducer

import { add } from 'date-fns'
import { v4 } from 'uuid'
import {
  ADD_FILE_SUCCESS,
  ADD_MEETING_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  ADD_QUESTION_SUCCESS,
  REMOVE_FILE_SUCCESS,
  REMOVE_MEETING_SUCCESS,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_QUESTION_SUCCESS,
  RESET_CREATE_FORM,
  UPDATE_CREATE_FORM_VALUE,
  UPDATE_DUPLICATED_QUESTION_SUCCESS,
  UPDATE_QUESTION_VALUE,
  VALIDATE_CREATE_FORM,
  VALIDATE_CREATE_FORM_ERROR
} from './RequestCreateForm.actions'

const requestCreateFormState = {
  additionalInfo: 0,
  additionalInfoURL: '',
  attachedFiles: [],
  dateTime: add(new Date(), { days: 1 }),
  description: '',
  meetings: [],
  name: '',
  products: [],
  questions: [{ duplicated: false, id: v4(), message: '' }],
  submitError: undefined,
  willBePublic: 0
}

/**
 * The RequestCreateForm's reducer.
 */
const requestCreateFormReducer = (state = requestCreateFormState, { payload, type }) => {
  switch (type) {
    case ADD_FILE_SUCCESS: {
      return { ...state, attachedFiles: state.attachedFiles.concat({ id: v4(), ...payload }) }
    }
    case ADD_MEETING_SUCCESS: {
      return { ...state, meetings: state.meetings.concat({ id: v4(), ...payload }) }
    }
    case ADD_PRODUCT_SUCCESS: {
      return { ...state, products: state.products.concat({ id: v4(), ...payload }) }
    }
    case ADD_QUESTION_SUCCESS: {
      return { ...state, questions: state.questions.concat({ duplicated: false, id: v4(), message: '' }) }
    }
    case REMOVE_FILE_SUCCESS: {
      return { ...state, attachedFiles: state.attachedFiles.filter(file => file.id !== payload.id) }
    }
    case REMOVE_MEETING_SUCCESS: {
      return { ...state, meetings: state.meetings.filter(meeting => meeting.id !== payload.id) }
    }
    case REMOVE_PRODUCT_SUCCESS: {
      return { ...state, products: state.products.filter(product => product.id !== payload.id) }
    }
    case REMOVE_QUESTION_SUCCESS: {
      return { ...state, questions: state.questions.filter(question => question.id !== payload.id) }
    }
    case RESET_CREATE_FORM: {
      return { ...state, ...requestCreateFormState }
    }
    case UPDATE_CREATE_FORM_VALUE: {
      return { ...state, [payload.key]: payload.value }
    }
    case UPDATE_DUPLICATED_QUESTION_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, [payload.key]: payload.value }
        )
      }
    }
    case UPDATE_QUESTION_VALUE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, [payload.key]: payload.value }
        )
      }
    }
    case VALIDATE_CREATE_FORM: {
      return { ...state, submitError: undefined }
    }
    case VALIDATE_CREATE_FORM_ERROR: {
      return { ...state, submitError: payload.error }
    }
    default: {
      return state
    }
  }
}

export default requestCreateFormReducer

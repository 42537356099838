import React, { memo, useEffect } from 'react'
import { checkInputErrorFileMaxSize, useFile } from '@chilecompra/react-kit'
import { Card, File, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { ACCEPTED_DOCUMENT_TYPES, MAX_MB } from '../../configs/settings/constants'
import { AttachedFiles, Files, RemoveIcon } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's attachedFiles.
 */
const AttachedFilesSection = props => {
  const { attachedFiles, isDisabled, onAdd, onRemove } = props

  const {
    error: fileError,
    onChange: handleFileChange,
    onReset: handleFileReset,
    setValue: setFileValue,
    value: fileValue
  } = useFile({
    errorCallbacks: [checkInputErrorFileMaxSize(MAX_MB)]
  })

  useEffect(() => {
    if (fileError !== '') return setFileValue('')

    if (fileValue) onAdd(fileValue)
  }, [fileError, fileValue])

  useEffect(() => {
    if (attachedFiles.length === 0) handleFileReset()
  }, [attachedFiles])

  return (
    <AttachedFiles>
      <File
        accept={ACCEPTED_DOCUMENT_TYPES}
        browseText={fileValue ? 'Agregar otro archivo' : 'Examinar'}
        disabled={isDisabled}
        error={fileError}
        info={`Tamaño máximo permitido: ${MAX_MB}MB.`}
        label="Documento anexo"
        onChange={handleFileChange}
        placeholder="Presiona examinar para adjuntar un archivo."
        value={fileValue}
      />

      {attachedFiles.length > 0 && (
        <>
          <Typography fontWeight="bold" variant="body2">
            Archivos adjuntos:
          </Typography>

          {attachedFiles.map(file => (
            <Card key={file.id} padding="24px">
              <Files>
                <Typography className="file__name" variant="body2">
                  {file.fileName.toLowerCase()}
                </Typography>

                <RemoveIcon disabled={isDisabled} onClick={onRemove(file.id)} />
              </Files>
            </Card>
          ))}
        </>
      )}
    </AttachedFiles>
  )
}

AttachedFilesSection.defaultProps = {
  attachedFiles: [],
  isDisabled: false
}

AttachedFilesSection.propTypes = {
  attachedFiles: PropTypes.array,
  isDisabled: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default memo(AttachedFilesSection)

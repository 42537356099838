import { useCallback, useState } from 'react'
import {
  useCheckError,
  useFormatter,
  useOverwrite,
  useSyncError
} from '@chilecompra/react-kit/components/Inputs/Inputs.utils'

/**
 * The TextArea's custom hook.
 */
const useTextArea = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  errorDependencies = [],
  formatCallbacks = [],
  initialValue = '',
  overwriteCallback = () => undefined
} = {}) => {
  const formatter = useFormatter(formatCallbacks)
  const [error, setError] = useState('')
  const [value, setValue] = useState(() => formatter(initialValue))
  const checkError = useCheckError(errorCallbacks, updatedError => setError(updatedError))

  const handleChange = useCallback(
    event => {
      const updatedValue = event.target?.value || ''
      const formattedValue = formatter(updatedValue)

      checkError(formattedValue)
      setValue(formattedValue)

      if (formattedValue === value) return

      changeCallback(formattedValue, updatedValue)
    },
    [changeCallback, checkError, formatter, value]
  )

  const handleError = useCallback(() => checkError(value), [checkError, value])

  const handleReset = useCallback(() => {
    setError('')
    setValue(initialValue)
  }, [initialValue])

  useOverwrite(value, overwriteCallback, updatedValue => {
    const formattedValue = formatter(updatedValue)

    checkError(formattedValue)
    setValue(formattedValue)
  })

  useSyncError(errorDependencies, () => checkError(value))

  return { error, onChange: handleChange, onError: handleError, onReset: handleReset, setValue, value }
}

export default useTextArea

import React, { memo, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { WhomIsAProblem } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's whomIsAProblem.
 */
const WhomIsAProblemSection = props => {
  const { isDisabled, onUpdate, peopleAffected, submitError } = props

  const peopleAffectedRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: peopleAffectedError,
    onChange: handlePeopleAffectedChange,
    value: peopleAffectedValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'peopleAffected', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => peopleAffected
  })

  useEffect(() => {
    if (
      conditionsFields([
        'DUPLICATED_QUESTION_ERROR',
        'DUPLICATED_QUESTION_OPTION_ERROR',
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR',
        'QUESTION_MAX_OPTION_LENGTH_ERROR',
        'QUESTION_OPTION_EMPTY_MESSAGE_ERROR',
        'WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'
      ])
    )
      return

    if (conditionsFields(['WHOM_IS_A_PROBLEM_UNSET_ERROR', 'WHOM_IS_A_PROBLEM_MAX_LENGTH_ERROR'])) {
      return peopleAffectedRef.current?.focus()
    }
  }, [submitError])

  return (
    <WhomIsAProblem>
      <TextArea
        disabled={isDisabled}
        error={peopleAffectedError}
        inputRef={peopleAffectedRef}
        label="Personas afectadas"
        maxCount={500}
        onChange={handlePeopleAffectedChange}
        required
        rows={4}
        value={peopleAffectedValue}
      />
    </WhomIsAProblem>
  )
}

WhomIsAProblemSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

WhomIsAProblemSection.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  peopleAffected: PropTypes.string.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(WhomIsAProblemSection)

import React, { memo } from 'react'
import { formatDNI, formatDate } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import ProtectByProfile from '../../components/Security/ProtectByProfile'
import { MEETING_TYPE_OPTIONS, USER_TYPES } from '../../configs/settings/constants'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import {
  AddMeetingLink,
  Container,
  Item,
  Meeting,
  MeetingBox,
  MeetingContent,
  MeetingEmpty,
  MeetingWrapper,
  Meetings,
  RemoveIcon,
  SearchLoadingIcon,
  Skeleton
} from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's meetings.
 */
const SectioMeetings = props => {
  const { isLoading, meetings, onDeleteMeeting, onShowMeetingDialog } = props

  const { canChangeRFI } = useAuthProvider()

  return (
    <Meetings>
      {isLoading && <Skeleton width="100%" />}

      {!isLoading && meetings.length === 0 && (
        <MeetingEmpty>
          <Typography color="black3" variant="body2">
            No se han registrado reuniones
          </Typography>

          {isLoading && <Skeleton width="128px" />}

          <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER && canChangeRFI}>
            {!isLoading && <AddMeetingLink onClick={onShowMeetingDialog}>Ingresar reunión</AddMeetingLink>}
          </ProtectByProfile>
        </MeetingEmpty>
      )}

      {!isLoading &&
        meetings.length > 0 &&
        meetings.map(meeting => (
          <Meeting key={meeting.id} margin="0 0 16px" padding="12px 24px">
            <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER && canChangeRFI}>
              <Container alignItems="center" justifyContent="flex-end">
                {meeting.loading && (
                  <>
                    <Typography display="inline" variant="body2">
                      Eliminando reunión...
                    </Typography>

                    <SearchLoadingIcon />
                  </>
                )}

                {!meeting.loading && meeting.error && (
                  <>
                    <Typography color="error" display="inline" margin="0 8px 0 0" variant="body2">
                      Reintente eliminar la reunión nuevamente...
                    </Typography>

                    <RemoveIcon onClick={() => onDeleteMeeting(meeting)} />
                  </>
                )}

                {!meeting.loading && !meeting.error && <RemoveIcon onClick={() => onDeleteMeeting(meeting)} />}
              </Container>
            </ProtectByProfile>

            <MeetingContent>
              <MeetingWrapper full>
                <MeetingBox padding="12px 0">
                  <Typography color="black3" variant="body2">
                    Tipo de reunión:
                  </Typography>

                  <Typography variant="body2">
                    {meeting.meetingOtherType ||
                      MEETING_TYPE_OPTIONS.find(({ value }) => value === meeting.meetingType).name}
                  </Typography>
                </MeetingBox>
              </MeetingWrapper>

              <MeetingWrapper>
                <MeetingBox padding="12px 0">
                  <Typography color="black3" variant="body2">
                    RUT:
                  </Typography>

                  <Typography variant="body2">{meeting.businessDni}</Typography>
                </MeetingBox>
              </MeetingWrapper>

              <MeetingWrapper>
                <MeetingBox padding="12px 0">
                  <Typography color="black3" variant="body2">
                    Fecha reunión:
                  </Typography>

                  <Typography variant="body2">
                    {meeting.date && formatDate(meeting.date, 'dd/MM/yyyy HH:mm')}
                  </Typography>
                </MeetingBox>
              </MeetingWrapper>

              <MeetingWrapper full>
                <MeetingBox padding="12px 0">
                  <Typography color="black3" variant="body2">
                    Razón Social:
                  </Typography>

                  <Typography variant="body2">{meeting.businessName}</Typography>
                </MeetingBox>
              </MeetingWrapper>

              <MeetingWrapper full>
                <MeetingBox padding="12px 0">
                  <Typography color="black3" variant="body2">
                    Motivo:
                  </Typography>

                  <Typography variant="body2">{meeting.reasonMeeting}</Typography>
                </MeetingBox>
              </MeetingWrapper>

              <MeetingWrapper full>
                <MeetingBox padding="12px 0 0">
                  <Typography color="black3" variant="body2">
                    Asistentes:
                  </Typography>

                  <Item>
                    {meeting.assistants.map(assistant => (
                      <Item key={assistant.id}>
                        <Container margin="0 0 8px">
                          <Typography margin="0 8px 0 0" variant="body2">
                            {`${assistant.names} ${assistant.lastNames}`}
                          </Typography>

                          <Typography variant="subtitle">
                            {`${assistant.isForeign ? assistant.foreignDni : formatDNI(assistant.dni, 1)} - ${
                              assistant.position
                            }`}
                          </Typography>
                        </Container>
                      </Item>
                    ))}
                  </Item>
                </MeetingBox>
              </MeetingWrapper>
            </MeetingContent>
          </Meeting>
        ))}

      <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER && canChangeRFI}>
        {!isLoading && meetings.length > 0 && (
          <AddMeetingLink onClick={onShowMeetingDialog}>Ingresar otra reunión</AddMeetingLink>
        )}
      </ProtectByProfile>
    </Meetings>
  )
}

SectioMeetings.defaultProps = {
  isLoading: false,
  meetings: []
}

SectioMeetings.propTypes = {
  isLoading: PropTypes.bool,
  meetings: PropTypes.array,
  onDeleteMeeting: PropTypes.func.isRequired,
  onShowMeetingDialog: PropTypes.func.isRequired
}

export default memo(SectioMeetings)

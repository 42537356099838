import React, { memo, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { WhenHappening } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's whenHappening.
 */
const WhenHappeningSection = props => {
  const { historyProblem, isDisabled, onUpdate, submitError } = props

  const historyProblemRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: historyProblemError,
    onChange: handleHistoryProblemChange,
    value: historyProblemValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'historyProblem', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => historyProblem
  })

  useEffect(() => {
    if (conditionsFields(['IS_INVESTIGATED_UNSET_ERROR', 'WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'])) return

    if (conditionsFields(['WHEN_HAPPENING_UNSET_ERROR', 'WHEN_HAPPENING_MAX_LENGTH_ERROR'])) {
      return historyProblemRef.current?.focus()
    }
  }, [submitError])

  return (
    <WhenHappening>
      <TextArea
        disabled={isDisabled}
        error={historyProblemError}
        inputRef={historyProblemRef}
        label="Historia de la problemática"
        maxCount={500}
        onChange={handleHistoryProblemChange}
        required
        rows={4}
        value={historyProblemValue}
      />
    </WhenHappening>
  )
}

WhenHappeningSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

WhenHappeningSection.propTypes = {
  historyProblem: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(WhenHappeningSection)

import React from 'react'
import {
  Button as BaseButton,
  CheckBox as BaseCheckBox,
  DatePicker as BaseDatePicker,
  Spinner as BaseSpinner
} from '@chilecompra/react-kit/components'
import { Close as BaseClose, Search as BaseSearchIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Assistant = styled(({ removable, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: ${props =>
      props.removable ? 'repeat(4, minmax(180px, 1fr)) auto' : 'repeat(auto-fit, minmax(180px, 1fr))'};

    ${props => props.theme.breakpoints.only('sm')} {
      grid-template-columns: ${props =>
        props.removable ? 'repeat(3, minmax(130px, 1fr))' : 'repeat(auto-fit, minmax(180px, 1fr))'};
    }

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: 1fr;
    }
  }
`
export const AddAssistantsLink = styled(BaseItem)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => props.theme.palette.blue[50]};
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    pointer-events: visible;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const CheckBox = styled(BaseCheckBox)`
  &.MuiFormControlLabel-root {
    align-items: center;
    justify-content: center;

    .MuiButtonBase-root {
      margin: 0 -4px;
      padding: 0;
    }
  }
`

export const Container = styled(BaseContainer)``

export const DatePicker = styled(BaseDatePicker)`
  &.MuiFormControl-root {
    &.calendar__icon {
      .MuiSvgIcon-root {
        height: 100%;
        padding: 0 6px;
      }

      .Mui-disabled .MuiSvgIcon-root {
        cursor: not-allowed;
      }
    }
  }
`

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogForm = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)``

export const ErrorMessage = styled(BaseContainer)`
  &.MuiGrid-container {
    color: ${props => props.theme.palette.red[50]};
    font-size: 14px;
    margin: 0;
    text-align: center;
  }
`

export const Item = styled(BaseItem)``

export const RemoveIcon = styled(BaseClose)`
  &.MuiSvgIcon-root {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
`

export const Search = styled(BaseButton)`
  &.MuiButtonBase-root {
    border-radius: 0;
  }
`

export const SearchIcon = styled(BaseSearchIcon)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.white};
    height: 24px;
    width: 24px;
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} color="white" size="18px" />)``

export const Strong = styled.span`
  font-weight: bold;
`

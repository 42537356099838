import React, { memo } from 'react'
import { Typography } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import { CategoryLink, TreeItem } from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's categoriesLevelTwo.
 */
const CategoriesLevelTwo = props => {
  const { categories, id, name, onGetProductsByCategory } = props

  return (
    <TreeItem
      className="category--one"
      label={
        <Typography fontWeight="bold" variant="body2">
          {name}
        </Typography>
      }
      nodeId={id.toString()}
    >
      {categories.map(category => (
        <TreeItem
          className="category--two"
          key={category.id}
          label={<CategoryLink variant="body2">{category.name}</CategoryLink>}
          nodeId={category.id?.toString()}
          onLabelClick={onGetProductsByCategory(category.id)}
        />
      ))}
    </TreeItem>
  )
}

CategoriesLevelTwo.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string.isRequired }))
    .isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onGetProductsByCategory: PropTypes.func.isRequired
}

export default memo(CategoriesLevelTwo)

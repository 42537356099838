import { DatePicker as BaseDatePicker } from '@chilecompra/react-kit/components'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const DatePicker = styled(BaseDatePicker)`
  &.MuiFormControl-root {
    &.calendar__icon {
      .MuiSvgIcon-root {
        height: 100%;
        padding: 0 6px;
      }

      .Mui-disabled .MuiSvgIcon-root {
        cursor: not-allowed;
      }
    }
  }
`

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogForm = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)`
  &.MuiGrid-container {
    text-align: center;
  }
`

export const ErrorMessage = styled(BaseContainer)`
  &.MuiGrid-container {
    color: ${props => props.theme.palette.red[50]};
    font-size: 14px;
    text-align: center;
  }
`

export const Item = styled(BaseItem)``

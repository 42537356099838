import {
  REACT_APP_FEATURE_FLAG_REQUEST_INNOVATION,
  REACT_APP_FEATURE_FLAG_SHOW_CU,
  REACT_APP_FEATURE_FLAG_SHOW_TRAINING
} from './environment'

export const features = {
  'request.innovation':
    REACT_APP_FEATURE_FLAG_REQUEST_INNOVATION === true || REACT_APP_FEATURE_FLAG_REQUEST_INNOVATION === 'true',
  'show.cu': REACT_APP_FEATURE_FLAG_SHOW_CU === true || REACT_APP_FEATURE_FLAG_SHOW_CU === 'true',
  'show.training': REACT_APP_FEATURE_FLAG_SHOW_TRAINING === true || REACT_APP_FEATURE_FLAG_SHOW_TRAINING === 'true'
}

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  useInput,
  useRadio,
  useViewport
} from '@chilecompra/react-kit'
import { Button, Dialog, Input, Typography } from '@chilecompra/react-kit/components'
import ProtectByFeatures from '../../components/Security/ProtectByFeatures'
import {
  INITIAL_REASON_VALUES,
  OTHER_REASON_OPTIONS,
  REASON_OPTIONS,
  REASON_OPTIONS_DICTIONARY
} from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { onResetCreateForm } from '../RequestCreateForm/RequestCreateForm.actions'
import { onCloseReasonDialog, onUpdateReasonValue } from './RequestReasonDialog.actions'
import {
  DialogButtons,
  DialogContent,
  DialogForm,
  DialogMessage,
  Item,
  OtherReason,
  Radio
} from './RequestReasonDialog.styles'

/**
 * The RequestReasonDialog's container.
 */
const RequestReasonDialog = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { size } = useViewport()

  const { otherReason, otherReasonMessage, reason, showDialog } = useSelector(state => state.requestReasonDialog)

  const { onChange: handleReasonChange, value: reasonValue } = useRadio({
    changeCallback: updatedValue => {
      handleOtherReasonMessageReset()
      const currentReasonMessage = REASON_OPTIONS.find(({ value }) => value === Number(updatedValue)).label

      if (otherReason !== 0) {
        batch(() => {
          dispatch(onUpdateReasonValue({ key: 'otherReason', value: INITIAL_REASON_VALUES.otherReason }))
          dispatch(onUpdateReasonValue({ key: 'otherReasonMessage', value: INITIAL_REASON_VALUES.otherReasonMessage }))
        })
      }

      batch(() => {
        dispatch(onUpdateReasonValue({ key: 'reason', value: Number(updatedValue) }))
        dispatch(onUpdateReasonValue({ key: 'reasonMessage', value: currentReasonMessage }))
      })
    },
    overwriteCallback: () => reason
  })

  const { onChange: handleOtherReasonChange, value: otherReasonValue } = useRadio({
    changeCallback: updatedValue => {
      if (reason !== 0) {
        batch(() => {
          dispatch(onUpdateReasonValue({ key: 'reason', value: INITIAL_REASON_VALUES.reason }))
          dispatch(onUpdateReasonValue({ key: 'reasonMessage', value: INITIAL_REASON_VALUES.reasonMessage }))
        })
      }

      dispatch(onUpdateReasonValue({ key: 'otherReason', value: Number(updatedValue) }))
    },
    overwriteCallback: () => otherReason
  })

  const {
    error: otherReasonMessageError,
    onChange: handleOtherReasonMessageChange,
    onReset: handleOtherReasonMessageReset,
    value: otherReasonMessageValue
  } = useInput({
    changeCallback: updatedValue => {
      dispatch(onUpdateReasonValue({ key: 'otherReasonMessage', value: updatedValue }))
    },
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => otherReasonMessage
  })

  const handleCloseReasonDialog = () => {
    if (otherReasonValue || reasonValue) handleOtherReasonMessageReset()

    dispatch(onCloseReasonDialog())
  }

  const handleCreateRequest = () => {
    dispatch(onResetCreateForm())
    handleCloseReasonDialog()
    navigate({ pathname: routes.create }, { replace: true })
  }

  const disableSubmit = useCallback(() => {
    const hasErrors = otherReasonMessageError !== ''
    const isIncomplete = (!otherReasonValue && !reasonValue) || (!otherReasonMessageValue && !reasonValue)

    return hasErrors || isIncomplete
  }, [otherReasonMessageError, otherReasonValue, otherReasonMessageValue, reasonValue])

  return (
    <Dialog
      disableBackdropClick
      margin="auto"
      maxWidth="600px"
      onClose={handleCloseReasonDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent padding="32px">
        <DialogMessage margin="0 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            ¿Cuál es el objetivo general de tu consulta?
          </Typography>

          <Typography variant="body2">
            Antes de continuar, cuéntanos el objetivo general de tu Consulta al Mercado.
          </Typography>
        </DialogMessage>

        <DialogForm margin="0 0 32px">
          <ProtectByFeatures rule={features => !features['request.innovation']}>
            <Radio
              onChange={handleReasonChange}
              options={REASON_OPTIONS.filter(reason => reason.value !== REASON_OPTIONS_DICTIONARY.REQUEST_INNOVATION)}
              value={reason}
            />
          </ProtectByFeatures>

          <ProtectByFeatures rule={features => features['request.innovation']}>
            <Radio onChange={handleReasonChange} options={REASON_OPTIONS} value={reason} />
          </ProtectByFeatures>

          <OtherReason alignItems="center" margin={size.isTiny ? '-22px 0 0' : '-32px 0 0'}>
            <Item sm={3} xs={4}>
              <Radio
                className="other__reason"
                onChange={handleOtherReasonChange}
                options={OTHER_REASON_OPTIONS}
                value={otherReason}
              />
            </Item>

            <Item sm={6} xs={7}>
              <Input
                disabled={otherReason === 0}
                error={otherReasonMessageError}
                onChange={handleOtherReasonMessageChange}
                required
                value={otherReasonMessage}
              />
            </Item>
          </OtherReason>
        </DialogForm>

        <DialogButtons alignItems="center" justifyContent="center">
          <Button color="primary" disabled={disableSubmit()} onClick={handleCreateRequest} variant="contained">
            Crear consulta
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestReasonDialog

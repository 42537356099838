import React, { memo } from 'react'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { AddQuestionLibraryLink, AddSuccessIcon, Card, WrapperGrid } from './RequestQuestionsLibraryDialog.styles'

/**
 * The RequestQuestionsLibraryDialog's item.
 */
const QuestionItem = props => {
  const { isAdded, message, onAddQuestionLibrary } = props

  return (
    <Card className="question__library" margin="0 0 8px" padding="16px">
      <WrapperGrid alignItems="center">
        <Typography className="question__library__name" variant="body2">
          {message}
        </Typography>

        {isAdded && (
          <AddQuestionLibraryLink disabled={isAdded}>
            <AddSuccessIcon /> Pregunta agregada
          </AddQuestionLibraryLink>
        )}

        {!isAdded && <AddQuestionLibraryLink onClick={onAddQuestionLibrary}>Agregar pregunta</AddQuestionLibraryLink>}
      </WrapperGrid>
    </Card>
  )
}

QuestionItem.propTypes = {
  isAdded: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onAddQuestionLibrary: PropTypes.func.isRequired
}

export default memo(QuestionItem)

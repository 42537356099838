import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkInputErrorMaxLength, useInput, usePagination } from '@chilecompra/react-kit'
import { Dialog, Input, Pagination, Toast, Typography } from '@chilecompra/react-kit/components'
import { INITIAL_ROWS_PER_PAGE } from '../../configs/settings/constants'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { onAddQuestionLibrarySuccess } from '../RequestCreateInnovationForm/RequestCreateInnovationForm.actions'
import { onCloseQuestionsLibraryDialog, onGetQuestionsLibraryThunk } from './RequestQuestionsLibraryDialog.actions'
import QuestionItem from './RequestQuestionsLibraryDialog.item'
import {
  Card,
  Container,
  DialogContent,
  DialogForm,
  DialogMessage,
  Item,
  NotFoundImg,
  SearchIcon,
  SearchLoadingIcon,
  Skeleton
} from './RequestQuestionsLibraryDialog.styles'

/**
 * The RequestQuestionsLibraryDialog's container.
 */
const RequestQuestionsLibraryDialog = () => {
  const dispatch = useDispatch()

  const { questions: questionsSaved } = useSelector(state => state.requestCreateInnovationForm)
  const {
    data,
    error,
    filters: { keyword },
    loading,
    pageCount,
    searching,
    searchingGeneral,
    showDialog
  } = useSelector(state => state.requestQuestionsLibraryDialog)

  const initialRowsPerPage = INITIAL_ROWS_PER_PAGE - 9
  const initialFiltersValues = { initialRowsPerPage, keyword: '', page: 1 }

  const { onChangePage, page, records, setPage } = usePagination({ data })

  const {
    error: keywordError,
    onChange: handleKeywordChange,
    onReset: handleKeywordReset,
    value: keywordValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => keyword
  })

  const handleAddQuestionLibrary = question => () => dispatch(onAddQuestionLibrarySuccess({ ...question }))

  const handleChangePage = (event, page) => {
    onChangePage(event, page)

    const filters = { ...initialFiltersValues, keyword, page }

    dispatch(onGetQuestionsLibraryThunk({ filters, searchingGeneral: true }))
  }

  const handleCloseQuestionsLibraryDialog = () => dispatch(onCloseQuestionsLibraryDialog())

  const handleSearchQuestions = () => {
    if (keywordError !== '' || searching) return

    const filters = { ...initialFiltersValues, keyword: keywordValue }
    setPage(initialFiltersValues.page)
    dispatch(onGetQuestionsLibraryThunk({ filters, searching: true }))
  }

  useEffect(() => {
    if (showDialog) {
      const filters = { ...initialFiltersValues }

      handleKeywordReset()
      setPage(initialFiltersValues.page)
      dispatch(onGetQuestionsLibraryThunk({ filters, loading: true }))
    }
  }, [showDialog])

  return (
    <Dialog
      disableBackdropClick
      margin="auto"
      maxWidth="900px"
      onClose={handleCloseQuestionsLibraryDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent padding="32px">
        <DialogMessage direction="column" margin="0 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            Biblioteca de preguntas
          </Typography>

          <Typography variant="body2">
            Te sugerimos preguntas que otros organismos han utilizado para realizar sus consultas.
          </Typography>
        </DialogMessage>

        <DialogForm direction="column" padding="16px 0">
          <Container justifyContent="flex-end" margin="0 0 8px">
            <Item xs={6}>
              <Input
                disabled={searching || searchingGeneral}
                error={keywordError}
                loading={loading}
                onBlur={handleSearchQuestions}
                onChange={handleKeywordChange}
                onKeyPress={({ key }) => key === 'Enter' && handleSearchQuestions()}
                placeholder="Buscar por palabra clave..."
                suffix={{
                  ...(searching && <SearchLoadingIcon />),
                  ...(!searching && (
                    <SearchIcon disabled={keywordError !== '' || searchingGeneral} onClick={handleSearchQuestions} />
                  ))
                }}
                value={keywordValue}
              />
            </Item>
          </Container>

          {(loading || searching || searchingGeneral) &&
            [...Array(initialRowsPerPage)].map((_, index) => <Skeleton height="50px" key={index} />)}

          {!error && !loading && !searching && !searchingGeneral && data.length === 0 && (
            <Card className="not__found" color="blank" enableBorder padding="24px">
              <Container alignItems="center" direction="column" justifyContent="center">
                <NotFoundImg src="/assets/images/not-found.svg" />

                <Typography fontWeight="bold" margin="16px 0" variant="h4">
                  No se encontraron resultados para tu búsqueda.
                </Typography>

                <Typography color="black3" variant="body2">
                  Intenta con otra palabra clave u otro criterio de búsqueda.
                </Typography>
              </Container>
            </Card>
          )}

          {!error &&
            !loading &&
            !searching &&
            !searchingGeneral &&
            data.length > 0 &&
            data.map(question => (
              <QuestionItem
                isAdded={questionsSaved.some(added => added.id === question.id)}
                key={question.id}
                message={question.message}
                onAddQuestionLibrary={handleAddQuestionLibrary(question)}
              />
            ))}

          {!error && !loading && !searching && data.length > 0 && (
            <Pagination
              count={pageCount}
              disabled={searching || searchingGeneral}
              onChange={handleChangePage}
              page={page}
              records={records}
              size="small"
              total={data.length}
            />
          )}

          {!loading && !searching && !searchingGeneral && error && (
            <Toast
              border
              severity="error"
              textDirection="vertical"
              title="Lo sentimos, estamos presentando problemas con nuestro servidor."
            >
              En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos
              minutos más.
            </Toast>
          )}
        </DialogForm>
      </DialogContent>
    </Dialog>
  )
}

export default RequestQuestionsLibraryDialog

import React, { memo } from 'react'
import { useViewport } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import { AddQuestionLink, AddQuestionLibraryLink, Container, Questions } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's questions.
 */
const QuestionsSection = props => {
  const { children, isDisabled, onAdd, onShowQuestionsLibraryDialog } = props

  const { size } = useViewport()

  return (
    <Questions>
      {children}

      <Container margin={size.isDownToMedium ? '32px 0 0' : '16px 0 0'}>
        <AddQuestionLink disabled={isDisabled} margin="0 16px 0 0" onClick={onAdd}>
          Agregar otra pregunta
        </AddQuestionLink>

        <AddQuestionLibraryLink disabled={isDisabled} onClick={onShowQuestionsLibraryDialog}>
          Biblioteca de preguntas
        </AddQuestionLibraryLink>
      </Container>
    </Questions>
  )
}

QuestionsSection.defaultProps = {
  isDisabled: false
}

QuestionsSection.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onShowQuestionsLibraryDialog: PropTypes.func.isRequired
}

export default memo(QuestionsSection)

import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuthProvider } from '../../containers/AuthProvider/AuthProvider.hook'

/**
 * The ProtectByProfile' component.
 */
const ProtectByProfile = props => {
  const { children, fallback, redirectTo, rule } = props

  const navigate = useNavigate()
  const { userType } = useAuthProvider()
  const result = rule(userType)

  if (!result && redirectTo) navigate(redirectTo, { replace: true })
  if (!result) return fallback(userType)

  return <>{children}</>
}

ProtectByProfile.defaultProps = {
  fallback: () => null,
  redirectTo: '',
  rule: () => true
}

ProtectByProfile.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectByProfile

/**
 * remove dots and middle dash from given DNI
 */
export const clearFormat = value => {
  if (value) return value.replace(/[.-]/g, '')
  else return ''
}

/**
 * function that return verification digit from a DNI
 */
export const computeDv = dni => {
  let mul = 2
  let sum = 0

  if (typeof dni !== 'number') return

  dni = dni.toString()

  for (let i = dni.length - 1; i >= 0; i--) {
    sum = sum + dni.charAt(i) * mul
    mul = (mul + 1) % 8 || 2
  }

  switch (sum % 11) {
    case 1:
      return 'k'
    case 0:
      return 0
    default:
      return 11 - (sum % 11)
  }
}

/**
 * function that validate if dni value is valid or not
 */
export const isValidDni = dni => {
  if (typeof dni !== 'string') return false

  const cDni = clearFormat(dni)
  const number = cDni.substring(0, cDni.length - 1)
  const minLength = 4

  if (cDni.length < minLength) return false

  const cDv = cDni.charAt(cDni.length - 1).toUpperCase()
  const nDni = parseInt(cDni.substr(0, cDni.length - 1))

  if (isNaN(nDni) || isNaN(number)) return false

  return computeDv(nDni).toString().toUpperCase() === cDv
}

import React, { memo, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { WhereHappening } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's whereHappening.
 */
const WhereHappeningSection = props => {
  const { isDisabled, locationProblem, onUpdate, submitError } = props

  const locationProblemRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: locationProblemError,
    onChange: handleLocationProblemChange,
    value: locationProblemValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'locationProblem', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => locationProblem
  })

  useEffect(() => {
    if (conditionsFields(['IS_INVESTIGATED_UNSET_ERROR', 'WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'])) return

    if (conditionsFields(['WHERE_HAPPENING_UNSET_ERROR', 'WHERE_HAPPENING_MAX_LENGTH_ERROR'])) {
      return locationProblemRef.current?.focus()
    }
  }, [submitError])

  return (
    <WhereHappening>
      <TextArea
        disabled={isDisabled}
        error={locationProblemError}
        inputRef={locationProblemRef}
        label="Lugar donde ocurre el problema"
        maxCount={500}
        onChange={handleLocationProblemChange}
        required
        rows={4}
        value={locationProblemValue}
      />
    </WhereHappening>
  )
}

WhereHappeningSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

WhereHappeningSection.propTypes = {
  isDisabled: PropTypes.bool,
  locationProblem: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(WhereHappeningSection)

import { batch } from 'react-redux'
import { REASON_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import { makeActionCreator } from '../../configs/store/utils'
import { onShowSnackbar } from '../SnackbarProvider/SnackbarProvider.actions'
import { checkErrors } from './RequestCreateForm.utils'

export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS'
export const onAddFileSuccess = makeActionCreator(ADD_FILE_SUCCESS, 'payload')
export const onAddFileThunk = payload => async dispatch => {
  const { file } = payload || {}

  return dispatch(onAddFileSuccess({ file, fileName: file.name }))
}

export const ADD_MEETING_SUCCESS = 'ADD_MEETING_SUCCESS'
export const onAddMeetingSuccess = makeActionCreator(ADD_MEETING_SUCCESS, 'payload')
export const onAddMeetingThunk = payload => async (dispatch, getState) => {
  const { closeMeetingCallback = () => undefined, resetMeetingFormCallback = () => undefined } = payload || {}
  const meeting = getState().requestMeetingDialog

  return batch(() => {
    closeMeetingCallback()
    resetMeetingFormCallback()
    dispatch(onAddMeetingSuccess(meeting))
    dispatch(onShowSnackbar({ message: 'Reunion agregada exitosamente', severity: 'success' }))
  })
}

export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const onAddProductSuccess = makeActionCreator(ADD_PRODUCT_SUCCESS, 'payload')

export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
export const onAddQuestionSuccess = makeActionCreator(ADD_QUESTION_SUCCESS, 'payload')

export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS'
export const onRemoveFileSuccess = makeActionCreator(REMOVE_FILE_SUCCESS, 'payload')

export const REMOVE_MEETING_SUCCESS = 'REMOVE_MEETING_SUCCESS'
export const onRemoveMeetingSuccess = makeActionCreator(REMOVE_MEETING_SUCCESS, 'payload')

export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS'
export const onRemoveProductSuccess = makeActionCreator(REMOVE_PRODUCT_SUCCESS, 'payload')

export const REMOVE_QUESTION_SUCCESS = 'REMOVE_QUESTION_SUCCESS'
export const onRemoveQuestionSuccess = makeActionCreator(REMOVE_QUESTION_SUCCESS, 'payload')

export const RESET_CREATE_FORM = 'RESET_CREATE_FORM'
export const onResetCreateForm = makeActionCreator(RESET_CREATE_FORM, 'payload')

export const UPDATE_CREATE_FORM_VALUE = 'UPDATE_CREATE_FORM_VALUE'
export const onUpdateCreateFormValue = makeActionCreator(UPDATE_CREATE_FORM_VALUE, 'payload')

export const UPDATE_DUPLICATED_QUESTION_SUCCESS = 'UPDATE_DUPLICATED_QUESTION_SUCCESS'
export const onUpdateDuplicatedQuestionSuccess = makeActionCreator(UPDATE_DUPLICATED_QUESTION_SUCCESS, 'payload')
export const onUpdateDuplicatedQuestionThunk = payload => async (dispatch, getState) => {
  const { id } = payload || {}
  const { questions } = getState().requestCreateForm

  const updated = questions.find(question => question.id === id)
  const duplicated = questions.some(question => question.id !== updated.id && question.message === updated.message)

  return dispatch(onUpdateDuplicatedQuestionSuccess({ ...payload, key: 'duplicated', value: duplicated }))
}

export const UPDATE_QUESTION_VALUE = 'UPDATE_QUESTION_VALUE'
export const onUpdateQuestionValue = makeActionCreator(UPDATE_QUESTION_VALUE, 'payload')
export const onUpdateQuestionValueThunk = payload => dispatch => {
  const { id } = payload || {}

  return batch(() => {
    dispatch(onUpdateQuestionValue(payload))
    dispatch(onUpdateDuplicatedQuestionThunk({ id }))
  })
}

export const VALIDATE_CREATE_FORM = 'VALIDATE_CREATE_FORM'
export const VALIDATE_CREATE_FORM_ERROR = 'VALIDATE_CREATE_FORM_ERROR'
export const onValidateCreateForm = makeActionCreator(VALIDATE_CREATE_FORM)
export const onValidateCreateFormError = makeActionCreator(VALIDATE_CREATE_FORM_ERROR, 'payload')
export const onValidateCreateFormThunk = payload => async (dispatch, getState) => {
  const { goToCreateStepTwoCallback = () => undefined, showFeedbackConfirmDialogCallbak = () => undefined } =
    payload || {}

  dispatch(onValidateCreateForm())

  try {
    const { additionalInfo, additionalInfoURL, dateTime, description, name, products, questions, willBePublic } =
      getState().requestCreateForm
    const { reason } = getState().requestReasonDialog

    const isInnovation = reason === REASON_OPTIONS_DICTIONARY.REQUEST_INNOVATION

    checkErrors({
      additionalInfo,
      additionalInfoURL,
      dateTime,
      description,
      isInnovation,
      name,
      products,
      questions,
      willBePublic
    })

    if (isInnovation) return goToCreateStepTwoCallback()

    return showFeedbackConfirmDialogCallbak()
  } catch (error) {
    return dispatch(onValidateCreateFormError({ error }))
  }
}

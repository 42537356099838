const routes = {
  authCallback: '/callback',
  create: '/crear-consulta-mercado-paso-uno',
  createStepTwo: '/crear-consulta-mercado-paso-dos',
  detail: '/detalle-consulta-mercado/:code',
  published: '/publicacion-consulta-mercado',
  root: '/',
  search: '/buscador',
  searchPublic: '/buscador-publico'
}

export default routes

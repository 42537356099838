import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  useDatePicker,
  useInput,
  useQuery,
  useSelect
} from '@chilecompra/react-kit'
import { Button, Card, Input, Select, Typography } from '@chilecompra/react-kit/components'
import { INITIAL_FILTERS_VALUES, STATUS_OPTIONS } from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { updateQuery } from '../../modules/utils/searches'
import { onGetRequestsThunk } from '../RequestList/RequestList.actions'
import { useAuthProvider } from './../AuthProvider/AuthProvider.hook'
import { DatePicker, Item, SearchIcon, SearchLoadingIcon, WrapperGrid } from './RequestSearch.styles'

/**
 * The RequestSearch's container.
 */
const RequestSearch = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()

  const {
    filters: { dateFrom, dateTo, keyword, status },
    isDownloading,
    loading,
    searching,
    searchingByGeneral
  } = useSelector(state => state.requestList)

  const generalDisabled = isDownloading || searching || searchingByGeneral
  const generalFilters = { ...INITIAL_FILTERS_VALUES, ...Object.fromEntries(query.entries()), dateFrom, dateTo }

  const {
    error: keywordError,
    onChange: handleKeywordChange,
    value: keywordValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => keyword
  })

  const {
    onChange: handleStatusChange,
    setValue: setStatusValue,
    value: statusValue
  } = useSelect({
    changeCallback: updatedValue => updatedValue === '' && setStatusValue(status),
    overwriteCallback: () => status
  })

  const {
    error: dateFromError,
    onChange: handleDateFromChange,
    value: dateFromValue
  } = useDatePicker({
    errorCallbacks: [checkInputErrorRequired()],
    overwriteCallback: () => dateFrom
  })

  const {
    error: dateToError,
    onChange: handleDateToChange,
    value: dateToValue
  } = useDatePicker({
    errorCallbacks: [checkInputErrorRequired()],
    overwriteCallback: () => dateTo
  })

  const handleSearchRequest = () => {
    if (searching) return

    const filters = {
      ...generalFilters,
      dateFrom: dateFromValue,
      dateTo: dateToValue,
      keyword: keywordValue,
      status: statusValue
    }

    dispatch(onGetRequestsThunk({ filters, searching: true }))
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  const disableSubmit = useCallback(() => {
    const hasErrors = dateFromError !== '' || dateToError !== '' || keywordError !== ''
    const isIncomplete = !dateFromValue || !dateToValue || !statusValue

    return hasErrors || isIncomplete
  }, [dateFromError, dateFromValue, dateToError, dateToValue, keywordError, statusValue])

  return (
    <Card color="default" elevation="1" padding="24px">
      <Typography fontWeight="bold" margin="0 0 24px" variant="h4">
        Búsqueda de Consultas al Mercado
      </Typography>

      <WrapperGrid alignItems="center" spacing={2}>
        <Item lg="auto" md={4} sm={6} xs={12}>
          <Input
            disabled={generalDisabled}
            error={keywordError}
            label="Id o Palabra clave"
            loading={loading}
            onChange={handleKeywordChange}
            onKeyPress={({ key }) => key === 'Enter' && handleSearchRequest()}
            value={keywordValue}
          />
        </Item>

        <Item lg="auto" md={4} sm={6} xs={12}>
          <Select
            disabled={generalDisabled}
            label="Estado"
            loading={loading}
            onChange={handleStatusChange}
            options={STATUS_OPTIONS}
            value={statusValue}
          />
        </Item>

        <Item lg="auto" md={4} sm={6} xs={12}>
          <DatePicker
            className="calendar__icon"
            disabled={generalDisabled}
            error={dateFromError}
            format="dd/MM/yyyy"
            label="Fecha desde"
            loading={loading}
            onChange={handleDateFromChange}
            required
            value={dateFromValue}
          />
        </Item>

        <Item lg="auto" md={4} sm={6} xs={12}>
          <DatePicker
            className="calendar__icon"
            disabled={generalDisabled}
            error={dateToError}
            format="dd/MM/yyyy"
            label="Fecha hasta"
            loading={loading}
            onChange={handleDateToChange}
            required
            value={dateToValue}
          />
        </Item>

        <Item lg="auto" md={4} xs={12}>
          <Button
            color="primary"
            disabled={disableSubmit() || isDownloading || loading || searchingByGeneral}
            loading={searching}
            onClick={handleSearchRequest}
            padding="0 16px"
            variant="contained"
            width="100%"
          >
            {searching ? <SearchLoadingIcon /> : <SearchIcon />} Buscar
          </Button>
        </Item>
      </WrapperGrid>
    </Card>
  )
}

export default RequestSearch

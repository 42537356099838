import { unFormatDNI } from '@chilecompra/react-kit'

/**
 * To check if a DNI corresponds to a natural person or not.
 */
export const isNaturalPerson = value => {
  value = unFormatDNI(value.toString())

  const dni = value.substring(0, value.length - 1)

  return parseInt(dni, 10) < 50000000
}

import React, { memo, useCallback, useEffect, useRef } from 'react'
import { useRadio, useViewport } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import { WILL_BE_REQUIRED_CONCEPT_OPTIONS } from '../../configs/settings/constants'
import { ProofsConceptRequired, Radio } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's proofsConceptRequired.
 */
const ProofsConceptRequiredSection = props => {
  const { isDisabled, onUpdate, submitError, willBeRequiredConcept } = props

  const { size } = useViewport()
  const willBeRequiredConceptdRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const { onChange: handleWillBeRequiredConceptChange, value: willBeRequiredConceptValue } = useRadio({
    changeCallback: updatedValue => onUpdate({ key: 'willBeRequiredConcept', value: Number(updatedValue) }),
    overwriteCallback: () => willBeRequiredConcept
  })

  const willBeRequiredConceptErrorText = useCallback(() => {
    if (!willBeRequiredConcept && submitError?.includes('WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR')) {
      return 'Este campo es obligatorio. Debes completar esta información para poder continuar.'
    }

    return ''
  }, [submitError, willBeRequiredConcept])

  useEffect(() => {
    if (
      conditionsFields([
        'DUPLICATED_QUESTION_ERROR',
        'DUPLICATED_QUESTION_OPTION_ERROR',
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR',
        'QUESTION_MAX_OPTION_LENGTH_ERROR',
        'QUESTION_OPTION_EMPTY_MESSAGE_ERROR'
      ])
    ) {
      return
    }

    if (conditionsFields(['WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'])) {
      const element = willBeRequiredConceptdRef.current
      const headerOffset = size.isDownToSmall ? 108 : 32
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      return window.scrollTo({ top: offsetPosition })
    }
  }, [submitError])

  return (
    <ProofsConceptRequired ref={willBeRequiredConceptdRef}>
      <Radio
        disabled={isDisabled}
        error={willBeRequiredConceptErrorText()}
        onChange={handleWillBeRequiredConceptChange}
        options={WILL_BE_REQUIRED_CONCEPT_OPTIONS}
        value={willBeRequiredConceptValue}
      />
    </ProofsConceptRequired>
  )
}

ProofsConceptRequiredSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

ProofsConceptRequiredSection.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  willBeRequiredConcept: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default memo(ProofsConceptRequiredSection)

import { customizeError } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import { getCategories, getCategoriesLevelTwo, getProducts, getProductsByCategory } from '../../services/products'

export const CLOSE_PRODUCT_DIOALOG = 'CLOSE_PRODUCT_DIOALOG'
export const onCloseProductDialog = makeActionCreator(CLOSE_PRODUCT_DIOALOG)

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const onGetCategories = makeActionCreator(GET_CATEGORIES)
export const onGetCategoriesError = makeActionCreator(GET_CATEGORIES_ERROR, 'payload')
export const onGetCategoriesSuccess = makeActionCreator(GET_CATEGORIES_SUCCESS, 'payload')
export const onGetCategoriesThunk = () => async dispatch => {
  dispatch(onGetCategories())

  try {
    const { data } = await getCategories()

    const categories = data.payload?.clasificadores?.map(category => ({ name: category.nombre, value: category.id }))

    return dispatch(onGetCategoriesSuccess({ categories }))
  } catch (error) {
    return dispatch(
      onGetCategoriesError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_CATEGORIES_LEVEL_TWO = 'GET_CATEGORIES_LEVEL_TWO'
export const GET_CATEGORIES_LEVEL_TWO_ERROR = 'GET_CATEGORIES_LEVEL_TWO_ERROR'
export const GET_CATEGORIES_LEVEL_TWO_SUCCESS = 'GET_CATEGORIES_LEVEL_TWO_SUCCESS'
export const onGetCategoriesLevelTwo = makeActionCreator(GET_CATEGORIES_LEVEL_TWO)
export const onGetCategoriesLevelTwoError = makeActionCreator(GET_CATEGORIES_LEVEL_TWO_ERROR, 'payload')
export const onGetCategoriesLevelTwoSuccess = makeActionCreator(GET_CATEGORIES_LEVEL_TWO_SUCCESS, 'payload')
export const onGetCategoriesLevelTwoThunk = payload => async dispatch => {
  const { categoryId } = payload || {}

  dispatch(onGetCategoriesLevelTwo())

  try {
    const { data } = await getCategoriesLevelTwo({ category: categoryId })

    const categoriesLevelTwo = data.payload?.categorias?.map(category => ({
      categories: category.categorias.map(category => ({ id: category.id, name: category.nombre })),
      name: category.nombre,
      value: category.id
    }))

    return dispatch(onGetCategoriesLevelTwoSuccess({ categoriesLevelTwo }))
  } catch (error) {
    return dispatch(
      onGetCategoriesLevelTwoError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const onGetProducts = makeActionCreator(GET_PRODUCTS)
export const onGetProductsError = makeActionCreator(GET_PRODUCTS_ERROR, 'payload')
export const onGetProductsSuccess = makeActionCreator(GET_PRODUCTS_SUCCESS, 'payload')
export const onGetProductsThunk = payload => async dispatch => {
  const { keyword } = payload || {}

  dispatch(onGetProducts())

  try {
    const { data } = await getProducts({ keyword })

    const products = data.payload?.categorias?.map(category => ({
      name: category.nombre,
      products: category.productos.map(product => ({
        categoryId: product.categoria,
        id: product.id,
        name: product.nombre
      }))
    }))

    dispatch(onGetProductsSuccess({ products }))
  } catch (error) {
    if (error.code === 404) return dispatch(onGetProductsSuccess({ products: [] }))

    return dispatch(
      onGetProductsError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY'
export const GET_PRODUCTS_BY_CATEGORY_ERROR = 'GET_PRODUCTS_BY_CATEGORY_ERROR'
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS = 'GET_PRODUCTS_BY_CATEGORY_SUCCESS'
export const onGetProductsByCategory = makeActionCreator(GET_PRODUCTS_BY_CATEGORY)
export const onGetProductsByCategoryError = makeActionCreator(GET_PRODUCTS_BY_CATEGORY_ERROR, 'payload')
export const onGetProductsByCategorySuccess = makeActionCreator(GET_PRODUCTS_BY_CATEGORY_SUCCESS, 'payload')
export const onGetProductsByCategoryThunk = payload => async dispatch => {
  const { categoryId } = payload || {}

  dispatch(onGetProductsByCategory())

  try {
    const { data } = await getProductsByCategory({ category: categoryId })

    const productsByCategory = data.payload?.productos?.map(product => ({
      categoryId: product.categoria,
      name: product.nombre,
      value: product.id
    }))

    return dispatch(onGetProductsByCategorySuccess({ productsByCategory }))
  } catch (error) {
    return dispatch(
      onGetProductsByCategoryError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const RESET_PRODUCTS_BY_CATEGORY = 'RESET_PRODUCTS_BY_CATEGORY'
export const onResetProductsByCategory = makeActionCreator(RESET_PRODUCTS_BY_CATEGORY, 'payload')

export const RESET_PRODUCT_FORM = 'RESET_PRODUCT_FORM'
export const onResetProductForm = makeActionCreator(RESET_PRODUCT_FORM, 'payload')

export const SHOW_PRODUCT_DIALOG = 'SHOW_PRODUCT_DIALOG'
export const onShowProductDialog = makeActionCreator(SHOW_PRODUCT_DIALOG)

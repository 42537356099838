import React, { forwardRef } from 'react'
import { Skeleton as SkeletonBase } from '@chilecompra/react-kit/components'
import { AccessTime as ClockIcon, Help as HelpIcon } from '@material-ui/icons'
import { MuiPickersUtilsProvider, TimePicker as TimePickerBase } from '@material-ui/pickers'
import styled, { createGlobalStyle } from 'styled-components'

export const BaseTimePicker = styled(({ className, size, success, textAlign, ...props }) => (
  <TimePickerDialog {...props} classes={{ root: className }} />
))`
  &.MuiFormControl-root {
    width: 100%;
  }

  &.MuiFormControl-root .MuiInputBase-root {
    background-clip: padding-box;
    background: ${props => props.theme.palette.brand.white};
    border-radius: 0.25rem;
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.gray[85])};
    box-sizing: border-box;
    color: ${props => props.theme.palette.gray[20]};
    cursor: pointer;
    display: flex;
    font-size: ${props => {
      if (props.size === 'large') return '16px'

      if (props.size === 'small') return '12px'

      return '14px'
    }};
    height: ${props => {
      if (props.size === 'large') return 'calc(1.5em + 0.75rem + 14px)'

      if (props.size === 'small') return 'calc(1.5em + 0.75rem)'

      return 'calc(1.5em + 0.75rem + 7px)'
    }};
    line-height: ${props => {
      if (props.size === 'small') return '14px'

      return '16px'
    }};
    overflow: hidden;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
  }

  &.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
    box-sizing: border-box;
    cursor: pointer;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding: 0.375rem 0.75rem;
    text-align: ${props => props.textAlign};
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: auto;

    &::placeholder {
      color: ${props => props.theme.palette.gray[75]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: ${props => {
        if (props.size === 'large') return '16px'

        if (props.size === 'small') return '12px'

        return '14px'
      }};
      line-height: ${props => {
        if (props.size === 'small') return '14px'

        return '16px'
      }};
      opacity: 1;
      transition: none;
    }
  }

  &.MuiFormControl-root .MuiInputBase-root.Mui-focused {
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.blue[50])};
    box-shadow: 0 0 0 0.2rem
      ${props => (props.success ? props.theme.palette.green[50.4] : props.theme.palette.blue[50.25])};
  }

  &.MuiFormControl-root .MuiInputBase-root.Mui-disabled {
    background: ${props => props.theme.palette.gray[95]};
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.gray[85])};
    color: ${props => props.theme.palette.brand.disableFont};
    cursor: not-allowed;
  }

  &.MuiFormControl-root .MuiInputBase-root.Mui-disabled {
    .MuiInputBase-input,
    .MuiSvgIcon-root {
      cursor: not-allowed;
    }
  }

  &.MuiFormControl-root .MuiInputBase-root.Mui-error {
    border: 1px solid ${props => props.theme.palette.red[50]};
  }

  &.MuiFormControl-root .MuiInputBase-root.Mui-error.Mui-focused {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.palette.red[50.5]};
  }

  &.MuiFormControl-root .MuiInputBase-root.MuiInput-underline:before,
  &.MuiFormControl-root .MuiInputBase-root.MuiInput-underline:hover:not(.Mui-disabled):before,
  &.MuiFormControl-root .MuiInputBase-root.MuiInput-underline:after {
    border: none;
    border-bottom: none;
  }
`

export const BaseTimePickerProvider = styled(MuiPickersUtilsProvider)``

export const BottomMessage = styled(({ size, ...props }) => <div {...props} />)`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => {
    if (props.size === 'large') return '14px'

    if (props.size === 'small') return '11px'

    return '12px'
  }};
  line-height: 14px;
  margin: 3px 0 0;
  min-height: 20px;
`

export const Error = styled.section`
  color: ${props => props.theme.palette.red[50]};
`

export const GlobalStyles = createGlobalStyle`
  .MuiDialog-root .MuiBackdrop-root {
    background: ${props => props.theme.palette.gray[50.5]};
  }
`

export const Info = styled.section`
  color: ${props => props.theme.palette.gray[50]};
`

export const Label = styled(({ size, ...props }) => <label {...props} />)`
  color: ${props => props.theme.palette.gray[50]};
  font-size: ${props => {
    if (props.size === 'large') return '14px'

    return '12px'
  }};
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: ${props => {
    if (props.size === 'small') return '14px'

    return '16px'
  }};
  margin: 0 0 0.5rem;
  text-transform: uppercase;
`

export const Placeholder = styled(({ size, textAlign, ...props }) => <section {...props} />)`
  align-items: center;
  box-sizing: border-box;
  color: ${props => props.theme.palette.gray[75]};
  display: flex;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => {
    if (props.size === 'large') return '16px'

    if (props.size === 'small') return '12px'

    return '14px'
  }};
  height: 100%;
  justify-content: ${props => {
    if (props.textAlign === 'end') return 'flex-end'

    if (props.textAlign === 'start') return 'flex-start'

    return props.textAlign
  }};
  left: 0;
  line-height: ${props => {
    if (props.size === 'small') return '14px'

    return '16px'
  }};
  padding: 0 30px 0 0.75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: none;
  user-select: none;
  width: 100%;
`

export const Required = styled(({ size, ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.gray[50]};
  font-size: ${props => {
    if (props.size === 'large') return '1.1rem'

    return '1rem'
  }};
  margin: 0 0 0 4px;
`

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width};
`

export const Skeleton = styled(({ size, ...props }) => <SkeletonBase {...props} />)`
  &.MuiSkeleton-root {
    height: ${props => {
      if (props.size === 'large') return '50px'

      if (props.size === 'small') return '30px'

      return '40px'
    }};
  }
`

export const Suffix = styled(({ disabled, ...props }) => <div {...props} />)`
  align-items: center;
  background: ${props => (props.disabled ? 'transparent' : props.theme.palette.brand.white)};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 41px;
`

export const TimeIcon = styled(({ size, ...props }) => <ClockIcon {...props} />)`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.gray[50]};
    font-size: ${props => {
      if (props.size === 'large') return '26px'

      if (props.size === 'small') return '20px'

      return '24px'
    }};
  }
`

export const TimePickerDialog = styled(({ className, size, success, textAlign, ...props }) => {
  return (
    <TimePickerBase
      {...props}
      PopoverProps={{
        ...props.PopoverProps,
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        classes: { ...props.PopoverProps?.classes, paper: className },
        transformOrigin: { horizontal: 'left', vertical: 'top' }
      }}
      variant="inline"
    />
  )
})`
  &.MuiPopover-paper {
    box-shadow: 0 10px 20px 0 ${props => props.theme.palette.blue[60.07]};
  }

  &.MuiPopover-paper .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background: ${props => props.theme.palette.blue[50]};
    }

    .MuiPickersToolbar-toolbar .MuiButtonBase-root:hover {
      background: transparent;
    }

    .MuiTypography-subtitle1,
    .MuiTypography-body1 {
      font-size: 16px;
    }

    .MuiTypography-h4 {
      font-size: 34px;
    }

    .MuiTypography-caption {
      font-size: 14px;
    }

    .MuiTypography-body2 {
      font-size: 14px;
    }

    .MuiTypography-h5 {
      font-size: 24px;
    }

    .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
      font-size: 24px;
    }
  }

  &.MuiPopover-paper .MuiPickersClock-container {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersClock-pin {
    background: ${props => props.theme.palette.blue[50]};
  }

  &.MuiPopover-paper .MuiPickersClockPointer-pointer {
    .MuiPickersClockPointer-thumb {
      border: 14px solid ${props => props.theme.palette.blue[50]};
    }
    background: ${props => props.theme.palette.blue[50]};
  }

  &.MuiPopover-paper .MuiPickersClockNumber-clockNumberSelected {
    background: ${props => props.theme.palette.blue[50]};
  }
`

export const TooltipIcon = styled(forwardRef(({ size, ...props }, ref) => <HelpIcon {...props} ref={ref} />))`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.gray[50]};
    font-size: ${props => {
      if (props.size === 'large') return '1rem'

      return '0.8rem'
    }};
    margin: 0 0 0 4px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import RFILayout from '../components/Layouts/RFILayout'
import ProtectByProfile from '../components/Security/ProtectByProfile'
import { USER_TYPES } from '../configs/settings/constants'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import RequestCreateContainer from '../containers/RequestCreate/RequestCreate.container'
import RequestFiltersContainer from '../containers/RequestFilters/RequestFilters.container'
import RequestListContainer from '../containers/RequestList/RequestList.container'
import RequestReasonDialogContainer from '../containers/RequestReasonDialog/RequestReasonDialog.container'
import RequestSearchContainer from '../containers/RequestSearch/RequestSearch.container'
import NotFoundPage from './NotFound'

/**
 * The Request's private page.
 */
const RequestPrivate = () => (
  <>
    <AuthProviderContainer>
      <ProtectByProfile
        fallback={() => <NotFoundPage />}
        rule={userType => userType === USER_TYPES.BUYER || userType === USER_TYPES.SELLER}
      >
        <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
          <RFILayout
            create={<RequestCreateContainer />}
            filters={<RequestFiltersContainer />}
            result={<RequestListContainer />}
            search={<RequestSearchContainer />}
          />
          <RequestReasonDialogContainer />
        </BrandLayout>
      </ProtectByProfile>
    </AuthProviderContainer>

    <ScrollRestoration />
  </>
)

export default RequestPrivate

import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Typography } from '@chilecompra/react-kit/components'
import { onResetReasonForm, onShowReasonDialog } from '../RequestReasonDialog/RequestReasonDialog.actions'
import { Card, Container, CreateRequestLink } from './RequestCreate.styles'

/**
 * The RequestCreate's container.
 */
const RequestCreate = () => {
  const dispatch = useDispatch()

  const { isDownloading, loading, searching, searchingByGeneral } = useSelector(state => state.requestList)

  const generalDisabled = isDownloading || loading || searching || searchingByGeneral

  const handleShowReasonDialog = () => {
    batch(() => {
      dispatch(onResetReasonForm())
      dispatch(onShowReasonDialog())
    })
  }

  return (
    <Card color="blank" enableBorder padding="24px">
      <Container alignItems="flex-start" direction="column" justifyContent="center">
        <Typography fontWeight="bold" margin=" 0 0 24px" variant="h4">
          Crear Consulta al Mercado
        </Typography>

        <Typography color="black3" margin=" 0 0 16px" variant="body2">
          Publica tus preguntas a través de nuestro formulario en línea.
        </Typography>

        <CreateRequestLink disabled={generalDisabled} onClick={handleShowReasonDialog}>
          Crear Consulta
        </CreateRequestLink>
      </Container>
    </Card>
  )
}

export default RequestCreate

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider, initializeAnalytics, initializeLogger } from '@chilecompra/react-kit'
import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_TASK_MANAGER_ID,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_SNIPPET_VERSION
} from './configs/settings/environment'
import storeSingleton from './configs/store/store'
import SnackbarProvider from './containers/SnackbarProvider/SnackbarProvider.container'
import Routing from './Routing'

initializeAnalytics({
  gaKey: REACT_APP_GOOGLE_ANALYTICS_KEY,
  gtmId: REACT_APP_GOOGLE_TASK_MANAGER_ID,
  hotjarId: REACT_APP_HOTJAR_ID,
  hotjarSV: REACT_APP_HOTJAR_SNIPPET_VERSION
})

initializeLogger({ environment: REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev' })

const element = document.getElementById('__root')
const root = createRoot(element)
const store = storeSingleton()

const tree = (
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <RouterProvider router={Routing} />

        <SnackbarProvider />
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>
)

root.render(tree)

import {
  CLOSE_REASON_DIALOG,
  RESET_REASON_FORM,
  SHOW_REASON_DIALOG,
  UPDATE_REASON_VALUE
} from './RequestReasonDialog.actions'

const requestReasonDialogState = {
  otherReason: 0,
  otherReasonMessage: '',
  reason: 0,
  reasonMessage: '',
  showDialog: false
}

/**
 * The RequestReasonDialog's reducer.
 */
const requestReasonDialogReducer = (state = requestReasonDialogState, { payload, type }) => {
  switch (type) {
    case CLOSE_REASON_DIALOG: {
      return { ...state, showDialog: false }
    }
    case RESET_REASON_FORM: {
      return { ...state, ...requestReasonDialogState }
    }
    case SHOW_REASON_DIALOG: {
      return { ...state, showDialog: true }
    }
    case UPDATE_REASON_VALUE: {
      return { ...state, [payload.key]: payload.value }
    }
    default: {
      return state
    }
  }
}

export default requestReasonDialogReducer

import {
  GET_PUBLIC_USER_AUTH_INFO,
  GET_PUBLIC_USER_AUTH_INFO_ERROR,
  GET_PUBLIC_USER_AUTH_INFO_SUCCESS
} from './PublicUserAuthProvider.actions'

const publicUserAuthProviderState = { dni: '', error: undefined, loading: true, name: '', token: undefined }

/**
 * The PublicUserAuthProvider's reducer.
 */
const publicUserAuthProviderReducer = (state = publicUserAuthProviderState, { payload, type }) => {
  switch (type) {
    case GET_PUBLIC_USER_AUTH_INFO: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_PUBLIC_USER_AUTH_INFO_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_PUBLIC_USER_AUTH_INFO_SUCCESS: {
      return { ...state, ...payload, error: undefined, loading: false }
    }
    default: {
      return state
    }
  }
}

export default publicUserAuthProviderReducer

import React, { memo, useCallback, useEffect, createRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired, useInput } from '@chilecompra/react-kit'
import { Input } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { QuestionMultipleChoice, RadioUncheckedIcon, RemoveOptionIcon } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's questionMultipleChoice.
 */
const QuestionMultipleChoiceItem = props => {
  const { duplicatedError, index, isDisabled, message, onRemove, onUpdate, removable, submitError } = props

  const messageRef = createRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: messageError,
    onChange: handleMessageChange,
    value: messageValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'message', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(200)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => message
  })

  const messageErrorText = useCallback(() => {
    if (messageError !== '') return messageError
    if (duplicatedError) return 'No puedes ingresar dos veces la misma opción.'
  }, [duplicatedError, messageError])

  useEffect(() => {
    if (
      conditionsFields([
        'DUPLICATED_QUESTION_ERROR',
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR'
      ])
    )
      return

    if (
      conditionsFields([
        'DUPLICATED_QUESTION_OPTION_ERROR',
        'QUESTION_MAX_OPTION_LENGTH_ERROR',
        'QUESTION_OPTION_EMPTY_MESSAGE_ERROR'
      ]) &&
      messageRef.current.getAttribute('aria-invalid') === 'true'
    ) {
      return messageRef.current?.focus()
    }
  }, [messageRef, submitError])

  return (
    <QuestionMultipleChoice removable={removable}>
      <RadioUncheckedIcon />

      <Input
        className="multiple__choice"
        disabled={isDisabled}
        error={messageErrorText()}
        inputRef={messageRef}
        onChange={handleMessageChange}
        placeholder={`Opción ${index}`}
        required
        value={messageValue}
      />

      {removable && <RemoveOptionIcon disabled={isDisabled} onClick={onRemove} />}
    </QuestionMultipleChoice>
  )
}

QuestionMultipleChoiceItem.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

QuestionMultipleChoiceItem.propTypes = {
  duplicatedError: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(QuestionMultipleChoiceItem)

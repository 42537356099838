import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { CREATE_SECTION_DICTIONARY } from '../../configs/settings/constants'
import AttachedFilesSection from './RequestCreateForm.attachedFiles'
import BasicInformationSection from './RequestCreateForm.basicInformation'
import ClosingDateSection from './RequestCreateForm.closingDate'
import MeetingItem from './RequestCreateForm.meeting'
import MeetingsSection from './RequestCreateForm.meetings'
import ProductItem from './RequestCreateForm.product'
import ProductsSection from './RequestCreateForm.products'
import QuestionItem from './RequestCreateForm.question'
import QuestionsSection from './RequestCreateForm.questions'

/**
 * The RequestCreateForm's sections.
 */
const SectionsForm = props => {
  const {
    attachedFiles,
    dateTime,
    description,
    isDisabled,
    isInnovation,
    meetings,
    name,
    onAddFile,
    onAddQuestion,
    onRemoveFile,
    onRemoveMeeting,
    onRemoveProduct,
    onRemoveQuestion,
    onShowMeetingDialog,
    onShowProductDialog,
    onUpdate,
    onUpdateQuestion,
    products,
    questions,
    sectionName,
    submitError
  } = props

  return (
    <>
      {sectionName === CREATE_SECTION_DICTIONARY.BASIC_INFORMATION && (
        <BasicInformationSection
          description={description}
          isDisabled={isDisabled}
          name={name}
          onUpdate={onUpdate}
          submitError={submitError}
        />
      )}

      {sectionName === CREATE_SECTION_DICTIONARY.CLOSING_DATE && (
        <ClosingDateSection dateTime={dateTime} isDisabled={isDisabled} onUpdate={onUpdate} submitError={submitError} />
      )}

      {sectionName === CREATE_SECTION_DICTIONARY.PRODUCTS && (
        <ProductsSection
          isDisabled={isDisabled}
          onShowProductDialog={onShowProductDialog}
          productsLength={products.length}
          submitError={submitError}
        >
          {products.map(product => (
            <ProductItem
              isDisabled={isDisabled}
              key={product.id}
              name={product.name}
              onRemove={onRemoveProduct(product.id)}
              productId={product.productId}
            />
          ))}
        </ProductsSection>
      )}

      {!isInnovation && sectionName === CREATE_SECTION_DICTIONARY.QUESTIONS && (
        <QuestionsSection isDisabled={isDisabled} onAdd={onAddQuestion}>
          {questions.map((question, index) => (
            <QuestionItem
              duplicatedError={!!question.duplicated}
              isDisabled={isDisabled}
              key={question.id}
              message={question.message}
              onRemove={onRemoveQuestion(question.id)}
              onUpdate={onUpdateQuestion(question.id)}
              removable={index > 0}
              submitError={submitError}
            />
          ))}
        </QuestionsSection>
      )}

      {sectionName === CREATE_SECTION_DICTIONARY.ATTACH_FILES && (
        <AttachedFilesSection
          attachedFiles={attachedFiles}
          isDisabled={isDisabled}
          onAdd={onAddFile}
          onRemove={onRemoveFile}
        />
      )}

      {sectionName === CREATE_SECTION_DICTIONARY.MEETINGS && (
        <MeetingsSection
          isDisabled={isDisabled}
          meetingsLength={meetings.length}
          onShowMeetingDialog={onShowMeetingDialog}
        >
          {meetings.map(meeting => (
            <MeetingItem
              assistants={meeting.assistants}
              businessDni={meeting.businessDni}
              businessName={meeting.businessName}
              dateTime={meeting.dateTime}
              isDisabled={isDisabled}
              key={meeting.id}
              meetingOtherType={meeting.meetingOtherType}
              meetingType={meeting.meetingType}
              onRemove={onRemoveMeeting(meeting.id)}
              reasonMeeting={meeting.reasonMeeting}
            />
          ))}
        </MeetingsSection>
      )}
    </>
  )
}

SectionsForm.defaultProps = {
  attachedFiles: [],
  isDisabled: false,
  isInnovation: false,
  meetings: [],
  products: [],
  submitError: undefined
}

SectionsForm.propTypes = {
  attachedFiles: PropTypes.array,
  dateTime: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInnovation: PropTypes.bool,
  meetings: PropTypes.array,
  name: PropTypes.string.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onRemoveMeeting: PropTypes.func.isRequired,
  onRemoveProduct: PropTypes.func.isRequired,
  onRemoveQuestion: PropTypes.func.isRequired,
  onShowMeetingDialog: PropTypes.func.isRequired,
  onShowProductDialog: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateQuestion: PropTypes.func.isRequired,
  products: PropTypes.array,
  questions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, message: PropTypes.string })).isRequired,
  sectionName: PropTypes.string.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(SectionsForm)

import { makeActionCreator } from '../../configs/store/utils'

export const CLOSE_REASON_DIALOG = 'CLOSE_REASON_DIALOG'
export const onCloseReasonDialog = makeActionCreator(CLOSE_REASON_DIALOG)

export const RESET_REASON_FORM = 'RESET_REASON_FORM'
export const onResetReasonForm = makeActionCreator(RESET_REASON_FORM)

export const SHOW_REASON_DIALOG = 'SHOW_REASON_DIALOG'
export const onShowReasonDialog = makeActionCreator(SHOW_REASON_DIALOG)

export const UPDATE_REASON_VALUE = 'UPDATE_REASON_VALUE'
export const onUpdateReasonValue = makeActionCreator(UPDATE_REASON_VALUE, 'payload')

import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import ProtectByProfile from '../components/Security/ProtectByProfile'
import { USER_TYPES } from '../configs/settings/constants'
import router from '../configs/settings/routes'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import RequestCreateFormContainer from '../containers/RequestCreateForm/RequestCreateForm.container'
import RequestFeedbackConfirmDialogContainer from '../containers/RequestFeedbackConfirmDialog/RequestFeedbackConfirmDialog.container'
import RequestFeedbackErrorDialogContainer from '../containers/RequestFeedbackErrorDialog/RequestFeedbackErrorDialog.container'
import RequestMeetingDialogContainer from '../containers/RequestMeetingDialog/RequestMeetingDialog.container'
import RequestProductDialogContainer from '../containers/RequestProductDialog/RequestProductDialog.container'

/**
 * The CreateRequest's page.
 */
const CreateRequest = () => (
  <>
    <AuthProviderContainer>
      <ProtectByProfile redirectTo={router.search} rule={userType => userType === USER_TYPES.BUYER}>
        <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
          <RequestCreateFormContainer />
          <RequestFeedbackConfirmDialogContainer />
          <RequestFeedbackErrorDialogContainer />
          <RequestMeetingDialogContainer />
          <RequestProductDialogContainer />
        </BrandLayout>
      </ProtectByProfile>
    </AuthProviderContainer>

    <ScrollRestoration />
  </>
)

export default CreateRequest

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '@chilecompra/react-kit'
import { Button, Dialog, Table, TableCell, TableRow, Typography } from '@chilecompra/react-kit/components'
import { onCloseHistoryDialog } from '../RequestHistory/RequestHistory.actions'
import { DialogButtons, DialogContent, DialogTable, DialogMessage, TableContainer } from './RequestHistory.styles'

/**
 * The RequestHistory's container.
 */
const RequestHistory = () => {
  const dispatch = useDispatch()

  const { histories, code } = useSelector(state => state.requestDetailInfo)
  const { showDialog } = useSelector(state => state.requestHistory)

  const handleCloseHistoryDialog = () => dispatch(onCloseHistoryDialog())

  return (
    <Dialog
      disableBackdropClick
      disableCloseIcon
      margin="auto"
      maxWidth="800px"
      onClose={handleCloseHistoryDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent padding="32px">
        <DialogMessage direction="column" margin="0 0 32px">
          <Typography fontWeight="bold" margin="0 0 8px" variant="h4">
            Historial Consulta al Mercado
          </Typography>

          <Typography variant="body2">{code || ''}</Typography>
        </DialogMessage>

        <DialogTable margin="0 0 32px">
          <TableContainer>
            <Table accessibility={{ label: 'Historial de consula al mercado' }} enableStripped height="300px">
              <TableRow enableHead>
                <TableCell padding="13px 16px" width="100px">
                  Usuario
                </TableCell>

                <TableCell padding="13px 16px" width="160px">
                  Organismo comprador
                </TableCell>

                <TableCell padding="13px 16px" width="140px">
                  Fecha
                </TableCell>

                <TableCell padding="13px 16px">Acción</TableCell>
              </TableRow>

              {histories.map(history => (
                <TableRow key={history.id}>
                  <TableCell disableBottomBorder padding="16px 16px" width="100px">
                    {history.usrCode?.name}
                  </TableCell>

                  <TableCell disableBottomBorder padding="16px 16px" width="160px">
                    {history.orgCode?.name}
                  </TableCell>

                  <TableCell disableBottomBorder padding="16px 16px" width="140px">
                    {history.registerDate && formatDate(history.registerDate, 'dd/MM/yyyy HH:mm')}
                  </TableCell>

                  <TableCell disableBottomBorder padding="16px 16px">
                    {history.comment}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </DialogTable>

        <DialogButtons alignItems="center" direction="column" justifyContent="center">
          <Button color="default" onClick={handleCloseHistoryDialog}>
            Cerrar
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestHistory

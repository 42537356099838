import { factoryService, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit'
import { REACT_APP_API_BASE_URL } from '../configs/settings/environment'

/**
 * Gets the questions library data.
 */
export const getQuestionsLibrary = factoryService(({ initialRowsPerPage, keyword, page }) => {
  const params = new URLSearchParams({
    ...(keyword && { palabraClave: keyword }),
    elementosTotales: initialRowsPerPage,
    pagina: page
  }).toString()

  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/biblioteca-preguntas?${params}`)
})

/**
 * Post send the request question.
 */
export const postQuestions = factoryService(({ questions, requestId }) => {
  const body = questions.map(question => ({ detalle: question.message }))

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/preguntas`, { body })
})

/**
 * Post send the request question innovation.
 */
export const postQuestionsInnovation = factoryService(({ questionsInnovation, requestId }) => {
  const body = questionsInnovation.map(question => ({
    crearOpcionesMultiplesList: question.multipleChoiceOptions.map(option => ({ detalle: option.message })),
    detalle: question.message,
    tipoPregunta: question.type
  }))

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/preguntas/innovacion`, { body })
})

/**
 * Post send the request response in question.
 */
export const postResponses = factoryService(({ message, questionId, requestId, token }) => {
  const body = { detalle: message, tokenClaveUnica: token }

  return postJsonRequest(
    `${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/preguntas/${questionId}/respuestas`,
    { body }
  )
})

import { sub } from 'date-fns'

export const ACCEPTED_DOCUMENT_TYPES =
  'Application/x-ms-shortcut, Image/heic, application/octet-stream, application/oxps, application/pdf, application/rtf, application/vnd.ms-excel, application/vnd.ms-excel.sheet.binary.macroenabled.12, application/vnd.ms-excel.sheet.macroenabled.12, application/vnd.ms-excel.template.macroenabled.12, application/vnd.ms-outlook, application/vnd.ms-powerpoint, application/vnd.ms-xpsdocument, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/x-7z-compressed, application/x-7z-compressed, application/x-rar-compressed, application/zip, image/bmp, image/gif, image/heif, image/jpeg, image/png,application/msword, image/tiff, image/vnd.dwg, image/webp, text/csv, text/html, text/html, text/plain, video/mp4'

export const ACCEPTED_FILTERS_KEYS = [
  'dateFrom',
  'dateTo',
  'isItems',
  'isParticipated',
  'keyword',
  'order',
  'orgBuyer',
  'page',
  'status'
]

export const ADDITIONAL_INFO_OPTIONS = [
  { id: 1, label: 'Si', value: 100 },
  { id: 2, label: 'No', value: 200 }
]

export const ADDITIONAL_INFO_DICTIONARY = { NO: 200, YES: 100 }

export const CAN_CHANGE_RFI_ROLES = [
  { id: 11, name: 'Operador' },
  { id: 16, name: 'Supervisor' },
  { id: 66, name: 'Abogado' }
]

export const CAN_CREATE_RFI_ROLES = [
  { id: 11, name: 'Operador' },
  { id: 16, name: 'Supervisor' }
]

export const CREATE_INNOVATION_SECTION_DICTIONARY = {
  HAS_INVESTIGATED: 'se-ha-investigado-antes',
  PROOFS_CONCEPT_REQUIRED: 'se-requieren-pruebas-de-concepto',
  QUESTIONS: 'preguntas-a-consultar',
  WHAT_HAPPENING: 'que-esta-sucediendo',
  WHEN_HAPPENING: 'desde-cuando-esta-sucediendo',
  WHERE_HAPPENING: 'donde-esta-sucediendo',
  WHOM_IS_A_PROBLEM: 'para-quien-o-quienes-es-un-problema'
}

export const CREATE_SECTION_DICTIONARY = {
  ATTACH_FILES: 'adjuntar-archivos',
  BASIC_INFORMATION: 'antecedentes-basicos',
  CLOSING_DATE: 'fecha-de-cierre',
  MEETINGS: 'registro-de-reuniones',
  PRODUCTS: 'productos-y-servicios-a-consultar',
  QUESTIONS: 'preguntas-a-consultar'
}

export const CREATE_INNOVATION_SECTION_OPTIONS = [
  {
    description:
      'Identifica qué aspecto de lo que se ha venido observando y que ha centrado la atención resulta destacable como para identificar en ello un problema.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.WHAT_HAPPENING,
    title: '¿Qué está sucediendo y cómo está sucediendo?'
  },
  {
    description: 'Cuéntanos la evolución en el tiempo de la problemática.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.WHEN_HAPPENING,
    title: '¿Desde cuándo ha venido sucediendo?'
  },
  {
    description:
      'Especifica el lugar o la dimensión espacial donde ocurre lo que se ha considerado objeto de observación para la investigación.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.WHERE_HAPPENING,
    title: '¿Dónde está sucediendo?'
  },
  {
    description: 'Agregar antecedentes de investigaciones preliminares relacionadas con el problema.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.HAS_INVESTIGATED,
    title: '¿Se ha investigado antes acerca de ello?'
  },
  {
    description: 'Puedes destacar a quiénes afecta el problema, como comunidad, personas, entidades, entre otros.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.WHOM_IS_A_PROBLEM,
    title: '¿Para quién o quiénes es un problema?'
  },
  {
    description: 'Indica si los proveedores deberán incorporar muestras o prototipos de sus soluciones.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.PROOFS_CONCEPT_REQUIRED,
    title: '¿Se requerirán pruebas de concepto o prototipos de investigación?'
  },
  {
    description: 'Escribe las dudas específicas que quieres que los proveedores respondan.',
    name: CREATE_INNOVATION_SECTION_DICTIONARY.QUESTIONS,
    title: 'Preguntas a consultar'
  }
]

export const CREATE_SECTION_OPTIONS = [
  {
    description: 'Ingresa información específica que ayude a los usuarios a responder y participar de tu consulta.',
    name: CREATE_SECTION_DICTIONARY.BASIC_INFORMATION,
    title: 'Antecedentes básicos'
  },
  {
    description: 'Te recomendamos seleccionar un día hábil.',
    name: CREATE_SECTION_DICTIONARY.CLOSING_DATE,
    title: 'Fecha de cierre'
  },
  {
    description: 'Los proveedores que oferten los rubros que ingresen, recibirán una notificación para responder.',
    name: CREATE_SECTION_DICTIONARY.PRODUCTS,
    title: 'Productos y servicios a consultar'
  },
  {
    description: 'Escribe las dudas específicas que quieres que los proveedores respondan.',
    name: CREATE_SECTION_DICTIONARY.QUESTIONS,
    title: 'Preguntas a consultar'
  },
  {
    description: 'Puedes subir archivos que especifiquen tu solicitud (opcional).',
    name: CREATE_SECTION_DICTIONARY.ATTACH_FILES,
    title: 'Adjuntar archivos'
  },
  {
    description: 'Registra tus reuniones con  empresas o instituciones (opcional).',
    name: CREATE_SECTION_DICTIONARY.MEETINGS,
    title: 'Registro de reuniones'
  }
]

export const DETAIL_SECTION_DICTIONARY = {
  ADDITIONAL_INFO: 'charlas-o-reuniones-informativas-dicionales',
  DESCRIPTION: 'descripción',
  END_DATE: 'fecha-cierre ',
  FILES: 'archivos',
  HAS_INVESTIGATED: '¿Se ha investigado antes acerca de ello?',
  HISTORY: 'historial',
  MEETINGS: 'reuniones',
  NAME: 'nombre',
  PRODUCTS: 'productos-y-servicios',
  PROOFS_CONCEPT_REQUIRED: '¿Se requerirán pruebas de concepto o prototipos de investigación?',
  PUBLICATION_DATE: 'pecha-publicacion',
  REASON: 'motivo',
  WHAT_HAPPENING: '¿Qué está sucediendo y cómo está sucediendo?',
  WHEN_HAPPENING: '¿Desde cuándo ha venido sucediendo?',
  WHERE_HAPPENING: '¿Dónde está sucediendo?',
  WHOM_IS_A_PROBLEM: '¿Para quién o quiénes es un problema?'
}

export const DETAIL_SECTION_OPTIONS = [
  { name: DETAIL_SECTION_DICTIONARY.NAME, title: 'Nombre' },
  { name: DETAIL_SECTION_DICTIONARY.DESCRIPTION, title: 'Descripción' },
  { name: DETAIL_SECTION_DICTIONARY.REASON, title: 'Motivo de la consulta' },
  { name: DETAIL_SECTION_DICTIONARY.PUBLICATION_DATE, title: 'Fecha de publicación' },
  { name: DETAIL_SECTION_DICTIONARY.END_DATE, title: 'Fecha de cierre ' },
  { name: DETAIL_SECTION_DICTIONARY.HISTORY, title: 'Historial' },
  { name: DETAIL_SECTION_DICTIONARY.PRODUCTS, title: 'Productos y servicios a consultar' },
  { name: DETAIL_SECTION_DICTIONARY.ADDITIONAL_INFO, title: 'Charlas o reuniones informativas adicionales' },
  { name: DETAIL_SECTION_DICTIONARY.MEETINGS, title: 'Reuniones registradas' },
  { name: DETAIL_SECTION_DICTIONARY.FILES, title: 'Adjuntos' }
]

export const DETAIL_INNOVATION_SECTION_OPTIONS = [
  ...DETAIL_SECTION_OPTIONS,
  { name: DETAIL_SECTION_DICTIONARY.WHAT_HAPPENING, title: '¿Qué está sucediendo y cómo está sucediendo?' },
  { name: DETAIL_SECTION_DICTIONARY.WHEN_HAPPENING, title: '¿Desde cuándo ha venido sucediendo?' },
  { name: DETAIL_SECTION_DICTIONARY.WHERE_HAPPENING, title: '¿Dónde está sucediendo?' },
  { name: DETAIL_SECTION_DICTIONARY.HAS_INVESTIGATED, title: '¿Se ha investigado antes acerca de ello?' },
  { name: DETAIL_SECTION_DICTIONARY.WHOM_IS_A_PROBLEM, title: '¿Para quién o quiénes es un problema?' },
  {
    name: DETAIL_SECTION_DICTIONARY.PROOFS_CONCEPT_REQUIRED,
    title: '¿Se requerirán pruebas de concepto o prototipos de investigación?'
  }
]

export const EXPIRES_AT = 60 * 60

export const INITIAL_REASON_VALUES = { otherReason: 0, otherReasonMessage: '', reason: 0, reasonMessage: '' }

export const INITIAL_ROWS_PER_PAGE = 15

export const INVESTIGATED_OPTIONS = [
  { id: 1, label: 'Si', value: 100 },
  { id: 2, label: 'No', value: 200 }
]

export const INVESTIGATED_DICTIONARY = { NO: 200, YES: 100 }

export const MAX_MB = 20

export const MEETING_ACTION = { CREATE_FORM: 'openByCreateFormPage', DETAIL_INFO: 'openByRequestDetailInfoPage' }

export const MEETING_TYPE_OPTIONS = [
  { id: 1, name: 'Presencial', value: 100 },
  { id: 2, name: 'Virtual', value: 200 },
  { id: 3, name: 'Otra', value: 300 }
]

export const MEETING_TYPE_OPTIONS_DICTIONARY = { OTHER: 300, PRESENTIAL: 100, VIRTUAL: 200 }

export const ORDER_OPTIONS = [
  { id: 1, name: 'Publicadas más recientes', value: 100 },
  { id: 2, name: 'Próximas a cerrar', value: 200 }
]

export const ORDER_OPTIONS_DICTIONARY = { MOST_RECENT_PUBLISHED: 100, SOON_TO_CLOSE: 200 }

export const OTHER_REASON_OPTIONS = [{ id: 1, label: 'Otro motivo', value: 1 }]

export const QUESTION_TYPE_OPTIONS = [
  { id: 1, name: 'Párrafo abierto', value: 100 },
  { id: 2, name: 'Opción múltiple', value: 200 }
]

export const QUESTION_TYPE_OPTIONS_DICTIONARY = { MULTIPLE_CHOICE: 200, OPEN_PARAGRAPH: 100 }

export const REASON_OPTIONS = [
  { id: 1, label: 'Obtener información técnica de un producto o servicio.', value: 100 },
  { id: 2, label: 'Recabar información económica y obtener referencias de costos.', value: 200 },
  { id: 3, label: 'Obtener tanto información técnica como económica del mercado.', value: 300 },
  { id: 4, label: 'Consulta para compras de innovación.', value: 400 }
]

export const REASON_OPTIONS_DICTIONARY = {
  INFO_ECONOMIC: 200,
  INFO_MARKET: 300,
  INFO_PRODUCTS_OR_SERVICES: 100,
  OTHER_REASON: 500,
  REQUEST_INNOVATION: 400
}

export const SNACKBAR_CLOSE_TIMEOUT = 5000

export const STATUS_OPTIONS = [
  { id: 1, name: 'Todos los estados', value: 1 },
  { id: 2, name: 'Publicadas', value: 100 },
  { id: 3, name: 'Cerradas', value: 200 }
]

export const STATUS_OPTIONS_DICTIONARY = { ALL_STATUS: 1, CLOSED: 200, PUBLISHED: 100 }

export const STATUS_OPTIONS_TRASLATE_DICTIONARY = { 100: 'Publicada', 200: 'Cerrada' }

export const TEXT_MAX_LENGTH = 500

export const INITIAL_FILTERS_VALUES = {
  dateFrom: sub(new Date(), { months: 3 }),
  dateTo: new Date(),
  isItems: false,
  isParticipated: false,
  keyword: '',
  order: ORDER_OPTIONS_DICTIONARY.MOST_RECENT_PUBLISHED,
  orgBuyer: '',
  page: 1,
  status: STATUS_OPTIONS_DICTIONARY.PUBLISHED
}

export const USER_TYPES = { BUYER: 'Comprador', SELLER: 'Proveedor' }

export const USER_TYPES_TRASLATE_DICTIONARY = { BUYER: 2, SELLER: 1 }

export const WILL_BE_PUBLIC_OPTIONS = [
  { id: 1, label: 'Si, serán públicas.', value: 100 },
  { id: 2, label: 'No, serán privadas.', value: 200 }
]

export const WILL_BE_PUBLIC_DICTIONARY = { NO: 200, YES: 100 }

export const WILL_BE_REQUIRED_CONCEPT_OPTIONS = [
  { id: 1, label: 'Si, se requerirán pruebas de concepto', value: 100 },
  { id: 2, label: 'No, no serán requeridos', value: 200 }
]

export const WILL_BE_REQUIRED_CONCEPT_DICTIONARY = { NO: 200, YES: 100 }

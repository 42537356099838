import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import HeaderContainer from '../containers/Header/Header.container'
import NotFoundContainer from '../containers/NotFound/NotFound.container'

/**
 * The NotFound's page.
 */
const NotFound = () => (
  <>
    <BrandLayout header={<HeaderContainer />}>
      <NotFoundContainer />
    </BrandLayout>

    <ScrollRestoration />
  </>
)

export default NotFound

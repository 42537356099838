import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, Toast } from '@chilecompra/react-kit/components'
import { SNACKBAR_CLOSE_TIMEOUT } from '../../configs/settings/constants'
import { onCloseSnackbar } from './SnackbarProvider.actions'

/**
 * The SnackbarProvider's container.
 */
const SnackbarProvider = () => {
  const dispatch = useDispatch()
  const { message, severity, showSnackbar } = useSelector(state => state.snackbarProvider)

  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') return

    dispatch(onCloseSnackbar())
  }

  useEffect(() => {
    if (showSnackbar) return

    const timeout = setTimeout(() => dispatch(onCloseSnackbar()), SNACKBAR_CLOSE_TIMEOUT)

    return () => {
      clearTimeout(timeout)
    }
  }, [showSnackbar])

  return (
    showSnackbar && (
      <Snackbar onClose={handleCloseSnackbar} open={showSnackbar}>
        <Toast padding="6px 16px" severity={severity}>
          {message}
        </Toast>
      </Snackbar>
    )
  )
}

export default SnackbarProvider

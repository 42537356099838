import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSecondOrderCallback } from '@chilecompra/react-kit'
import { Button, Dialog, Tab, Typography } from '@chilecompra/react-kit/components'
import { onAddProductSuccess } from '../RequestCreateForm/RequestCreateForm.actions'
import {
  onCloseProductDialog,
  onGetCategoriesLevelTwoThunk,
  onGetCategoriesThunk,
  onGetProductsByCategoryThunk,
  onGetProductsThunk,
  onResetProductForm,
  onResetProductsByCategory
} from './RequestProductDialog.actions'
import CategoriesLevelOne from './RequestProductDialog.categoriesLevelOne'
import CategoriesLevelTwo from './RequestProductDialog.categoriesLevelTwo'
import ProductItem from './RequestProductDialog.product'
import ProductsByCategory from './RequestProductDialog.productsByCategory'
import SearchProducts from './RequestProductDialog.searchProducts'
import {
  ArrowDownIcon,
  ArrowRightIcon,
  Container,
  DialogButtons,
  DialogContent,
  DialogForm,
  DialogMessage,
  Divider,
  Item,
  Tabs,
  TreeView,
  WrapperBox
} from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's container.
 */
const RequestProductDialog = () => {
  const dispatch = useDispatch()

  const { products: productsSaved } = useSelector(state => state.requestCreateForm)
  const {
    categories,
    categoriesLevelTwo,
    category,
    error,
    errorProductsByCategory,
    keyword,
    loading,
    loadingCatergoriesLevelTwo,
    loadingProductsByCategory,
    products,
    productsByCategory,
    showDialog
  } = useSelector(state => state.requestProductDialog)

  const handleCloseProductDialog = () => dispatch(onCloseProductDialog())
  const handleResetProductsByCategory = useCallback(() => dispatch(onResetProductsByCategory()), [])
  const handleSearchProducts = keyword => dispatch(onGetProductsThunk({ keyword }))

  const handleAddProduct = useSecondOrderCallback((_, name, productId) => {
    dispatch(onAddProductSuccess({ name, productId }))
  })

  const handleGetCategoriesLevelTwo = useCallback(categoryId => {
    dispatch(onGetCategoriesLevelTwoThunk({ categoryId }))
  }, [])

  const handleGetProductsByCategory = useSecondOrderCallback((_, categoryId) => {
    dispatch(onGetProductsByCategoryThunk({ categoryId }))
  })

  const handleTabChange = tab => {
    dispatch(onResetProductForm())

    if (tab === 'tab__items') dispatch(onGetCategoriesThunk())
  }

  return (
    <Dialog
      disableBackdropClick
      margin="auto"
      maxWidth="900px"
      onClose={handleCloseProductDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent padding="32px">
        <DialogMessage margin="0 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            Agregar productos o servicios
          </Typography>

          <Typography variant="body2">
            Selecciona los productos o servicios a consultar. Puedes buscar por plabra clave o navegar en el listado de
            productos y servicios.
          </Typography>
        </DialogMessage>

        <DialogForm>
          <Item xs={12}>
            <Tabs onChange={handleTabChange} value="tab__products" variant="standard">
              <Tab id="tab__products" label="Buscar por producto o servicio">
                <SearchProducts
                  isError={!!error}
                  isLoading={loading}
                  keyword={keyword}
                  onSearchProducts={handleSearchProducts}
                  productsLength={products.length}
                >
                  {products.map(category => (
                    <Container key={category.name} margin="0 0 32px">
                      <Item margin="0 0 8px" xs={12}>
                        <Typography fontWeight="bold" variant="body2">
                          {category.name}
                        </Typography>
                      </Item>

                      {category.products.map(product => (
                        <Item key={product.id} xs={12}>
                          <ProductItem
                            isAdded={productsSaved.some(added => added.productId === product.id)}
                            name={product.name}
                            onAdd={handleAddProduct}
                            productId={product.id}
                          />
                        </Item>
                      ))}
                    </Container>
                  ))}
                </SearchProducts>
              </Tab>

              <Tab id="tab__items" label="Navegar por rubros">
                <CategoriesLevelOne
                  categories={categories}
                  categoriesLevelTwoLength={categoriesLevelTwo.length}
                  category={category}
                  isError={!!error}
                  isLoading={loading}
                  isLoadingCatergoriesLevelTwo={loadingCatergoriesLevelTwo}
                  onFetchCategoriesLevelTwo={handleGetCategoriesLevelTwo}
                  onResetProductsByCategory={handleResetProductsByCategory}
                  productsByCategoryLength={productsByCategory.length}
                >
                  <TreeView defaultCollapseIcon={<ArrowDownIcon />} defaultExpandIcon={<ArrowRightIcon />}>
                    {categoriesLevelTwo.map(level2 => (
                      <CategoriesLevelTwo
                        categories={level2.categories}
                        id={level2.value}
                        key={level2.value}
                        name={level2.name}
                        onGetProductsByCategory={handleGetProductsByCategory}
                      />
                    ))}
                  </TreeView>
                </CategoriesLevelOne>

                <Divider margin="16px 0" />

                {productsByCategory.length > 0 && (
                  <Container>
                    <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
                      Selecciona los productos a agregar
                    </Typography>

                    <ProductsByCategory
                      isError={!!errorProductsByCategory}
                      isLoading={loadingProductsByCategory}
                      productsByCategoryLength={productsByCategory.length}
                    >
                      <WrapperBox middle={productsByCategory.length > 0}>
                        <Container>
                          {productsByCategory.map(product => (
                            <Item key={product.value} margin="0 0 8px" xs={12}>
                              <ProductItem
                                isAdded={productsSaved.some(added => added.productId === product.value)}
                                name={product.name}
                                onAdd={handleAddProduct}
                                productId={product.value}
                              />
                            </Item>
                          ))}
                        </Container>
                      </WrapperBox>
                    </ProductsByCategory>
                  </Container>
                )}
              </Tab>
            </Tabs>
          </Item>
        </DialogForm>

        <DialogButtons alignItems="center" justifyContent="flex-start">
          <Button color="default" disabled={loading} onClick={handleCloseProductDialog} variant="outlined">
            Volver
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestProductDialog

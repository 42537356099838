import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useViewport } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import ProtectByProfile from '../../components/Security/ProtectByProfile'
import { REASON_OPTIONS_DICTIONARY, USER_TYPES } from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import Breadcrumbs from './Heading.breadcrumbs'
import { Container, Item } from './Heading.styles'

/**
 * The Heading's container.
 */
const Heading = () => {
  const location = useLocation()
  const { isPrivate, userType } = useAuthProvider()
  const { size } = useViewport()

  const { reason } = useSelector(state => state.requestReasonDialog)

  const isCreateRequestInnovationPage = routes.createStepTwo === location.pathname
  const isCreateRequestPage = routes.create === location.pathname
  const isInnovation = reason === REASON_OPTIONS_DICTIONARY.REQUEST_INNOVATION
  const isPublishedRequestPage = routes.published === location.pathname
  const isRequestPage = routes.root === location.pathname || routes.search === location.pathname

  const steps = useMemo(() => {
    if (isInnovation) return `PASO ${isCreateRequestInnovationPage ? 2 : 1} DE 2`

    return 'PASO 1 DE 1'
  }, [isCreateRequestInnovationPage, isInnovation])

  return (
    <Container padding="16px 0">
      <Item xs={12}>
        <Breadcrumbs />
      </Item>

      {isRequestPage && (
        <Item md={userType === USER_TYPES.BUYER ? 9 : 12} xs={12}>
          <Typography fontWeight="bold" margin="32px 0 8px" variant={size.isDownToTiny ? 'h3' : 'h2'}>
            Consultas al mercado
          </Typography>

          <Typography variant="body2">
            <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER}>
              Consulta a proveedores y expertos sobre una necesidad en particular respecto a un producto o servicio.
              Cualquier ciudadano con conocimiento en el rubro podrá entregar información que facilite tus procesos de
              compra.
            </ProtectByProfile>

            <ProtectByProfile rule={userType => userType === USER_TYPES.SELLER}>
              Participa respondiendo las consultas de todos los organismos públicos. Tu conocimiento en el rubro es útil
              para facilitar y mejorar los procesos de compra del Estado.
            </ProtectByProfile>

            {!isPrivate &&
              'Participa respondiendo las consultas de todos los organismos públicos. Tu conocimiento en el rubro es útil para facilitar y mejorar los procesos de compra del Estado.'}
          </Typography>
        </Item>
      )}

      {(isCreateRequestInnovationPage || isCreateRequestPage) && (
        <Item md={9} xs={12}>
          <Typography fontWeight="bold" margin="32px 0 4px" variant="caption">
            {steps}
          </Typography>

          <Typography fontWeight="bold" margin="0 0 8px" variant={size.isDownToTiny ? 'h3' : 'h2'}>
            Crear Consulta al Mercado
          </Typography>

          <Typography variant="body2">
            Una vez publicada tu consulta, se le informará a todos los proveedores que ofertan los productos y servicios
            que selecciones para que participen.
          </Typography>
        </Item>
      )}

      {isPublishedRequestPage && (
        <Item md={9} xs={12}>
          <Typography fontWeight="bold" margin="32px 0 8px" variant={size.isDownToTiny ? 'h3' : 'h2'}>
            Comprobante Consulta al Mercado
          </Typography>
        </Item>
      )}
    </Container>
  )
}

export default Heading

import { batch } from 'react-redux'
import { customizeError, formatDate } from '@chilecompra/react-kit'
import {
  ADDITIONAL_INFO_DICTIONARY,
  INVESTIGATED_DICTIONARY,
  REASON_OPTIONS_DICTIONARY,
  WILL_BE_PUBLIC_DICTIONARY,
  WILL_BE_REQUIRED_CONCEPT_DICTIONARY
} from '../../configs/settings/constants'
import { makeActionCreator } from '../../configs/store/utils'
import { postAttachedFiles } from '../../services/files'
import { postMeetings } from '../../services/meetings'
import { postProducts } from '../../services/products'
import { postQuestions, postQuestionsInnovation } from '../../services/questions'
import { postAdditionalInfoLink, postRequest, postRequestInnovation } from '../../services/request'

export const CLOSE_FEEDBACK_CONFIRM_DIALOG = 'CLOSE_FEEDBACK_CONFIRM_DIALOG'
export const onCloseFeedbackConfirmDialog = makeActionCreator(CLOSE_FEEDBACK_CONFIRM_DIALOG)

export const PUBLICATED_REQUEST = 'PUBLICATED_REQUEST'
export const PUBLICATED_REQUEST_ERROR = 'PUBLICATED_REQUEST_ERROR'
export const PUBLICATED_REQUEST_SUCCESS = 'PUBLICATED_REQUEST_SUCCESS'
export const onPublicatedRequest = makeActionCreator(PUBLICATED_REQUEST, 'payload')
export const onPublicatedRequestError = makeActionCreator(PUBLICATED_REQUEST_ERROR, 'payload')
export const onPublicatedRequestSuccess = makeActionCreator(PUBLICATED_REQUEST_SUCCESS, 'payload')
export const onPublicatedRequestThunk = payload => async (dispatch, getState) => {
  const { goToPublishedPageCallback = () => undefined, showFeedbackErrorDialogCallback = () => undefined } =
    payload || {}

  dispatch(onPublicatedRequest())

  try {
    const {
      additionalInfo,
      additionalInfoURL,
      attachedFiles,
      dateTime,
      description,
      meetings,
      name,
      products,
      questions,
      willBePublic
    } = getState().requestCreateForm
    const {
      antecedents,
      descriptionProblem,
      historyProblem,
      isInvestigated,
      locationProblem,
      peopleAffected,
      questions: questionsInnovation,
      willBeRequiredConcept
    } = getState().requestCreateInnovationForm
    const { otherReason, otherReasonMessage, reason, reasonMessage } = getState().requestReasonDialog

    const isInnovation = reason === REASON_OPTIONS_DICTIONARY.REQUEST_INNOVATION

    const currentRequest = {
      additionalInfo: additionalInfo === ADDITIONAL_INFO_DICTIONARY.YES,
      description,
      endDate: formatDate(dateTime, `yyyy-MM-dd ${formatDate(dateTime, 'HH:mm:ss')}`),
      name,
      reasonMessage: reason ? reasonMessage : otherReason ? otherReasonMessage : 'Sin motivo',
      reasonType: reason || (otherReason === 1 ? REASON_OPTIONS_DICTIONARY.OTHER_REASON : 0),
      willBePublic: willBePublic === WILL_BE_PUBLIC_DICTIONARY.YES
    }

    const currentRequestInnovation = {
      antecedents,
      descriptionProblem,
      historyProblem,
      isInvestigated: isInvestigated === INVESTIGATED_DICTIONARY.YES,
      locationProblem,
      peopleAffected,
      willBeRequiredConcept: willBeRequiredConcept === WILL_BE_REQUIRED_CONCEPT_DICTIONARY.YES
    }

    const { data } = isInnovation
      ? await postRequestInnovation({ ...currentRequest, ...currentRequestInnovation })
      : await postRequest({ ...currentRequest })

    if (data.payload?.id) {
      if (additionalInfo === ADDITIONAL_INFO_DICTIONARY.YES)
        await postAdditionalInfoLink({
          additionalInfoURLs: [{ type: 100, url: additionalInfoURL }],
          requestId: data.payload?.id
        })

      if (products.length > 0) await postProducts({ products, requestId: data.payload?.id })

      if (isInnovation && questionsInnovation.length > 0) {
        await postQuestionsInnovation({ questionsInnovation, requestId: data.payload?.id })
      } else if (questions.length > 0 && questions.some(({ message }) => message !== '')) {
        await postQuestions({ questions, requestId: data.payload?.id })
      }

      if (meetings.length > 0) await postMeetings({ meetings, requestId: data.payload?.id })
      if (attachedFiles.length > 0) await postAttachedFiles({ attachedFiles, requestId: data.payload?.id })
    }

    return batch(() => {
      goToPublishedPageCallback({ code: data.payload?.codigo })
      dispatch(onPublicatedRequestSuccess())
    })
  } catch (error) {
    return batch(() => {
      showFeedbackErrorDialogCallback()
      dispatch(
        onPublicatedRequestError({
          error: customizeError({
            code: error.code,
            originalError: error.originalError,
            reason: 'SOMETHING_WENT_WRONG_ERROR'
          }).toObject()
        })
      )
    })
  }
}

export const SHOW_FEEDBACK_CONFIRM_DIALOG = 'SHOW_FEEDBACK_CONFIRM_DIALOG'
export const onShowFeedbackConfirmDialog = makeActionCreator(SHOW_FEEDBACK_CONFIRM_DIALOG)

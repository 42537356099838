import React, { memo, useEffect, useRef } from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Button, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { AddProductLink, Container, NotFoundImg, Products, ProductsEmpty } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's products.
 */
const ProductsSection = props => {
  const { children, isDisabled, onShowProductDialog, productsLength, submitError } = props

  const { size } = useViewport()
  const productsRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  useEffect(() => {
    if (
      conditionsFields([
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR',
        'DUPLICATED_QUESTION_ERROR'
      ])
    ) {
      return
    }

    if (conditionsFields(['PRODUCTS_UNSET_ERROR'])) {
      const element = productsRef.current
      const headerOffset = size.isDownToSmall ? 108 : 32
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      return window.scrollTo({ top: offsetPosition })
    }
  }, [submitError])

  return (
    <Products ref={productsRef}>
      {productsLength === 0 && (
        <>
          <ProductsEmpty
            color={conditionsFields(['PRODUCTS_UNSET_ERROR']) ? 'error' : 'default'}
            enableBorder={conditionsFields(['PRODUCTS_UNSET_ERROR'])}
            padding="32px"
          >
            <Container alignItems="center" direction="column" justifyContent="center">
              <NotFoundImg src="/assets/images/not-found.svg" />

              <Typography color="black3" margin="16px 0" variant="body2">
                No tienes productos o servicios agregados
              </Typography>

              <Button
                color="primary"
                disabled={isDisabled}
                margin="16px 0 0"
                onClick={onShowProductDialog}
                variant="outlined"
              >
                Agregar productos
              </Button>
            </Container>
          </ProductsEmpty>

          {conditionsFields(['PRODUCTS_UNSET_ERROR']) && (
            <Typography color="error" variant="subtitle">
              Este campo es obligatorio. Debes completar esta información para poder continuar.
            </Typography>
          )}
        </>
      )}

      {productsLength > 0 && (
        <>
          {children}

          <Container>
            <AddProductLink disabled={isDisabled} onClick={onShowProductDialog}>
              Agregar otros productos
            </AddProductLink>
          </Container>
        </>
      )}
    </Products>
  )
}

ProductsSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

ProductsSection.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onShowProductDialog: PropTypes.func.isRequired,
  productsLength: PropTypes.number.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(ProductsSection)

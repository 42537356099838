import React, { memo, useCallback, useEffect } from 'react'
import {
  checkInputErrorFileMaxSize,
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  formatDate,
  useFile,
  useRadio
} from '@chilecompra/react-kit'
import { Button, Card, File, Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import ProtectByFeatures from '../../components/Security/ProtectByFeatures'
import { ACCEPTED_DOCUMENT_TYPES, MAX_MB } from '../../configs/settings/constants'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { Container, Files, Radio, RemoveIcon } from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's addResponse.
 */
const AddResponseForm = props => {
  const {
    isError,
    isLoading,
    isResponseMultipleOptions,
    multipleChoiceOptions,
    onAdd,
    onRemove,
    onResponse,
    onShowUniquePassDialog,
    publicUserDni,
    questionId,
    responseUser,
    tempResponseAttachedFiles
  } = props

  const { isPrivate } = useAuthProvider()

  const {
    error: messageError,
    onChange: handleMessageChange,
    value: messageValue
  } = useTextArea({
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ]
  })

  const { onChange: handleMultipleChoiceOptionChange, value: multipleChoiceOptionValue } = useRadio()

  const {
    error: fileError,
    onChange: handleFileChange,
    onReset: handleFileReset,
    setValue: setFileValue,
    value: fileValue
  } = useFile({
    errorCallbacks: [checkInputErrorFileMaxSize(MAX_MB)]
  })

  const handleResponse = () => {
    onResponse({ message: messageValue || multipleChoiceOptionValue, questionId, tempResponseAttachedFiles })
  }

  const messageErrorText = useCallback(() => {
    if (messageError !== '') return messageError
    if (isError) return 'Error al enviar la respuesta, intente nuevamente.'
  }, [isError, messageError])

  useEffect(() => {
    if (fileError !== '') return setFileValue('')

    if (fileValue) onAdd({ file: fileValue, questionId })
  }, [fileError, fileValue])

  useEffect(() => {
    if (tempResponseAttachedFiles.length === 0) handleFileReset()
  }, [tempResponseAttachedFiles])

  return (
    <Container>
      {(isPrivate || publicUserDni) && (
        <>
          {multipleChoiceOptions.length === 0 && (
            <TextArea
              disabled={isLoading}
              error={messageErrorText()}
              margin="0 0 8px"
              maxCount={500}
              onChange={handleMessageChange}
              rows={4}
              value={messageValue}
            />
          )}

          {multipleChoiceOptions.length !== 0 && (
            <Radio
              disabled={isResponseMultipleOptions || isLoading}
              onChange={handleMultipleChoiceOptionChange}
              options={multipleChoiceOptions.map(option => ({ label: option.message, value: option.message }))}
              value={multipleChoiceOptionValue || (responseUser?.message ? responseUser?.message : 0)}
            />
          )}

          {!isResponseMultipleOptions && (
            <>
              <File
                accept={ACCEPTED_DOCUMENT_TYPES}
                browseText={fileValue ? 'Agregar otro archivo' : 'Examinar'}
                disabled={isLoading || (!messageValue && !multipleChoiceOptionValue)}
                error={fileError}
                info={`Tamaño máximo permitido: ${MAX_MB}MB.`}
                label="Documento anexo"
                margin="0 0 16px"
                onChange={handleFileChange}
                placeholder="Presiona examinar para adjuntar un archivo."
                value={fileValue}
              />

              {tempResponseAttachedFiles.length > 0 && (
                <Container margin="0 0 32px">
                  <Typography fontWeight="bold" margin="0 0 16px" variant="body2">
                    Archivos adjuntos:
                  </Typography>

                  {tempResponseAttachedFiles.map(file => (
                    <Card key={file.id} margin="0 0 8px" padding="24px">
                      <Files>
                        <Typography className="file__name" variant="body2">
                          {file.fileName.toLowerCase()}
                        </Typography>

                        <RemoveIcon
                          disabled={isLoading || !messageValue}
                          onClick={() => onRemove({ fileId: file.id, questionId })}
                        />
                      </Files>
                    </Card>
                  ))}
                </Container>
              )}
            </>
          )}

          <Container alignItems="center" justifyContent={isResponseMultipleOptions ? 'flex-start' : 'flex-end'}>
            <Button
              color="primary"
              disabled={(!messageValue && !multipleChoiceOptionValue) || messageError !== '' || isLoading}
              loading={isLoading}
              onClick={handleResponse}
            >
              Responder
            </Button>
          </Container>

          {isResponseMultipleOptions && (
            <Toast margin="32px 0 0" severity="info">
              Ya respondiste esta pregunta con fecha{' '}
              {responseUser &&
                formatDate(
                  responseUser.registerDate,
                  `dd/MM/yyyy 'a las' ${formatDate(responseUser.registerDate, 'HH:mm')}`
                )}
            </Toast>
          )}
        </>
      )}

      <ProtectByFeatures rule={features => features['show.cu']}>
        {!isPrivate && !publicUserDni && (
          <Button color="primary" onClick={onShowUniquePassDialog} width="200px">
            Responder
          </Button>
        )}
      </ProtectByFeatures>
    </Container>
  )
}

AddResponseForm.defaultProps = {
  isError: false,
  isLoading: false,
  isResponseMultipleOptions: false,
  multipleChoiceOptions: [],
  responseUser: undefined
}

AddResponseForm.propTypes = {
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResponseMultipleOptions: PropTypes.bool,
  multipleChoiceOptions: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onResponse: PropTypes.func.isRequired,
  onShowUniquePassDialog: PropTypes.func.isRequired,
  publicUserDni: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  responseUser: PropTypes.shape(),
  tempResponseAttachedFiles: PropTypes.arrayOf(PropTypes.shape())
}

export default memo(AddResponseForm)

import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie, deleteCookie, putCookie, useEffectOnce } from '@chilecompra/react-kit'
import { addMilliseconds } from 'date-fns'
import Keycloak from 'keycloak-js'
import PropTypes from 'prop-types'
import {
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL
} from '../../configs/settings/environment'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { onGetPublicUserAuthInfoThunk } from './PublicUserAuthProvider.actions'
import PublicUserAuthContext from './PublicUserAuthProvider.context'

const _keycloak = new Keycloak({
  clientId: REACT_APP_KEYCLOAK_CLIENT_ID,
  realm: REACT_APP_KEYCLOAK_REALM,
  url: REACT_APP_KEYCLOAK_URL
})

/**
 * The PublicUserAuthProvider's container.
 */
const PublicUserAuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { isPrivate } = useAuthProvider()
  const publicUserToken = getCookie('public_user_token')

  const { error } = useSelector(state => state.publicUserAuthProvider)

  const initOptions = {
    enableLogging: true,
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    promiseType: 'native',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
  }

  const initKeycloak = useCallback(() => {
    _keycloak
      .init(initOptions)
      .then(authenticated => {
        console.warn('Initialize keycloak')

        if (!authenticated) return

        putCookie('public_user_token', _keycloak.token, { expires: addMilliseconds(new Date(), 900 * 1000) })
        dispatch(onGetPublicUserAuthInfoThunk({ token: _keycloak.token }))
      })
      .catch(() => console.error('Error to initialize keycloak'))
  }, [])

  useEffectOnce(() => {
    if (!isPrivate && !publicUserToken) initKeycloak()
  })

  useEffect(() => {
    if (!isPrivate && error?.originalError?.response.data?.errores?.some(err => err.codigo === '500-1')) {
      deleteCookie('public_user_token')

      return initKeycloak()
    }

    if (!error && !isPrivate && publicUserToken) dispatch(onGetPublicUserAuthInfoThunk({ token: publicUserToken }))
  }, [error, publicUserToken])

  const onLoginUniquePass = () => _keycloak.login()

  return <PublicUserAuthContext.Provider value={{ onLoginUniquePass }}>{children}</PublicUserAuthContext.Provider>
}

PublicUserAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default PublicUserAuthProvider

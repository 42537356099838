import React, { memo } from 'react'
import { Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { Card, Container, Item, NotFoundImg, Skeleton, WrapperBox } from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's productsByCategory.
 */
const ProductsByCategory = props => {
  const { children, isError, isLoading, productsByCategoryLength } = props

  return (
    <Container>
      {isLoading && (
        <WrapperBox middle={productsByCategoryLength > 0}>
          {[...Array(3)].map((_, index) => (
            <Skeleton height="40px" key={index} />
          ))}
        </WrapperBox>
      )}

      {!isError && !isLoading && productsByCategoryLength === 0 && (
        <WrapperBox middle={productsByCategoryLength > 0}>
          <Item xs={12}>
            <Card className="not__found" color="blank" padding="24px">
              <Container alignItems="center" direction="column" justifyContent="center">
                <NotFoundImg src="/assets/images/not-found.svg" />

                <Typography fontWeight="bold" margin="16px 0" variant="h4">
                  No se encontraron resultados para tu búsqueda.
                </Typography>

                <Typography color="black3" variant="body2">
                  Intenta con otra palabra clave u otro criterio de búsqueda.
                </Typography>
              </Container>
            </Card>
          </Item>
        </WrapperBox>
      )}

      {!isError && !isLoading && productsByCategoryLength > 0 && (
        <WrapperBox middle={productsByCategoryLength > 0}>
          <Item xs={12}>{children}</Item>
        </WrapperBox>
      )}

      {!isLoading && isError && (
        <WrapperBox middle={productsByCategoryLength > 0}>
          <Item xs={12}>
            <Toast
              border
              severity="error"
              textDirection="vertical"
              title="Lo sentimos, estamos presentando problemas con nuestro servidor."
            >
              En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos
              minutos más.
            </Toast>
          </Item>
        </WrapperBox>
      )}
    </Container>
  )
}

ProductsByCategory.defaultProps = {
  isError: false,
  isLoading: false
}

ProductsByCategory.propTypes = {
  children: PropTypes.node.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  productsByCategoryLength: PropTypes.number.isRequired
}

export default memo(ProductsByCategory)

import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { deleteCookie } from '@chilecompra/react-kit'
import BrandLayout from '../components/Layouts/BrandLayout'
import RFILayout from '../components/Layouts/RFILayout'
import ProtectByProfile from '../components/Security/ProtectByProfile'
import { USER_TYPES } from '../configs/settings/constants'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import RequestFiltersContainer from '../containers/RequestFilters/RequestFilters.container'
import RequestListContainer from '../containers/RequestList/RequestList.container'
import RequestReasonDialogContainer from '../containers/RequestReasonDialog/RequestReasonDialog.container'
import RequestSearchContainer from '../containers/RequestSearch/RequestSearch.container'
import NotFoundPage from './NotFound'

/**
 * The Request's public page.
 */
const RequestPublic = () => {
  return (
    <>
      <AuthProviderContainer>
        <ProtectByProfile
          fallback={userType => {
            if (userType === USER_TYPES.BUYER || userType === USER_TYPES.SELLER) {
              deleteCookie('access_token')
              window.location?.reload(true)

              return <NotFoundPage />
            }
          }}
          rule={userType => !(userType === USER_TYPES.BUYER || userType === USER_TYPES.SELLER)}
        >
          <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
            <RFILayout
              filters={<RequestFiltersContainer />}
              result={<RequestListContainer />}
              search={<RequestSearchContainer />}
            />
            <RequestReasonDialogContainer />
          </BrandLayout>
        </ProtectByProfile>
      </AuthProviderContainer>

      <ScrollRestoration />
    </>
  )
}

export default RequestPublic

import React from 'react'
import PropTypes from 'prop-types'
import { Body, Container, Featured, Header } from './BrandLayout.styles'

/**
 * The BrandLayout's component.
 */
const BrandLayout = props => {
  const { children, featured, header } = props

  return (
    <Container direction="column">
      {header && <Header>{header}</Header>}

      {featured && <Featured>{featured}</Featured>}

      <Body>{children}</Body>
    </Container>
  )
}

BrandLayout.defaultProps = {
  featured: null,
  header: null
}

BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  featured: PropTypes.node,
  header: PropTypes.node
}

export default BrandLayout

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { usePathParam, useQuery } from '@chilecompra/react-kit'
import { Breadcrumbs as BaseBreadcrumbs } from '@chilecompra/react-kit/components'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../configs/settings/environment'
import { PATH_PARAM } from '../../configs/settings/regexes'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { Breadcrumb } from './Heading.styles'

/**
 * The Heading's breadcrumbs.
 */
const Breadcrumbs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()
  const code = usePathParam('code')

  const { filters } = useSelector(state => state.requestList)

  const isCreateRequestPage = routes.create === location.pathname || routes.createStepTwo === location.pathname
  const isDetailRequestPage = routes.detail.replace(PATH_PARAM, code) === location.pathname
  const isPublishedRequestPage = routes.published === location.pathname
  const isRequestPage = routes.root === location.pathname || routes.search === location.pathname

  const handleGoHomeWithParams = () => {
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  const breadcrumbsList = [
    {
      isExternal: true,
      isShow: true,
      label: isPrivate ? 'Escritorio' : 'Home',
      to: isPrivate
        ? `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`
        : `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`
    },
    {
      class: !isRequestPage ? 'breadcrumb__link' : undefined,
      isShow: true,
      label: 'Consultas al Mercado',
      onRedirectTo: !isRequestPage ? handleGoHomeWithParams : undefined
    },
    { isShow: isDetailRequestPage, label: 'Detalle de la consulta' },
    { isShow: isCreateRequestPage, label: 'Crear consulta' },
    { isShow: isPublishedRequestPage, label: 'Comprobante' }
  ]

  const breadcrumbs = breadcrumbsList.reduce((acc, breadcrumb) => {
    if (breadcrumb.isShow) return [...acc, breadcrumb]

    return acc
  }, [])

  return (
    <BaseBreadcrumbs>
      {breadcrumbs.map(breadcrumb => (
        <Breadcrumb
          key={breadcrumb.label}
          {...{
            ...(breadcrumb.class && { className: breadcrumb.class }),
            ...(breadcrumb.onRedirectTo && { onClick: breadcrumb.onRedirectTo }),
            ...(breadcrumb.to && breadcrumb.isExternal && { target: '_top' }),
            ...(breadcrumb.to && breadcrumb.isExternal && { type: 'external' }),
            ...(breadcrumb.to && { to: breadcrumb.to })
          }}
          variant={breadcrumb.to ? 'link' : 'text'}
        >
          {breadcrumb.label}
        </Breadcrumb>
      ))}
    </BaseBreadcrumbs>
  )
}

export default Breadcrumbs

import React from 'react'
import {
  Card as BaseCard,
  Radio as BaseRadio,
  Skeleton as BaseSkeleton,
  Spinner as BaseSpinner
} from '@chilecompra/react-kit/components'
import {
  ArrowDownward as BaseArrowDownward,
  ArrowForward as BaseArrowForward,
  Close as BaseClose,
  KeyboardArrowDown as BaseKeyboardArrowDown,
  KeyboardArrowUp as BaseKeyboardArrowUp
} from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const AddMeetingLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const ArrowDownIcon = styled(({ isErrorDownloading, ...props }) => <BaseArrowDownward {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => (props.isErrorDownloading ? props.theme.palette.red[50] : props.theme.palette.blue[50])};
    height: 16px;
    margin-left: 8px;
    width: 16px;
  }
`

export const ArrowForward = styled(BaseArrowForward)`
  &.MuiSvgIcon-root {
    height: 18px;
    margin-right: 8px;
    width: 18px;
    color: ${props => props.theme.palette.brand.disableFont};
  }
`

export const AttachedFiles = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};

    &:last-child {
      border-bottom: none;
    }
  }
`

export const AttachedFilesLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const AttachedFilesWrapper = styled(BaseContainer)`
  &.MuiGrid-container {
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};

    &:last-child {
      border-bottom: none;
    }
  }
`

export const AttachedFilesResponseWrapper = styled(BaseContainer)`
  &.MuiGrid-container {
    grid-column: 2 / 3;
  }
`

export const BaseGridOneColumn = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const AdditionalInfo = styled(BaseGridOneColumn)``

export const Container = styled(BaseContainer)``

export const Description = styled(BaseGridOneColumn)``

export const DownloadLink = styled(({ disabled, isErrorDownloading, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props =>
      props.disabled
        ? props.theme.palette.brand.disableFont
        : props.isErrorDownloading
        ? props.theme.palette.red[50]
        : props.theme.palette.blue[50]};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => (props.isErrorDownloading ? props.theme.palette.red[35] : props.theme.palette.blue[35])};
      text-decoration: underline;
    }
  }
`

export const EndDate = styled(({ isLoading, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: ${props => (props.isLoading ? '1fr auto' : 'auto 1fr')};
  }
`

export const ExtendEndDateLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    margin-right: 16px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const Files = styled(BaseItem)`
  &.MuiGrid-item {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;

    .base__file__name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
    }
  }
`

export const Heading = styled(BaseContainer)`
  &.MuiGrid-container {
    gap: 16px;
  }
`

export const HasInvestigated = styled(BaseGridOneColumn)``

export const History = styled(BaseGridOneColumn)``

export const HistoryLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const Item = styled(({ order, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: flex;
    flex-direction: column;
    order: ${props => props.order};
  }
`

export const Meeting = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const Meetings = styled(BaseContainer)``

export const MeetingBox = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 8px;
    grid-template-columns: 110px 1fr;

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: 1fr;
    }
  }
`

export const MeetingContent = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${props => props.theme.breakpoints.down('md')} {
      grid-template-columns: 1fr;
    }
  }
`

export const MeetingEmpty = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: auto 1fr;
  }
`

export const MeetingWrapper = styled(({ full, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};
    display: grid;
    grid-column: ${props => props.full && '1 / 3'};

    ${props => props.theme.breakpoints.down('md')} {
      grid-column: auto;
    }

    :last-child {
      border-bottom: none;
    }
  }
`

export const Name = styled(BaseGridOneColumn)``

export const PublicationDate = styled(BaseGridOneColumn)``

export const Products = styled(BaseItem)``

export const ProductsWrapper = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: auto 1fr;
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};

    &:last-child {
      border-bottom: none;
    }
  }
`

export const ProofsConceptRequired = styled(BaseGridOneColumn)``

export const Radio = styled(BaseRadio)`
  &.MuiFormGroup-root {
    &[direction='vertical'] {
      margin: 0;

      .MuiFormControlLabel-root {
        margin: 0 0 8px;
      }
    }
  }
`

export const Reason = styled(BaseGridOneColumn)``

export const RemoveIcon = styled(({ disabled, ...props }) => <BaseClose {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.disabled && props.theme.palette.brand.disableFont};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 18px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    width: 18px;
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} size="14px" />)`
  &.MuiCircularProgress-root {
    margin-left: 8px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  &.MuiSkeleton-root {
    transform: none;

    &.questions__and__answers {
      height: 200px;
    }
  }
`

export const SeeLessArrowUpIcon = styled(BaseKeyboardArrowUp)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    height: 16px;
    margin-left: 4px;
    width: 16px;
  }
`

export const SeeMoreArrowDownIcon = styled(BaseKeyboardArrowDown)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    height: 16px;
    margin-left: 4px;
    width: 16px;
  }
`

export const SeeMoreText = styled(BaseItem)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => props.theme.palette.blue[50]};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 400;
    pointer-events: visible;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
    }
  }
`

export const SummaryLink = styled.a`
  align-items: center;
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  pointer-events: visible;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
    text-decoration: underline;
  }
`

export const WhatHappening = styled(BaseGridOneColumn)``

export const WhenHappening = styled(BaseGridOneColumn)``

export const WhereHappening = styled(BaseGridOneColumn)``

export const WhomIsAProblem = styled(BaseGridOneColumn)``

export const WrapperAddResponse = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: 240px 1fr;

    > .MuiGrid-container {
      grid-column: 2/3;
    }

    ${props => props.theme.breakpoints.down('md')} {
      gap: 0;
      grid-template-columns: 1fr;
    }
  }
`

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    border-top: 1px solid ${props => props.theme.palette.brand.gray2};
    display: grid;
    gap: 32px;
    grid-template-columns: 240px 1fr;

    ${props => props.theme.breakpoints.down('sm')} {
      gap: 16px;
      grid-template-columns: 1fr;
    }
  }
`

export const WrapperQuestions = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: 240px 1fr;

    .question {
      overflow-wrap: anywhere;
    }

    ${props => props.theme.breakpoints.down('md')} {
      gap: 16px;
      grid-template-columns: 1fr;
    }
  }
`

export const WrapperResponse = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 8px;
    grid-template-columns: 22px 1fr;
    height: 100%;

    .response {
      overflow-wrap: anywhere;
    }
  }
`

export const WrapperResponses = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: 240px 1fr;

    ${props => props.theme.breakpoints.down('md')} {
      gap: 16px;
      grid-template-columns: 1fr;
    }
  }
`

export const Strong = styled.span`
  font-weight: bold;
`

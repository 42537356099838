import React, { memo } from 'react'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import ProtectByFeatures from '../../components/Security/ProtectByFeatures'
import { CREATE_INNOVATION_SECTION_DICTIONARY } from '../../configs/settings/constants'
import { REACT_APP_CAPACITACION_BASE_URL } from '../../configs/settings/environment'
import HasInvestigatedSection from './RequestCreateInnovationForm.hasInvestigated'
import ProofsConceptRequiredSection from './RequestCreateInnovationForm.proofsConceptRequired'
import QuestionItem from './RequestCreateInnovationForm.question'
import QuestionsSection from './RequestCreateInnovationForm.questions'
import { Card, Container, QuestionLibraryLink, ReviewTrainingLink } from './RequestCreateInnovationForm.styles'
import WhatHappeningSection from './RequestCreateInnovationForm.whatHappening'
import WhenHappeningSection from './RequestCreateInnovationForm.whenHappening'
import WhereHappeningSection from './RequestCreateInnovationForm.whereHappening'
import WhomIsAProblemSection from './RequestCreateInnovationForm.whomIsAProblem'

/**
 * The RequestCreateInnovationForm's sections.
 */
const SectionsForm = props => {
  const {
    antecedents,
    descriptionProblem,
    historyProblem,
    isDisabled,
    isInvestigated,
    locationProblem,
    onAddQuestion,
    onAddQuestionMultipleChoice,
    onInitialQuestionsMultipleChoice,
    onRemoveQuestion,
    onRemoveQuestionMultipleChoice,
    onResetQuestionsMultipleChoice,
    onShowQuestionsLibraryDialog,
    onUpdate,
    onUpdateQuestion,
    onUpdateQuestionMultipleChoiceValue,
    peopleAffected,
    questions,
    sectionName,
    submitError,
    willBeRequiredConcept
  } = props

  return (
    <>
      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.WHAT_HAPPENING && (
        <>
          <WhatHappeningSection
            descriptionProblem={descriptionProblem}
            isDisabled={isDisabled}
            onUpdate={onUpdate}
            submitError={submitError}
          />

          <ProtectByFeatures rule={features => features['show.training']}>
            <Card color="primary" padding="24px">
              <Container direction="column" justifyContent="center">
                <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
                  Ejemplos de Consultas para Compras de Innovación
                </Typography>

                <Typography margin="0 0 16px" variant="body2">
                  Puedes revisar casos aplicados de uso para esta herramientas que te ayuden y guíen en el proceso.
                </Typography>

                <ReviewTrainingLink href={REACT_APP_CAPACITACION_BASE_URL} target="_blank">
                  Revisar capacitaciones y ejemplos
                </ReviewTrainingLink>
              </Container>
            </Card>
          </ProtectByFeatures>
        </>
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.WHEN_HAPPENING && (
        <WhenHappeningSection
          historyProblem={historyProblem}
          isDisabled={isDisabled}
          onUpdate={onUpdate}
          submitError={submitError}
        />
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.WHERE_HAPPENING && (
        <WhereHappeningSection
          isDisabled={isDisabled}
          locationProblem={locationProblem}
          onUpdate={onUpdate}
          submitError={submitError}
        />
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.HAS_INVESTIGATED && (
        <HasInvestigatedSection
          antecedents={antecedents}
          isDisabled={isDisabled}
          isInvestigated={isInvestigated}
          onUpdate={onUpdate}
          submitError={submitError}
        />
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.WHOM_IS_A_PROBLEM && (
        <WhomIsAProblemSection
          isDisabled={isDisabled}
          onUpdate={onUpdate}
          peopleAffected={peopleAffected}
          submitError={submitError}
        />
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.PROOFS_CONCEPT_REQUIRED && (
        <ProofsConceptRequiredSection
          isDisabled={isDisabled}
          onUpdate={onUpdate}
          submitError={submitError}
          willBeRequiredConcept={willBeRequiredConcept}
        />
      )}

      {sectionName === CREATE_INNOVATION_SECTION_DICTIONARY.QUESTIONS && (
        <>
          <QuestionsSection
            isDisabled={isDisabled}
            onAdd={onAddQuestion}
            onShowQuestionsLibraryDialog={onShowQuestionsLibraryDialog}
          >
            {questions.map((question, index) => (
              <QuestionItem
                duplicatedError={!!question.duplicated}
                isDisabled={isDisabled}
                key={question.id}
                message={question.message}
                multipleChoiceOptions={question.multipleChoiceOptions}
                onAddQuestionMultipleChoice={onAddQuestionMultipleChoice(question.id)}
                onInitialQuestionsMultipleChoice={onInitialQuestionsMultipleChoice(question.id)}
                onRemove={onRemoveQuestion(question.id)}
                onRemoveQuestionMultipleChoice={onRemoveQuestionMultipleChoice(question.id)}
                onResetQuestionsMultipleChoice={onResetQuestionsMultipleChoice(question.id)}
                onUpdate={onUpdateQuestion(question.id)}
                onUpdateQuestionMultipleChoiceValue={onUpdateQuestionMultipleChoiceValue(question.id)}
                questionType={question.type}
                removable={index > 0}
                submitError={submitError}
              />
            ))}
          </QuestionsSection>

          <Card color="primary" padding="24px">
            <Container direction="column" justifyContent="center">
              <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
                Biblioteca de preguntas sugeridas
              </Typography>

              <Typography margin="0 0 16px" variant="body2">
                Te sugerimos preguntas que otros organismos han utilizado para realizar sus consultas.
              </Typography>

              <QuestionLibraryLink disabled={isDisabled} onClick={onShowQuestionsLibraryDialog}>
                Revisar biblioteca de preguntas
              </QuestionLibraryLink>
            </Container>
          </Card>
        </>
      )}
    </>
  )
}

SectionsForm.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

SectionsForm.propTypes = {
  antecedents: PropTypes.string.isRequired,
  descriptionProblem: PropTypes.string.isRequired,
  historyProblem: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInvestigated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationProblem: PropTypes.string.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  onAddQuestionMultipleChoice: PropTypes.func.isRequired,
  onInitialQuestionsMultipleChoice: PropTypes.func.isRequired,
  onRemoveQuestion: PropTypes.func.isRequired,
  onRemoveQuestionMultipleChoice: PropTypes.func.isRequired,
  onResetQuestionsMultipleChoice: PropTypes.func.isRequired,
  onShowQuestionsLibraryDialog: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateQuestion: PropTypes.func.isRequired,
  onUpdateQuestionMultipleChoiceValue: PropTypes.func.isRequired,
  peopleAffected: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, message: PropTypes.string })).isRequired,
  sectionName: PropTypes.string.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  willBeRequiredConcept: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default memo(SectionsForm)

import { v4 } from 'uuid'
import { MEETING_TYPE_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import {
  ADD_MEETING_ASSISTANT_SUCCESS,
  CLOSE_MEETING_DIALOG,
  GET_ASSISTANT_INFO,
  GET_ASSISTANT_INFO_ERROR,
  GET_ASSISTANT_INFO_SUCCESS,
  REMOVE_MEETING_ASSISTANT_SUCCESS,
  RESET_MEETING_FORM,
  SHOW_MEETING_DIALOG,
  UPDATE_DECEASED_ASSISTANT_SUCCESS,
  UPDATE_DUPLICATED_ASSISTANT_SUCCESS,
  UPDATE_MEETING_ASSISTANT_SUCCESS,
  UPDATE_MEETING_VALUE
} from './RequestMeetingDialog.actions'

const currentInitAssistent = [...Array(2)].map(() => ({
  deceased: false,
  disabled: true,
  dni: '',
  duplicated: false,
  foreignDni: '',
  id: v4(),
  isForeign: false,
  lastNames: '',
  loading: false,
  names: '',
  position: ''
}))

const requestMeetingDialogState = {
  action: '',
  assistants: currentInitAssistent,
  businessDni: '',
  businessName: '',
  dateTime: new Date(),
  error: undefined,
  isErrorForm: undefined,
  loading: false,
  meetingOtherType: '',
  meetingType: MEETING_TYPE_OPTIONS_DICTIONARY.VIRTUAL,
  reasonMeeting: '',
  showDialog: false
}

/**
 * The RequestMeetingDialog's reducer.
 */
const requestMeetingDialogReducer = (state = requestMeetingDialogState, { payload, type }) => {
  switch (type) {
    case ADD_MEETING_ASSISTANT_SUCCESS: {
      return {
        ...state,
        assistants: state.assistants.concat({
          deceased: false,
          disabled: true,
          dni: '',
          duplicated: false,
          foreignDni: '',
          id: v4(),
          isForeign: false,
          lastNames: '',
          loading: false,
          names: '',
          position: ''
        })
      }
    }
    case CLOSE_MEETING_DIALOG: {
      return { ...state, showDialog: false }
    }
    case GET_ASSISTANT_INFO: {
      return {
        ...state,
        assistants: state.assistants.map(assistant =>
          assistant.id !== payload.id ? assistant : { ...assistant, disabled: true, loading: true }
        )
      }
    }
    case GET_ASSISTANT_INFO_ERROR: {
      return {
        ...state,
        assistants: state.assistants.map(assistant =>
          assistant.id !== payload.id ? assistant : { ...assistant, disabled: false, loading: false }
        )
      }
    }
    case GET_ASSISTANT_INFO_SUCCESS: {
      return {
        ...state,
        assistants: state.assistants.map(assistant =>
          assistant.id !== payload.id
            ? assistant
            : { ...assistant, ...payload.assistant, disabled: true, loading: false }
        )
      }
    }
    case REMOVE_MEETING_ASSISTANT_SUCCESS: {
      return { ...state, assistants: state.assistants.filter(assistant => assistant.id !== payload.id) }
    }
    case RESET_MEETING_FORM: {
      return { ...state, ...requestMeetingDialogState }
    }
    case SHOW_MEETING_DIALOG: {
      return { ...state, ...payload, showDialog: true }
    }
    case UPDATE_DECEASED_ASSISTANT_SUCCESS:
    case UPDATE_DUPLICATED_ASSISTANT_SUCCESS: {
      return {
        ...state,
        assistants: state.assistants.map(assistant =>
          assistant.id !== payload.id ? assistant : { ...assistant, [payload.key]: payload.value }
        )
      }
    }
    case UPDATE_MEETING_ASSISTANT_SUCCESS: {
      return {
        ...state,
        assistants: state.assistants.map(assistant =>
          assistant.id !== payload.id ? assistant : { ...assistant, [payload.key]: payload.value }
        )
      }
    }
    case UPDATE_MEETING_VALUE: {
      return { ...state, [payload.key]: payload.value }
    }
    default: {
      return state
    }
  }
}

export default requestMeetingDialogReducer

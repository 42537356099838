import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { putCookie, useEffectOnce, useQueryParam } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import routes from '../../configs/settings/routes'
import { Container } from './AuthCallback.styles'

/**
 * The AuthCallback's container.
 */
const AuthCallback = props => {
  const { fallback } = props

  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const accessToken = useQueryParam('access_token')

  useEffectOnce(() => {
    if (!accessToken) return setError(true)

    putCookie('access_token', accessToken)
    navigate(routes.root)
  })

  if (error) return fallback()

  return (
    <Container alignItems="center" justifyContent="center">
      <Spinner />
    </Container>
  )
}

AuthCallback.defaultProps = {
  fallback: () => null
}

AuthCallback.propTypes = {
  fallback: PropTypes.func
}

export default AuthCallback

import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import ProtectByProfile from '../components/Security/ProtectByProfile'
import { USER_TYPES } from '../configs/settings/constants'
import router from '../configs/settings/routes'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import RequestPublishedSuccessfullyContainer from '../containers/RequestPublishedSuccessfully/RequestPublishedSuccessfully.container'

/**
 * The PublishedRequest's page.
 */
const PublishedRequest = () => (
  <>
    <AuthProviderContainer>
      <ProtectByProfile redirectTo={router.search} rule={userType => userType === USER_TYPES.BUYER}>
        <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
          <RequestPublishedSuccessfullyContainer />
        </BrandLayout>
      </ProtectByProfile>
    </AuthProviderContainer>

    <ScrollRestoration />
  </>
)

export default PublishedRequest

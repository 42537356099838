import React, { memo } from 'react'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { Product, ProductWrapper, RemoveIcon } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's product.
 */
const ProductItem = props => {
  const { isDisabled, name, onRemove, productId } = props

  return (
    <Product padding="24px">
      <ProductWrapper>
        <Typography color="black3" variant="body2">
          {productId}
        </Typography>

        <Typography variant="body2">{name}</Typography>

        <RemoveIcon disabled={isDisabled} onClick={onRemove} />
      </ProductWrapper>
    </Product>
  )
}

ProductItem.defaultProps = {
  isDisabled: false
}

ProductItem.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired
}

export default memo(ProductItem)

import { factoryService, getJsonRequest, patchJsonRequest, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../configs/settings/environment'

/**
 * Extend end date.
 */
export const extendEndDate = factoryService(({ requestId, extendEndDate }) => {
  const body = { fechaCierre: extendEndDate }

  return patchJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}`, { body })
})

/**
 * Gets the request detail data.
 */
export const getRequestDetail = factoryService(({ code }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/codigo/${code}`)
})

/**
 * Gets the search request data.
 */
export const getRequests = factoryService(
  ({ dateFrom, dateTo, isItems, isParticipated, keyword, order, orgBuyer, page, status }) => {
    const params = new URLSearchParams({
      ...(isItems && { misRubros: isItems }),
      ...(isParticipated && { misConsultas: isParticipated }),
      ...(keyword && { palabraClave: keyword }),
      ...(orgBuyer && { organismoComprador: orgBuyer }),
      desde: dateFrom,
      estado: status,
      hasta: dateTo,
      ordenarPor: order,
      pagina: page
    }).toString()

    return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado?${params}`)
  }
)

/**
 * Post send the request data.
 */
export const postAdditionalInfoLink = factoryService(({ additionalInfoURLs, requestId }) => {
  const body = additionalInfoURLs.map(additionalInfoURL => ({
    tipo: additionalInfoURL.type,
    url: additionalInfoURL.url
  }))

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/urls`, { body })
})

/**
 * Post send the request data.
 */
export const postRequest = factoryService(
  ({ additionalInfo, description, endDate, name, reasonMessage, reasonType, willBePublic }) => {
    const body = {
      charlasInformativas: additionalInfo,
      descripcion: description,
      fechaCierre: endDate,
      motivo: reasonMessage,
      nombre: name,
      respuestasPublicas: willBePublic,
      tipoMotivo: reasonType
    }

    return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado`, { body })
  }
)

/**
 * Post send the request innovation data.
 */
export const postRequestInnovation = factoryService(
  ({
    additionalInfo,
    antecedents,
    description,
    descriptionProblem,
    endDate,
    historyProblem,
    isInvestigated,
    locationProblem,
    name,
    peopleAffected,
    reasonMessage,
    reasonType,
    willBePublic,
    willBeRequiredConcept
  }) => {
    const body = {
      afectadosProblema: peopleAffected,
      antecedentesProblema: antecedents,
      charlasInformativas: additionalInfo,
      descripcion: description,
      descripcionProblema: descriptionProblem,
      fechaCierre: endDate,
      historiaProblema: historyProblem,
      investigacionPrevia: isInvestigated,
      lugarProblema: locationProblem,
      motivo: reasonMessage,
      nombre: name,
      pruebasConcepto: willBeRequiredConcept,
      respuestasPublicas: willBePublic,
      tipoMotivo: reasonType
    }

    return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/innovacion`, { body })
  }
)

import React from 'react'
import { ArrowBack as BaseArrowBack, ShoppingCartOutlined as BaseShoppingCartOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem, BaseLayout } from '../../configs/styles/BaseGrid'

export const Container = styled(BaseContainer)``

export const ExternalLink = styled(({ color, ...props }) => <a {...props} />)`
  align-items: center;
  color: ${props => props.theme.palette.MP[props.color].main};
  display: flex;
  font-size: 14px;
  height: 42px;
  justify-content: center;
  text-decoration: none;
  user-select: none;

  &:hover {
    color: ${props => props.theme.palette.MP[props.color].hover};
  }
`

export const GoBackIcon = styled(BaseArrowBack)`
  &.MuiSvgIcon-root {
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }
`

export const Item = styled(BaseItem)``

export const LinkButton = styled(({ variant, ...props }) => <a {...props} />)`
  align-items: center;
  background: ${props =>
    props.variant === 'outlined' ? props.theme.palette.brand.white : props.theme.palette.MP.endeavour};
  border: ${props => `1px solid ${props.theme.palette.MP.endeavour}`};
  color: ${props =>
    props.variant === 'outlined' ? props.theme.palette.MP.endeavour : props.theme.palette.brand.white};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  width: 160px;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }

  &:hover {
    color: ${props => props.theme.palette.brand.white};
    background: ${props =>
      props.variant === 'outlined' ? props.theme.palette.MP.endeavour : props.theme.palette.MP.congressBlue};
  }
`

export const Logo = styled.img`
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 215px;

  ${props => props.theme.breakpoints.down('md')} {
    width: 185px;
  }

  ${props => props.theme.breakpoints.only('xs')} {
    width: 155px;
  }
`

export const LogoLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const Navbar = styled(BaseLayout)``

export const NavbarLink = styled(({ selected, ...props }) => <a {...props} />)`
  align-items: center;
  border-top: 5px solid ${props => (props.selected ? props.theme.palette.MP.mountainMeadow : 'transparent')};
  color: ${props => props.theme.palette.MP.veniceBlue};
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  justify-content: center;
  padding: 0 16px;
  text-decoration: none;
  user-select: none;

  ${props => props.theme.breakpoints.only('xs')} {
    padding: 0 8px;
  }

  &:hover {
    border-top: 5px solid
      ${props => (props.selected ? props.theme.palette.MP.mountainMeadow : props.theme.palette.MP.corduroy)};
    box-shadow: inset 0 -3px 0 0 ${props => props.theme.palette.brand.white};
    color: ${props => props.theme.palette.MP.regalBlue};
    text-decoration: none;
  }

  &:focus {
    background: ${props => props.theme.palette.MP.black};
    color: ${props => props.theme.palette.brand.white};
  }
`

export const ShoppingCartIcon = styled(BaseShoppingCartOutlined)`
  &.MuiSvgIcon-root {
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }
`

export const TopLine = styled(BaseContainer)`
  &.MuiGrid-container {
    background: transparent url(/assets/images/top-bar.jpg) repeat-x;
    overflow: hidden;
    position: relative;
  }
`

export const Wrapper = styled(Item)`
  &.MuiGrid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    ${props => props.theme.breakpoints.down('sm')} {
      padding: 8px 0 0;
    }
  }
`

import { batch } from 'react-redux'
import { customizeError } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import { getIdentity } from '../../services/identities'

export const ADD_MEETING_ASSISTANT_SUCCESS = 'ADD_MEETING_ASSISTANT_SUCCESS'
export const onAddMeetingAssistantSuccess = makeActionCreator(ADD_MEETING_ASSISTANT_SUCCESS, 'payload')

export const CLOSE_MEETING_DIALOG = 'CLOSE_MEETING_DIALOG'
export const onCloseMeetingDialog = makeActionCreator(CLOSE_MEETING_DIALOG)

export const GET_ASSISTANT_INFO = 'GET_ASSISTANT_INFO'
export const GET_ASSISTANT_INFO_ERROR = 'GET_ASSISTANT_INFO_ERROR'
export const GET_ASSISTANT_INFO_SUCCESS = 'GET_ASSISTANT_INFO_SUCCESS'
export const onGetAssistant = makeActionCreator(GET_ASSISTANT_INFO, 'payload')
export const onGetAssistantError = makeActionCreator(GET_ASSISTANT_INFO_ERROR, 'payload')
export const onGetAssistantSuccess = makeActionCreator(GET_ASSISTANT_INFO_SUCCESS, 'payload')
export const onGetAssistantThunk = payload => async dispatch => {
  const { id, dni } = payload || {}

  dispatch(onGetAssistant({ id }))

  try {
    const { data } = await getIdentity({ dni })

    const assistant = {
      dni,
      lastNames:
        data.payload?.apellidoPaterno && data.payload?.apellidoMaterno
          ? `${data.payload?.apellidoPaterno} ${data.payload?.apellidoMaterno}`
          : data.payload?.apellidoPaterno || '',
      names: data.payload?.nombres || ''
    }

    return batch(() => {
      dispatch(onGetAssistantSuccess({ assistant, id }))
      dispatch(
        onUpdateDeceasedAssistantSuccess({ id, key: 'deceased', value: data.payload?.fechaDefuncion !== '0000-00-00' })
      )
    })
  } catch (error) {
    return dispatch(
      onGetAssistantError({
        error: customizeError({ code: error.code, originalError: error.originalError }).toObject(),
        id
      })
    )
  }
}

export const REMOVE_MEETING_ASSISTANT_SUCCESS = 'REMOVE_MEETING_ASSISTANT_SUCCESS'
export const onRemoveMeetingAssistantSuccess = makeActionCreator(REMOVE_MEETING_ASSISTANT_SUCCESS, 'payload')

export const RESET_MEETING_FORM = 'RESET_MEETING_FORM'
export const onResetMeetingForm = makeActionCreator(RESET_MEETING_FORM)

export const SHOW_MEETING_DIALOG = 'SHOW_MEETING_DIALOG'
export const onShowMeetingDialog = makeActionCreator(SHOW_MEETING_DIALOG, 'payload')

export const UPDATE_MEETING_ASSISTANT_SUCCESS = 'UPDATE_MEETING_ASSISTANT_SUCCESS'
export const onUpdateMeetingAssistantSuccess = makeActionCreator(UPDATE_MEETING_ASSISTANT_SUCCESS, 'payload')
export const onUpdateMeetingAssistantThunk = payload => dispatch => {
  const { id } = payload || {}
  const validDuplicated = payload.key === 'dni' || payload.key === 'foreignDni'

  return batch(() => {
    dispatch(onUpdateMeetingAssistantSuccess(payload))
    validDuplicated && dispatch(onUpdateDuplicatedAssistantThunk({ id }))
  })
}

export const UPDATE_DECEASED_ASSISTANT_SUCCESS = 'UPDATE_DECEASED_ASSISTANT_SUCCESS'
export const onUpdateDeceasedAssistantSuccess = makeActionCreator(UPDATE_DECEASED_ASSISTANT_SUCCESS, 'payload')

export const UPDATE_DUPLICATED_ASSISTANT_SUCCESS = 'UPDATE_DUPLICATED_ASSISTANT_SUCCESS'
export const onUpdateDuplicatedAssistantSuccess = makeActionCreator(UPDATE_DUPLICATED_ASSISTANT_SUCCESS, 'payload')
export const onUpdateDuplicatedAssistantThunk = payload => async (dispatch, getState) => {
  const { id } = payload || {}
  const { assistants } = getState().requestMeetingDialog

  const updated = assistants.find(assistant => assistant.id === id)
  const duplicated = assistants.some(assistant => {
    return (
      assistant.id !== updated.id &&
      ((assistant.dni !== '' && assistant.dni === updated.dni) ||
        (assistant.foreignDni !== '' && assistant.foreignDni === updated.foreignDni))
    )
  })

  return dispatch(onUpdateDuplicatedAssistantSuccess({ ...payload, key: 'duplicated', value: duplicated }))
}

export const UPDATE_MEETING_VALUE = 'UPDATE_MEETING_VALUE'
export const onUpdateRequestMeetingValue = makeActionCreator(UPDATE_MEETING_VALUE, 'payload')

import { combineReducers } from 'redux'
import authProviderReducer from '../../containers/AuthProvider/AuthProvider.reducer'
import publicUserAuthProviderReducer from '../../containers/PublicUserAuthProvider/PublicUserAuthProvider.reducer'
import requestCreateFormReducer from '../../containers/RequestCreateForm/RequestCreateForm.reducer'
import requestCreateInnovationFormReducer from '../../containers/RequestCreateInnovationForm/RequestCreateInnovationForm.reducer'
import requestDetailInfoReducer from '../../containers/RequestDetailInfo/RequestDetailInfo.reducer'
import requestExtendEndDateReducer from '../../containers/RequestExtendEndDate/RequestExtendEndDate.reducer'
import requestFeedbackConfirmDialogReducer from '../../containers/RequestFeedbackConfirmDialog/RequestFeedbackConfirmDialog.reducer'
import requestFeedbackErrorDialogReducer from '../../containers/RequestFeedbackErrorDialog/RequestFeedbackErrorDialog.reducer'
import requestHistoryReducer from '../../containers/RequestHistory/RequestHistory.reducer'
import requestListReducer from '../../containers/RequestList/RequestList.reducer'
import requestMeetingDialogReducer from '../../containers/RequestMeetingDialog/RequestMeetingDialog.reducer'
import requestProductDialogReducer from '../../containers/RequestProductDialog/RequestProductDialog.reducer'
import requestQuestionsLibraryDialogReducer from '../../containers/RequestQuestionsLibraryDialog/RequestQuestionsLibraryDialog.reducer'
import requestReasonDialogReducer from '../../containers/RequestReasonDialog/RequestReasonDialog.reducer'
import requestUniquePassDialogReducer from '../../containers/RequestUniquePassDialog/RequestUniquePassDialog.reducer'
import snackbarProviderReducer from '../../containers/SnackbarProvider/SnackbarProvider.reducer'

export default combineReducers({
  authProvider: authProviderReducer,
  publicUserAuthProvider: publicUserAuthProviderReducer,
  requestCreateForm: requestCreateFormReducer,
  requestCreateInnovationForm: requestCreateInnovationFormReducer,
  requestDetailInfo: requestDetailInfoReducer,
  requestExtendEndDate: requestExtendEndDateReducer,
  requestFeedbackConfirmDialog: requestFeedbackConfirmDialogReducer,
  requestFeedbackErrorDialog: requestFeedbackErrorDialogReducer,
  requestHistory: requestHistoryReducer,
  requestList: requestListReducer,
  requestMeetingDialog: requestMeetingDialogReducer,
  requestProductDialog: requestProductDialogReducer,
  requestQuestionsLibraryDialog: requestQuestionsLibraryDialogReducer,
  requestReasonDialog: requestReasonDialogReducer,
  requestUniquePassDialog: requestUniquePassDialogReducer,
  snackbarProvider: snackbarProviderReducer
})

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { onCloseFeedbackErrorDialog } from './RequestFeedbackErrorDialog.actions'
import { DialogButtons, DialogContent, DialogIcon, DialogMessage } from './RequestFeedbackErrorDialog.styles'

/**
 * The RequestFeedbackErrorDialog's container.
 */
const RequestFeedbackErrorDialog = () => {
  const dispatch = useDispatch()

  const { showDialog } = useSelector(state => state.requestFeedbackErrorDialog)

  const handleCloseFeedbackErrorDialog = () => dispatch(onCloseFeedbackErrorDialog())

  return (
    <Dialog
      disableBackdropClick
      disableCloseIcon
      margin="auto"
      maxWidth="500px"
      onClose={handleCloseFeedbackErrorDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent alignItems="center" justifyContent="center" padding="32px">
        <DialogIcon alt="Error al publicar consulta al mercado" src="/assets/icons/circle-error.svg" />

        <DialogMessage alignItems="center" justifyContent="center" margin="16px 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            La consulta no pudo ser publicada
          </Typography>

          <Typography variant="body2">
            Lo sentimos, no hemos podido publicar tu solicitud, por favor inténtalo en unos minutos más.
          </Typography>
        </DialogMessage>

        <DialogButtons alignItems="center" justifyContent="center">
          <Button color="default" onClick={handleCloseFeedbackErrorDialog}>
            Volver
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestFeedbackErrorDialog

import React, { memo, useCallback, useEffect } from 'react'
import { batch } from 'react-redux'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  formatDNI,
  useCheckBox,
  useInput,
  useViewport
} from '@chilecompra/react-kit'
import { Button, Input } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import {
  checkErrorDni,
  checkErrorDniBusiness,
  checkErrorDniForeign,
  checkNationalDniError
} from '../../modules/utils/checkers'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { isNaturalPerson } from '../../modules/utils/helpers'
import {
  Assistant,
  CheckBox,
  Container,
  RemoveIcon,
  Search,
  SearchIcon,
  SearchLoadingIcon
} from './RequestMeetingDialog.styles'

/**
 * The RequestMeetingDialog's assistant.
 */
const AssistantItem = props => {
  const {
    deceasedError,
    disabledIdentity,
    dni,
    duplicatedError,
    foreignDni,
    isForeign,
    lastNames,
    loadingIdentity,
    names,
    onFetch,
    onRemove,
    onUpdate,
    onUpdateForm,
    position,
    removable
  } = props

  const { size } = useViewport()

  const { onChange: handleIsForeignChange, value: isForeignValue } = useCheckBox({
    changeCallback: updatedValue => {
      handleDniReset()
      handleForeignDnReset()
      handleNamesReset()
      handlePositionReset()
      handleResetLastNames()

      batch(() => {
        onUpdate({ key: 'isForeign', value: updatedValue })
        onUpdate({ key: 'dni', value: '' })
        onUpdate({ key: 'foreignDni', value: '' })
        onUpdate({ key: 'lastNames', value: '' })
        onUpdate({ key: 'names', value: '' })
        onUpdate({ key: 'position', value: '' })
      })
    },
    overwriteCallback: () => isForeign
  })

  const {
    error: dniError,
    onChange: handleDniChange,
    onReset: handleDniReset,
    value: dniValue
  } = useInput({
    changeCallback: updatedValue => {
      handleResetLastNames()
      handleNamesReset()
      handlePositionReset()

      batch(() => {
        onUpdate({ key: 'dni', value: formatDNI(updatedValue, 2) })
        onUpdate({ key: 'deceased', value: false })
        onUpdate({ key: 'lastNames', value: '' })
        onUpdate({ key: 'names', value: '' })
        onUpdate({ key: 'position', value: '' })
      })
    },
    errorCallbacks: [
      checkInputErrorRequired(),
      checkErrorDni('RUT con formato incorrecto'),
      checkErrorDniBusiness(),
      checkInputErrorMaxLength(15)
    ],
    formatCallbacks: [formatDNI],
    overwriteCallback: () => formatDNI(dni)
  })

  const {
    error: foreignDniError,
    onChange: handleForeignDniChange,
    onReset: handleForeignDnReset,
    value: foreignDniValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'foreignDni', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired(),
      checkErrorDniForeign('ID o DNI con formato incorrecto'),
      checkNationalDniError()
    ],
    overwriteCallback: () => foreignDni
  })

  const {
    error: namesError,
    onChange: handleNamesChange,
    onReset: handleNamesReset,
    value: namesValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'names', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => names
  })

  const {
    error: lastNamesError,
    onChange: handleLastNamesChange,
    onReset: handleResetLastNames,
    value: lastNamesValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'lastNames', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => lastNames
  })

  const {
    error: positionError,
    onChange: handlePositionChange,
    onReset: handlePositionReset,
    value: positionValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'position', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => position
  })

  const handleDniFetch = useCallback(() => {
    if (!dniError && dniValue && isNaturalPerson(dniValue)) onFetch(formatDNI(dniValue, 2))
  }, [dniError, dniValue])

  const dniErrorText = useCallback(() => {
    if (dniError !== '') return dniError
    if (deceasedError) return 'El RUT es de una persona fallecida.'
    if (duplicatedError) return 'No puedes ingresar dos veces al mismo asistente.'
  }, [deceasedError, dniError, duplicatedError])

  const foreignDniErrorText = useCallback(() => {
    if (foreignDniError !== '') return foreignDniError
    if (duplicatedError) return 'No puedes ingresar dos veces al mismo asistente.'
  }, [foreignDniError, duplicatedError])

  useEffect(() => {
    const validateForm = dniError !== '' || lastNamesError !== '' || namesError !== '' || positionError !== ''

    if (!validateForm) return

    onUpdateForm({ key: 'isErrorForm', value: true })

    return () => onUpdateForm({ key: 'isErrorForm', value: false })
  }, [dniError, lastNamesError, namesError, positionError])

  return (
    <>
      <Container margin="16px 0 8px">
        <CheckBox
          checked={isForeignValue}
          disabled={false}
          label="Usuario extranjero"
          margin="0"
          onChange={handleIsForeignChange}
        />
      </Container>

      <Assistant removable={removable}>
        {!isForeignValue && (
          <Input
            disabled={loadingIdentity}
            error={dniErrorText()}
            label="RUT"
            maxLength={15}
            onChange={handleDniChange}
            required
            suffix={
              <Search
                color="primary"
                disabled={!!dniError || dniValue === '' || duplicatedError || loadingIdentity}
                onClick={handleDniFetch}
                padding="0"
                width="45px"
              >
                {loadingIdentity && <SearchLoadingIcon />}
                {!loadingIdentity && <SearchIcon />}
              </Search>
            }
            value={dniValue}
          />
        )}

        {isForeignValue && (
          <Input
            disabled={loadingIdentity}
            error={foreignDniErrorText()}
            label="Id extranjero"
            maxLength={15}
            onChange={handleForeignDniChange}
            required
            value={foreignDniValue}
          />
        )}

        <Input
          disabled={!isForeignValue && disabledIdentity}
          error={namesError}
          label="Nombres"
          loading={loadingIdentity}
          onChange={handleNamesChange}
          required
          value={namesValue}
        />

        <Input
          disabled={!isForeignValue && disabledIdentity}
          error={lastNamesError}
          label="Apellidos"
          loading={loadingIdentity}
          onChange={handleLastNamesChange}
          required
          value={lastNamesValue}
        />

        <Input
          disabled={loadingIdentity}
          error={positionError}
          label="Cargo"
          onChange={handlePositionChange}
          required
          value={positionValue}
        />

        {removable && size.isUpToMedium && <RemoveIcon onClick={onRemove} />}

        {removable && size.isDownToSmall && (
          <Button onClick={onRemove} padding="0" variant="outlined">
            Borrar
          </Button>
        )}
      </Assistant>
    </>
  )
}

AssistantItem.propTypes = {
  deceasedError: PropTypes.bool.isRequired,
  disabledIdentity: PropTypes.bool.isRequired,
  dni: PropTypes.string.isRequired,
  duplicatedError: PropTypes.bool.isRequired,
  foreignDni: PropTypes.string.isRequired,
  isForeign: PropTypes.bool.isRequired,
  lastNames: PropTypes.string.isRequired,
  loadingIdentity: PropTypes.bool.isRequired,
  names: PropTypes.string.isRequired,
  onFetch: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateForm: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
  removable: PropTypes.bool.isRequired
}

export default memo(AssistantItem)

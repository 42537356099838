import React from 'react'
import { DatePicker as BaseDatePicker, Spinner as BaseSpinner } from '@chilecompra/react-kit/components'
import { Search as BaseSearchIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Container = styled(BaseContainer)``

export const DatePicker = styled(BaseDatePicker)`
  &.MuiFormControl-root {
    &.calendar__icon {
      .MuiSvgIcon-root {
        height: 100%;
        padding: 0 6px;
      }

      .Mui-disabled .MuiSvgIcon-root {
        cursor: not-allowed;
      }
    }
  }
`

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    grid-template-columns: 26% 20% repeat(3, 18%);

    ${props => props.theme.breakpoints.only('lg')} {
      grid-template-columns: 22% repeat(3, 20%) 18%;
    }

    ${props => props.theme.breakpoints.down('md')} {
      display: flex;
    }
  }
`

export const Item = styled(BaseItem)``

export const SearchIcon = styled(BaseSearchIcon)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.white};
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} color="white" size="18px" />)``

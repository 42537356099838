import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { features } from '../../configs/settings/features'

/**
 * The ProtectByFeatures' component.
 */
const ProtectByFeatures = props => {
  const { children, fallback, redirectTo, rule } = props

  const navigate = useNavigate()
  const result = rule(features)

  if (!result && redirectTo) navigate(redirectTo, { replace: true })
  if (!result) return fallback(features)

  return <>{children}</>
}

ProtectByFeatures.defaultProps = {
  fallback: () => null,
  redirectTo: '',
  rule: () => true
}

ProtectByFeatures.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
  redirectTo: PropTypes.string,
  rule: PropTypes.func
}

export default ProtectByFeatures

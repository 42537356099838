import React, { memo, useEffect, useCallback, useRef } from 'react'
import { checkInputErrorRequired, useDatePicker, useViewport } from '@chilecompra/react-kit'
import { add, differenceInDays, differenceInHours } from 'date-fns'
import PropTypes from 'prop-types'
import TimePicker, { useTimePicker } from '../../components/Inputs/TimePicker'
import { ClosingDate, DatePicker } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's closingDate.
 */
const ClosingDateSection = props => {
  const { dateTime, isDisabled, onUpdate, submitError } = props

  const { size } = useViewport()
  const dateTimeRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))
  const validMinOneDay = differenceInDays(dateTime, new Date()) === 0 || differenceInHours(dateTime, new Date()) < 24

  const {
    error: dateError,
    onChange: handleDateChange,
    value: dateValue
  } = useDatePicker({
    changeCallback: updatedValue => onUpdate({ key: 'dateTime', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired()],
    errorDependencies: [submitError],
    overwriteCallback: () => dateTime
  })

  const {
    error: timeError,
    onChange: handleTimeChange,
    value: timeValue
  } = useTimePicker({
    changeCallback: updatedValue => onUpdate({ key: 'dateTime', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired()],
    errorDependencies: [submitError],
    overwriteCallback: () => dateTime
  })

  const dateTimeErrorText = useCallback(() => {
    if (submitError?.includes('MIN_ONE_DAY_ERROR') && validMinOneDay) {
      return 'La fecha de cierre debe ser minimo a 24 horas.'
    }

    return ''
  }, [submitError, validMinOneDay])

  useEffect(() => {
    if (conditionsFields(['ADDITIONAL_INFO_UNSET_ERROR', 'PRODUCTS_UNSET_ERROR', 'WILL_BE_PUBLIC_UNSET_ERROR'])) return

    if (conditionsFields(['DATE_TIME_UNSET_ERROR ', 'MIN_ONE_DAY_ERROR'])) {
      const element = dateTimeRef.current
      const headerOffset = size.isDownToSmall ? 108 : 32
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      return window.scrollTo({ top: offsetPosition })
    }
  }, [submitError])

  return (
    <ClosingDate ref={dateTimeRef}>
      <DatePicker
        className="calendar__icon"
        disabled={isDisabled}
        error={dateError || dateTimeErrorText()}
        format="dd/MM/yyyy"
        label="Fecha"
        minDate={add(new Date(), { days: 1 })}
        onChange={handleDateChange}
        required
        value={dateValue}
      />

      <TimePicker
        disabled={isDisabled}
        error={timeError || dateTimeErrorText()}
        label="Hora"
        onChange={handleTimeChange}
        required
        value={timeValue}
      />
    </ClosingDate>
  )
}

ClosingDateSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

ClosingDateSection.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired,
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(ClosingDateSection)

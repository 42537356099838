import React, { memo } from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Button, Toast } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { Meetings, Strong } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's meetings.
 */
const MeetingsSection = props => {
  const { children, isDisabled, meetingsLength, onShowMeetingDialog } = props

  const { size } = useViewport()

  return (
    <Meetings>
      {meetingsLength === 0 && (
        <Toast severity="info">
          Puedes registrar las reuniones ya realizadas con proveedores. También{' '}
          <Strong>podrás registrar las reuniones</Strong> que tengas a medida que avance la consulta, incluso posterior
          a su fecha de cierre.
        </Toast>
      )}

      {meetingsLength > 0 && children}

      <Button
        color="primary"
        disabled={isDisabled}
        margin="16px 0 0"
        onClick={onShowMeetingDialog}
        variant="outlined"
        width={size.isTiny ? '100%' : '240px'}
      >
        {meetingsLength > 0 ? 'Ingresar otra reunión' : 'Ingresar reunión'}
      </Button>
    </Meetings>
  )
}

MeetingsSection.defaultProps = {
  isDisabled: false
}

MeetingsSection.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  meetingsLength: PropTypes.number.isRequired,
  onShowMeetingDialog: PropTypes.func.isRequired
}

export default memo(MeetingsSection)

import {
  DNI_FOREIGN_CHECK,
  ONLY_LETTERS_NUMBERS_AND_SOME_SYMBOLS_CHECK,
  URL_CHECK
} from '../../configs/settings/regexes'
import { isValidDni } from './dni-utils'
import { isNaturalPerson } from './helpers'

/**
 * Check if the given string value has a DNI valid.
 */
export const checkErrorDni = customMessage => value => isValidDni(value) ? '' : customMessage || 'El rut es inválido'

/**
 * Check if the given string value has a DNI valid for person.
 */
export const checkErrorDniBusiness = customMessage => value => {
  return isNaturalPerson(value) ? '' : customMessage || 'No se permiten personas jurídicas'
}

/**
 * Check if the given string value has a DNI foreign valid.
 */
export const checkErrorDniForeign = customMessage => value => {
  return DNI_FOREIGN_CHECK.test(value) ? '' : customMessage || 'El dni es inválido.'
}

/**
 * Check if the given string value is a National DNI.
 */
export const checkNationalDniError = customMessage => value => {
  return isValidDni(value) ? customMessage || 'Has ingresado un RUT chileno' : ''
}

/**
 * To check if the given value contains only letters, numbers, and some symbols (._-,).
 */
export const checkOnlyLettersNumbersAndSomeSymbols = value => ONLY_LETTERS_NUMBERS_AND_SOME_SYMBOLS_CHECK.test(value)

/**
 * Check if the given string value is a URL.
 */
export const checkErrorUrl = customMessage => value => {
  return URL_CHECK.test(value) ? '' : customMessage || 'La URL tiene un formato incorrecto.'
}

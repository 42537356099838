import React from 'react'
import { Link as BaseLink } from 'react-router-dom'
import { Card as BaseCard, Skeleton as BaseSkeleton, Spinner as BaseSpinner } from '@chilecompra/react-kit/components'
import { ArrowDownward as BaseArrowDownward } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const ArrowDownIcon = styled(({ disabled, isErrorDownloading, ...props }) => <BaseArrowDownward {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props =>
      props.disabled
        ? props.theme.palette.brand.disableFont
        : props.isErrorDownloading
        ? props.theme.palette.red[50]
        : props.theme.palette.blue[50]};
    height: 16px;
    margin-left: 8px;
    width: 16px;
  }
`

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    display: flex;

    &.not__found {
      align-items: center;
      justify-content: center;
      min-height: 320px;

      .MuiGrid-container {
        height: 100%;
        text-align: center;
      }
    }

    &.request {
      align-items: center;
      justify-content: center;
      min-height: 130px;

      .MuiGrid-container {
        .MuiTypography-root {
          &.text__row {
            &--1 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              overflow: hidden;
            }

            &--2 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              overflow: hidden;
            }
          }
        }
      }

      &:hover {
        background: ${props => props.theme.palette.gray[95]};
      }
    }
  }
`

export const Container = styled(BaseContainer)``

export const DownloadLink = styled(({ disabled, isErrorDownloading, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props =>
      props.disabled
        ? props.theme.palette.brand.disableFont
        : props.isErrorDownloading
        ? props.theme.palette.red[50]
        : props.theme.palette.blue[50]};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => (props.isErrorDownloading ? props.theme.palette.red[35] : props.theme.palette.blue[35])};
      text-decoration: underline;
    }
  }
`

export const Item = styled(({ textAlign, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    text-align: ${props => props.textAlign};
    width: 100%;
  }
`

export const Link = styled(({ disabled, ...props }) => <BaseLink {...props} />)`
  align-items: center;
  color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: 400;
  pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
    text-decoration: underline;
  }
`

export const NotFoundImg = styled.img`
  color: ${props => props.theme.palette.gray[50]};
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 82px;
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} size="18px" />)`
  &.MuiCircularProgress-root {
    margin-left: 8px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  &.MuiSkeleton-root {
    margin-bottom: 16px;
    transform: none;
  }
`

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    grid-template-columns: 3fr repeat(2, 1fr) 1.5fr 1fr;

    ${props => props.theme.breakpoints.between('sm', 'md')} {
      grid-template-columns: 2fr repeat(2, 1fr);

      .MuiGrid-item:nth-child(1) {
        grid-row: 1 / 3;
      }

      .MuiGrid-item:nth-child(4) {
        grid-column: 2;

        .MuiGrid-container {
          justify-content: end;
        }
      }

      .MuiGrid-item:nth-child(5) {
        grid-column: 3;
        text-align: end;
      }
    }

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: repeat(2, 1fr);

      .MuiGrid-item:nth-child(1) {
        grid-column: 1 / 4;
      }

      .MuiGrid-item:nth-child(2) {
        grid-column: 1 / 2;
        text-align: start;
      }

      .MuiGrid-item:nth-child(3) {
        grid-column: 2 / 4;
        text-align: start;
      }

      .MuiGrid-item:nth-child(4) {
        grid-column: 2 / 4;
        grid-row: 1;
        padding: 0;

        .MuiGrid-container {
          justify-content: end;
        }
      }

      .MuiGrid-item:nth-child(5) {
        grid-column: 1 / 4;
        text-align: start;
      }
    }
  }
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { usePublicUserAuthProvider } from '../PublicUserAuthProvider/PublicUserAuthProvider.hook'
import { onCloseUniquePassDialog } from './RequestUniquePassDialog.actions'
import { UniquePassIcon, DialogButtons, DialogContent, DialogMessage } from './RequestUniquePassDialog.styles'

/**
 * The RequestUniquePassDialog's container.
 */
const RequestUniquePassDialog = () => {
  const dispatch = useDispatch()
  const { isPrivate } = useAuthProvider()
  const { onLoginUniquePass } = usePublicUserAuthProvider()
  const { loading } = useSelector(state => state.requestFeedbackConfirmDialog)
  const { showDialog } = useSelector(state => state.requestUniquePassDialog)

  const handleCloseUniquePassDialog = () => dispatch(onCloseUniquePassDialog())
  const handleSignedUniquePass = () => onLoginUniquePass()

  if (isPrivate) return null

  return (
    <Dialog
      disableBackdropClick
      disableCloseIcon
      margin="auto"
      maxWidth="500px"
      onClose={handleCloseUniquePassDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent alignItems="center" justifyContent="center" padding="32px">
        <DialogMessage alignItems="center" justifyContent="center" margin="16px 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            Para responder debes verificar tu identidad con Clave Única
          </Typography>

          <Typography variant="body2">Antes de responder, deberás verificar tu identidad con Clave Única.</Typography>
        </DialogMessage>

        <DialogButtons alignItems="center" direction="column" justifyContent="center">
          <Button
            color="primary"
            disabled={loading}
            loading={loading}
            margin="0 0 16px"
            onClick={handleSignedUniquePass}
          >
            <UniquePassIcon alt="CU" src="/assets/icons/unique-pass.svg" />
            Ir a Clave Única
          </Button>

          <Button color="default" disabled={loading} onClick={handleCloseUniquePassDialog} variant="text">
            Cancelar y volver
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestUniquePassDialog

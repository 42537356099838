export const {
  PUBLIC_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_COMPRA_AGIL_BASE_URL,
  REACT_APP_API_PRINCIPAL_URL,
  REACT_APP_BASE_URL,
  REACT_APP_CAPACITACION_BASE_URL,
  REACT_APP_CENTRO_AYUDA_BASE_URL,
  REACT_APP_CHILECOMPRA_BASE_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_FEATURE_FLAG_REQUEST_INNOVATION,
  REACT_APP_FEATURE_FLAG_SHOW_CU,
  REACT_APP_FEATURE_FLAG_SHOW_TRAINING,
  REACT_APP_GOOGLE_ANALYTICS_KEY = null,
  REACT_APP_GOOGLE_CAPTCHA_KEY = null,
  REACT_APP_GOOGLE_TASK_MANAGER_ID = null,
  REACT_APP_HOTJAR_ID = null,
  REACT_APP_HOTJAR_SNIPPET_VERSION = null,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_MERCADO_PUBLICO_BASE_URL,
  REACT_APP_PROVIDERS_BASE_URL
} = process.env

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod' && process.env.REACT_APP_ENVIRONMENT !== 'test'

import React from 'react'
import { createPortal } from 'react-dom'
import {
  REACT_APP_CAPACITACION_BASE_URL,
  REACT_APP_CENTRO_AYUDA_BASE_URL,
  REACT_APP_CHILECOMPRA_BASE_URL,
  REACT_APP_MERCADO_PUBLICO_BASE_URL
} from '../../configs/settings/environment'
import { NavbarLink, Navbar, TopLine } from './Header.styles'

/**
 * The Header's navbar transversal.
 */
const NavbarTransversal = () => {
  const navbarLinksList = [
    { href: REACT_APP_CHILECOMPRA_BASE_URL, label: 'ChileCompra' },
    { href: `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`, isSelected: true, label: 'Mercado Público' },
    { href: REACT_APP_CAPACITACION_BASE_URL, label: 'Capacitación' },
    { href: REACT_APP_CENTRO_AYUDA_BASE_URL, label: 'Centro de Ayuda' }
  ]

  const element = document.getElementById('__menu')

  const tree = (
    <TopLine>
      <Navbar justifyContent="flex-end">
        {navbarLinksList.map(navbarLink => (
          <NavbarLink href={navbarLink.href} key={navbarLink.label} selected={navbarLink.isSelected} target="_top">
            {navbarLink.label}
          </NavbarLink>
        ))}
      </Navbar>
    </TopLine>
  )

  if (!element) return null

  return createPortal(tree, element)
}

export default NavbarTransversal

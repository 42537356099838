import { customizeError, formatDNI, putCookie } from '@chilecompra/react-kit'
import { addMilliseconds } from 'date-fns'
import { makeActionCreator } from '../../configs/store/utils'
import { getAuthInfo, getPublicToken } from '../../services/auth'

export const GET_AUTH_INFO = 'GET_AUTH_INFO'
export const GET_AUTH_INFO_ERROR = 'GET_AUTH_INFO_ERROR'
export const GET_AUTH_INFO_SUCCESS = 'GET_AUTH_INFO_SUCCESS'
export const onGetAuthInfo = makeActionCreator(GET_AUTH_INFO)
export const onGetAuthInfoError = makeActionCreator(GET_AUTH_INFO_ERROR, 'payload')
export const onGetAuthInfoSuccess = makeActionCreator(GET_AUTH_INFO_SUCCESS, 'payload')
export const onGetAuthInfoThunk = () => async dispatch => {
  dispatch(onGetAuthInfo())

  try {
    const { data } = await getAuthInfo()

    const authInfo = {
      business: { dni: formatDNI(data.payload?.rut, 2) || '', name: data.payload?.razonSocial || '' },
      entCode: data.payload?.codigoEmpresa || '',
      orgCode: data.payload?.codigoOrganismo || '',
      roles: data.payload?.roles || [],
      userType: data.payload?.tipoUsuario || 'Publico',
      usrCode: data.payload?.codigoUsuario || ''
    }

    return dispatch(onGetAuthInfoSuccess({ authInfo }))
  } catch (error) {
    return dispatch(
      onGetAuthInfoError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'UNAUTHORIZED_ERROR'
        }).toObject()
      })
    )
  }
}

export const GET_PUBLIC_TOKEN = 'GET_PUBLIC_TOKEN'
export const GET_PUBLIC_TOKEN_ERROR = 'GET_PUBLIC_TOKEN_ERROR'
export const GET_PUBLIC_TOKEN_SUCCESS = 'GET_PUBLIC_TOKEN_SUCCESS'
export const onGetPublicToken = makeActionCreator(GET_PUBLIC_TOKEN)
export const onGetPublicTokenError = makeActionCreator(GET_PUBLIC_TOKEN_ERROR, 'payload')
export const onGetPublicTokenSuccess = makeActionCreator(GET_PUBLIC_TOKEN_SUCCESS)
export const onGetPublicTokenThunk = () => async dispatch => {
  dispatch(onGetPublicToken())

  try {
    const { data } = await getPublicToken()

    putCookie('access_token', data.payload?.access_token, {
      expires: addMilliseconds(new Date(), data.payload?.expires_in * 1000)
    })

    return dispatch(onGetPublicTokenSuccess())
  } catch (error) {
    return dispatch(
      onGetPublicTokenError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'PUBLIC_TOKEN_ERROR'
        }).toObject()
      })
    )
  }
}

import React, { memo, useState } from 'react'
import { formatDate } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import ProtectByProfile from '../../components/Security/ProtectByProfile'
import {
  DETAIL_SECTION_DICTIONARY,
  STATUS_OPTIONS_DICTIONARY,
  TEXT_MAX_LENGTH,
  USER_TYPES
} from '../../configs/settings/constants'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import SectionMeetings from './RequestDetailInfo.meetings'
import {
  AdditionalInfo,
  AttachedFiles,
  AttachedFilesLink,
  AttachedFilesWrapper,
  Container,
  Description,
  EndDate,
  ExtendEndDateLink,
  HasInvestigated,
  History,
  HistoryLink,
  Name,
  Products,
  ProductsWrapper,
  ProofsConceptRequired,
  PublicationDate,
  Reason,
  SearchLoadingIcon,
  SeeLessArrowUpIcon,
  SeeMoreArrowDownIcon,
  SeeMoreText,
  Skeleton,
  WhatHappening,
  WhenHappening,
  WhereHappening,
  WhomIsAProblem
} from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's section.
 */
const SectionsDetail = props => {
  const {
    additionalInfo,
    additionalInfoUrls,
    antecedents,
    attachedFiles,
    description,
    descriptionProblem,
    endDate,
    histories,
    historyProblem,
    isDisabled,
    isInnovation,
    isInvestigated,
    isLoading,
    loadingAttachedFiles,
    loadingMeetings,
    locationProblem,
    meetings,
    name,
    onDeleteMeeting,
    onDownloadFile,
    onShowExtendEndDateDialog,
    onShowHistoryDialog,
    onShowMeetingDialog,
    peopleAffected,
    products,
    publicationDate,
    reason,
    sectionName,
    status,
    willBeRequiredConcept
  } = props

  const [showSeeMore, setShowSeeMore] = useState(false)
  const { canChangeRFI } = useAuthProvider()

  const handleShowSeeMore = () => setShowSeeMore(!showSeeMore)

  const handleShowParagraph = textValue => {
    return showSeeMore
      ? textValue
      : `${textValue.trim()?.slice(0, TEXT_MAX_LENGTH)} ${textValue.trim()?.length > TEXT_MAX_LENGTH ? '...' : ''}`
  }

  return (
    <>
      {sectionName === DETAIL_SECTION_DICTIONARY.NAME && (
        <Name>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(name)}

            {!isLoading && name.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </Name>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.DESCRIPTION && (
        <Description>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(description)}

            {!isLoading && description.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </Description>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.REASON && (
        <Reason>
          <Typography variant="body2">{isLoading ? <Skeleton /> : reason}</Typography>
        </Reason>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.PUBLICATION_DATE && (
        <PublicationDate>
          <Typography variant="body2">
            {isLoading ? <Skeleton /> : formatDate(publicationDate, 'dd-MM-yyyy HH:mm')}
          </Typography>
        </PublicationDate>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.END_DATE && (
        <EndDate isLoading={isLoading}>
          <Typography variant="body2">
            {isLoading ? <Skeleton width="100%" /> : formatDate(endDate, 'dd-MM-yyyy HH:mm')}
          </Typography>

          <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER}>
            <Container>
              {isLoading && <Skeleton width="128px" />}

              {!isLoading && canChangeRFI && (
                <ExtendEndDateLink
                  disabled={isDisabled || status === STATUS_OPTIONS_DICTIONARY.CLOSED}
                  onClick={onShowExtendEndDateDialog}
                >
                  Ampliar fecha de cierre
                </ExtendEndDateLink>
              )}
            </Container>
          </ProtectByProfile>
        </EndDate>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.HISTORY && (
        <History>
          <Container>
            {isLoading && <Skeleton width="100%" />}

            {!isLoading && (
              <HistoryLink
                disabled={histories.length === 0 || isDisabled || status === STATUS_OPTIONS_DICTIONARY.CLOSED}
                onClick={onShowHistoryDialog}
              >
                Ver el Historial
              </HistoryLink>
            )}
          </Container>
        </History>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.PRODUCTS && (
        <Products>
          {isLoading && (
            <>
              <Skeleton margin="0 0 8px" width="100%" />
              <Skeleton margin="0 0 8px" width="100%" />
              <Skeleton width="100%" />
            </>
          )}

          {!isLoading && products.length === 0 && (
            <Typography color="black3" variant="body2">
              No se han registrado productos
            </Typography>
          )}

          {!isLoading &&
            products.length > 0 &&
            products.map(product => (
              <ProductsWrapper key={product.id} padding="8px 0">
                <Typography color="black3" variant="body2">
                  {product.code}
                </Typography>

                <Typography variant="body2">{product.name}</Typography>
              </ProductsWrapper>
            ))}
        </Products>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.ADDITIONAL_INFO && (
        <AdditionalInfo>
          <Typography variant="body2">
            {isLoading ? (
              <Skeleton />
            ) : additionalInfo ? (
              additionalInfoUrls.map(additionalInfoUrl => additionalInfoUrl.url)
            ) : (
              'No'
            )}
          </Typography>
        </AdditionalInfo>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.MEETINGS && (
        <SectionMeetings
          isLoading={isLoading || loadingMeetings}
          meetings={meetings}
          onDeleteMeeting={onDeleteMeeting}
          onShowMeetingDialog={onShowMeetingDialog}
        />
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.WHAT_HAPPENING && (
        <WhatHappening>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(descriptionProblem)}

            {!isLoading && descriptionProblem.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </WhatHappening>
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.WHEN_HAPPENING && (
        <WhenHappening>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(historyProblem)}

            {!isLoading && historyProblem.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </WhenHappening>
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.WHERE_HAPPENING && (
        <WhereHappening>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(locationProblem)}

            {!isLoading && locationProblem.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </WhereHappening>
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.HAS_INVESTIGATED && (
        <HasInvestigated>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : isInvestigated ? handleShowParagraph(antecedents) : 'No'}

            {!isLoading && antecedents.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </HasInvestigated>
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.WHOM_IS_A_PROBLEM && (
        <WhomIsAProblem>
          <Typography component="span" variant="body2">
            {isLoading ? <Skeleton /> : handleShowParagraph(peopleAffected)}

            {!isLoading && peopleAffected.length > TEXT_MAX_LENGTH && (
              <SeeMoreText onClick={handleShowSeeMore}>
                Ver {showSeeMore ? 'menos' : 'más'}
                {showSeeMore ? <SeeLessArrowUpIcon /> : <SeeMoreArrowDownIcon />}
              </SeeMoreText>
            )}
          </Typography>
        </WhomIsAProblem>
      )}

      {isInnovation && sectionName === DETAIL_SECTION_DICTIONARY.PROOFS_CONCEPT_REQUIRED && (
        <ProofsConceptRequired>
          <Typography variant="body2">
            {isLoading ? (
              <Skeleton />
            ) : willBeRequiredConcept ? (
              'Si, se requerirán pruebas de concepto'
            ) : (
              'No, no serán requeridos'
            )}
          </Typography>
        </ProofsConceptRequired>
      )}

      {sectionName === DETAIL_SECTION_DICTIONARY.FILES && (
        <AttachedFiles>
          {(isLoading || loadingAttachedFiles) && (
            <>
              <Skeleton margin="0 0 8px" width="100%" />
              <Skeleton margin="0 0 8px" width="100%" />
              <Skeleton width="100%" />
            </>
          )}

          {!isLoading && !loadingAttachedFiles && attachedFiles.length === 0 && (
            <Typography color="black3" variant="body2">
              No se han registrado archivos
            </Typography>
          )}

          {!isLoading &&
            !loadingAttachedFiles &&
            attachedFiles.length > 0 &&
            attachedFiles.map(file => (
              <AttachedFilesWrapper key={file.id}>
                {!file.loading && file.error && (
                  <Typography color="error" display="inline" margin="0 8px 0" padding="8px 0" variant="body2">
                    Error. Reintente la descarga de nuevo...
                  </Typography>
                )}

                <AttachedFilesLink disabled={file.loading} onClick={() => onDownloadFile(file)} padding="8px 0">
                  {file.loading && (
                    <>
                      Descargando... <SearchLoadingIcon />
                    </>
                  )}
                  {!file.loading && file.error && file.name}
                  {!file.loading && !file.error && file.name}
                </AttachedFilesLink>
              </AttachedFilesWrapper>
            ))}
        </AttachedFiles>
      )}
    </>
  )
}

SectionsDetail.defaultProps = {
  additionalInfoUrls: [],
  attachedFiles: [],
  histories: [],
  isDisabled: false,
  isLoading: false,
  loadingAttachedFiles: false,
  loadingMeetings: false,
  meetings: [],
  products: []
}

SectionsDetail.propTypes = {
  additionalInfo: PropTypes.bool.isRequired,
  additionalInfoUrls: PropTypes.array,
  antecedents: PropTypes.string.isRequired,
  attachedFiles: PropTypes.array,
  description: PropTypes.string.isRequired,
  descriptionProblem: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  histories: PropTypes.array,
  historyProblem: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInnovation: PropTypes.bool.isRequired,
  isInvestigated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  loadingAttachedFiles: PropTypes.bool,
  loadingMeetings: PropTypes.bool,
  locationProblem: PropTypes.string.isRequired,
  meetings: PropTypes.array,
  name: PropTypes.string.isRequired,
  onDeleteMeeting: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onShowExtendEndDateDialog: PropTypes.func.isRequired,
  onShowHistoryDialog: PropTypes.func.isRequired,
  onShowMeetingDialog: PropTypes.func.isRequired,
  peopleAffected: PropTypes.string.isRequired,
  products: PropTypes.array,
  publicationDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  reason: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  willBeRequiredConcept: PropTypes.bool.isRequired
}

export default memo(SectionsDetail)

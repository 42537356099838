import React, { useCallback, useMemo, useRef } from 'react'
import { Tooltip } from '@chilecompra/react-kit/components'
import DateFnsUtils from '@date-io/date-fns'
import EsLocale from 'date-fns/locale/es'
import PropTypes from 'prop-types'
import {
  BaseTimePicker,
  BaseTimePickerProvider,
  BottomMessage,
  Error,
  GlobalStyles,
  Info,
  Label,
  Placeholder,
  Required,
  Root,
  Skeleton,
  Suffix,
  TimeIcon,
  TooltipIcon,
  Wrapper
} from './TimePicker.styles'

/**
 * The TimePicker's component.
 */
const TimePicker = props => {
  const {
    disableFuture,
    disablePast,
    disableToolbar,
    disabled,
    error,
    format,
    info,
    label,
    loading,
    margin,
    maxDate,
    minDate,
    onChange,
    padding,
    placeholder,
    required,
    size,
    textAlign,
    tooltip,
    value,
    width,
    ...restProps
  } = props

  const open = useRef(false)

  const handleBlur = useCallback(
    event => {
      if (restProps?.onBlur && typeof restProps?.onBlur === 'function') restProps?.onBlur(event)
      if (open.current) return

      onChange(value)
    },
    [onChange, restProps, value]
  )

  const handleClick = useCallback(event => event?.stopPropagation(), [])

  const handleClose = useCallback(() => {
    open.current = false
  }, [])

  const handleOpen = useCallback(() => {
    open.current = true
  }, [])

  const showPlaceholder = useMemo(() => placeholder !== '' && value === null, [placeholder, value])

  return (
    <>
      <GlobalStyles />

      <Root margin={margin} padding={padding} width={width}>
        {label && (
          <Label size={size}>
            {label}

            {required && <Required size={size} />}

            {tooltip && (
              <Tooltip content={tooltip}>
                <TooltipIcon size={size} />
              </Tooltip>
            )}
          </Label>
        )}

        <Wrapper>
          {loading && <Skeleton size={size} variant="rect" />}

          {!loading && (
            <BaseTimePickerProvider locale={EsLocale} utils={DateFnsUtils}>
              <BaseTimePicker
                autoOk
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                disableToolbar={disableToolbar}
                error={Boolean(error)}
                format={format}
                InputProps={{
                  endAdornment: (
                    <Suffix disabled={disabled}>
                      <TimeIcon size={size} />
                    </Suffix>
                  )
                }}
                invalidDateMessage=""
                maxDate={maxDate}
                maxDateMessage=""
                minDate={minDate}
                minDateMessage=""
                onBlur={handleBlur}
                onChange={onChange}
                onClick={handleClick}
                onClose={handleClose}
                onOpen={handleOpen}
                size={size}
                textAlign={textAlign}
                value={value}
                {...restProps}
              />
            </BaseTimePickerProvider>
          )}

          {!loading && showPlaceholder && (
            <Placeholder size={size} textAlign={textAlign}>
              {placeholder}
            </Placeholder>
          )}
        </Wrapper>

        <BottomMessage size={size}>
          {!error && <Info>{info}</Info>}

          {error && <Error>{error}</Error>}
        </BottomMessage>
      </Root>
    </>
  )
}

TimePicker.defaultProps = {
  disableFuture: false,
  disablePast: false,
  disableToolbar: false,
  disabled: false,
  error: '',
  format: 'hh:mm',
  info: '',
  label: '',
  loading: false,
  margin: 0,
  maxDate: undefined,
  minDate: undefined,
  onChange: () => undefined,
  padding: 0,
  placeholder: '',
  required: false,
  size: 'medium',
  textAlign: 'initial',
  tooltip: '',
  value: null,
  width: '100%'
}

TimePicker.propTypes = {
  /**
   * Disable future.
   */
  disableFuture: PropTypes.bool,
  /**
   * Disable past.
   */
  disablePast: PropTypes.bool,
  /**
   * Disable toolbar.
   */
  disableToolbar: PropTypes.bool,
  /**
   * Disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Error.
   */
  error: PropTypes.string,
  /**
   * Format.
   */
  format: PropTypes.string,
  /**
   * Info.
   */
  info: PropTypes.string,
  /**
   * Label.
   */
  label: PropTypes.string,
  /**
   * Loading.
   */
  loading: PropTypes.bool,
  /**
   * Margin.
   */
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Max date.
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Min date.
   */
  minDate: PropTypes.instanceOf(Date),
  /**
   * On change.
   */
  onChange: PropTypes.func,
  /**
   * Padding.
   */
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * Required.
   */
  required: PropTypes.bool,
  /**
   * Size.
   */
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /**
   * Text align.
   */
  textAlign: PropTypes.oneOf(['center', 'end', 'initial', 'start']),
  /**
   * Tooltip.
   */
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Value.
   */
  value: PropTypes.instanceOf(Date),
  /**
   * Width.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default TimePicker

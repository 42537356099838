import React from 'react'
import { useViewport } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import ProtectByProfile from '../../../components/Security/ProtectByProfile'
import { USER_TYPES } from '../../../configs/settings/constants'
import { useAuthProvider } from '../../../containers/AuthProvider/AuthProvider.hook'
import { Container, Item } from './RFILayout.styles'

/**
 * The RFILayout's component.
 */
const RFILayout = props => {
  const { create, filters, result, search } = props

  const { size } = useViewport()
  const { canCreateRFI, userType } = useAuthProvider()

  return (
    <Container padding="16px 0" spacing={4}>
      <Item
        lg={userType === USER_TYPES.BUYER && canCreateRFI ? 9 : 12}
        md={userType === USER_TYPES.BUYER && canCreateRFI ? 8 : 12}
        order={size.isDownToSmall ? 2 : 1}
        xs={12}
      >
        {search}
      </Item>

      <ProtectByProfile rule={userType => userType === USER_TYPES.BUYER && canCreateRFI}>
        <Item lg={3} md={4} order={size.isDownToSmall ? 1 : 2} xs={12}>
          {create}
        </Item>
      </ProtectByProfile>

      <Item lg={3} md={4} order={3} xs={12}>
        {filters}
      </Item>

      <Item lg={9} md={8} order={4} xs={12}>
        {result}
      </Item>
    </Container>
  )
}

RFILayout.defaultProps = {
  create: undefined
}

RFILayout.propTypes = {
  create: PropTypes.node,
  filters: PropTypes.node.isRequired,
  result: PropTypes.node.isRequired,
  search: PropTypes.node.isRequired
}

export default RFILayout

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie, useEffectOnce } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { CAN_CHANGE_RFI_ROLES, CAN_CREATE_RFI_ROLES, USER_TYPES } from '../../configs/settings/constants'
import { onGetAuthInfoThunk, onGetPublicTokenThunk } from './AuthProvider.actions'
import AuthContext from './AuthProvider.context'
import { Container } from './AuthProvider.styles'

/**
 * The AuthProvider's container.
 */
const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const accessToken = getCookie('access_token')

  const {
    authInfo: { error, loading: loadingAuthInfo, roles, userType },
    publicToken: { loading: loadingPublicToken }
  } = useSelector(state => state.authProvider)

  const getValidRoles = collection => {
    const currentRoles = collection.map(rol => ({ ...rol, isValid: roles?.includes(rol.id) }))

    return currentRoles?.some(rol => rol.isValid)
  }

  const canChangeRFI = getValidRoles(CAN_CHANGE_RFI_ROLES)

  const canCreateRFI = getValidRoles(CAN_CREATE_RFI_ROLES)

  const isPrivate = useMemo(() => userType === USER_TYPES.BUYER || userType === USER_TYPES.SELLER, [userType])

  useEffectOnce(() => {
    if (!accessToken) dispatch(onGetPublicTokenThunk())
  })

  useEffect(() => {
    if (!loadingAuthInfo && !userType && accessToken) dispatch(onGetAuthInfoThunk())
  }, [accessToken])

  if (!accessToken || !userType || error || loadingAuthInfo || loadingPublicToken) {
    return (
      <Container alignItems="center" justifyContent="center">
        <Spinner />
      </Container>
    )
  }

  return (
    <AuthContext.Provider value={{ canChangeRFI, canCreateRFI, isPrivate, userType }}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthProvider

import React from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Button } from '@chilecompra/react-kit/components'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../configs/settings/environment'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import NavbarTransversal from './Header.navbarTransversal'
import { Container, GoBackIcon, Logo, LogoLink } from './Header.styles'

/**
 * The Header's container.
 */
const Header = () => {
  const { isPrivate } = useAuthProvider()
  const { size } = useViewport()

  const handleGoHome = () => {
    if (isPrivate) return window.open(`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`, '_top')

    window.open(`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`, '_top')
  }

  return (
    <Container
      alignItems={isPrivate ? 'center' : 'flex-start'}
      justifyContent="space-between"
      padding={isPrivate ? '32px 0 16px' : '16px 0'}
    >
      {!isPrivate && <NavbarTransversal />}

      <LogoLink href={`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`} target="_top">
        <Logo alt="Mercado Público" src="/assets/images/logo-mercadopublico.svg" />
      </LogoLink>

      <Button
        onClick={handleGoHome}
        padding={size.isTiny ? '0 16px' : '0 32px'}
        size={size.isTiny ? 'small' : 'medium'}
        variant="outlined"
      >
        <GoBackIcon /> {isPrivate ? 'Volver a Escritorio' : 'Volver al inicio'}
      </Button>
    </Container>
  )
}

export default Header

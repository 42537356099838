import styled from 'styled-components'
import { BaseContainer } from '../../configs/styles/BaseGrid'

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogMessage = styled(BaseContainer)`
  &.MuiGrid-container {
    text-align: center;
  }
`

export const UniquePassIcon = styled.img`
  margin-right: 8px;
`

import { formatDate } from '@chilecompra/react-kit'
import { ACCEPTED_FILTERS_KEYS } from '../../configs/settings/constants'

const defaultDeletekeys = ['codigo-consulta', 't']
const defaultformatDates = ['dateFrom', 'dateTo']

/**
 * Updates the given query object.
 */
export const updateQuery = ({ deleteKeys = defaultDeletekeys, filters, formatDates = defaultformatDates, query }) => {
  deleteKeys.forEach(key => query.delete(key))

  const acceptedFilters = Object.entries(filters).filter(([key]) => ACCEPTED_FILTERS_KEYS.includes(key))

  const dictionary = acceptedFilters.reduce((collection, [key, value]) => {
    query.delete(key)

    if (!value || value === '') return collection

    return collection.concat([[key, value]])
  }, [])

  dictionary.forEach(([key, value]) => {
    const currentValue = typeof value === 'string' ? value.toLowerCase() : value

    if (formatDates.includes(key)) return query.set(key, formatDate(currentValue, 'dd-MM-yyyy'))

    return query.set(key, currentValue)
  })
}

import { customizeError } from '@chilecompra/react-kit/errors'

/**
 * Checks some common errors.
 */
export const checkErrors = ({
  antecedents,
  descriptionProblem,
  historyProblem,
  isInvestigated,
  locationProblem,
  peopleAffected,
  questions,
  willBeRequiredConcept
}) => {
  const errors = []

  if (descriptionProblem === '') errors.push('WHAT_HAPPENING_UNSET_ERROR')

  if (descriptionProblem.length > 500) errors.push('WHAT_HAPPENING_MAX_LENGTH_ERROR')

  if (historyProblem === '') errors.push('WHEN_HAPPENING_UNSET_ERROR')

  if (historyProblem.length > 500) errors.push('WHEN_HAPPENING_MAX_LENGTH_ERROR')

  if (locationProblem === '') errors.push('WHERE_HAPPENING_UNSET_ERROR')

  if (locationProblem.length > 500) errors.push('WHERE_HAPPENING_MAX_LENGTH_ERROR')

  if (!isInvestigated) errors.push('IS_INVESTIGATED_UNSET_ERROR')

  if (antecedents === '') errors.push('HAS_INVESTIGATED_UNSET_ERROR')

  if (antecedents.length > 500) errors.push('HAS_INVESTIGATED_MAX_LENGTH_ERROR')

  if (peopleAffected === '') errors.push('WHOM_IS_A_PROBLEM_UNSET_ERROR')

  if (peopleAffected.length > 500) errors.push('WHOM_IS_A_PROBLEM_MAX_LENGTH_ERROR')

  if (!willBeRequiredConcept) errors.push('WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR')

  if (questions.length === 0) errors.push('QUESTIONS_UNSET_ERROR')

  if (questions.some(({ message }) => message === '')) errors.push('QUESTION_EMPTY_MESSAGE_ERROR')

  if (questions.some(({ message }) => message.length > 300)) errors.push('QUESTION_MAX_LENGTH_ERROR')

  if (Array.from(new Set([...questions].map(({ message }) => message))).length < questions.length) {
    errors.push('DUPLICATED_QUESTION_ERROR')
  }

  if (questions.some(({ multipleChoiceOptions }) => multipleChoiceOptions.some(({ message }) => message === ''))) {
    errors.push('QUESTION_OPTION_EMPTY_MESSAGE_ERROR')
  }

  if (
    questions.some(({ multipleChoiceOptions }) => multipleChoiceOptions.some(({ message }) => message.length > 200))
  ) {
    errors.push('QUESTION_MAX_OPTION_LENGTH_ERROR')
  }

  questions.forEach(({ multipleChoiceOptions }) => {
    if (
      multipleChoiceOptions.length > 0 &&
      Array.from(new Set([...multipleChoiceOptions].map(({ message }) => message))).length <
        multipleChoiceOptions.length
    ) {
      errors.push('DUPLICATED_QUESTION_OPTION_ERROR')
    }
  })

  if (errors.length === 0) return

  throw customizeError({ reason: errors })
}

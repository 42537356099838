import React from 'react'
import { Card as BaseCard, Radio as BaseRadio } from '@chilecompra/react-kit/components'
import { Close as BaseClose, RadioButtonUnchecked as BaseRadioButtonUnchecked } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const AddQuestionLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const AddQuestionLibraryLink = styled(AddQuestionLink)``

export const AddQuestionMultipleChoiceLink = styled(AddQuestionLink)`
  &.MuiGrid-item {
    align-self: center;
  }
`

export const BaseGridOneColumn = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    align-self: flex-start;
  }
`

export const Container = styled(({ borderTop, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    border-top: ${props => props.borderTop && `1px solid ${props.theme.palette.brand.gray2}`};
  }
`

export const HasInvestigated = styled(BaseGridOneColumn)``

export const Item = styled(({ order, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: flex;
    flex-direction: column;
    order: ${props => props.order};
  }
`

export const ProofsConceptRequired = styled(BaseGridOneColumn)``

export const Question = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 160px 1fr;
    grid-template-rows: 1fr;

    ${props => props.theme.breakpoints.down('md')} {
      grid-template-columns: 150px 1fr;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: 1fr;
    }
  }
`

export const Questions = styled(BaseGridOneColumn)`
  &.MuiGrid-item {
    grid-auto-rows: min-content;
  }
`

export const QuestionMultipleChoice = styled(({ removable, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-column: 1 / 3;
    grid-template-columns: ${props => (props.removable ? 'auto 1fr auto' : 'auto 1fr')};

    .multiple__choice {
      border-left: none;
      border-radius: 0;
      border-right: none;
      border-top: none;

      &.Mui-focused {
        box-shadow: 0 0.2rem 0 0 #0064ff40;
      }

      &.Mui-error {
        &.Mui-focused {
          box-shadow: 0 0.2rem 0 0 #e22c2c80;
        }
      }

      .MuiInputBase-input {
        padding: 0;
      }
    }
  }
`

export const QuestionLibraryLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const QuestionType = styled(({ removable, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: ${props => (props.removable ? '1fr auto' : '1fr')};
  }
`

export const Radio = styled(BaseRadio)`
  &.MuiFormGroup-root {
    &[direction='horizontal'] {
      display: flex;
      flex-direction: row;
      gap: 32px;
      margin: 0;
    }

    &[direction='vertical'] {
      margin: 0;

      .MuiFormControlLabel-root {
        margin: 0 0 16px;
      }
    }
  }
`
export const RadioUncheckedIcon = styled(({ disabled, ...props }) => <BaseRadioButtonUnchecked {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.disableFont};
    height: 40px;
    width: 24px;
  }
`

export const RemoveIcon = styled(({ disabled, ...props }) => <BaseClose {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.disabled && props.theme.palette.brand.disableFont};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 40px;
    margin-top: 25px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    width: 24px;
  }
`

export const RemoveOptionIcon = styled(RemoveIcon)`
  &.MuiSvgIcon-root {
    margin-top: 0;
  }
`

export const ReviewTrainingLink = styled(({ disabled, ...props }) => <a {...props} />)`
  align-items: center;
  color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: 400;
  pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
    text-decoration: underline;
  }
`

export const WhatHappening = styled(BaseGridOneColumn)``

export const WhenHappening = styled(BaseGridOneColumn)``

export const WhereHappening = styled(BaseGridOneColumn)``

export const WhomIsAProblem = styled(BaseGridOneColumn)``

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: 240px calc(75% - 280px) auto;
    position: relative;

    &:before {
      border-top: 1px solid ${props => props.theme.palette.brand.gray2};
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: calc(75% - 8px);
    }

    ${props => props.theme.breakpoints.only('md')} {
      .MuiPaper-root {
        grid-column: 1 / 3;
      }
    }

    ${props => props.theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr;

      &:before {
        width: calc(100%);
      }
    }
  }
`

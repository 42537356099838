import { customizeError } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import { getQuestionsLibrary } from '../../services/questions'

export const CLOSE_QUESTIONS_LIBRARY_DIOALOG = 'CLOSE_QUESTIONS_LIBRARY_DIOALOG'
export const onCloseQuestionsLibraryDialog = makeActionCreator(CLOSE_QUESTIONS_LIBRARY_DIOALOG)

export const GET_QUESTIONS_LIBRARY = 'GET_QUESTIONS_LIBRARY'
export const GET_QUESTIONS_LIBRARY_ERROR = 'GET_QUESTIONS_LIBRARY_ERROR'
export const GET_QUESTIONS_LIBRARY_SUCCESS = 'GET_QUESTIONS_LIBRARY_SUCCESS'
export const onGetQuestionsLibrary = makeActionCreator(GET_QUESTIONS_LIBRARY, 'payload')
export const onGetQuestionsLibraryError = makeActionCreator(GET_QUESTIONS_LIBRARY_ERROR, 'payload')
export const onGetQuestionsLibrarySuccess = makeActionCreator(GET_QUESTIONS_LIBRARY_SUCCESS, 'payload')
export const onGetQuestionsLibraryThunk = payload => async dispatch => {
  const { filters, loading, searching, searchingGeneral } = payload || {}

  const currentFilters = {
    initialRowsPerPage: Number(filters.initialRowsPerPage),
    keyword: filters.keyword.toLowerCase().trim(),
    page: Number(filters.page)
  }

  dispatch(onGetQuestionsLibrary({ filters: currentFilters, loading, searching, searchingGeneral }))

  try {
    const { data } = await getQuestionsLibrary({ ...currentFilters })

    return dispatch(
      onGetQuestionsLibrarySuccess({
        data: data.payload?.resultados?.map(question => ({
          id: question.id,
          message: question.detalle?.trim(),
          multipleChoiceOptions: question.bibliotecaOpcionPreguntaResponseList?.map(option => ({
            duplicated: false,
            id: option.id,
            message: option.valor?.trim()
          })),
          type: question.tipoPregunta
        })),
        pageCount: data.payload?.pageCount,
        summaryCount: data.payload?.resultCount
      })
    )
  } catch (error) {
    if (error.originalError?.code === 'ERR_CANCELED') return

    return dispatch(
      onGetQuestionsLibraryError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const SHOW_QUESTIONS_LIBRARY_DIALOG = 'SHOW_QUESTIONS_LIBRARY_DIALOG'
export const onShowQuestionsLibraryDialog = makeActionCreator(SHOW_QUESTIONS_LIBRARY_DIALOG)

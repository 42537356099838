import { v4 } from 'uuid'
import { QUESTION_TYPE_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import {
  ADD_QUESTION_INNOVATION_SUCCESS,
  ADD_QUESTION_LIBRARY_SUCCESS,
  ADD_QUESTION_MULTIPLE_CHOICE_SUCCESS,
  INITIAL_QUESTIONS_MULTIPLE_CHOICE,
  REMOVE_QUESTION_INNOVATION_SUCCESS,
  REMOVE_QUESTION_MULTIPLE_CHOICE_SUCCESS,
  RESET_CREATE_INNOVATION_FORM,
  RESET_QUESTIONS_MULTIPLE_CHOICE,
  UPDATE_CREATE_INNOVATION_FORM_VALUE,
  UPDATE_DUPLICATED_QUESTION_INNOVATION_SUCCESS,
  UPDATE_DUPLICATED_QUESTION_MULTIPLE_CHOICE_SUCCESS,
  UPDATE_QUESTION_INNOVATION_VALUE,
  UPDATE_QUESTION_MULTIPLE_CHOICE_VALUE,
  VALIDATE_CREATE_INNOVATION_FORM,
  VALIDATE_CREATE_INNOVATION_FORM_ERROR
} from './RequestCreateInnovationForm.actions'

const requestCreateInnovationForm = {
  antecedents: '',
  descriptionProblem: '',
  historyProblem: '',
  isInvestigated: 0,
  locationProblem: '',
  peopleAffected: '',
  questions: [
    {
      duplicated: false,
      id: v4(),
      message: '',
      multipleChoiceOptions: [],
      type: QUESTION_TYPE_OPTIONS_DICTIONARY.OPEN_PARAGRAPH
    }
  ],
  submitError: undefined,
  willBeRequiredConcept: 0
}

/**
 * The RequestCreateInnovationForm's reducer.
 */
const requestCreateInnovationFormReducer = (state = requestCreateInnovationForm, { payload, type }) => {
  switch (type) {
    case ADD_QUESTION_INNOVATION_SUCCESS: {
      return {
        ...state,
        questions: state.questions.concat({
          duplicated: false,
          id: v4(),
          message: '',
          multipleChoiceOptions: [],
          type: QUESTION_TYPE_OPTIONS_DICTIONARY.OPEN_PARAGRAPH
        })
      }
    }
    case ADD_QUESTION_LIBRARY_SUCCESS: {
      return {
        ...state,
        questions: state.questions.concat({
          duplicated: false,
          id: payload.id,
          message: payload.message,
          multipleChoiceOptions: payload.multipleChoiceOptions,
          type: payload.type
        })
      }
    }
    case ADD_QUESTION_MULTIPLE_CHOICE_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                multipleChoiceOptions: question.multipleChoiceOptions.concat({
                  duplicated: false,
                  id: v4(),
                  message: ''
                })
              }
        )
      }
    }
    case INITIAL_QUESTIONS_MULTIPLE_CHOICE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                multipleChoiceOptions: [
                  { duplicated: false, id: v4(), message: '' },
                  { duplicated: false, id: v4(), message: '' }
                ]
              }
        )
      }
    }
    case REMOVE_QUESTION_INNOVATION_SUCCESS: {
      return { ...state, questions: state.questions.filter(question => question.id !== payload.id) }
    }
    case REMOVE_QUESTION_MULTIPLE_CHOICE_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                multipleChoiceOptions: question.multipleChoiceOptions.filter(option => option.id !== payload.idOption)
              }
        )
      }
    }
    case RESET_QUESTIONS_MULTIPLE_CHOICE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, multipleChoiceOptions: [] }
        )
      }
    }
    case RESET_CREATE_INNOVATION_FORM: {
      return { ...state, ...requestCreateInnovationForm }
    }
    case UPDATE_CREATE_INNOVATION_FORM_VALUE: {
      return { ...state, [payload.key]: payload.value }
    }
    case UPDATE_DUPLICATED_QUESTION_INNOVATION_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, [payload.key]: payload.value }
        )
      }
    }
    case UPDATE_DUPLICATED_QUESTION_MULTIPLE_CHOICE_SUCCESS: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                multipleChoiceOptions: question.multipleChoiceOptions.map(option =>
                  option.id !== payload.idOption ? option : { ...option, [payload.key]: payload.value }
                )
              }
        )
      }
    }
    case UPDATE_QUESTION_INNOVATION_VALUE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id ? question : { ...question, [payload.key]: payload.value }
        )
      }
    }
    case UPDATE_QUESTION_MULTIPLE_CHOICE_VALUE: {
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id !== payload.id
            ? question
            : {
                ...question,
                multipleChoiceOptions: question.multipleChoiceOptions.map(option =>
                  option.id !== payload.idOption
                    ? option
                    : { ...option, [payload.entryOption.key]: payload.entryOption.value }
                )
              }
        )
      }
    }
    case VALIDATE_CREATE_INNOVATION_FORM: {
      return { ...state, submitError: undefined }
    }
    case VALIDATE_CREATE_INNOVATION_FORM_ERROR: {
      return { ...state, submitError: payload.error }
    }
    default: {
      return state
    }
  }
}

export default requestCreateInnovationFormReducer

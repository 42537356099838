import React, { memo, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired, useInput } from '@chilecompra/react-kit'
import { Input } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { BasicInformation } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's basicInformation.
 */
const BasicInformationSection = props => {
  const { description, isDisabled, name, onUpdate, submitError } = props

  const descriptionRef = useRef(null)
  const nameRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: nameError,
    onChange: handleNameChange,
    value: nameValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'name', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(100)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => name
  })

  const {
    error: descriptionError,
    onChange: handleDescriptionChange,
    value: descriptionValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'description', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => description
  })

  useEffect(() => {
    if (
      conditionsFields([
        'ADDITIONAL_INFO_UNSET_ERROR',
        'DATE_TIME_UNSET_ERROR ',
        'MIN_ONE_DAY_ERROR',
        'PRODUCTS_UNSET_ERROR',
        'WILL_BE_PUBLIC_UNSET_ERROR'
      ])
    )
      return

    if (conditionsFields(['DESCRIPTION_UNSET_ERROR', 'DESCRIPTION_MAX_LENGTH_ERROR'])) {
      return descriptionRef.current?.focus()
    }

    if (conditionsFields(['NAME_MAX_LENGTH_ERROR', 'NAME_UNSET_ERROR'])) return nameRef.current?.focus()
  }, [submitError])

  return (
    <BasicInformation>
      <Input
        disabled={isDisabled}
        error={nameError}
        info="Este será el nombre que verán los usuarios al momento de responder."
        inputRef={nameRef}
        label="Nombre"
        maxCount={100}
        onChange={handleNameChange}
        required
        value={nameValue}
      />

      <TextArea
        disabled={isDisabled}
        error={descriptionError}
        info="Ingresa una descripción breve que explique tu Consulta al Mercado."
        inputRef={descriptionRef}
        label="Descripción"
        maxCount={500}
        onChange={handleDescriptionChange}
        required
        rows={4}
        value={descriptionValue}
      />
    </BasicInformation>
  )
}

BasicInformationSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

BasicInformationSection.propTypes = {
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(BasicInformationSection)

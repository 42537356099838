import React, { memo } from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Tag, Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { STATUS_OPTIONS_DICTIONARY, STATUS_OPTIONS_TRASLATE_DICTIONARY } from '../../configs/settings/constants'
import { Heading, Item, Skeleton } from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's heading.
 */
const HeadingDetailInfo = props => {
  const { size } = useViewport()

  const { code, isError, isLoading, status } = props

  return (
    <Item md={9} order={1} xs={12}>
      <Heading>
        <Typography fontWeight="bold" variant={size.isTiny ? 'h3' : 'h2'}>
          Consultas al mercado
        </Typography>

        <Typography fontWeight="bold" variant={size.isTiny ? 'h3' : 'h2'}>
          {isLoading ? <Skeleton width="300px" /> : code}
        </Typography>

        {!isError && (
          <Tag
            color={status === STATUS_OPTIONS_DICTIONARY.PUBLISHED ? 'success' : 'default'}
            loading={isLoading}
            padding="0 24px"
            text={status ? STATUS_OPTIONS_TRASLATE_DICTIONARY[status] : 'Sin información'}
          />
        )}
      </Heading>

      {!isLoading && isError && (
        <Toast
          border
          margin="32px 0 0"
          severity="error"
          textDirection="vertical"
          title="Lo sentimos, estamos presentando problemas con nuestro servidor."
        >
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Toast>
      )}
    </Item>
  )
}

HeadingDetailInfo.defaultProps = {
  isError: false,
  isLoading: false
}

HeadingDetailInfo.propTypes = {
  code: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default memo(HeadingDetailInfo)

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSecondOrderCallback } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { MEETING_ACTION, MEETING_TYPE_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import { onAddMeetingThunk } from '../RequestCreateForm/RequestCreateForm.actions'
import { onUploadMeetingThunk } from '../RequestDetailInfo/RequestDetailInfo.actions'
import {
  onAddMeetingAssistantSuccess,
  onCloseMeetingDialog,
  onGetAssistantThunk,
  onRemoveMeetingAssistantSuccess,
  onResetMeetingForm,
  onUpdateMeetingAssistantThunk,
  onUpdateRequestMeetingValue
} from './RequestMeetingDialog.actions'
import AssistantItem from './RequestMeetingDialog.assistant'
import MeetingBasicInformation from './RequestMeetingDialog.basicInformation'
import {
  AddAssistantsLink,
  DialogButtons,
  DialogContent,
  DialogForm,
  DialogMessage,
  ErrorMessage,
  Strong
} from './RequestMeetingDialog.styles'

/**
 * The RequestMeetingDialog's container.
 */
const RequestMeetingDialog = () => {
  const dispatch = useDispatch()

  const { errorMeetings, loadingMeetings } = useSelector(state => state.requestDetailInfo)
  const {
    action,
    assistants,
    businessName,
    businessDni,
    dateTime,
    isErrorForm,
    meetingOtherType,
    meetingType,
    reasonMeeting,
    showDialog
  } = useSelector(state => state.requestMeetingDialog)

  const handleAddMeetingAssistant = useCallback(() => dispatch(onAddMeetingAssistantSuccess()), [])
  const handleCloseMeetingDialog = () => dispatch(onCloseMeetingDialog())
  const handleFetchAssistant = useSecondOrderCallback((dni, id) => dispatch(onGetAssistantThunk({ dni, id })))
  const hanldeUpdateMeetingValue = useCallback(entry => dispatch(onUpdateRequestMeetingValue({ ...entry })), [])

  const handleAddMeeting = useCallback(() => {
    if (action === MEETING_ACTION.CREATE_FORM) {
      return dispatch(
        onAddMeetingThunk({
          closeMeetingCallback: () => handleCloseMeetingDialog(),
          resetMeetingFormCallback: () => dispatch(onResetMeetingForm())
        })
      )
    }

    if (action === MEETING_ACTION.DETAIL_INFO) {
      return dispatch(
        onUploadMeetingThunk({
          closeMeetingCallback: () => handleCloseMeetingDialog(),
          resetMeetingFormCallback: () => dispatch(onResetMeetingForm())
        })
      )
    }
  }, [action])

  const handleRemoveMeetingAssistant = useSecondOrderCallback((_, id) => {
    dispatch(onRemoveMeetingAssistantSuccess({ id }))
  })

  const handleUpdateMeetingAssistant = useSecondOrderCallback((entry, id) => {
    dispatch(onUpdateMeetingAssistantThunk({ ...entry, id }))
  })

  const disableSubmit = useCallback(() => {
    const isIncomplete =
      !businessName ||
      !businessDni ||
      !dateTime ||
      !meetingType ||
      !reasonMeeting ||
      (meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER && !meetingOtherType) ||
      assistants.length === 0 ||
      assistants.some(({ deceased, dni, duplicated, foreignDni, isForeign, lastNames, names, position }) => {
        const identifier = isForeign ? foreignDni : dni

        return !identifier || !lastNames || !names || !position || deceased || duplicated
      })

    return isErrorForm || isIncomplete
  }, [assistants, businessDni, businessName, dateTime, isErrorForm, meetingOtherType, meetingType, reasonMeeting])

  return (
    <Dialog
      disableBackdropClick
      margin="auto"
      maxWidth="1000px"
      onClose={handleCloseMeetingDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent padding="32px">
        <DialogMessage margin="0 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            Registro de reuniones
          </Typography>

          <Typography variant="body2">
            Puedes registrar las reuniones ya realizadas con empresas, instituciones, especialistas, entre otros.
            También podrás <Strong>registrar las reuniones</Strong> que tengas a medida que avance la consulta, incluso
            posterior a su fecha de cierre.
          </Typography>
        </DialogMessage>

        <DialogForm margin="0 0 32px">
          <MeetingBasicInformation
            businessDni={businessDni}
            businessName={businessName}
            dateTime={dateTime}
            isShowDialog={showDialog}
            meetingOtherType={meetingOtherType}
            meetingType={meetingType}
            onUpdate={hanldeUpdateMeetingValue}
            reasonMeeting={reasonMeeting}
          />

          <Typography fontWeight="bold" margin="16px 0" variant="body1">
            Asistentes
          </Typography>

          {assistants.map((assistant, index) => (
            <AssistantItem
              deceasedError={!!assistant.deceased}
              disabledIdentity={assistant.disabled}
              dni={assistant.dni}
              duplicatedError={!!assistant.duplicated}
              foreignDni={assistant.foreignDni}
              isForeign={assistant.isForeign}
              key={assistant.id}
              lastNames={assistant.lastNames}
              loadingIdentity={assistant.loading}
              names={assistant.names}
              onFetch={handleFetchAssistant(assistant.id)}
              onRemove={handleRemoveMeetingAssistant(assistant.id)}
              onUpdate={handleUpdateMeetingAssistant(assistant.id)}
              onUpdateForm={hanldeUpdateMeetingValue}
              position={assistant.position}
              removable={index > 1}
            />
          ))}

          <AddAssistantsLink onClick={handleAddMeetingAssistant}>Agregar otro asistente</AddAssistantsLink>

          {!loadingMeetings && errorMeetings && (
            <ErrorMessage alignItems="center" justifyContent="center">
              {errorMeetings && 'Lo sentimos, no se pudo crear la reunión. Intente nuevamente.'}
            </ErrorMessage>
          )}
        </DialogForm>

        <DialogButtons alignItems="center" justifyContent="center">
          <Button
            color={!errorMeetings ? 'primary' : 'error'}
            disabled={disableSubmit()}
            loading={loadingMeetings}
            onClick={handleAddMeeting}
            variant={!errorMeetings ? 'contained' : 'outlined'}
          >
            {!errorMeetings && 'Registrar reunión'}
            {errorMeetings && 'Reintentar'}
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestMeetingDialog

import React, { memo, useEffect } from 'react'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  formatDNI,
  useDatePicker,
  useInput,
  useSelect
} from '@chilecompra/react-kit'
import { Input, Select } from '@chilecompra/react-kit/components'
import { sub } from 'date-fns'
import PropTypes from 'prop-types'
import TimePicker, { useTimePicker } from '../../components/Inputs/TimePicker'
import { MEETING_TYPE_OPTIONS, MEETING_TYPE_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import { checkErrorDni } from '../../modules/utils/checkers'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { Container, DatePicker, Item } from './RequestMeetingDialog.styles'

/**
 * The RequestMeetingDialog's basicInformation.
 */
const MeetingBasicInformation = props => {
  const { businessName, businessDni, dateTime, isShowDialog, meetingOtherType, meetingType, onUpdate, reasonMeeting } =
    props

  const { onChange: handleMeetingTypeChange } = useSelect({
    changeCallback: updatedValue => {
      handleMeetingOtherTypeReset()
      onUpdate({ key: 'meetingOtherType', value: '' })

      if (updatedValue !== '') onUpdate({ key: 'meetingType', value: updatedValue })
    },
    overwriteCallback: () => meetingType
  })

  const {
    error: meetingOtherTypeError,
    onChange: handleMeetingOtherTypeChange,
    onReset: handleMeetingOtherTypeReset,
    value: meetingOtherTypeValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'meetingOtherType', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(100)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => meetingOtherType
  })

  const {
    error: dateError,
    onChange: handleDateChange,
    onReset: handleDateReset,
    value: dateValue
  } = useDatePicker({
    changeCallback: updatedValue => onUpdate({ key: 'dateTime', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: dateTime,
    overwriteCallback: () => dateTime
  })

  const {
    error: timeError,
    onChange: handleTimeChange,
    onReset: handleTimeReset,
    value: timeValue
  } = useTimePicker({
    changeCallback: updatedValue => onUpdate({ key: 'dateTime', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: dateTime,
    overwriteCallback: () => dateTime
  })

  const {
    error: reasonMeetingError,
    onChange: handleReasonMeetingChange,
    onReset: handleReasonMeetingReset,
    value: reasonMeetingValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'reasonMeeting', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => reasonMeeting
  })

  const {
    error: businessDniError,
    onChange: handlebusinessDniChange,
    onReset: handlebusinessDniReset,
    value: businessDniValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'businessDni', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired(),
      checkErrorDni('RUT con formato incorrecto'),
      checkInputErrorMaxLength(15)
    ],
    formatCallbacks: [formatDNI],
    overwriteCallback: () => formatDNI(businessDni)
  })

  const {
    error: businessNameError,
    onChange: handleBusinessNameChange,
    onReset: handleBusinessNameReset,
    value: businessNameValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'businessName', value: updatedValue }),
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => businessName
  })

  useEffect(() => {
    const validateForm =
      (meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER && meetingOtherTypeError !== '') ||
      businessNameError !== '' ||
      businessDniError !== '' ||
      dateError !== '' ||
      reasonMeetingError !== '' ||
      timeError !== ''

    if (!validateForm) return

    onUpdate({ key: 'isErrorForm', value: true })

    return () => onUpdate({ key: 'isErrorForm', value: false })
  }, [businessNameError, businessDniError, dateError, meetingOtherTypeError, reasonMeetingError, timeError])

  useEffect(() => {
    if (isShowDialog) {
      handleBusinessNameReset()
      handlebusinessDniReset()
      handleDateReset()
      handleMeetingOtherTypeReset()
      handleReasonMeetingReset()
      handleTimeReset()
    }
  }, [isShowDialog])

  return (
    <Container spacing={2}>
      <Item
        md={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 3 : 4}
        sm={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 6 : 4}
        xs={12}
      >
        <Select
          label="Tipo de reunión"
          onChange={handleMeetingTypeChange}
          options={MEETING_TYPE_OPTIONS}
          required
          value={meetingType}
        />
      </Item>

      {meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER && (
        <Item md={3} sm={6} xs={12}>
          <Input
            error={meetingOtherTypeError}
            label="Otro tipo de reunión"
            onChange={handleMeetingOtherTypeChange}
            required
            value={meetingOtherTypeValue}
          />
        </Item>
      )}

      <Item
        md={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 3 : 4}
        sm={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 6 : 4}
        xs={6}
      >
        <DatePicker
          className="calendar__icon"
          error={dateError}
          format="dd/MM/yyyy"
          label="Fecha"
          minDate={sub(new Date(), { days: 29 })}
          onChange={handleDateChange}
          required
          value={dateValue}
        />
      </Item>

      <Item
        md={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 3 : 4}
        sm={meetingType === MEETING_TYPE_OPTIONS_DICTIONARY.OTHER ? 6 : 4}
        xs={6}
      >
        <TimePicker error={timeError} label="Hora" onChange={handleTimeChange} required value={timeValue} />
      </Item>

      <Item xs={12}>
        <Input
          error={reasonMeetingError}
          label="Motivo de la reunión"
          onChange={handleReasonMeetingChange}
          required
          value={reasonMeetingValue}
        />
      </Item>

      <Item sm={4} xs={12}>
        <Input
          error={businessDniError}
          label="RUT empresa"
          maxLength={15}
          onChange={handlebusinessDniChange}
          required
          value={businessDniValue}
        />
      </Item>

      <Item sm={8} xs={12}>
        <Input
          error={businessNameError}
          label="Razón social"
          onChange={handleBusinessNameChange}
          required
          value={businessNameValue}
        />
      </Item>
    </Container>
  )
}

MeetingBasicInformation.propTypes = {
  businessDni: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  dateTime: PropTypes.instanceOf(Date).isRequired,
  isShowDialog: PropTypes.bool.isRequired,
  meetingOtherType: PropTypes.string.isRequired,
  meetingType: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  reasonMeeting: PropTypes.string.isRequired
}

export default memo(MeetingBasicInformation)

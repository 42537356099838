import { factoryService, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_PRINCIPAL_URL } from '../configs/settings/environment'

/**
 * To get the person information from the civil registry.
 */
export const getIdentity = factoryService(({ dni }) => {
  const body = { rut: dni }

  return postJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/registro-civil/informacionPersona`, { body })
})

import { CLOSE_FEEDBACK_ERROR_DIALOG, SHOW_FEEDBACK_ERROR_DIALOG } from './RequestFeedbackErrorDialog.actions'

const requestFeedbackErrorDialogState = { showDialog: false }

/**
 * The RequestFeedbackErrorDialog's reducer.
 */
const requestFeedbackErrorDialogReducer = (state = requestFeedbackErrorDialogState, { type }) => {
  switch (type) {
    case CLOSE_FEEDBACK_ERROR_DIALOG: {
      return { ...state, showDialog: false }
    }
    case SHOW_FEEDBACK_ERROR_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestFeedbackErrorDialogReducer

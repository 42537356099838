import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import ProtectByProfile from '../components/Security/ProtectByProfile'
import { USER_TYPES } from '../configs/settings/constants'
import router from '../configs/settings/routes'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import RequestCreateInnovationFormContainer from '../containers/RequestCreateInnovationForm/RequestCreateInnovationForm.container'
import RequestFeedbackConfirmDialogContainer from '../containers/RequestFeedbackConfirmDialog/RequestFeedbackConfirmDialog.container'
import RequestFeedbackErrorDialogContainer from '../containers/RequestFeedbackErrorDialog/RequestFeedbackErrorDialog.container'
import RequestQuestionsLibraryDialogContainer from '../containers/RequestQuestionsLibraryDialog/RequestQuestionsLibraryDialog.container'

/**
 * The CreateRequestInnovation 's page.
 */
const CreateRequestInnovation = () => (
  <>
    <AuthProviderContainer>
      <ProtectByProfile redirectTo={router.search} rule={userType => userType === USER_TYPES.BUYER}>
        <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
          <RequestCreateInnovationFormContainer />
          <RequestFeedbackConfirmDialogContainer />
          <RequestFeedbackErrorDialogContainer />
          <RequestQuestionsLibraryDialogContainer />
        </BrandLayout>
      </ProtectByProfile>
    </AuthProviderContainer>

    <ScrollRestoration />
  </>
)

export default CreateRequestInnovation

import { Breadcrumb as BaseBreadcrumb } from '@chilecompra/react-kit/components'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Breadcrumb = styled(BaseBreadcrumb)`
  font-size: 12px;

  &.breadcrumb__link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Container = styled(BaseContainer)``

export const Item = styled(BaseItem)``

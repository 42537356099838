import React from 'react'
import { Card as BaseCard, Skeleton as BaseSkeleton, Spinner as BaseSpinner } from '@chilecompra/react-kit/components'
import { Check as BaseCheck, Search as BaseSearchIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const AddQuestionLibraryLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.blue[20] : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const AddSuccessIcon = styled(BaseCheck)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    cursor: pointer;
    height: 18px;
    margin-right: 8px;
    pointer-events: visible;
    width: 18px;
  }
`

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    display: flex;

    &.not__found {
      align-items: center;
      justify-content: center;
      min-height: 320px;

      .MuiGrid-container {
        height: 100%;
        text-align: center;
      }
    }

    &.question__library {
      align-items: center;
      justify-content: center;
      min-height: 70px;

      .MuiGrid-container {
        .question__library__name {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          overflow: hidden;
        }
      }
    }
  }
`

export const Container = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogForm = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)``

export const Item = styled(BaseItem)``

export const NotFoundImg = styled.img`
  color: ${props => props.theme.palette.gray[50]};
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 82px;
`

export const SearchIcon = styled(({ disabled, ...props }) => <BaseSearchIcon {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.gray[50]};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 24px;
    margin-right: 8px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    width: 24px;

    &:hover {
      color: ${props => props.theme.palette.blue[50]};
    }
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} size="18px" />)`
  &.MuiCircularProgress-root {
    margin-right: 8px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  &.MuiSkeleton-root {
    margin-bottom: 16px;
    transform: none;
  }
`

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr auto;
  }
`

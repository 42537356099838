import React, { memo, useCallback, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired, useRadio, useViewport } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { INVESTIGATED_OPTIONS } from '../../configs/settings/constants'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { HasInvestigated, Radio } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's hasInvestigated.
 */
const HasInvestigatedSection = props => {
  const { antecedents, isDisabled, isInvestigated, onUpdate, submitError } = props

  const { size } = useViewport()
  const antecedentsRef = useRef(null)
  const isInvestigatedRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const { onChange: handleIsInvestigatedChange, value: isInvestigatedValue } = useRadio({
    changeCallback: updatedValue => onUpdate({ key: 'isInvestigated', value: Number(updatedValue) }),
    overwriteCallback: () => isInvestigated
  })

  const {
    error: antecedentsError,
    onChange: handleAntecedentsChange,
    value: antecedentsValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'antecedents', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => antecedents
  })

  const isInvestigatedErrorText = useCallback(() => {
    if (!isInvestigatedValue && submitError?.includes('IS_INVESTIGATED_UNSET_ERROR')) {
      return 'Este campo es obligatorio. Debes completar esta información para poder continuar.'
    }

    return ''
  }, [isInvestigatedValue, submitError])

  useEffect(() => {
    if (
      conditionsFields([
        'DUPLICATED_QUESTION_ERROR',
        'DUPLICATED_QUESTION_OPTION_ERROR',
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR',
        'QUESTION_MAX_OPTION_LENGTH_ERROR',
        'QUESTION_OPTION_EMPTY_MESSAGE_ERROR',
        'WHOM_IS_A_PROBLEM_MAX_LENGTH_ERROR',
        'WHOM_IS_A_PROBLEM_UNSET_ERROR',
        'WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'
      ])
    )
      return

    if (conditionsFields(['HAS_INVESTIGATED_UNSET_ERROR', 'HAS_INVESTIGATED_MAX_LENGTH_ERROR'])) {
      return antecedentsRef.current?.focus()
    }

    if (conditionsFields(['IS_INVESTIGATED_UNSET_ERROR'])) {
      const element = isInvestigatedRef.current
      const headerOffset = size.isDownToSmall ? 108 : 32
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      return window.scrollTo({ top: offsetPosition })
    }
  }, [submitError])

  return (
    <HasInvestigated ref={isInvestigatedRef}>
      <Radio
        direction="horizontal"
        disabled={isDisabled}
        error={isInvestigatedErrorText()}
        onChange={handleIsInvestigatedChange}
        options={INVESTIGATED_OPTIONS}
        value={isInvestigatedValue}
      />

      <TextArea
        disabled={isDisabled}
        error={antecedentsError}
        inputRef={antecedentsRef}
        label="Antecedentes"
        maxCount={500}
        onChange={handleAntecedentsChange}
        required
        rows={4}
        value={antecedentsValue}
      />
    </HasInvestigated>
  )
}

HasInvestigatedSection.defaultProps = {
  isDisabled: false,
  isInvestigated: undefined,
  submitError: undefined
}

HasInvestigatedSection.propTypes = {
  antecedents: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInvestigated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(HasInvestigatedSection)

import { factoryService, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit'
import { REACT_APP_API_BASE_URL, REACT_APP_API_COMPRA_AGIL_BASE_URL } from '../configs/settings/environment'

/**
 * Gets a list of categories products.
 */
export const getCategories = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_COMPRA_AGIL_BASE_URL}/v1/compra-agil/catalogo/categorias/clasificadores`)
})

/**
 * Gets a list of categories of level two products.
 */
export const getCategoriesLevelTwo = factoryService(({ category }) => {
  return getJsonRequest(
    `${REACT_APP_API_COMPRA_AGIL_BASE_URL}/v1/compra-agil/catalogo/categorias/clasificadores/${category}`
  )
})

/**
 * Gets the products list
 */
export const getProducts = factoryService(({ keyword }) => {
  return getJsonRequest(`${REACT_APP_API_COMPRA_AGIL_BASE_URL}/v1/compra-agil/catalogo/productos?query=${keyword}`)
})

/**
 * Gets a list of products by category.
 */
export const getProductsByCategory = factoryService(({ category }) => {
  return getJsonRequest(
    `${REACT_APP_API_COMPRA_AGIL_BASE_URL}/v1/compra-agil/catalogo/categorias/${category}/productos`
  )
})

/**
 * Post send the request Products.
 */
export const postProducts = factoryService(({ products, requestId }) => {
  const body = products.map(product => ({ codigoRubro: product.productId, nombre: product.name }))

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/rubros`, { body })
})

import { CLOSE_UNIQUE_PASS_DIALOG, SHOW_UNIQUE_PASS_DIALOG } from './RequestUniquePassDialog.actions'

const requestUniquePassDialogState = { showDialog: false }

/**
 * The RequestUniquePassDialog's reducer.
 */
const requestUniquePassDialogReducer = (state = requestUniquePassDialogState, { type }) => {
  switch (type) {
    case CLOSE_UNIQUE_PASS_DIALOG: {
      return { ...state, showDialog: false }
    }
    case SHOW_UNIQUE_PASS_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestUniquePassDialogReducer

import React, { memo, useCallback, useEffect } from 'react'
import { checkInputErrorFileMaxSize, useFile } from '@chilecompra/react-kit'
import { File } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { ACCEPTED_DOCUMENT_TYPES, MAX_MB } from '../../configs/settings/constants'
import { Item } from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's uploadAttachedFiles.
 */
const SectionUploadAttachedFiles = props => {
  const { initialFile, isError, isLoading, onUploadFile } = props

  const {
    error: fileError,
    onChange: handleFileChange,
    setValue: setFileValue,
    value: fileValue
  } = useFile({
    changeCallback: updatedValue => onUploadFile(updatedValue),
    errorCallbacks: [checkInputErrorFileMaxSize(MAX_MB)],
    initialValue: initialFile
  })

  const fileErrorText = useCallback(() => {
    if (fileError !== '') return fileError
    if (isError) return 'Error al subir el archivo, intente nuevamente.'
  }, [isError, fileError])

  useEffect(() => {
    if (!isLoading || isError) setFileValue(initialFile)
  }, [isError, isLoading])

  return (
    <Item>
      <File
        accept={ACCEPTED_DOCUMENT_TYPES}
        browseText="Examinar"
        disabled={isLoading}
        error={fileErrorText()}
        info={`Tamaño máximo permitido: ${MAX_MB}MB.`}
        label="Documento anexo"
        onChange={handleFileChange}
        placeholder="Presiona examinar para adjuntar un archivo."
        value={fileValue}
      />
    </Item>
  )
}

SectionUploadAttachedFiles.defaultProps = {
  initialFile: '',
  isError: false,
  isLoading: false
}

SectionUploadAttachedFiles.propTypes = {
  initialFile: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onUploadFile: PropTypes.func.isRequired
}

export default memo(SectionUploadAttachedFiles)

import { ONLY_LETTERS_NUMBERS_AND_SOME_SYMBOLS_FORMAT } from '../../configs/settings/regexes'
import { checkOnlyLettersNumbersAndSomeSymbols } from './checkers'

/**
 * Formats a given string value to only letters, numbers, and some symbols (._-,?!¿¡).
 */
export const formatOnlyLettersNumbersAndSomeSymbols = value => {
  return checkOnlyLettersNumbersAndSomeSymbols(value)
    ? value
    : value.replace(ONLY_LETTERS_NUMBERS_AND_SOME_SYMBOLS_FORMAT, '')
}

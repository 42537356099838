import { Card as BaseCard } from '@chilecompra/react-kit/components'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`

export const Container = styled(BaseContainer)``

export const Item = styled(BaseItem)``

export const Published = styled.img`
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 216px;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 176px;
  }
`

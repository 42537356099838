import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useAccessToken, useViewport } from '@chilecompra/react-kit'
import { Card, Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { EXPIRES_AT, USER_TYPES, USER_TYPES_TRASLATE_DICTIONARY } from '../../configs/settings/constants'
import { REACT_APP_PROVIDERS_BASE_URL } from '../../configs/settings/environment'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import AddResponseForm from './RequestDetailInfo.addResponse'
import {
  ArrowDownIcon,
  ArrowForward,
  AttachedFilesLink,
  AttachedFilesResponseWrapper,
  Container,
  DownloadLink,
  Item,
  SearchLoadingIcon,
  Skeleton,
  Strong,
  SummaryLink,
  WrapperAddResponse,
  WrapperQuestions,
  WrapperResponse,
  WrapperResponses
} from './RequestDetailInfo.styles'

/**
 * The RequestDetailInfo's questionsAndAnswers.
 */
const QuestionsAndAnswers = props => {
  const {
    detailUsrCode,
    isClose,
    isDownloading,
    isError,
    isErrorDownloading,
    isLoading,
    isWillBePublic,
    onAddFile,
    onDownloadFile,
    onDownloadQuestionsAndAnswers,
    onRemoveFile,
    onResponse,
    onShowUniquePassDialog,
    publicUserDni,
    questions
  } = props

  const accessToken = useAccessToken()
  const { size } = useViewport()
  const { userType } = useAuthProvider()
  const {
    authInfo: { usrCode }
  } = useSelector(state => state.authProvider)

  const handleGoToSellerCard = dni => () => {
    window.location.href = `${REACT_APP_PROVIDERS_BASE_URL}/ficha/callback?access_token=${accessToken}&expires_in=${EXPIRES_AT}&rut=${dni}`
  }

  return (
    <Item margin="32px 0 16px" md={9} order={4} xs={12}>
      <Container>
        <Typography fontWeight="bold" margin="0 0 32px" variant={size.isDownToTiny ? 'h3' : 'h2'}>
          Preguntas y respuestas
        </Typography>
      </Container>

      {isLoading && (
        <>
          <Skeleton className="questions__and__answers" margin="0 0 16px" width="100%" />
          <Skeleton className="questions__and__answers" margin="0 0 16px" width="100%" />
          <Skeleton className="questions__and__answers" width="100%" />
        </>
      )}

      {!isError && !isLoading && (
        <>
          {questions.length > 0 && (
            <>
              {isWillBePublic && (
                <Toast margin="0 0 32px" severity="info">
                  Las respuestas que se ingresen en esta sección serán de caracter <Strong>público</Strong>, y todos los
                  usuarios podrán visualizarlas.
                </Toast>
              )}

              {!isWillBePublic && (
                <Toast margin="0 0 32px" severity="info">
                  Las respuestas que {userType === USER_TYPES.BUYER ? 'ingresen los usuarios' : 'ingreses'} en esta
                  sección serán de caracter <Strong>privado</Strong>, y solamente{' '}
                  {userType === USER_TYPES.BUYER ? 'tú podrás' : 'el organismo comprador podrá'} visualizarlas.
                </Toast>
              )}

              {isWillBePublic && (
                <Container justifyContent="flex-end" margin="0 0 16px">
                  <DownloadLink
                    disabled={isDownloading}
                    isErrorDownloading={!!isErrorDownloading}
                    onClick={onDownloadQuestionsAndAnswers}
                  >
                    {isDownloading && 'Descargando...'}
                    {!isDownloading && isErrorDownloading && 'Reintente la descarga de nuevo...'}
                    {!isDownloading && !isErrorDownloading && 'Descargar resultados en excel'}
                  </DownloadLink>

                  {isDownloading && <SearchLoadingIcon />}

                  {!isDownloading && <ArrowDownIcon isErrorDownloading={!!isErrorDownloading} />}
                </Container>
              )}

              {questions.map(question => {
                const responseUser =
                  (question.multipleChoiceOptions?.length !== 0 &&
                    question.responses?.find(response => response.usrCode?.id === usrCode)) ||
                  undefined

                return (
                  <Item key={question.id} margin="0 0 32px" xs={12}>
                    <Card color="blank" enableBorder padding="24px">
                      <WrapperQuestions margin="0 0 32px">
                        <Item>
                          <Typography fontWeight="bold" margin="0 0 4px" variant="body2">
                            {question.usrCode?.name}
                          </Typography>

                          <Typography margin="0 0 4px" variant="subtitle">
                            {question.entCode?.name}
                          </Typography>

                          <Typography variant="subtitle">{question.orgCode?.name}</Typography>
                        </Item>

                        <Item>
                          <Typography
                            className="question"
                            margin={question.attachedFiles.length > 0 ? '0 0 8px' : undefined}
                            variant="body2"
                          >
                            {question.message}
                          </Typography>

                          {question.attachedFiles.length > 0 &&
                            question.attachedFiles.map(file => (
                              <AttachedFilesResponseWrapper key={file.id}>
                                <AttachedFilesLink onClick={() => onDownloadFile(file)}>{file.name}</AttachedFilesLink>
                              </AttachedFilesResponseWrapper>
                            ))}
                        </Item>
                      </WrapperQuestions>

                      {question.responses.map(response => {
                        const isBuyer = response.entCode?.userType === USER_TYPES_TRASLATE_DICTIONARY.BUYER
                        const isNotPublicUser =
                          response.usrCode?.id !== usrCode || response.publicUserUniquePass?.dni !== publicUserDni
                        const isPublicUser =
                          response.usrCode?.id === usrCode && response.publicUserUniquePass?.dni === publicUserDni
                        const isSeller = response.entCode?.userType === USER_TYPES_TRASLATE_DICTIONARY.SELLER

                        return (
                          <WrapperResponses key={response.id} margin="0 0 32px">
                            <Item>
                              <Typography fontWeight="bold" margin="0 0 4px" variant="body2">
                                {response.usrCode?.name || response.publicUserUniquePass?.name}
                              </Typography>

                              {isBuyer && response.entCode?.name && (
                                <Typography margin="0 0 4px" variant="subtitle">
                                  {response.entCode?.name}
                                </Typography>
                              )}

                              {isSeller && response.entCode?.name && (
                                <Container margin="0 0 4px">
                                  <SummaryLink onClick={handleGoToSellerCard(response.entCode?.dni)} target="_blank">
                                    {response.entCode?.name}
                                  </SummaryLink>
                                </Container>
                              )}

                              <Typography variant="subtitle"> {response.orgCode?.name}</Typography>
                            </Item>

                            {isWillBePublic && (
                              <WrapperResponse>
                                <ArrowForward />

                                <Typography className="response" color="black3" variant="body2">
                                  {response.message}
                                </Typography>

                                {response.attachedFiles.length > 0 &&
                                  response.attachedFiles.map(file => (
                                    <AttachedFilesResponseWrapper key={file.id}>
                                      <AttachedFilesLink onClick={() => onDownloadFile(file)}>
                                        {file.name}
                                      </AttachedFilesLink>
                                    </AttachedFilesResponseWrapper>
                                  ))}
                              </WrapperResponse>
                            )}

                            {!isWillBePublic && (
                              <>
                                {(detailUsrCode.id === usrCode || isPublicUser) && (
                                  <WrapperResponse>
                                    <ArrowForward />

                                    <Typography className="response" color="black3" variant="body2">
                                      {response.message}
                                    </Typography>

                                    {response.attachedFiles.length > 0 &&
                                      response.attachedFiles.map(file => (
                                        <AttachedFilesResponseWrapper key={file.id}>
                                          <AttachedFilesLink onClick={() => onDownloadFile(file)}>
                                            {file.name}
                                          </AttachedFilesLink>
                                        </AttachedFilesResponseWrapper>
                                      ))}
                                  </WrapperResponse>
                                )}

                                {detailUsrCode.id !== usrCode && isNotPublicUser && (
                                  <Toast severity="info">
                                    Las respuestas de esta Consulta al Mercado son de caracter <Strong>privado</Strong>,
                                    y solamente el organismo comprador puede visualizarlas.
                                  </Toast>
                                )}
                              </>
                            )}
                          </WrapperResponses>
                        )
                      })}

                      {!isClose && (
                        <WrapperAddResponse>
                          <AddResponseForm
                            isError={question.error}
                            isLoading={question.loading}
                            isResponseMultipleOptions={responseUser?.usrCode?.id === usrCode}
                            multipleChoiceOptions={question.multipleChoiceOptions}
                            onAdd={onAddFile}
                            onRemove={onRemoveFile}
                            onResponse={onResponse}
                            onShowUniquePassDialog={onShowUniquePassDialog}
                            publicUserDni={publicUserDni}
                            questionId={question.id}
                            responseUser={responseUser}
                            tempResponseAttachedFiles={question.tempResponseAttachedFiles}
                          />
                        </WrapperAddResponse>
                      )}
                    </Card>
                  </Item>
                )
              })}
            </>
          )}

          {questions.length === 0 && (
            <Toast border severity="warning" textDirection="vertical">
              Sin preguntas ni respuestas.
            </Toast>
          )}
        </>
      )}

      {!isLoading && isError && (
        <Toast
          border
          severity="error"
          textDirection="vertical"
          title="Lo sentimos, estamos presentando problemas con nuestro servidor."
        >
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Toast>
      )}
    </Item>
  )
}

QuestionsAndAnswers.defaultProps = {
  isClose: false,
  isDownloading: false,
  isError: false,
  isErrorDownloading: false,
  isLoading: false,
  isWillBePublic: false,
  questions: []
}

QuestionsAndAnswers.propTypes = {
  detailUsrCode: PropTypes.shape().isRequired,
  isClose: PropTypes.bool,
  isDownloading: PropTypes.bool,
  isError: PropTypes.bool,
  isErrorDownloading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isWillBePublic: PropTypes.bool,
  onAddFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onDownloadQuestionsAndAnswers: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onResponse: PropTypes.func.isRequired,
  onShowUniquePassDialog: PropTypes.func.isRequired,
  publicUserDni: PropTypes.string.isRequired,
  questions: PropTypes.array
}

export default memo(QuestionsAndAnswers)

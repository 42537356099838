import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { AddQuestionLink, Container, Questions } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's questions.
 */
const QuestionsSection = props => {
  const { children, isDisabled, onAdd } = props

  return (
    <Questions>
      {children}

      <Container>
        <AddQuestionLink disabled={isDisabled} onClick={onAdd}>
          Agregar otra pregunta
        </AddQuestionLink>
      </Container>
    </Questions>
  )
}

QuestionsSection.defaultProps = {
  isDisabled: false
}

QuestionsSection.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onAdd: PropTypes.func.isRequired
}

export default memo(QuestionsSection)

import React, { memo } from 'react'
import { useSelect } from '@chilecompra/react-kit'
import { Select, Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { Card, Container, Item, NotFoundImg, Skeleton, WrapperBox } from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's categoriesLevelOne.
 */
const CategoriesLevelOne = props => {
  const {
    categories,
    categoriesLevelTwoLength,
    category,
    children,
    isError,
    isLoading,
    isLoadingCatergoriesLevelTwo,
    onFetchCategoriesLevelTwo,
    onResetProductsByCategory,
    productsByCategoryLength
  } = props

  const generalLoading = !isLoading && !isLoadingCatergoriesLevelTwo

  const {
    onChange: handleCategoryChange,
    setValue: setCategoryValue,
    value: categoryValue
  } = useSelect({
    changeCallback: updatedValue => {
      updatedValue === '' && setCategoryValue(category)

      if (updatedValue !== '') {
        if (productsByCategoryLength > 0) onResetProductsByCategory()

        onFetchCategoriesLevelTwo(updatedValue)
      }
    },
    overwriteCallback: () => category
  })

  return (
    <Container>
      <Item margin="0 0 16px" xs={9}>
        <Select
          disabled={categories.length === 0}
          label="Categoría general"
          loading={isLoading}
          onChange={handleCategoryChange}
          options={categories}
          placeholder="Seleccione una categoria para buscar..."
          value={categoryValue}
        />
      </Item>

      {(isLoading || isLoadingCatergoriesLevelTwo) && (
        <WrapperBox>
          {[...Array(7)].map((_, index) => (
            <Skeleton height="40px" key={index} />
          ))}
        </WrapperBox>
      )}

      {!isError && generalLoading && (categories.length === 0 || categoriesLevelTwoLength === 0) && (
        <WrapperBox>
          <Item xs={12}>
            <Card className="not__found" color="blank" padding="24px">
              <Container alignItems="center" direction="column" justifyContent="center">
                <NotFoundImg src="/assets/images/not-found.svg" />

                <Typography fontWeight="bold" margin="16px 0" variant="h4">
                  No se encontraron resultados para tu búsqueda.
                </Typography>

                <Typography color="black3" variant="body2">
                  Intenta con otra palabra clave u otro criterio de búsqueda.
                </Typography>
              </Container>
            </Card>
          </Item>
        </WrapperBox>
      )}

      {!isError && generalLoading && categories.length > 0 && categoriesLevelTwoLength > 0 && (
        <WrapperBox middle={productsByCategoryLength > 0}>
          <Item xs={12}>{children}</Item>
        </WrapperBox>
      )}

      {!isLoading && !isLoadingCatergoriesLevelTwo && isError && (
        <WrapperBox>
          <Item xs={12}>
            <Toast
              border
              severity="error"
              textDirection="vertical"
              title="Lo sentimos, estamos presentando problemas con nuestro servidor."
            >
              En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos
              minutos más.
            </Toast>
          </Item>
        </WrapperBox>
      )}
    </Container>
  )
}

CategoriesLevelOne.defaultProps = {
  categories: [],
  category: '',
  isError: false,
  isLoading: false,
  isLoadingCatergoriesLevelTwo: false
}

CategoriesLevelOne.propTypes = {
  categories: PropTypes.array,
  categoriesLevelTwoLength: PropTypes.number.isRequired,
  category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingCatergoriesLevelTwo: PropTypes.bool,
  onFetchCategoriesLevelTwo: PropTypes.func.isRequired,
  onResetProductsByCategory: PropTypes.func.isRequired,
  productsByCategoryLength: PropTypes.number.isRequired
}

export default memo(CategoriesLevelOne)

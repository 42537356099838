import styled from 'styled-components'
import { BaseContainer, BaseLayout } from '../../../configs/styles/BaseGrid'

export const Body = styled(BaseLayout)``

export const Container = styled(BaseContainer)``

export const Featured = styled(BaseLayout)``

export const Header = styled(BaseLayout)``

import React from 'react'
import { Card as BaseCard, DatePicker as BaseDatePicker, Radio as BaseRadio } from '@chilecompra/react-kit/components'
import { Close as BaseClose } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const AddProductLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const AddQuestionLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const BaseGridOneColumn = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const AttachedFiles = styled(BaseGridOneColumn)``

export const BasicInformation = styled(BaseGridOneColumn)``

export const ClosingDate = styled(BaseItem)`
  &.MuiGrid-item {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, minmax(180px, 1fr));

    ${props => props.theme.breakpoints.down('xs')} {
      grid-template-columns: 1fr;
    }
  }
`

export const Container = styled(({ borderTop, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    border-top: ${props => props.borderTop && `1px solid ${props.theme.palette.brand.gray2}`};
  }
`

export const DatePicker = styled(BaseDatePicker)`
  &.MuiFormControl-root {
    &.calendar__icon {
      .MuiSvgIcon-root {
        height: 100%;
        padding: 0 6px;
      }

      .Mui-disabled .MuiSvgIcon-root {
        cursor: not-allowed;
      }
    }
  }
`

export const ExtraFormGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);

    ${props => props.theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr;
    }
  }
`

export const Files = styled(BaseItem)`
  &.MuiGrid-item {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;

    .base__file__name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
    }
  }
`

export const Item = styled(BaseItem)`
  &.MuiGrid-item {
    &.additional__info,
    &.will__be__public {
      section {
        display: none;
      }
    }
  }
`

export const Meeting = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const MeetingBox = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 8px;
    grid-template-columns: 110px 1fr;

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: 1fr;
    }
  }
`

export const MeetingContent = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${props => props.theme.breakpoints.down('md')} {
      grid-template-columns: 1fr;
    }
  }
`

export const MeetingWrapper = styled(({ full, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    border-bottom: 1px solid ${props => props.theme.palette.brand.gray2};
    display: grid;
    grid-column: ${props => props.full && '1 / 3'};

    ${props => props.theme.breakpoints.down('md')} {
      grid-column: auto;
    }

    :last-child {
      border-bottom: none;
    }
  }
`

export const Meetings = styled(BaseGridOneColumn)``

export const NotFoundImg = styled.img`
  color: ${props => props.theme.palette.gray[50]};
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 82px;
`

export const Product = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const ProductWrapper = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 16px;
    grid-template-columns: auto 1fr auto;
  }
`

export const Products = styled(BaseContainer)`
  &.MuiGrid-container {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }
`

export const ProductsEmpty = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const Question = styled(({ removable, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: ${props => (props.removable ? '1fr 100px' : '1fr')};

    ${props => props.theme.breakpoints.only('xs')} {
      grid-template-columns: 1fr;
    }
  }
`

export const Questions = styled(BaseGridOneColumn)``

export const Radio = styled(BaseRadio)`
  &.MuiFormGroup-root {
    &[direction='vertical'] {
      margin: 0;

      .MuiFormControlLabel-root {
        margin: 0 0 8px;
      }
    }
  }
`

export const RemoveIcon = styled(({ disabled, ...props }) => <BaseClose {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.disabled && props.theme.palette.brand.disableFont};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 18px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    width: 18px;
  }
`

export const Strong = styled.span`
  font-weight: bold;
`

export const WrapperCard = styled(BaseCard)`
  &.MuiPaper-root {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const WrapperGrid = styled(BaseContainer)`
  &.MuiGrid-container {
    border-top: 1px solid ${props => props.theme.palette.brand.gray2};
    display: grid;
    gap: 32px;
    grid-template-columns: 240px 1fr;

    ${props => props.theme.breakpoints.down('sm')} {
      grid-template-columns: 1fr;
    }
  }
`

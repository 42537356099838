import React, { useCallback } from 'react'
import { Tooltip } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import {
  BaseInput,
  BottomMessage,
  Counter,
  Details,
  Error,
  ErrorIcon,
  Info,
  Label,
  Required,
  Root,
  Skeleton,
  SuccessIcon,
  TooltipIcon,
  Wrapper
} from './TextArea.styles'

/**
 * The TextArea's component.
 */
const TextArea = props => {
  const {
    autoFocus,
    disabled,
    error,
    info,
    label,
    loading,
    margin,
    maxCount,
    maxLength,
    multiline,
    onChange,
    padding,
    placeholder,
    prefix,
    required,
    rows,
    size,
    success,
    suffix,
    textAlign,
    tooltip,
    type,
    value,
    ...restProps
  } = props

  const handleBlur = useCallback(() => {
    if (value) return

    onChange({ type: 'blur' })
  }, [onChange, value])

  return (
    <Root margin={margin} padding={padding}>
      {label && (
        <Label size={size}>
          {label}

          {required && <Required size={size} />}

          {tooltip && (
            <Tooltip content={tooltip}>
              <TooltipIcon size={size} />
            </Tooltip>
          )}
        </Label>
      )}

      {loading && <Skeleton size={size} variant="rect" />}

      {!loading && (
        <Wrapper>
          <BaseInput
            autoFocus={autoFocus}
            disabled={disabled}
            endAdornment={suffix}
            error={Boolean(error)}
            inputProps={{ maxLength }}
            multiline={multiline}
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            showIcon={(error || success) && !prefix && !suffix}
            size={size}
            startAdornment={prefix}
            success={success}
            textAlign={textAlign}
            type={type}
            value={value}
            {...restProps}
          />
          {!prefix && !suffix && error && <ErrorIcon size={size} />}

          {!error && !prefix && !suffix && success && <SuccessIcon size={size} />}
        </Wrapper>
      )}

      <Details gridColumnGap={maxCount > 0 ? '5px' : 'initial'}>
        <BottomMessage size={size}>
          {!error && <Info>{info}</Info>}

          {error && <Error>{error}</Error>}
        </BottomMessage>

        {maxCount > 0 && (
          <Counter size={size}>
            {value?.length}/{maxCount}
          </Counter>
        )}
      </Details>
    </Root>
  )
}

TextArea.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: '',
  info: '',
  label: '',
  loading: false,
  margin: 0,
  maxCount: 0,
  multiline: true,
  onChange: () => undefined,
  padding: 0,
  placeholder: '',
  prefix: null,
  required: false,
  rows: 4,
  size: 'medium',
  success: false,
  suffix: null,
  textAlign: 'initial',
  tooltip: '',
  type: 'text'
}

TextArea.propTypes = {
  /**
   * Autofocus.
   */
  autoFocus: PropTypes.bool,
  /**
   * Disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Error.
   */
  error: PropTypes.string,
  /**
   * Info.
   */
  info: PropTypes.string,
  /**
   * Label.
   */
  label: PropTypes.string,
  /**
   * Loading.
   */
  loading: PropTypes.bool,
  /**
   * Margin.
   */
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Max count.
   */
  maxCount: PropTypes.number,
  /**
   * Max length.
   */
  maxLength: PropTypes.number,
  /**
   * Multiline.
   */
  multiline: PropTypes.bool,
  /**
   * On change.
   */
  onChange: PropTypes.func,
  /**
   * Padding.
   */
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * Prefix.
   */
  prefix: PropTypes.node,
  /**
   * Required.
   */
  required: PropTypes.bool,
  /**
   * Rows.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Size.
   */
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /**
   * Success.
   */
  success: PropTypes.bool,
  /**
   * Suffix.
   */
  suffix: PropTypes.node,
  /**
   * Text align.
   */
  textAlign: PropTypes.oneOf(['center', 'end', 'initial', 'start']),
  /**
   * Tooltip.
   */
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Type.
   */
  type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
  /**
   * Value.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default TextArea

import React, { memo, useCallback } from 'react'
import {
  checkInputErrorMaxLength,
  checkInputErrorMinLength,
  checkInputErrorRequired,
  useInput
} from '@chilecompra/react-kit'
import { Button, Input, Toast, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import {
  Card,
  Container,
  Item,
  NotFoundImg,
  SearchIcon,
  SearchLoadingIcon,
  Skeleton,
  WrapperBox
} from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's searchProducts.
 */
const SearchProducts = props => {
  const { children, isLoading, isError, keyword, onSearchProducts, productsLength } = props

  const {
    error: keywordError,
    onChange: handleKeywordChange,
    value: keywordValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorMinLength(3), checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => keyword
  })

  const handleSearchProducts = () => onSearchProducts(keywordValue)

  const disableSubmit = useCallback(() => {
    const hasErrors = keywordError !== ''
    const isIncomplete = !keywordValue

    return hasErrors || isIncomplete
  }, [keywordError, keywordValue])

  return (
    <Container>
      <Item margin="0 0 32px" md={9} xs={12}>
        <Card padding="24px">
          <Container alignItems="center" spacing={4}>
            <Item sm={8} xs={12}>
              <Input
                disabled={isLoading}
                error={keywordError}
                label="Id o Palabra clave"
                onChange={handleKeywordChange}
                onKeyPress={({ key }) => key === 'Enter' && handleSearchProducts()}
                required
                type="text"
                value={keywordValue}
              />
            </Item>

            <Item sm={4} xs={12}>
              <Button
                color="primary"
                disabled={disableSubmit() || isLoading}
                onClick={handleSearchProducts}
                padding="0 16px"
                variant="contained"
                width="100%"
              >
                {isLoading ? <SearchLoadingIcon /> : <SearchIcon />} Buscar
              </Button>
            </Item>
          </Container>
        </Card>
      </Item>

      {isLoading && (
        <WrapperBox>
          {[...Array(7)].map((_, index) => (
            <Skeleton height="40px" key={index} />
          ))}
        </WrapperBox>
      )}

      {!isError && !isLoading && productsLength === 0 && (
        <WrapperBox>
          <Item xs={12}>
            <Card className="not__found" color="blank" padding="24px">
              <Container alignItems="center" direction="column" justifyContent="center">
                <NotFoundImg src="/assets/images/not-found.svg" />

                <Typography fontWeight="bold" margin="16px 0" variant="h4">
                  No se encontraron resultados para tu búsqueda.
                </Typography>

                <Typography color="black3" variant="body2">
                  Intenta con otra palabra clave u otro criterio de búsqueda.
                </Typography>
              </Container>
            </Card>
          </Item>
        </WrapperBox>
      )}

      {!isError && !isLoading && productsLength > 0 && (
        <WrapperBox>
          <Item xs={12}>{children}</Item>
        </WrapperBox>
      )}

      {!isLoading && isError && (
        <WrapperBox>
          <Item xs={12}>
            <Toast
              border
              severity="error"
              textDirection="vertical"
              title="Lo sentimos, estamos presentando problemas con nuestro servidor."
            >
              En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos
              minutos más.
            </Toast>
          </Item>
        </WrapperBox>
      )}
    </Container>
  )
}

SearchProducts.defaultProps = {
  isError: false,
  isLoading: false
}

SearchProducts.propTypes = {
  children: PropTypes.node.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  keyword: PropTypes.string.isRequired,
  onSearchProducts: PropTypes.func.isRequired,
  productsLength: PropTypes.number.isRequired
}

export default memo(SearchProducts)

import { factoryService, getJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL, REACT_APP_API_PRINCIPAL_URL } from '../configs/settings/environment'

/**
 * Gets the user info contained in the token.
 */
export const getAuthInfo = factoryService(() => getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/auth/info`))

/**
 * Gets a valid public token.
 */
export const getPublicToken = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_PRINCIPAL_URL}/v1/auth/publico`, { isPublic: true })
})

/**
 * Gets a valid public user by keycloak token.
 */
export const getPublicUserAuthInfo = factoryService(({ token }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/usuario/${token}`)
})

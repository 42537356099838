import React, { memo, useCallback, useEffect, createRef } from 'react'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  useInput,
  useSecondOrderCallback,
  useSelect
} from '@chilecompra/react-kit'
import { Input, Select } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { QUESTION_TYPE_OPTIONS, QUESTION_TYPE_OPTIONS_DICTIONARY } from '../../configs/settings/constants'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import QuestionMultipleChoiceItem from './RequestCreateInnovationForm.questionMultipleChoice'
import {
  AddQuestionMultipleChoiceLink,
  Question,
  QuestionMultipleChoice,
  QuestionType,
  RadioUncheckedIcon,
  RemoveIcon
} from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's question.
 */
const QuestionItem = props => {
  const {
    duplicatedError,
    isDisabled,
    message,
    multipleChoiceOptions,
    onAddQuestionMultipleChoice,
    onInitialQuestionsMultipleChoice,
    onRemove,
    onRemoveQuestionMultipleChoice,
    onResetQuestionsMultipleChoice,
    onUpdate,
    onUpdateQuestionMultipleChoiceValue,
    questionType,
    removable,
    submitError
  } = props

  const messageRef = createRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const { onChange: handleQuestionTypeChange } = useSelect({
    changeCallback: updatedValue => {
      if (updatedValue !== '') {
        onUpdate({ key: 'type', value: updatedValue })

        if (updatedValue === QUESTION_TYPE_OPTIONS_DICTIONARY.MULTIPLE_CHOICE) {
          onInitialQuestionsMultipleChoice()
        } else onResetQuestionsMultipleChoice()
      }
    },
    overwriteCallback: () => questionType
  })

  const {
    error: messageError,
    onChange: handleMessageChange,
    value: messageValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'message', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(300)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => message
  })

  const messageErrorText = useCallback(() => {
    if (messageError !== '') return messageError
    if (duplicatedError) return 'No puedes ingresar dos veces la misma pregunta.'
  }, [duplicatedError, messageError])

  const handleUpdateQuestionMultipleChoiceValue = useSecondOrderCallback((entryOption, idOption) => {
    onUpdateQuestionMultipleChoiceValue({ entryOption, idOption })
  })

  const handleRemoveQuestionMultipleChoice = useSecondOrderCallback((_, idOption) => {
    onRemoveQuestionMultipleChoice(idOption)
  })

  useEffect(() => {
    if (
      conditionsFields([
        'DUPLICATED_QUESTION_ERROR',
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR'
      ]) &&
      messageRef.current.getAttribute('aria-invalid') === 'true'
    ) {
      return messageRef.current?.focus()
    }
  }, [messageRef, submitError])

  return (
    <Question>
      <Select
        label="Tipo"
        onChange={handleQuestionTypeChange}
        options={QUESTION_TYPE_OPTIONS}
        required
        value={questionType}
      />

      <QuestionType removable={removable}>
        <Input
          disabled={isDisabled}
          error={messageErrorText()}
          inputRef={messageRef}
          label="Pregunta"
          maxCount={300}
          onChange={handleMessageChange}
          required
          value={messageValue}
        />

        {removable && <RemoveIcon disabled={isDisabled} onClick={onRemove} />}
      </QuestionType>

      {questionType === QUESTION_TYPE_OPTIONS_DICTIONARY.MULTIPLE_CHOICE && (
        <>
          {multipleChoiceOptions.map((option, index) => (
            <QuestionMultipleChoiceItem
              duplicatedError={!!option.duplicated}
              index={index + 1}
              isDisabled={isDisabled}
              key={option.id}
              message={option.message}
              onRemove={handleRemoveQuestionMultipleChoice(option.id)}
              onUpdate={handleUpdateQuestionMultipleChoiceValue(option.id)}
              removable={index > 1}
              submitError={submitError}
            />
          ))}

          <QuestionMultipleChoice>
            <RadioUncheckedIcon />

            <AddQuestionMultipleChoiceLink disabled={isDisabled} onClick={onAddQuestionMultipleChoice}>
              Agregar otra opción
            </AddQuestionMultipleChoiceLink>
          </QuestionMultipleChoice>
        </>
      )}
    </Question>
  )
}

QuestionItem.defaultProps = {
  isDisabled: false,
  multipleChoiceOptions: [],
  submitError: undefined
}

QuestionItem.propTypes = {
  duplicatedError: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  multipleChoiceOptions: PropTypes.array,
  onAddQuestionMultipleChoice: PropTypes.func.isRequired,
  onInitialQuestionsMultipleChoice: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveQuestionMultipleChoice: PropTypes.func.isRequired,
  onResetQuestionsMultipleChoice: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateQuestionMultipleChoiceValue: PropTypes.func.isRequired,
  questionType: PropTypes.number.isRequired,
  removable: PropTypes.bool.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(QuestionItem)

import React, { memo } from 'react'
import { formatDate, formatDNI } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { MEETING_TYPE_OPTIONS } from '../../configs/settings/constants'
import {
  Container,
  Item,
  Meeting,
  MeetingBox,
  MeetingContent,
  MeetingWrapper,
  RemoveIcon
} from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's meeting.
 */
const MeetingItem = props => {
  const {
    assistants,
    businessName,
    businessDni,
    dateTime,
    isDisabled,
    meetingOtherType,
    meetingType,
    onRemove,
    reasonMeeting
  } = props

  return (
    <Meeting padding="12px 24px">
      <Container alignItems="center" justifyContent="flex-end">
        <RemoveIcon disabled={isDisabled} onClick={onRemove} />
      </Container>

      <MeetingContent>
        <MeetingWrapper full>
          <MeetingBox padding="12px 0">
            <Typography color="black3" variant="body2">
              Tipo de reunión:
            </Typography>

            <Typography variant="body2">
              {meetingOtherType || MEETING_TYPE_OPTIONS.find(({ value }) => value === meetingType).name}
            </Typography>
          </MeetingBox>
        </MeetingWrapper>

        <MeetingWrapper>
          <MeetingBox padding="12px 0">
            <Typography color="black3" variant="body2">
              RUT:
            </Typography>

            <Typography variant="body2">{businessDni}</Typography>
          </MeetingBox>
        </MeetingWrapper>

        <MeetingWrapper>
          <MeetingBox padding="12px 0">
            <Typography color="black3" variant="body2">
              Fecha reunión:
            </Typography>

            <Typography variant="body2">
              {dateTime && formatDate(dateTime, `dd/MM/yyyy 'a las' ${formatDate(dateTime, 'HH:mm')}`)}
            </Typography>
          </MeetingBox>
        </MeetingWrapper>

        <MeetingWrapper full>
          <MeetingBox padding="12px 0">
            <Typography color="black3" variant="body2">
              Razón Social:
            </Typography>

            <Typography variant="body2">{businessName}</Typography>
          </MeetingBox>
        </MeetingWrapper>

        <MeetingWrapper full>
          <MeetingBox padding="12px 0">
            <Typography color="black3" variant="body2">
              Motivo:
            </Typography>

            <Typography variant="body2">{reasonMeeting}</Typography>
          </MeetingBox>
        </MeetingWrapper>

        <MeetingWrapper full>
          <MeetingBox padding="12px 0 0">
            <Typography color="black3" variant="body2">
              Asistentes:
            </Typography>

            <Item>
              {assistants.map(assistant => (
                <Item key={assistant.id}>
                  <Container margin="0 0 8px">
                    <Typography margin="0 8px 0 0" variant="body2">
                      {`${assistant.names} ${assistant.lastNames}`}
                    </Typography>

                    <Typography variant="subtitle">
                      {`${assistant.isForeign ? assistant.foreignDni : formatDNI(assistant.dni, 1)} - ${
                        assistant.position
                      }`}
                    </Typography>
                  </Container>
                </Item>
              ))}
            </Item>
          </MeetingBox>
        </MeetingWrapper>
      </MeetingContent>
    </Meeting>
  )
}

MeetingItem.defaultProps = {
  isDisabled: false
}

MeetingItem.propTypes = {
  assistants: PropTypes.arrayOf(
    PropTypes.shape({
      dni: PropTypes.string.isRequired,
      lastNames: PropTypes.string.isRequired,
      names: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired
    })
  ).isRequired,
  businessDni: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  dateTime: PropTypes.instanceOf(Date).isRequired,
  isDisabled: PropTypes.bool,
  meetingOtherType: PropTypes.string.isRequired,
  meetingType: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  reasonMeeting: PropTypes.string.isRequired
}

export default memo(MeetingItem)

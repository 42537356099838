import {
  CLOSE_PRODUCT_DIOALOG,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_LEVEL_TWO,
  GET_CATEGORIES_LEVEL_TWO_ERROR,
  GET_CATEGORIES_LEVEL_TWO_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_BY_CATEGORY,
  GET_PRODUCTS_BY_CATEGORY_ERROR,
  GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  RESET_PRODUCTS_BY_CATEGORY,
  RESET_PRODUCT_FORM,
  SHOW_PRODUCT_DIALOG
} from './RequestProductDialog.actions'

const requestProductDialogState = {
  categories: [],
  categoriesLevelTwo: [],
  category: '',
  error: undefined,
  errorProductsByCategory: undefined,
  keyword: '',
  loading: false,
  loadingCatergoriesLevelTwo: false,
  loadingProductsByCategory: false,
  products: [],
  productsByCategory: [],
  showDialog: false
}

/**
 * The RequestProductDialog's reducer.
 */
const requestProductDialogReducer = (state = requestProductDialogState, { payload, type }) => {
  switch (type) {
    case CLOSE_PRODUCT_DIOALOG: {
      return { ...state, showDialog: false }
    }
    case GET_CATEGORIES: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_CATEGORIES_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_CATEGORIES_SUCCESS: {
      return { ...state, categories: payload.categories, error: undefined, loading: false }
    }
    case GET_CATEGORIES_LEVEL_TWO: {
      return { ...state, error: undefined, loadingCatergoriesLevelTwo: true }
    }
    case GET_CATEGORIES_LEVEL_TWO_ERROR: {
      return { ...state, error: payload.error, loadingCatergoriesLevelTwo: false }
    }
    case GET_CATEGORIES_LEVEL_TWO_SUCCESS: {
      return {
        ...state,
        categoriesLevelTwo: payload.categoriesLevelTwo,
        error: undefined,
        loadingCatergoriesLevelTwo: false
      }
    }
    case GET_PRODUCTS: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_PRODUCTS_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_PRODUCTS_SUCCESS: {
      return { ...state, error: undefined, loading: false, products: payload.products }
    }
    case GET_PRODUCTS_BY_CATEGORY: {
      return { ...state, errorProductsByCategory: undefined, loadingProductsByCategory: true }
    }
    case GET_PRODUCTS_BY_CATEGORY_ERROR: {
      return { ...state, errorProductsByCategory: payload.error, loadingProductsByCategory: false }
    }
    case GET_PRODUCTS_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        errorProductsByCategory: undefined,
        loadingProductsByCategory: false,
        productsByCategory: payload.productsByCategory
      }
    }
    case RESET_PRODUCTS_BY_CATEGORY: {
      return { ...state, productsByCategory: [] }
    }
    case RESET_PRODUCT_FORM: {
      return { ...state, ...requestProductDialogState, showDialog: state.showDialog }
    }
    case SHOW_PRODUCT_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestProductDialogReducer

import React, { memo } from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { AddIcon, AddProductLink, AddSuccessIcon, Container, Item } from './RequestProductDialog.styles'

/**
 * The RequestProductDialog's product.
 */
const ProductsItem = props => {
  const { isAdded, name, onAdd, productId } = props

  const { size } = useViewport()
  return (
    <Container alignItems="center" isAdded={isAdded} margin="0 0 4px" padding="4px 16px">
      {isAdded && (
        <AddProductLink disabled={isAdded} md={3} sm={5} xs={6}>
          <AddSuccessIcon /> Producto agregado
        </AddProductLink>
      )}

      {!isAdded && (
        <AddProductLink md={3} onClick={onAdd(name, productId)} sm={5} xs={6}>
          <AddIcon /> Agregar al listado
        </AddProductLink>
      )}

      <Item md={2} sm={7} xs={6}>
        <Typography color="black3" variant="body2">
          {productId}
        </Typography>
      </Item>

      <Item margin={size.isDownToSmall ? '8px 0 0' : undefined} md={7} xs={12}>
        <Typography variant="body2">{name}</Typography>
      </Item>
    </Container>
  )
}

ProductsItem.propTypes = {
  isAdded: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired
}

export default memo(ProductsItem)

import { batch } from 'react-redux'
import { formatDate } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import { extendEndDate } from '../../services/request'
import { onShowSnackbar } from '../SnackbarProvider/SnackbarProvider.actions'

export const CLOSE_EXTEND_END_DATE_DIOALOG = 'CLOSE_EXTEND_END_DATE_DIOALOG'
export const onCloseExtendEndDateDialog = makeActionCreator(CLOSE_EXTEND_END_DATE_DIOALOG)

export const EXTEND_END_DATE = 'EXTEND_END_DATE'
export const EXTEND_END_DATE_ERROR = 'EXTEND_END_DATE_ERROR'
export const EXTEND_END_DATE_SUCCESS = 'EXTEND_END_DATE_SUCCESS'
export const onExtendEndDate = makeActionCreator(EXTEND_END_DATE)
export const onExtendEndDateError = makeActionCreator(EXTEND_END_DATE_ERROR, 'payload')
export const onExtendEndDateSuccess = makeActionCreator(EXTEND_END_DATE_SUCCESS, 'payload')
export const onExtendEndDateThunk = payload => async (dispatch, getState) => {
  const { dateTime, reloadDetailCallback = () => undefined } = payload || {}

  dispatch(onExtendEndDate())

  try {
    const { id: requestId } = getState().requestDetailInfo

    await extendEndDate({
      extendEndDate: formatDate(dateTime, `yyyy-MM-dd ${formatDate(dateTime, 'HH:mm:ss')}`),
      requestId
    })

    return batch(() => {
      dispatch(onCloseExtendEndDateDialog())
      dispatch(onShowSnackbar({ message: 'Plazo extendido exitosamente', severity: 'success' }))
      dispatch(onExtendEndDateSuccess())
      reloadDetailCallback()
    })
  } catch (error) {
    return dispatch(onExtendEndDateError())
  }
}

export const SHOW_EXTEND_END_DATE_DIALOG = 'SHOW_EXTEND_END_DATE_DIALOG'
export const onShowExtendEndDateDialog = makeActionCreator(SHOW_EXTEND_END_DATE_DIALOG, 'payload')

export const UPDATE_EXTEND_END_DATE_VALUE = 'UPDATE_EXTEND_END_DATE_VALUE'
export const onUpdateExtendEndDateValue = makeActionCreator(UPDATE_EXTEND_END_DATE_VALUE, 'payload')

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import routes from '../../configs/settings/routes'
import { onShowFeedbackErrorDialog } from '../RequestFeedbackErrorDialog/RequestFeedbackErrorDialog.actions'
import { onCloseFeedbackConfirmDialog, onPublicatedRequestThunk } from './RequestFeedbackConfirmDialog.actions'
import { DialogButtons, DialogContent, DialogIcon, DialogMessage } from './RequestFeedbackConfirmDialog.styles'

/**
 * The RequestFeedbackConfirmDialog's container.
 */
const RequestFeedbackConfirmDialog = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()

  const { loading, showDialog } = useSelector(state => state.requestFeedbackConfirmDialog)

  const handleCloseFeedbackConfirmDialog = () => dispatch(onCloseFeedbackConfirmDialog())

  const handleRequesPublicated = () => {
    if (loading) return

    dispatch(
      onPublicatedRequestThunk({
        goToPublishedPageCallback: ({ code }) => {
          query.set('codigo-consulta', code)

          handleCloseFeedbackConfirmDialog()
          navigate({ pathname: routes.published, search: query.toString() }, { replace: true })
        },
        showFeedbackErrorDialogCallback: () => {
          batch(() => {
            handleCloseFeedbackConfirmDialog()
            dispatch(onShowFeedbackErrorDialog())
          })
        }
      })
    )
  }

  return (
    <Dialog
      disableBackdropClick
      disableCloseIcon
      margin="auto"
      maxWidth="500px"
      onClose={handleCloseFeedbackConfirmDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent alignItems="center" justifyContent="center" padding="32px">
        <DialogIcon alt="Publicar consulta al mercado" src="/assets/icons/circle-warning.svg" />

        <DialogMessage alignItems="center" justifyContent="center" margin="16px 0 32px">
          <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
            Estás a punto de publicar tu Consulta al Mercado
          </Typography>

          <Typography variant="body2">
            Una vez publicada, tu consulta quedará visible publicamente en nuestra plataforma.
          </Typography>
        </DialogMessage>

        <DialogButtons alignItems="center" direction="column" justifyContent="center">
          <Button
            color="primary"
            disabled={loading}
            loading={loading}
            margin="0 0 16px"
            onClick={handleRequesPublicated}
          >
            Publicar consulta
          </Button>

          <Button color="default" disabled={loading} onClick={handleCloseFeedbackConfirmDialog} variant="text">
            Cancelar y volver
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestFeedbackConfirmDialog

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import { formatDate, useEffectOnce, useQuery, useQueryParam, useViewport } from '@chilecompra/react-kit'
import { Button, Typography } from '@chilecompra/react-kit/components'
import { PATH_PARAM } from '../../configs/settings/regexes'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { onResetCreateForm } from '../RequestCreateForm/RequestCreateForm.actions'
import { onResetMeetingForm } from '../RequestMeetingDialog/RequestMeetingDialog.actions'
import { onResetProductForm } from '../RequestProductDialog/RequestProductDialog.actions'
import { onResetReasonForm } from '../RequestReasonDialog/RequestReasonDialog.actions'
import { useAuthProvider } from './../AuthProvider/AuthProvider.hook'
import { Card, Container, Item, Published } from './RequestPublishedSuccessfully.styles'

/**
 * The RequestPublishedSuccessfully's container.
 */
const RequestPublishedSuccessfully = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()
  const { size } = useViewport()
  const code = useQueryParam('codigo-consulta')

  const { dateTime } = useSelector(state => state.requestCreateForm)
  const { filters } = useSelector(state => state.requestList)
  const { otherReason, reason } = useSelector(state => state.requestReasonDialog)

  const handleGoRequestDetail = () => {
    batch(() => {
      dispatch(onResetCreateForm())
      dispatch(onResetMeetingForm())
      dispatch(onResetProductForm())
      dispatch(onResetReasonForm())
    })

    navigate(routes.detail.replace(PATH_PARAM, code))
  }

  const handleGoHomeWithParams = () => {
    batch(() => {
      dispatch(onResetCreateForm())
      dispatch(onResetMeetingForm())
      dispatch(onResetProductForm())
      dispatch(onResetReasonForm())
    })

    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  useEffectOnce(() => {
    if (!otherReason && !reason) return handleGoHomeWithParams()
  })

  if (!otherReason && !reason) return

  return (
    <Container padding="16px 0">
      <Item md={9} xs={12}>
        <Card color="blank" enableBorder margin="0 0 32px" padding="48px 24px">
          <Container alignItems="center" direction="column" justifyContent="center">
            <Item lg={7} md={10} xs={12}>
              <Published src="/assets/images/published.svg" />

              <Typography fontWeight="bold" margin="16px 0" variant="h4">
                Tu Consulta al Mercado ha sido publicada exitosamente
              </Typography>

              <Typography margin="0 0 32px" variant="body2">
                Puedes revisar el estado del proceso desde la opción Consulta al Mercado, podrás ampliar la fecha de
                cierre y revisar las respuestas de los proveedores.
              </Typography>

              <Card margin="0 0 64px" padding="24px">
                <Typography color="black3" fontWeight="bold" margin="0 0 8px" variant="body1">
                  {code}
                </Typography>

                <Typography variant="subtitle">
                  El proceso cerrará el {formatDate(dateTime, `dd-MM-yyyy ${formatDate(dateTime, 'HH:mm')}`)}
                </Typography>
              </Card>

              <Container alignItems="center" direction="column" justifyContent="center">
                <Button color="primary" margin="0 0 16px" onClick={handleGoRequestDetail}>
                  Ver consulta publicada
                </Button>

                <Button
                  color="default"
                  margin="0 0 32px"
                  onClick={handleGoHomeWithParams}
                  padding={size.isTiny ? '0 16px' : undefined}
                  variant="text"
                >
                  Volver al listado de Consultas al Mercado
                </Button>
              </Container>
            </Item>
          </Container>
        </Card>
      </Item>
    </Container>
  )
}

export default RequestPublishedSuccessfully

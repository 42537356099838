import React from 'react'
import { Link as BaseLink } from 'react-router-dom'
import { Spinner as BaseSpinner } from '@chilecompra/react-kit/components'
import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
  AccordionSummary as BaseAccordionSummary
} from '@material-ui/core'
import { ExpandMore as BaseExpandMoreIcon, Search as BaseSearchIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { BaseContainer } from '../../configs/styles/BaseGrid'

export const Accordion = styled(BaseAccordion)`
  &.MuiAccordion-root {
    &.Mui-expanded:last-child {
      margin: 0;
    }

    &::before {
      display: none;
    }
  }
`

export const AccordionDetails = styled(BaseAccordionDetails)`
  &.MuiAccordionDetails-root {
    flex-direction: column;
    padding: 24px 0;
  }
`

export const AccordionSummary = styled(BaseAccordionSummary)`
  &.MuiAccordionSummary-root {
    background: ${props => props.theme.palette.gray[95]};
    border-radius: 4px;
    padding: 0 16px;

    &.Mui-expanded {
      min-height: 48px;
    }

    .MuiAccordionSummary-content,
    .MuiAccordionSummary-expandIcon {
      margin: 0;
    }
  }
`

export const Container = styled(BaseContainer)``

export const ExpandIcon = styled(BaseExpandMoreIcon)``

export const Link = styled(({ disabled, ...props }) => <BaseLink {...props} />)`
  align-items: center;
  color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 16px 0 45px;
  pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
  }
`

export const SearchIcon = styled(({ disabled, ...props }) => <BaseSearchIcon {...props} />)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.gray[50]};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 24px;
    margin-right: 8px;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    width: 24px;

    &:hover {
      color: ${props => props.theme.palette.blue[50]};
    }
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} size="18px" />)`
  &.MuiCircularProgress-root {
    margin-right: 8px;
  }
`

import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import routes from './configs/settings/routes'
import AuthProviderContainer from './containers/AuthProvider/AuthProvider.container'
import AuthProviderContext from './containers/AuthProvider/AuthProvider.context'
import AuthCallbackPage from './pages/AuthCallback'
import CreateRequestPage from './pages/CreateRequest'
import CreateRequestInnovationPage from './pages/CreateRequestInnovation'
import DetailRequestPage from './pages/DetailRequest'
import NotFoundPage from './pages/NotFound'
import PublishedRequestPage from './pages/PublishedRequest'
import RequestPrivatePage from './pages/RequestPrivate'
import RequestPublicPage from './pages/RequestPublic'

/**
 * The Routing's component.
 */
const routeComponents = [
  <Route element={<AuthCallbackPage />} key="authCallback" path={routes.authCallback} />,
  <Route element={<CreateRequestInnovationPage />} key="createStepTwo" path={routes.createStepTwo} />,
  <Route element={<CreateRequestPage />} key="create" path={routes.create} />,
  <Route element={<DetailRequestPage />} key="detail" path={routes.detail} />,
  <Route element={<PublishedRequestPage />} key="published" path={routes.published} />,
  <Route
    element={
      <AuthProviderContainer>
        <AuthProviderContext.Consumer>
          {({ isPrivate }) => (isPrivate ? <RequestPrivatePage /> : <RequestPublicPage />)}
        </AuthProviderContext.Consumer>
      </AuthProviderContainer>
    }
    errorElement={
      <AuthProviderContainer>
        <NotFoundPage />
      </AuthProviderContainer>
    }
    key="root"
    path={routes.root}
  />,
  <Route element={<RequestPrivatePage />} key="search" path={routes.search} />,
  <Route element={<RequestPublicPage />} key="searchPublic" path={routes.searchPublic} />
]

const Routing = createBrowserRouter(createRoutesFromElements(routeComponents))

export default Routing

import {
  CLOSE_EXTEND_END_DATE_DIOALOG,
  EXTEND_END_DATE,
  EXTEND_END_DATE_ERROR,
  EXTEND_END_DATE_SUCCESS,
  SHOW_EXTEND_END_DATE_DIALOG,
  UPDATE_EXTEND_END_DATE_VALUE
} from './RequestExtendEndDate.actions'

const requestExtendEndDateState = { dateTime: undefined, error: 0, loading: false, showDialog: false }

/**
 * The RequestExtendEndDate's reducer.
 */
const requestExtendEndDateReducer = (state = requestExtendEndDateState, { payload, type }) => {
  switch (type) {
    case CLOSE_EXTEND_END_DATE_DIOALOG: {
      return { ...state, showDialog: false }
    }
    case EXTEND_END_DATE: {
      return { ...state, error: 0, loading: true }
    }
    case EXTEND_END_DATE_ERROR: {
      return { ...state, error: state.error + 1, loading: false }
    }
    case EXTEND_END_DATE_SUCCESS: {
      return { ...state, error: 0, loading: false }
    }
    case SHOW_EXTEND_END_DATE_DIALOG: {
      return { ...state, dateTime: payload.dateTime, showDialog: true }
    }
    case UPDATE_EXTEND_END_DATE_VALUE: {
      return { ...state, [payload.key]: payload.value }
    }
    default: {
      return state
    }
  }
}

export default requestExtendEndDateReducer

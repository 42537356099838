import React from 'react'
import { Card as BaseCard } from '@chilecompra/react-kit/components'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    display: flex;

    .MuiGrid-container {
      height: 100%;
    }
  }
`

export const Container = styled(BaseContainer)``

export const CreateRequestLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.brand.disableFont : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

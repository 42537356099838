import React from 'react'
import { ScrollRestoration } from 'react-router-dom'
import BrandLayout from '../components/Layouts/BrandLayout'
import AuthProviderContainer from '../containers/AuthProvider/AuthProvider.container'
import HeaderContainer from '../containers/Header/Header.container'
import HeadingContainer from '../containers/Heading/Heading.container'
import PublicUserAuthProviderContainer from '../containers/PublicUserAuthProvider/PublicUserAuthProvider.container'
import RequestDetailInfoContainer from '../containers/RequestDetailInfo/RequestDetailInfo.container'
import RequestExtendEndDateContainer from '../containers/RequestExtendEndDate/RequestExtendEndDate.container'
import RequestHistoryContainer from '../containers/RequestHistory/RequestHistory.container'
import RequestMeetingDialogContainer from '../containers/RequestMeetingDialog/RequestMeetingDialog.container'
import RequestUniquePassDialogContainer from '../containers/RequestUniquePassDialog/RequestUniquePassDialog.container'

/**
 * The DetailRequest's page.
 */
const DetailRequest = () => (
  <>
    <AuthProviderContainer>
      <BrandLayout featured={<HeadingContainer />} header={<HeaderContainer />}>
        <RequestDetailInfoContainer />
        <RequestExtendEndDateContainer />
        <RequestHistoryContainer />
        <RequestMeetingDialogContainer />
        <PublicUserAuthProviderContainer>
          <RequestUniquePassDialogContainer />
        </PublicUserAuthProviderContainer>
      </BrandLayout>
    </AuthProviderContainer>

    <ScrollRestoration />
  </>
)

export default DetailRequest

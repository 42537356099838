import { useCallback, useState } from 'react'
import { useCheckError, useOverwrite, useSyncError } from '@chilecompra/react-kit/components/Inputs/Inputs.utils'

/**
 * The TimePicker's custom hook.
 */
const useTimePicker = ({
  changeCallback = () => undefined,
  errorCallbacks = [],
  errorDependencies = [],
  initialValue = null,
  overwriteCallback = () => undefined
} = {}) => {
  const [error, setError] = useState('')
  const [value, setValue] = useState(initialValue)
  const checkError = useCheckError(errorCallbacks, updatedError => setError(updatedError))

  const handleChange = useCallback(
    updatedValue => {
      checkError(updatedValue)
      setValue(updatedValue)

      if (updatedValue === value) return

      changeCallback(updatedValue)
    },
    [changeCallback, checkError, value]
  )

  const handleError = useCallback(() => checkError(value), [checkError, value])

  const handleReset = useCallback(() => {
    setError('')
    setValue(initialValue)
  }, [initialValue])

  useOverwrite(value, overwriteCallback, updatedValue => {
    checkError(updatedValue)
    setValue(updatedValue)
  })

  useSyncError(errorDependencies, () => checkError(value))

  return { error, onChange: handleChange, onError: handleError, onReset: handleReset, setValue, value }
}

export default useTimePicker

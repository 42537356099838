import React, { memo, useEffect, useRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'
import TextArea, { useTextArea } from '../../components/Inputs/TextArea'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { WhatHappening } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's whatHappening.
 */
const WhatHappeningSection = props => {
  const { descriptionProblem, isDisabled, onUpdate, submitError } = props

  const descriptionProblemRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: descriptionProblemError,
    onChange: handleDescriptionProblemChange,
    value: descriptionProblemValue
  } = useTextArea({
    changeCallback: updatedValue => onUpdate({ key: 'descriptionProblem', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(500)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => descriptionProblem
  })

  useEffect(() => {
    if (conditionsFields(['IS_INVESTIGATED_UNSET_ERROR', 'WILL_BE_REQUIRED_CONCEPT_UNSET_ERROR'])) return

    if (conditionsFields(['WHAT_HAPPENING_UNSET_ERROR', 'WHAT_HAPPENING_MAX_LENGTH_ERROR'])) {
      return descriptionProblemRef.current?.focus()
    }
  }, [submitError])

  return (
    <WhatHappening>
      <TextArea
        disabled={isDisabled}
        error={descriptionProblemError}
        inputRef={descriptionProblemRef}
        label="Descripción del problema"
        maxCount={500}
        onChange={handleDescriptionProblemChange}
        required
        rows={4}
        value={descriptionProblemValue}
      />
    </WhatHappening>
  )
}

WhatHappeningSection.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

WhatHappeningSection.propTypes = {
  descriptionProblem: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(WhatHappeningSection)

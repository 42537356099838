import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkInputErrorRequired, usePathParam, useDatePicker } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { differenceInDays, differenceInHours } from 'date-fns'
import TimePicker, { useTimePicker } from '../../components/Inputs/TimePicker'
import { onRequestDetailInfoThunk } from '../RequestDetailInfo/RequestDetailInfo.actions'
import {
  onCloseExtendEndDateDialog,
  onExtendEndDateThunk,
  onUpdateExtendEndDateValue
} from '../RequestExtendEndDate/RequestExtendEndDate.actions'
import {
  DatePicker,
  DialogButtons,
  DialogContent,
  DialogForm,
  DialogMessage,
  ErrorMessage,
  Item
} from './RequestExtendEndDate.styles'

/**
 * The RequestExtendEndDate's container.
 */
const RequestExtendEndDate = () => {
  const dispatch = useDispatch()
  const code = usePathParam('code')

  const { dateTime, error, loading, showDialog } = useSelector(state => state.requestExtendEndDate)
  const { endDate, publicationDate } = useSelector(state => state.requestDetailInfo)

  const validMinOneDay =
    differenceInDays(dateTime, new Date(publicationDate)) === 0 ||
    differenceInHours(dateTime, new Date(publicationDate)) < 2

  const {
    error: dateError,
    onChange: handleDateChange,
    value: dateValue
  } = useDatePicker({
    changeCallback: updatedValue => dispatch(onUpdateExtendEndDateValue({ key: 'dateTime', value: updatedValue })),
    errorCallbacks: [checkInputErrorRequired()],
    overwriteCallback: () => dateTime
  })

  const {
    error: timeError,
    onChange: handleTimeChange,
    value: timeValue
  } = useTimePicker({
    changeCallback: updatedValue => dispatch(onUpdateExtendEndDateValue({ key: 'dateTime', value: updatedValue })),
    errorCallbacks: [checkInputErrorRequired()],
    overwriteCallback: () => dateTime
  })

  const handleCloseExtendEndDateDialog = () => dispatch(onCloseExtendEndDateDialog())

  const handleExtendDate = () => {
    if (loading) return

    dispatch(
      onExtendEndDateThunk({
        dateTime,
        reloadDetailCallback: () => dispatch(onRequestDetailInfoThunk({ code }))
      })
    )
  }

  const dateTimeErrorText = useCallback(() => {
    if (validMinOneDay) return ' '

    return ''
  }, [validMinOneDay])

  return (
    <Dialog
      disableBackdropClick
      disableCloseIcon
      margin="auto"
      maxWidth="500px"
      onClose={handleCloseExtendEndDateDialog}
      open={showDialog}
      padding="16px"
    >
      <DialogContent alignItems="center" justifyContent="center" padding="32px">
        <DialogMessage alignItems="center" justifyContent="center" margin="0 0 16px">
          <Typography fontWeight="bold" variant="h4">
            Ampliar fecha de cierre
          </Typography>
        </DialogMessage>

        <DialogForm margin="0 0 8px" spacing={2}>
          <Item xs={6}>
            <DatePicker
              className="calendar__icon"
              disabled={loading}
              error={dateError || dateTimeErrorText()}
              format="dd/MM/yyyy"
              label="Fecha"
              minDate={endDate}
              onChange={handleDateChange}
              required
              value={dateValue}
            />
          </Item>

          <Item xs={6}>
            <TimePicker
              disabled={loading}
              error={timeError || dateTimeErrorText()}
              label="Hora"
              onChange={handleTimeChange}
              required
              value={timeValue}
            />
          </Item>

          {!!dateTimeErrorText() && (
            <ErrorMessage alignItems="center" justifyContent="center" margin="0 0 16px">
              La fecha de cierre debe ser minimo a 24 horas.
            </ErrorMessage>
          )}

          {!loading && error > 0 && (
            <ErrorMessage alignItems="center" justifyContent="center" margin="0 0 16px">
              {error < 4 && 'Lo sentimos, no se pudo extender el plazo del convenio.'}
              {error >= 4 && (
                <>
                  Lo sentimos, al parecer nuestro sistema presenta problemas.
                  <br />
                  No ha sido posible extender el plazo del convenio, inténtalo en unos minutos más.
                </>
              )}
            </ErrorMessage>
          )}
        </DialogForm>

        <DialogButtons alignItems="center" direction="column" justifyContent="center">
          <Button
            color={error === 0 ? 'primary' : 'error'}
            disabled={validMinOneDay}
            loading={loading}
            margin="0 0 16px"
            onClick={handleExtendDate}
            variant={error === 0 ? 'contained' : 'outlined'}
          >
            {error === 0 && 'Confirmar ampliación de fecha'}
            {error > 0 && 'Reintentar'}
          </Button>

          <Button color="default" disabled={loading} onClick={handleCloseExtendEndDateDialog} variant="text">
            Cancelar
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}

export default RequestExtendEndDate

import React, { memo, useEffect, useRef } from 'react'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  useInput,
  useRadio,
  useViewport
} from '@chilecompra/react-kit'
import { Input, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import {
  ADDITIONAL_INFO_DICTIONARY,
  ADDITIONAL_INFO_OPTIONS,
  WILL_BE_PUBLIC_OPTIONS
} from '../../configs/settings/constants'
import { checkErrorUrl } from '../../modules/utils/checkers'
import { Container, ExtraFormGrid, Item, Radio, WrapperCard } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's extraForm.
 */
const SectionExtraForm = props => {
  const { additionalInfo, additionalInfoURL, isDisabled, onUpdate, submitError, willBePublic } = props

  const { size } = useViewport()
  const additionalInfoURLRef = useRef(null)
  const extraFormRef = useRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const { onChange: handleAdditionalInfoChange, value: additionalInfoValue } = useRadio({
    changeCallback: updatedValue => {
      handleadditionalInfoURLReset()
      onUpdate({ key: 'additionalInfo', value: Number(updatedValue) })
    },
    overwriteCallback: () => additionalInfo
  })

  const { onChange: handleWillBePublicChange, value: willBePublicValue } = useRadio({
    changeCallback: updatedValue => onUpdate({ key: 'willBePublic', value: Number(updatedValue) }),
    overwriteCallback: () => willBePublic
  })

  const {
    error: additionalInfoURLError,
    onChange: handleadditionalInfoURLChange,
    onReset: handleadditionalInfoURLReset,
    value: additionalInfoURLValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'additionalInfoURL', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkErrorUrl(),
      checkInputErrorMaxLength(300)
    ],
    errorDependencies: [submitError],
    overwriteCallback: () => additionalInfoURL
  })

  useEffect(() => {
    if (
      conditionsFields([
        'ADDITIONAL_INFO_URL_DONT_MATCH_ERROR',
        'ADDITIONAL_INFO_URL_MAX_LENGTH_ERROR',
        'ADDITIONAL_INFO_URL_UNSET_ERROR'
      ])
    ) {
      return additionalInfoURLRef.current?.focus()
    }

    if (conditionsFields(['ADDITIONAL_INFO_UNSET_ERROR', 'WILL_BE_PUBLIC_UNSET_ERROR'])) {
      const element = extraFormRef.current
      const headerOffset = size.isDownToSmall ? 108 : 32
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      return window.scrollTo({ top: offsetPosition })
    }
  }, [submitError])

  return (
    <ExtraFormGrid padding="0 0 32px" ref={extraFormRef}>
      <WrapperCard
        color={!additionalInfoValue && conditionsFields(['ADDITIONAL_INFO_UNSET_ERROR']) ? 'error' : 'blank'}
        enableBorder
        padding="24px"
      >
        <Container alignItems="center" justifyContent="center" spacing={2}>
          <Item lg={9} md={12} sm={8} xs={12}>
            <Typography fontWeight="bold" margin="0 0 8px" variant="body2">
              ¿Se ofrecerán charlas o reuniones informativas adicionales?
            </Typography>

            <Typography variant="subtitle">
              Ingresa información de las charlas que podrás realizar, puedes incorporar links a videollamadas, URLs,
              videos, etc.
            </Typography>
          </Item>

          <Item lg={3} md={12} sm={4} xs={12}>
            <Radio
              disabled={isDisabled}
              onChange={handleAdditionalInfoChange}
              options={ADDITIONAL_INFO_OPTIONS}
              value={additionalInfoValue}
            />
          </Item>

          {additionalInfoValue === ADDITIONAL_INFO_DICTIONARY.YES && (
            <Item xs={12}>
              <Input
                disabled={isDisabled}
                error={additionalInfoURLError}
                info="Ingresa el detalle de la reunión informativa."
                inputRef={additionalInfoURLRef}
                label="Link o URL"
                maxCount={300}
                onChange={handleadditionalInfoURLChange}
                required
                value={additionalInfoURLValue}
              />
            </Item>
          )}
        </Container>

        {!additionalInfoValue && conditionsFields(['ADDITIONAL_INFO_UNSET_ERROR']) && (
          <Typography color="error" margin="8px 0 0" variant="subtitle">
            Este campo es obligatorio. Debes completar esta información para poder continuar.
          </Typography>
        )}
      </WrapperCard>

      <WrapperCard
        color={!willBePublicValue && conditionsFields(['WILL_BE_PUBLIC_UNSET_ERROR']) ? 'error' : 'blank'}
        enableBorder
        padding="24px"
      >
        <Container alignItems="center" justifyContent="center" spacing={2}>
          <Item md={12} sm={8} xl={7} xs={12}>
            <Typography fontWeight="bold" margin="0 0 8px" variant="body2">
              ¿Las respuesta ingresadas serán públicas?
            </Typography>

            <Typography variant="subtitle">
              Si seleccionas esta opción, las respuestas ingresadas por los proveedores podrás visualizarlas solamente
              tú.
            </Typography>
          </Item>

          <Item md={12} sm={4} xl={5} xs={12}>
            <Radio
              disabled={isDisabled}
              onChange={handleWillBePublicChange}
              options={WILL_BE_PUBLIC_OPTIONS}
              value={willBePublicValue}
            />
          </Item>
        </Container>

        {!willBePublicValue && conditionsFields(['WILL_BE_PUBLIC_UNSET_ERROR']) && (
          <Typography color="error" margin="8px 0 0" variant="subtitle">
            Este campo es obligatorio. Debes completar esta información para poder continuar.
          </Typography>
        )}
      </WrapperCard>
    </ExtraFormGrid>
  )
}

SectionExtraForm.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

SectionExtraForm.propTypes = {
  additionalInfo: PropTypes.number.isRequired,
  additionalInfoURL: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  willBePublic: PropTypes.number.isRequired
}

export default memo(SectionExtraForm)

import { batch } from 'react-redux'
import { customizeError, downloadFile, formatDNI, generateBlob } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../configs/store/utils'
import {
  dowloadFile,
  downloadQuestionsAndAnswers,
  postAttachedFiles,
  postAttachedFilesToResponse
} from '../../services/files'
import { deleteMeeting, postMeetings } from '../../services/meetings'
import { postResponses } from '../../services/questions'
import { getRequestDetail } from '../../services/request'
import { onShowSnackbar } from '../SnackbarProvider/SnackbarProvider.actions'

export const ADD_QUESTION_FILE_TEMP_SUCCESS = 'ADD_QUESTION_FILE_TEMP_SUCCESS'
export const onAddQuestionFileTempSuccess = makeActionCreator(ADD_QUESTION_FILE_TEMP_SUCCESS, 'payload')
export const onAddQuestionFileTempThunk = payload => async dispatch => {
  const { file, questionId } = payload || {}

  return dispatch(onAddQuestionFileTempSuccess({ file, fileName: file.name, id: questionId }))
}

export const DELETE_MEETING = 'DELETE_MEETING'
export const DELETE_MEETING_ERROR = 'DELETE_MEETING_ERROR'
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS'
export const onDeleteMeeting = makeActionCreator(DELETE_MEETING, 'payload')
export const onDeleteMeetingError = makeActionCreator(DELETE_MEETING_ERROR, 'payload')
export const onDeleteMeetingSuccess = makeActionCreator(DELETE_MEETING_SUCCESS, 'payload')
export const onDeleteMeetingThunk = payload => async (dispatch, getState) => {
  const { meeting, reloadDetailCallback = () => undefined } = payload || {}

  dispatch(onDeleteMeeting({ id: meeting.id }))

  try {
    const { id: requestId } = getState().requestDetailInfo

    await deleteMeeting({ meetingId: meeting.id, requestId })

    return batch(() => {
      dispatch(onDeleteMeetingSuccess({ id: meeting.id }))
      dispatch(onShowSnackbar({ message: 'Reunión eliminada exitosamente', severity: 'success' }))
      reloadDetailCallback()
    })
  } catch (error) {
    return batch(() => {
      dispatch(
        onDeleteMeetingError({
          error: customizeError({
            code: error.code,
            originalError: error.originalError,
            reason: 'SOMETHING_WENT_WRONG_ERROR'
          }).toObject(),
          id: meeting.id
        })
      )
    })
  }
}

export const DOWNLOAD_QUESTIONS_AND_ANSWERS = 'DOWNLOAD_QUESTIONS_AND_ANSWERS'
export const DOWNLOAD_QUESTIONS_AND_ANSWERS_ERROR = 'DOWNLOAD_QUESTIONS_AND_ANSWERS_ERROR'
export const DOWNLOAD_QUESTIONS_AND_ANSWERS_SUCCESS = 'DOWNLOAD_QUESTIONS_AND_ANSWERS_SUCCESS'
export const onDownloadQuestionsAndAnswers = makeActionCreator(DOWNLOAD_QUESTIONS_AND_ANSWERS)
export const onDownloadQuestionsAndAnswersError = makeActionCreator(DOWNLOAD_QUESTIONS_AND_ANSWERS_ERROR, 'payload')
export const onDownloadQuestionsAndAnswersSuccess = makeActionCreator(DOWNLOAD_QUESTIONS_AND_ANSWERS_SUCCESS, 'payload')
export const onDownloadQuestionsAndAnswersThunk = () => async (dispatch, getState) => {
  dispatch(onDownloadQuestionsAndAnswers())

  try {
    const { id: requestId } = getState().requestDetailInfo
    const { data, headers } = await downloadQuestionsAndAnswers({ requestId })
    const blob = generateBlob(data, headers['content-type'])

    downloadFile(blob, 'consulta-al-mercado-preguntas-y-respuestas')

    return dispatch(onDownloadQuestionsAndAnswersSuccess())
  } catch (error) {
    return dispatch(
      onDownloadQuestionsAndAnswersError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const onDownloadFile = makeActionCreator(DOWNLOAD_FILE, 'payload')
export const onDownloadFileError = makeActionCreator(DOWNLOAD_FILE_ERROR, 'payload')
export const onDownloadFileSuccess = makeActionCreator(DOWNLOAD_FILE_SUCCESS, 'payload')
export const onDownloadFileThunk = payload => async dispatch => {
  const { id, code, name } = payload || {}

  dispatch(onDownloadFile({ id }))

  try {
    const { data, headers } = await dowloadFile({ fileId: code })
    const blob = generateBlob(data, headers['content-type'])

    downloadFile(blob, name)

    return dispatch(onDownloadFileSuccess({ id }))
  } catch (error) {
    return dispatch(
      onDownloadFileError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject(),
        id
      })
    )
  }
}

export const GET_REQUEST_DETAIL_INFO = 'GET_REQUEST_DETAIL_INFO'
export const GET_REQUEST_DETAIL_INFO_ERROR = 'GET_REQUEST_DETAIL_INFO_ERROR'
export const GET_REQUEST_DETAIL_INFO_SUCCESS = 'GET_REQUEST_DETAIL_INFO_SUCCESS'
export const onRequestDetailInfo = makeActionCreator(GET_REQUEST_DETAIL_INFO, 'payload')
export const onRequestDetailInfoError = makeActionCreator(GET_REQUEST_DETAIL_INFO_ERROR, 'payload')
export const onRequestDetailInfoSuccess = makeActionCreator(GET_REQUEST_DETAIL_INFO_SUCCESS, 'payload')
export const onRequestDetailInfoThunk = payload => async dispatch => {
  const { code } = payload || {}

  dispatch(onRequestDetailInfo())

  try {
    const { data } = await getRequestDetail({ code })

    const attachedFiles = data.payload?.adjuntos?.map(file => ({
      code: file.codigoAdjunto,
      comment: file.comentario,
      error: undefined,
      id: file.id,
      loading: false,
      name: file.nombre,
      registerDate: new Date(file.fechaRegistro)
    }))

    const histories = data.payload?.historial?.map(history => ({
      comment: history.comentario,
      id: history.id,
      orgCode: { id: history.codigoOrganismo, name: history.nombreOrganismo },
      registerDate: new Date(history.fechaRegistro),
      usrCode: { id: history.codigoUsuario, name: history.nombreUsuario }
    }))

    const meetings = data.payload?.reuniones?.map(meeting => ({
      assistants: meeting.asistentes?.map(assistant => ({
        dni: assistant.rut,
        foreignDni: assistant.extranjeroId,
        id: assistant.id,
        isForeign: assistant.extranjero,
        lastNames: assistant.apellido,
        names: assistant.nombre,
        position: assistant.cargo,
        registerDate: new Date(assistant.fechaRegistro)
      })),
      businessDni: meeting.rutEmpresa,
      businessName: meeting.razonSocial,
      date: new Date(meeting.fecha),
      error: undefined,
      id: meeting.id,
      loading: false,
      meetingOtherType: meeting.motivoTipoReunion,
      meetingType: meeting.tipoReunion,
      reasonMeeting: meeting.motivo,
      registerDate: new Date(meeting.fechaRegistro)
    }))

    const products = data.payload?.rubros?.map(product => ({
      code: product.codigoRubro,
      id: product.id,
      name: product.nombre,
      registerDate: new Date(product.fechaRegistro)
    }))

    const additionalInfoUrls = data.payload?.urlsConsulta?.map(url => ({
      id: url.id,
      registerDate: new Date(url.fechaRegistro),
      type: url.tipo,
      url: url.url
    }))

    const questions = data.payload?.preguntas?.map(question => ({
      attachedFiles: question.adjuntos?.map(file => ({
        code: file.codigoAdjunto,
        comment: file.comentario,
        error: undefined,
        id: file.id,
        loading: false,
        name: file.nombre,
        registerDate: new Date(file.fechaRegistro)
      })),
      entCode: {
        dni: question.rutOrganismo,
        id: question.codigoEmpresa,
        name: question.nombreEmpresa,
        userType: question.tipoOrganismo
      },
      error: undefined,
      id: question.id,
      loading: false,
      message: question.detalle,
      multipleChoiceOptions: question.opcionesMultiplesDTOS?.map(option => ({
        id: option.id,
        message: option.detalle,
        registerDate: new Date(option.fechaRegistro)
      })),
      orgCode: { id: question.codigoSucursal, name: question.nombreSucursal },
      questionType: question.tipoPregunta,
      registerDate: new Date(question.fechaRegistro),
      responses: question.respuestas?.map(response => ({
        attachedFiles: response.adjuntos?.map(file => ({
          code: file.codigoAdjunto,
          comment: file.comentario,
          error: undefined,
          id: file.id,
          loading: false,
          name: file.nombre,
          registerDate: new Date(file.fechaRegistro)
        })),
        entCode: {
          dni: response.rutOrganismo,
          id: response.codigoEmpresa,
          name: response.nombreEmpresa,
          userType: response.tipoOrganismo
        },
        id: response.id,
        loading: false,
        message: response.detalle,
        orgCode: { id: response.codigoOrganismo, name: response.nombreOrganismo },
        publicUserUniquePass: {
          dni: formatDNI(response.usuarioClaveUnicaDTO?.rut, 2),
          id: response.usuarioClaveUnicaDTO?.id,
          name: response.usuarioClaveUnicaDTO?.nombre
        },
        registerDate: new Date(response.fechaRegistro),
        usrCode: { id: response.codigoUsuario, name: response.nombreUsuario }
      })),
      tempResponseAttachedFiles: [],
      usrCode: { id: question.codigoUsuario, name: question.nombreUsuario }
    }))

    const currentDetail = {
      additionalInfo: data.payload?.charlasInformativas || false,
      additionalInfoUrls,
      antecedents: data.payload?.antecedentesProblema || '',
      attachedFiles,
      code: data.payload?.codigoConsulta || '',
      description: data.payload?.descripcion || '',
      descriptionProblem: data.payload?.descripcionProblema || '',
      endDate: new Date(data.payload?.fechaCierre),
      entCode: {
        dni: data.payload?.rutOrganismo,
        id: data.payload?.codigoInstitucion,
        name: data.payload?.nombreInstitucion,
        userType: data.payload?.tipoOrganismo
      },
      histories,
      historyProblem: data.payload?.historiaProblema || '',
      id: data.payload?.id || '',
      isActive: data.payload?.activo || false,
      isInnovation: data.payload?.esInnovacion || false,
      isInvestigated: data.payload?.investigacionPrevia || false,
      locationProblem: data.payload?.lugarProblema || '',
      meetings,
      name: data.payload?.nombre || '',
      orgCode: { id: data.payload?.codigoUnidadCompra, name: data.payload?.nombreUnidadCompra },
      peopleAffected: data.payload?.afectadosProblema || '',
      products,
      publicationDate: new Date(data.payload?.fechaPublicacion),
      questions,
      reason: data.payload?.motivo || '',
      registerDate: new Date(data.payload?.fechaRegistro),
      status: data.payload?.estado || '',
      usrCode: { id: data.payload?.codigoUsuario, name: data.payload?.nombreUsuario },
      willBePublic: data.payload?.respuestasPublicas || false,
      willBeRequiredConcept: data.payload?.pruebasConcepto || false
    }

    return dispatch(onRequestDetailInfoSuccess({ ...currentDetail }))
  } catch (error) {
    return dispatch(
      onRequestDetailInfoError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const REMOVE_QUESTION_FILE_TEMP_SUCCESS = 'REMOVE_QUESTION_FILE_TEMP_SUCCESS'
export const onRemoveQuestionFileTempSuccess = makeActionCreator(REMOVE_QUESTION_FILE_TEMP_SUCCESS, 'payload')
export const onRemoveQuestionFileTempThunk = payload => async dispatch => {
  const { fileId, questionId } = payload || {}

  return dispatch(onRemoveQuestionFileTempSuccess({ fileId, id: questionId }))
}

export const RESET_DETAIL = 'RESET_DETAIL'
export const onResetDetail = makeActionCreator(RESET_DETAIL, 'payload')

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const onUploadFile = makeActionCreator(UPLOAD_FILE, 'payload')
export const onUploadFileError = makeActionCreator(UPLOAD_FILE_ERROR, 'payload')
export const onUploadFileSuccess = makeActionCreator(UPLOAD_FILE_SUCCESS, 'payload')
export const onUploadFileThunk = payload => async (dispatch, getState) => {
  const { file } = payload || {}

  dispatch(onUploadFile())

  try {
    const { code, id: requestId } = getState().requestDetailInfo

    await postAttachedFiles({ attachedFiles: [{ file }], requestId })

    return batch(() => {
      dispatch(onUploadFileSuccess())
      dispatch(onShowSnackbar({ message: 'Archivo agregado exitosamente', severity: 'success' }))
      dispatch(onRequestDetailInfoThunk({ code }))
    })
  } catch (error) {
    return batch(() => {
      dispatch(
        onUploadFileError({
          error: customizeError({
            code: error.code,
            originalError: error.originalError,
            reason: 'SOMETHING_WENT_WRONG_ERROR'
          }).toObject()
        })
      )
    })
  }
}

export const UPLOAD_MEETING = 'UPLOAD_MEETING'
export const UPLOAD_MEETING_ERROR = 'UPLOAD_MEETING_ERROR'
export const UPLOAD_MEETING_SUCCESS = 'UPLOAD_MEETING_SUCCESS'
export const onUploadMeeting = makeActionCreator(UPLOAD_MEETING, 'payload')
export const onUploadMeetingError = makeActionCreator(UPLOAD_MEETING_ERROR, 'payload')
export const onUploadMeetingSuccess = makeActionCreator(UPLOAD_MEETING_SUCCESS, 'payload')
export const onUploadMeetingThunk = payload => async (dispatch, getState) => {
  const { closeMeetingCallback = () => undefined, resetMeetingFormCallback = () => undefined } = payload || {}

  dispatch(onUploadMeeting())

  try {
    const { code, id: requestId } = getState().requestDetailInfo
    const { assistants, businessDni, businessName, dateTime, meetingOtherType, meetingType, reasonMeeting } =
      getState().requestMeetingDialog

    await postMeetings({
      meetings: [{ assistants, businessDni, businessName, dateTime, meetingOtherType, meetingType, reasonMeeting }],
      requestId
    })

    return batch(() => {
      closeMeetingCallback()
      resetMeetingFormCallback()
      dispatch(onUploadMeetingSuccess())
      dispatch(onShowSnackbar({ message: 'Reunión agregada exitosamente', severity: 'success' }))
      dispatch(onRequestDetailInfoThunk({ code }))
    })
  } catch (error) {
    return batch(() => {
      dispatch(
        onUploadMeetingError({
          error: customizeError({
            code: error.code,
            originalError: error.originalError,
            reason: 'SOMETHING_WENT_WRONG_ERROR'
          }).toObject()
        })
      )
    })
  }
}

export const SEND_RESPONSE = 'SEND_RESPONSE'
export const SEND_RESPONSE_ERROR = 'SEND_RESPONSE_ERROR'
export const SEND_RESPONSE_SUCCESS = 'SEND_RESPONSE_SUCCESS'
export const onSendResponse = makeActionCreator(SEND_RESPONSE, 'payload')
export const onSendResponseError = makeActionCreator(SEND_RESPONSE_ERROR, 'payload')
export const onSendResponseSuccess = makeActionCreator(SEND_RESPONSE_SUCCESS, 'payload')
export const onSendResponseThunk = payload => async (dispatch, getState) => {
  const { message, questionId, tempResponseAttachedFiles } = payload || {}

  dispatch(onSendResponse({ id: questionId }))

  try {
    const { code, id: requestId } = getState().requestDetailInfo
    const { token } = getState().publicUserAuthProvider
    const { data } = await postResponses({ message, questionId, requestId, token })

    if (tempResponseAttachedFiles.length > 0) {
      await postAttachedFilesToResponse({
        attachedFiles: tempResponseAttachedFiles,
        questionId,
        requestId,
        responseId: data.payload?.id
      })
    }

    return batch(() => {
      dispatch(onSendResponseSuccess({ id: questionId }))
      dispatch(onRequestDetailInfoThunk({ code }))
    })
  } catch (error) {
    return dispatch(
      onSendResponseError({
        error: customizeError({
          code: error.code,
          originalError: error.originalError,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject(),
        id: questionId
      })
    )
  }
}

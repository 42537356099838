import {
  CLOSE_FEEDBACK_CONFIRM_DIALOG,
  PUBLICATED_REQUEST,
  PUBLICATED_REQUEST_ERROR,
  PUBLICATED_REQUEST_SUCCESS,
  SHOW_FEEDBACK_CONFIRM_DIALOG
} from './RequestFeedbackConfirmDialog.actions'

const requestFeedbackConfirmDialogState = { error: undefined, loading: false, showDialog: false }

/**
 * The RequestFeedbackConfirmDialog's reducer.
 */
const requestFeedbackConfirmDialogReducer = (state = requestFeedbackConfirmDialogState, { payload, type }) => {
  switch (type) {
    case CLOSE_FEEDBACK_CONFIRM_DIALOG: {
      return { ...state, showDialog: false }
    }
    case PUBLICATED_REQUEST: {
      return { ...state, error: undefined, loading: true }
    }
    case PUBLICATED_REQUEST_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case PUBLICATED_REQUEST_SUCCESS: {
      return { ...state, error: undefined, loading: false }
    }
    case SHOW_FEEDBACK_CONFIRM_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestFeedbackConfirmDialogReducer

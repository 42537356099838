import {
  CLOSE_QUESTIONS_LIBRARY_DIOALOG,
  GET_QUESTIONS_LIBRARY,
  GET_QUESTIONS_LIBRARY_ERROR,
  GET_QUESTIONS_LIBRARY_SUCCESS,
  SHOW_QUESTIONS_LIBRARY_DIALOG
} from './RequestQuestionsLibraryDialog.actions'

const requestQuestionsLibraryDialogState = {
  data: [],
  error: undefined,
  filters: { initialRowsPerPage: 10, keyword: '', page: 1 },
  loading: false,
  multipleChoiceOptions: [],
  pageCount: 0,
  searching: false,
  searchingGeneral: false,
  showDialog: false,
  summaryCount: 0
}

/**
 * The RequestQuestionsLibraryDialog's reducer.
 */
const requestQuestionsLibraryDialogReducer = (state = requestQuestionsLibraryDialogState, { payload, type }) => {
  switch (type) {
    case CLOSE_QUESTIONS_LIBRARY_DIOALOG: {
      return { ...state, showDialog: false }
    }
    case GET_QUESTIONS_LIBRARY: {
      return {
        ...state,
        error: undefined,
        filters: { ...payload.filters },
        loading: payload.loading ?? state.loading,
        searching: payload.searching ?? state.searching,
        searchingGeneral: payload.searchingGeneral ?? state.searchingGeneral
      }
    }
    case GET_QUESTIONS_LIBRARY_ERROR: {
      return { ...state, error: payload.error, loading: false, searching: false, searchingGeneral: false }
    }
    case GET_QUESTIONS_LIBRARY_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        error: undefined,
        loading: false,
        pageCount: payload.pageCount,
        searching: false,
        searchingGeneral: false,
        summaryCount: payload.summaryCount
      }
    }
    case SHOW_QUESTIONS_LIBRARY_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestQuestionsLibraryDialogReducer

import React, { useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useQuery, useEffectOnce, usePathParam, useViewport } from '@chilecompra/react-kit'
import { Button, Card, Typography } from '@chilecompra/react-kit/components'
import ProtectByProfile from '../../components/Security/ProtectByProfile'
import {
  DETAIL_SECTION_OPTIONS,
  MEETING_ACTION,
  STATUS_OPTIONS_DICTIONARY,
  DETAIL_INNOVATION_SECTION_OPTIONS,
  USER_TYPES
} from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { onShowExtendEndDateDialog } from '../RequestExtendEndDate/RequestExtendEndDate.actions'
import { onShowHistoryDialog } from '../RequestHistory/RequestHistory.actions'
import { onResetMeetingForm, onShowMeetingDialog } from '../RequestMeetingDialog/RequestMeetingDialog.actions'
import { onShowUniquePassDialog } from '../RequestUniquePassDialog/RequestUniquePassDialog.actions'
import {
  onAddQuestionFileTempThunk,
  onDeleteMeetingThunk,
  onDownloadFileThunk,
  onDownloadQuestionsAndAnswersThunk,
  onRemoveQuestionFileTempThunk,
  onRequestDetailInfoThunk,
  onResetDetail,
  onSendResponseThunk,
  onUploadFileThunk
} from './RequestDetailInfo.actions'
import HeadingDetailInfo from './RequestDetailInfo.heading'
import QuestionsAndAnswers from './RequestDetailInfo.questionsAndAnswers'
import SectionsDetail from './RequestDetailInfo.sections'
import { Container, Item, WrapperGrid } from './RequestDetailInfo.styles'
import SectionUploadAttachedFiles from './RequestDetailInfo.uploadAttachedFiles'

/**
 * The RequestDetailInfo's container.
 */
const RequestDetailInfo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { size } = useViewport()
  const { canChangeRFI, isPrivate, userType } = useAuthProvider()
  const code = usePathParam('code')

  const {
    authInfo: { orgCode: authProviderOrgCode }
  } = useSelector(state => state.authProvider)
  const { dni: publicUserDni } = useSelector(state => state.publicUserAuthProvider)
  const {
    additionalInfo,
    additionalInfoUrls,
    antecedents,
    attachedFiles,
    description,
    descriptionProblem,
    endDate,
    entCode,
    error,
    errorAttachedFiles,
    errorDownloading,
    histories,
    historyProblem,
    isDownloading,
    isInnovation,
    isInvestigated,
    loading,
    loadingAttachedFiles,
    loadingMeetings,
    locationProblem,
    meetings,
    name,
    orgCode,
    peopleAffected,
    products,
    publicationDate,
    questions,
    reason,
    status,
    usrCode,
    willBePublic,
    willBeRequiredConcept
  } = useSelector(state => state.requestDetailInfo)
  const { filters } = useSelector(state => state.requestList)

  const handleDownloadFile = useCallback(file => dispatch(onDownloadFileThunk({ ...file })), [])
  const handleResponse = useCallback(response => dispatch(onSendResponseThunk({ ...response })), [])
  const handleShowCUDialog = useCallback(() => dispatch(onShowUniquePassDialog()), [])
  const handleShowHistoryDialog = useCallback(() => dispatch(onShowHistoryDialog()), [])
  const handleUploadFile = useCallback(file => dispatch(onUploadFileThunk({ file })), [])

  const handleAddFile = useCallback(({ file, questionId }) => {
    dispatch(onAddQuestionFileTempThunk({ file, questionId }))
  }, [])

  const handleDeleteMeeting = useCallback(meeting => {
    dispatch(
      onDeleteMeetingThunk({ meeting, reloadDetailCallback: () => dispatch(onRequestDetailInfoThunk({ code })) })
    )
  }, [])

  const handleDownloadQuestionsAndAnswers = useCallback(() => {
    dispatch(onDownloadQuestionsAndAnswersThunk())
  }, [])

  const handleGoBackWithParams = () => {
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  const handleRemoveFile = useCallback(({ fileId, questionId }) => {
    dispatch(onRemoveQuestionFileTempThunk({ fileId, questionId }))
  }, [])

  const handleShowExtendEndDateDialog = useCallback(() => {
    dispatch(onShowExtendEndDateDialog({ dateTime: endDate }))
  }, [endDate])

  const handleShowMeetingDialog = useCallback(() => {
    batch(() => {
      dispatch(onResetMeetingForm())
      dispatch(onShowMeetingDialog({ action: MEETING_ACTION.DETAIL_INFO }))
    })
  }, [])

  const detailSectionOptions = useMemo(() => {
    if (isInnovation) return DETAIL_INNOVATION_SECTION_OPTIONS

    return DETAIL_SECTION_OPTIONS
  }, [DETAIL_SECTION_OPTIONS, DETAIL_INNOVATION_SECTION_OPTIONS, isInnovation])

  /* Initial request detail */
  useEffectOnce(() => dispatch(onRequestDetailInfoThunk({ code })))

  useEffect(() => {
    if (!loading && userType === USER_TYPES.BUYER && authProviderOrgCode !== orgCode?.id) {
      batch(() => {
        dispatch(onResetDetail())
        handleGoBackWithParams()
      })
    }
  }, [loading])

  if (!loading && userType === USER_TYPES.BUYER && authProviderOrgCode !== orgCode?.id) return

  return (
    <Container padding="16px 0" spacing={4}>
      <HeadingDetailInfo code={code} isError={!!error} isLoading={loading} status={status} />

      <Item md={9} order={size.isDownToMedium ? 3 : 2} xs={12}>
        {!error && (
          <Container>
            {detailSectionOptions.map(section => (
              <WrapperGrid key={section.name} padding="18px 0">
                <Typography fontWeight="bold" variant="body2">
                  {section.title}
                </Typography>

                <SectionsDetail
                  additionalInfo={additionalInfo}
                  additionalInfoUrls={additionalInfoUrls}
                  antecedents={antecedents}
                  attachedFiles={attachedFiles}
                  description={description}
                  descriptionProblem={descriptionProblem}
                  endDate={endDate}
                  histories={histories}
                  historyProblem={historyProblem}
                  isDisabled={loading}
                  isInnovation={isInnovation}
                  isInvestigated={isInvestigated}
                  isLoading={loading}
                  loadingAttachedFiles={loadingAttachedFiles}
                  loadingMeetings={loadingMeetings}
                  locationProblem={locationProblem}
                  meetings={meetings}
                  name={name}
                  onDeleteMeeting={handleDeleteMeeting}
                  onDownloadFile={handleDownloadFile}
                  onShowExtendEndDateDialog={handleShowExtendEndDateDialog}
                  onShowHistoryDialog={handleShowHistoryDialog}
                  onShowMeetingDialog={handleShowMeetingDialog}
                  peopleAffected={peopleAffected}
                  products={products}
                  publicationDate={publicationDate}
                  reason={reason}
                  sectionName={section.name}
                  status={status}
                  willBeRequiredConcept={willBeRequiredConcept}
                />
              </WrapperGrid>
            ))}

            <ProtectByProfile
              rule={userType =>
                status !== STATUS_OPTIONS_DICTIONARY.CLOSED && userType === USER_TYPES.BUYER && canChangeRFI
              }
            >
              <WrapperGrid padding="18px 0">
                <Item>
                  <Typography fontWeight="bold" margin="0 0 8px" variant="body2">
                    Adjuntar archivos
                  </Typography>

                  <Typography variant="subtitle">
                    Puedes subir archivos que especifiquen tu solicitud (opcional).
                  </Typography>
                </Item>

                <SectionUploadAttachedFiles
                  isError={!!errorAttachedFiles}
                  isLoading={loading || loadingAttachedFiles}
                  onUploadFile={handleUploadFile}
                />
              </WrapperGrid>
            </ProtectByProfile>
          </Container>
        )}
      </Item>

      {!error && !loading && (
        <Item lg={3} md={9} order={size.isDownToMedium ? 2 : 3} xs={12}>
          <Card padding="24px">
            <Container direction="column" justifyContent="center">
              <Typography fontWeight="bold" margin="0 0 16px" variant="h4">
                Datos para esta consulta
              </Typography>

              <Typography margin="0 0 8px" variant="body2">
                {entCode.name}
              </Typography>

              <Typography margin="0 0 8px" variant="body2">
                {entCode.dni}
              </Typography>

              <Typography variant="subtitle">{orgCode.name}</Typography>
            </Container>
          </Card>
        </Item>
      )}

      <QuestionsAndAnswers
        detailUsrCode={usrCode}
        isClose={status === STATUS_OPTIONS_DICTIONARY.CLOSED}
        isDownloading={isDownloading}
        isError={!!error}
        isErrorDownloading={errorDownloading}
        isLoading={loading}
        isWillBePublic={willBePublic}
        onAddFile={handleAddFile}
        onDownloadFile={handleDownloadFile}
        onDownloadQuestionsAndAnswers={handleDownloadQuestionsAndAnswers}
        onRemoveFile={handleRemoveFile}
        onResponse={handleResponse}
        onShowUniquePassDialog={handleShowCUDialog}
        publicUserDni={publicUserDni}
        questions={questions}
      />

      <Item order={5} xs={12}>
        <Container>
          <Button disabled={loading || loadingAttachedFiles} onClick={handleGoBackWithParams} variant="outlined">
            Volver
          </Button>
        </Container>
      </Item>
    </Container>
  )
}

export default RequestDetailInfo

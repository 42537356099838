import React from 'react'
import { useViewport } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'
import { Container, Image404, Item } from './NotFound.styles'

/**
 * The NotFound's container.
 */
const NotFound = () => {
  const { size } = useViewport()

  return (
    <Container padding="16px 0">
      <Item xs={12}>
        <Typography margin={size.isDownToTiny ? '0 0 48px' : undefined} variant={size.isDownToTiny ? 'h3' : 'h2'}>
          La página solicitada no existe
        </Typography>

        <Image404 alt="La página solicitada no existe" src="/assets/images/404.svg" />
      </Item>
    </Container>
  )
}

export default NotFound

import { TableContainer as BaseTableContainer } from '@material-ui/core'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogTable = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)`
  &.MuiGrid-container {
    text-align: center;
  }
`

export const Item = styled(BaseItem)``

export const TableContainer = styled(BaseTableContainer)`
  &.MuiTableContainer-root {
    margin-bottom: 20px;
  }
`

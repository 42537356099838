import { deleteJsonRequest, factoryService, formatDate, postJsonRequest } from '@chilecompra/react-kit'
import { REACT_APP_API_BASE_URL } from '../configs/settings/environment'

/**
 * Delete meeting from request meetings.
 */
export const deleteMeeting = factoryService(({ meetingId, requestId }) => {
  return deleteJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/reunion/${meetingId}`)
})

/**
 * Post send the request meetings.
 */
export const postMeetings = factoryService(({ meetings, requestId }) => {
  const body = meetings.map(meeting => ({
    asistentes: meeting.assistants.map(assistant => ({
      apellido: assistant.lastNames,
      cargo: assistant.position,
      extranjero: assistant.isForeign,
      extranjeroId: assistant.foreignDni,
      nombre: assistant.names,
      rut: assistant.dni
    })),
    fecha: formatDate(meeting.dateTime, 'yyyy-MM-dd HH:mm:ss'),
    motivo: meeting.reasonMeeting,
    motivoTipoReunion: meeting.meetingOtherType,
    razonSocial: meeting.businessName,
    rutEmpresa: meeting.businessDni,
    tipoReunion: meeting.meetingType
  }))

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/consulta-mercado/${requestId}/reuniones`, { body })
})

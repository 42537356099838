import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce, usePagination, useQuery, useQueryParam } from '@chilecompra/react-kit'
import { Pagination, Toast, Typography } from '@chilecompra/react-kit/components'
import { isValid, parse } from 'date-fns'
import {
  INITIAL_FILTERS_VALUES,
  INITIAL_ROWS_PER_PAGE,
  ORDER_OPTIONS,
  ORDER_OPTIONS_DICTIONARY,
  STATUS_OPTIONS,
  STATUS_OPTIONS_DICTIONARY
} from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { onDownloadRequestsThunk, onGetRequestsThunk } from './RequestList.actions'
import ListItem from './RequestList.item'
import {
  ArrowDownIcon,
  Card,
  Container,
  DownloadLink,
  NotFoundImg,
  SearchLoadingIcon,
  Skeleton
} from './RequestList.styles'

/**
 * The RequestList's container.
 */
const RequestList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isPrivate } = useAuthProvider()
  const query = useQuery()
  const paramDateFrom = useQueryParam('dateFrom')
  const paramDateTo = useQueryParam('dateTo')
  const paramOrder = useQueryParam('order')
  const paramPage = useQueryParam('page')
  const paramStatus = useQueryParam('status')

  const {
    data,
    error,
    errorDownloading,
    filters: { dateFrom, dateTo },
    isDownloading,
    loading,
    pageCount,
    searching,
    searchingByGeneral
  } = useSelector(state => state.requestList)

  const generalDisabled = loading || searching || searchingByGeneral
  const generalFilters = { ...INITIAL_FILTERS_VALUES, ...Object.fromEntries(query.entries()), dateFrom, dateTo }
  const generalLoading = !loading && !searching && !searchingByGeneral
  const initialPage = (paramPage ? Number(paramPage) : null) || INITIAL_FILTERS_VALUES.page
  const initialRowsPerPage = INITIAL_ROWS_PER_PAGE

  const { onChangePage, page, records, setPage } = usePagination({
    data,
    initialPage,
    initialRowsPerPage
  })

  const handleDownloadRequests = () => dispatch(onDownloadRequestsThunk())

  const handleChangePage = (event, page) => {
    onChangePage(event, page)

    const filters = { ...generalFilters, page }

    dispatch(onGetRequestsThunk({ filters, searchingByGeneral: true }))
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  /* Initial request */
  useEffectOnce(() => {
    const parseDateFrom = parse(paramDateFrom, 'dd-MM-yyyy', new Date())
    const parseDateTo = parse(paramDateTo, 'dd-MM-yyyy', new Date())

    const validatedFilters = {
      dateFrom: isValid(parseDateFrom) ? parseDateFrom : INITIAL_FILTERS_VALUES.dateFrom,
      dateTo: isValid(parseDateTo) ? parseDateTo : INITIAL_FILTERS_VALUES.dateTo,
      ...(paramOrder && {
        order: ORDER_OPTIONS.some(order => order.value === Number(paramOrder))
          ? paramOrder
          : ORDER_OPTIONS_DICTIONARY.MOST_RECENT_PUBLISHED
      }),
      ...(paramPage && { page: Number(paramPage) ? paramPage : INITIAL_FILTERS_VALUES.page }),
      ...(paramStatus && {
        status: STATUS_OPTIONS.some(status => status.value === Number(paramStatus))
          ? paramStatus
          : STATUS_OPTIONS_DICTIONARY.PUBLISHED
      })
    }

    const filters = { ...generalFilters, ...validatedFilters }

    dispatch(onGetRequestsThunk({ filters, loading: true }))
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  })

  useEffect(() => {
    if (initialPage !== INITIAL_FILTERS_VALUES.page && pageCount !== 0 && initialPage > pageCount) {
      const filters = { ...generalFilters, page: INITIAL_FILTERS_VALUES.page }

      setPage(INITIAL_FILTERS_VALUES.page)
      dispatch(onGetRequestsThunk({ filters, page: INITIAL_FILTERS_VALUES.page, searchingByGeneral: true }))
      updateQuery({ filters, query })
      navigate(
        { pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() },
        { replace: true }
      )
    }
  }, [pageCount])

  return (
    <Container direction="column" padding="16px 0">
      <Container justifyContent="flex-end" margin="0 0 16px">
        <DownloadLink
          disabled={!!error || data.length === 0 || generalDisabled || isDownloading}
          isErrorDownloading={!!errorDownloading}
          onClick={handleDownloadRequests}
        >
          {isDownloading && 'Descargando...'}
          {!isDownloading && errorDownloading && 'Reintente la descarga de nuevo...'}
          {!isDownloading && !errorDownloading && 'Descargar resultados en excel'}
        </DownloadLink>

        {isDownloading && <SearchLoadingIcon />}

        {!isDownloading && (
          <ArrowDownIcon
            disabled={!!error || data.length === 0 || generalDisabled}
            isErrorDownloading={!!errorDownloading}
          />
        )}
      </Container>

      {generalDisabled &&
        [...Array(initialRowsPerPage - 10)].map((_, index) => <Skeleton height="130px" key={index} />)}

      {!error && data.length === 0 && generalLoading && (
        <Card className="not__found" color="blank" enableBorder padding="24px">
          <Container alignItems="center" direction="column" justifyContent="center">
            <NotFoundImg src="/assets/images/not-found.svg" />

            <Typography fontWeight="bold" margin="16px 0" variant="h4">
              No se encontraron resultados para tu búsqueda.
            </Typography>

            <Typography color="black3" variant="body2">
              Intenta con otra palabra clave u otro criterio de búsqueda.
            </Typography>
          </Container>
        </Card>
      )}

      {!error &&
        data.length > 0 &&
        generalLoading &&
        data.map(request => (
          <ListItem
            code={request.code}
            endDate={request.endDate}
            entCodeName={request.entCode?.name}
            isDisabled={isDownloading}
            key={request.id}
            name={request.name}
            orgCodeName={request.orgCode?.name}
            publicationDate={request.publicationDate}
            status={request.status}
          />
        ))}

      {!error && data.length > 0 && generalLoading && (
        <Pagination
          count={pageCount}
          disabled={isDownloading}
          onChange={handleChangePage}
          page={page}
          records={records}
          size="small"
          total={data.length}
        />
      )}

      {generalLoading && error && (
        <Toast
          border
          severity="error"
          textDirection="vertical"
          title="Lo sentimos, estamos presentando problemas con nuestro servidor."
        >
          En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
          más.
        </Toast>
      )}
    </Container>
  )
}

export default RequestList

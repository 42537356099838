import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const Container = styled(BaseContainer)``

export const Item = styled(BaseItem)`
  &.MuiGrid-item {
    text-align: center;
  }
`

export const Image404 = styled.img`
  display: block;
  height: auto;
  margin: 0 auto;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
  width: 70%;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`

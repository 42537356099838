import React from 'react'
import {
  Card as BaseCard,
  Skeleton as BaseSkeleton,
  Spinner as BaseSpinner,
  Tabs as BaseTabs,
  Typography as BaseTypography
} from '@chilecompra/react-kit/components'
import { Divider as DividerBase } from '@material-ui/core'
import {
  Add as BaseAdd,
  Check as BaseCheck,
  KeyboardArrowDown as BaseKeyboardArrowDown,
  KeyboardArrowRight as BaseKeyboardArrowRight,
  Search as BaseSearchIcon
} from '@material-ui/icons'
import { TreeItem as BaseTreeItem, TreeView as BaseTreeView } from '@material-ui/lab'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const AddIcon = styled(BaseAdd)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    cursor: pointer;
    height: 18px;
    margin-right: 8px;
    pointer-events: visible;
    width: 18px;
  }
`

export const AddSuccessIcon = styled(BaseCheck)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    cursor: pointer;
    height: 18px;
    margin-right: 8px;
    pointer-events: visible;
    width: 18px;
  }
`

export const ArrowDownIcon = styled(BaseKeyboardArrowDown)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    height: 22px;
    width: 22px;
  }
`

export const ArrowRightIcon = styled(BaseKeyboardArrowRight)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.blue[50]};
    height: 22px;
    width: 22px;
  }
`

export const Card = styled(BaseCard)`
  &.MuiPaper-root {
    display: flex;

    &.not__found {
      align-items: center;
      justify-content: center;

      .MuiGrid-container {
        text-align: center;
      }
    }
  }
`

export const AddProductLink = styled(({ disabled, ...props }) => <BaseItem {...props} />)`
  &.MuiGrid-item {
    align-items: center;
    color: ${props => (props.disabled ? props.theme.palette.blue[20] : props.theme.palette.blue[50])};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    font-size: 14px;
    font-weight: 400;
    pointer-events: ${props => (props.disabled ? 'none' : 'visible')};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.palette.blue[35]};
      text-decoration: underline;
    }
  }
`

export const CategoryLink = styled(BaseTypography)`
  &.MuiTypography-root {
    color: ${props => props.theme.palette.brand.disableFont};
    cursor: pointer;
    pointer-events: visible;
    text-decoration: underline;

    &:hover {
      color: ${props => props.theme.palette.blue[50]};
    }
  }
`

export const Container = styled(({ isAdded, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    background: ${props => props.isAdded && props.theme.palette.blue[95]};
  }
`

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 32px 0;
    }
  }
`

export const DialogForm = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)``

export const Divider = styled(({ margin, ...props }) => <DividerBase {...props} />)`
  &.MuiDivider-root {
    margin: ${props => props.margin};
  }
`

export const Item = styled(BaseItem)``

export const NotFoundImg = styled.img`
  color: ${props => props.theme.palette.gray[50]};
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 82px;
`

export const Tabs = styled(BaseTabs)`
  &.MuiTabs-root .MuiButtonBase-root {
    max-width: 364px;
    padding: 0 16px;
  }
`

export const TreeItem = styled(BaseTreeItem)`
  &.MuiTreeItem-root {
    &.Mui-selected {
      > .MuiTreeItem-content .MuiTreeItem-label {
        background: transparent;
      }

      :hover,
      :focus {
        > .MuiTreeItem-content .MuiTreeItem-label {
          background: transparent;
        }
      }
    }

    &.category--one,
    &.category--two {
      margin-bottom: 4px;

      :last-child {
        margin-bottom: 0;
      }
    }

    &.category--two {
      &.Mui-selected {
        > .MuiTreeItem-content .MuiTreeItem-label {
          background: ${props => props.theme.palette.blue[95]};
        }

        .MuiTypography-root {
          color: ${props => props.theme.palette.blue[50]};
        }

        :hover,
        :focus {
          > .MuiTreeItem-content .MuiTreeItem-label {
            background: ${props => props.theme.palette.blue[95]};
          }
        }
      }
    }

    .MuiTreeItem-label {
      padding: 4px 8px;

      :hover {
        background: transparent;
      }
    }

    :focus > .MuiTreeItem-content .MuiTreeItem-label {
      background: transparent;
    }
  }
`

export const TreeView = styled(BaseTreeView)``

export const SearchIcon = styled(BaseSearchIcon)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.white};
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }
`

export const SearchLoadingIcon = styled(props => <BaseSpinner {...props} color="white" size="18px" />)`
  &.MuiCircularProgress-root {
    margin-right: 8px;
  }
`

export const Skeleton = styled(BaseSkeleton)`
  &.MuiSkeleton-root {
    margin-bottom: 16px;
    transform: none;
  }
`

export const WrapperBox = styled(({ middle, ...props }) => <BaseContainer {...props} />)`
  &.MuiGrid-container {
    flex-direction: column;
    height: ${props => (props.middle ? '210px' : '400px')};
    overflow: auto;
    padding-right: 16px;
  }
`

import React, { forwardRef } from 'react'
import {
  ErrorIcon as BaseErrorIcon,
  Skeleton as SkeletonBase,
  SuccessIcon as BaseSuccessIcon
} from '@chilecompra/react-kit/components'
import { InputBase } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import styled from 'styled-components'

export const BaseInput = styled(({ size, showIcon, success, textAlign, ...props }) => <InputBase {...props} />)`
  &.MuiInputBase-root {
    background-clip: padding-box;
    background: ${props => props.theme.palette.brand.white};
    border-radius: 0.25rem;
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.gray[85])};
    box-sizing: border-box;
    color: ${props => props.theme.palette.gray[20]};
    display: flex;
    font-size: ${props => {
      if (props.size === 'large') return '16px'

      if (props.size === 'small') return '12px'

      return '14px'
    }};
    line-height: ${props => {
      if (props.size === 'small') return '14px'

      return '16px'
    }};
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &.MuiInputBase-root .MuiInputBase-input {
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    padding: ${props => {
      if (!props.showIcon) return '0.375rem 0.75rem'

      if (props.size === 'large') return '0.375rem 2.375rem 0.375rem 0.75rem'

      if (props.size === 'small') return '0.375rem 1.8rem 0.375rem 0.75rem'

      return '0.375rem 2rem 0.375rem 0.75rem'
    }};
    text-align: ${props => props.textAlign};
    width: auto;

    &::placeholder {
      color: ${props => props.theme.palette.gray[75]};
      font-family: ${props => props.theme.typography.fontFamily};
      font-size: ${props => {
        if (props.size === 'large') return '16px'

        if (props.size === 'small') return '12px'

        return '14px'
      }};
      line-height: ${props => {
        if (props.size === 'small') return '14px'

        return '16px'
      }};
      opacity: 1;
      transition: none;
    }
  }

  &.MuiInputBase-root.Mui-focused {
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.blue[50])};
    box-shadow: 0 0 0 0.2rem
      ${props => (props.success ? props.theme.palette.green[50.4] : props.theme.palette.blue[50.25])};
  }

  &.MuiInputBase-root.Mui-disabled {
    background: ${props => props.theme.palette.gray[95]};
    border: 1px solid ${props => (props.success ? props.theme.palette.green[50] : props.theme.palette.gray[85])};
    color: ${props => props.theme.palette.brand.disableFont};
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-disabled .MuiInputBase-input {
    cursor: not-allowed;
  }

  &.MuiInputBase-root.Mui-error {
    border: 1px solid ${props => props.theme.palette.red[50]};
  }

  &.MuiInputBase-root.Mui-error.Mui-focused {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.palette.red[50.5]};
  }
`

export const BottomMessage = styled(({ size, ...props }) => <div {...props} />)`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => {
    if (props.size === 'large') return '14px'

    if (props.size === 'small') return '11px'

    return '12px'
  }};
  line-height: 16px;
  min-height: 20px;
`

export const Counter = styled(({ size, ...props }) => <div {...props} />)`
  color: ${props => props.theme.palette.gray[50]};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => {
    if (props.size === 'large') return '14px'

    if (props.size === 'small') return '11px'

    return '12px'
  }};
  line-height: 16px;
  min-height: 20px;
`

export const Details = styled(({ gridColumnGap, ...props }) => <div {...props} />)`
  display: grid;
  flex-flow: row wrap;
  grid-column-gap: ${props => props.gridColumnGap || 'initial'};
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin: 3px 0 0;
`

export const Error = styled.section`
  color: ${props => props.theme.palette.red[50]};
`

export const ErrorIcon = styled(BaseErrorIcon)`
  &.MuiSvgIcon-root {
    bottom: 0;
    fill: ${props => props.theme.palette.red[50]};
    font-size: 18px;
    margin: auto 0;
    pointer-events: none;
    position: absolute;
    right: 11px;
    top: 0;
    user-select: none;
  }
`

export const Info = styled.section`
  color: ${props => props.theme.palette.gray[50]};
`

export const Label = styled(({ size, ...props }) => <label {...props} />)`
  color: ${props => props.theme.palette.gray[50]};
  font-size: ${props => {
    if (props.size === 'large') return '14px'

    return '12px'
  }};
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: ${props => {
    if (props.size === 'small') return '14px'

    return '16px'
  }};
  margin: 0 0 0.5rem;
  text-transform: uppercase;
`

export const Prefix = styled.div`
  align-items: center;
  background: ${props => props.theme.palette.gray[95]};
  display: flex;
  font-family: ${props => props.theme.typography.fontFamily};
  height: 100%;
  justify-content: center;
  width: ${props => props.width || 'initial'};
`

export const Required = styled(({ size, ...props }) => <span {...props}>*</span>)`
  color: ${props => props.theme.palette.gray[50]};
  font-size: ${props => {
    if (props.size === 'large') return '1.1rem'

    return '1rem'
  }};
  margin: 0 0 0 4px;
`

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.fontFamily};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const Skeleton = styled(({ size, ...props }) => <SkeletonBase {...props} />)`
  &.MuiSkeleton-root {
    height: ${props => {
      if (props.size === 'large') return '50px'

      if (props.size === 'small') return '30px'

      return '40px'
    }};
  }
`

export const SuccessIcon = styled(({ size, ...props }) => <BaseSuccessIcon {...props} />)`
  &.MuiSvgIcon-root {
    bottom: 0;
    fill: ${props => props.theme.palette.green[50]};
    font-size: 18px;
    font-size: ${props => {
      if (props.size === 'large') return '22px'

      if (props.size === 'small') return '14px'

      return '18px'
    }};
    margin: auto 0;
    pointer-events: none;
    position: absolute;
    right: 11px;
    top: 0;
    user-select: none;
  }
`

export const Suffix = styled.div`
  align-items: center;
  background: ${props => props.theme.palette.gray[95]};
  cursor: pointer;
  display: flex;
  font-family: ${props => props.theme.typography.fontFamily};
  height: 100%;
  justify-content: center;
  width: ${props => props.width || 'initial'};
`

export const TooltipIcon = styled(forwardRef(({ size, ...props }, ref) => <HelpIcon {...props} ref={ref} />))`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.gray[50]};
    font-size: ${props => {
      if (props.size === 'large') return '1rem'

      return '0.8rem'
    }};
    margin: 0 0 0 4px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

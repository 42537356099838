import {
  GET_AUTH_INFO,
  GET_AUTH_INFO_ERROR,
  GET_AUTH_INFO_SUCCESS,
  GET_PUBLIC_TOKEN,
  GET_PUBLIC_TOKEN_ERROR,
  GET_PUBLIC_TOKEN_SUCCESS
} from './AuthProvider.actions'

const authProviderState = {
  authInfo: {
    business: { dni: '', name: '' },
    entCode: '',
    error: undefined,
    loading: false,
    orgCode: '',
    roles: [],
    userType: '',
    usrCode: ''
  },
  publicToken: { error: undefined, loading: false }
}

/**
 * The AuthProvider's reducer.
 */
const authProviderReducer = (state = authProviderState, { payload, type }) => {
  switch (type) {
    case GET_AUTH_INFO: {
      return { ...state, authInfo: { ...state.authInfo, error: undefined, loading: true } }
    }
    case GET_AUTH_INFO_ERROR: {
      return { ...state, authInfo: { ...state.authInfo, error: payload.error, loading: false } }
    }
    case GET_AUTH_INFO_SUCCESS: {
      return { ...state, authInfo: { ...state.authInfo, ...payload.authInfo, error: undefined, loading: false } }
    }
    case GET_PUBLIC_TOKEN: {
      return { ...state, publicToken: { ...state.publicToken, error: undefined, loading: true } }
    }
    case GET_PUBLIC_TOKEN_ERROR: {
      return { ...state, publicToken: { ...state.publicToken, error: payload.error, loading: false } }
    }
    case GET_PUBLIC_TOKEN_SUCCESS: {
      return { ...state, publicToken: { ...state.publicToken, error: undefined, loading: false } }
    }
    default: {
      return state
    }
  }
}

export default authProviderReducer

import React, { memo } from 'react'
import { formatDate } from '@chilecompra/react-kit'
import { Tag, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { STATUS_OPTIONS_DICTIONARY, STATUS_OPTIONS_TRASLATE_DICTIONARY } from '../../configs/settings/constants'
import { PATH_PARAM } from '../../configs/settings/regexes'
import routes from '../../configs/settings/routes'
import { Card, Container, Item, Link, WrapperGrid } from './RequestList.styles'

/**
 * The RequestList's item.
 */
const ListItem = props => {
  const { code, endDate, entCodeName, isDisabled, name, orgCodeName, publicationDate, status } = props

  return (
    <Card className="request" color="blank" elevation="1" enableHover margin="0 0 16px" padding="24px">
      <WrapperGrid alignItems="center" justifyContent="space-between" spacing={2}>
        <Item textAlign="start">
          <Typography className="text__row--1" margin="0 0 4px" variant="subtitle">
            {code}
          </Typography>

          <Typography className="text__row--2" fontWeight="bold" margin="0 0 4px" variant="body1">
            {name}
          </Typography>

          <Typography className="text__row--2" variant="subtitle">
            {entCodeName}
          </Typography>

          <Typography className="text__row--2" variant="subtitle">
            {orgCodeName}
          </Typography>
        </Item>

        <Item textAlign="end">
          <Typography margin="0 0 4px" variant="subtitle">
            Publicada el
          </Typography>

          <Typography margin="0 0 4px" variant="body1">
            {publicationDate && formatDate(new Date(publicationDate), 'dd/MM/yyyy')}
          </Typography>

          <Typography variant="subtitle">
            {publicationDate && formatDate(new Date(publicationDate), 'HH:mm')}
          </Typography>
        </Item>

        <Item textAlign="end">
          <Typography margin="0 0 4px" variant="subtitle">
            Finaliza el
          </Typography>

          <Typography margin="0 0 4px" variant="body1">
            {endDate && formatDate(new Date(endDate), 'dd/MM/yyyy')}
          </Typography>

          <Typography variant="subtitle">{endDate && formatDate(new Date(endDate), 'HH:mm')}</Typography>
        </Item>

        <Item textAlign="center">
          <Container alignItems="center" justifyContent="center">
            <Tag
              color={status === STATUS_OPTIONS_DICTIONARY.PUBLISHED ? 'success' : 'default'}
              padding="0 24px"
              text={STATUS_OPTIONS_TRASLATE_DICTIONARY[status]}
            />
          </Container>
        </Item>

        <Item textAlign="center">
          <Link disabled={isDisabled} to={routes.detail.replace(PATH_PARAM, code)}>
            Ver detalle
          </Link>
        </Item>
      </WrapperGrid>
    </Card>
  )
}

ListItem.defaultProps = {
  isDisabled: false
}

ListItem.propTypes = {
  code: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  entCodeName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  orgCodeName: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired
}

export default memo(ListItem)

import { Radio as BaseRadio } from '@chilecompra/react-kit/components'
import styled from 'styled-components'
import { BaseContainer, BaseItem } from '../../configs/styles/BaseGrid'

export const DialogButtons = styled(BaseContainer)``

export const DialogContent = styled(BaseContainer)`
  &.MuiGrid-container {
    ${props => props.theme.breakpoints.only('sm')} {
      padding: 32px 16px;
    }

    ${props => props.theme.breakpoints.only('xs')} {
      padding: 28px 0;
    }
  }
`

export const DialogForm = styled(BaseContainer)``

export const DialogMessage = styled(BaseContainer)``

export const Item = styled(BaseItem)``

export const OtherReason = styled(BaseContainer)``

export const Radio = styled(BaseRadio)`
  &.MuiFormGroup-root {
    &[direction='vertical'] {
      margin: 0;

      &.other__reason .MuiFormControlLabel-root {
        margin: 0;
      }

      .MuiFormControlLabel-root {
        margin: 0 0 16px;
      }
    }
  }
`

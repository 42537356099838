import React, { memo, useCallback, useEffect, createRef } from 'react'
import { checkInputErrorMaxLength, checkInputErrorRequired, useInput } from '@chilecompra/react-kit'
import { Button, Input } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { Question } from './RequestCreateForm.styles'

/**
 * The RequestCreateForm's question.
 */
const QuestionItem = props => {
  const { duplicatedError, isDisabled, message, onRemove, onUpdate, removable, submitError } = props

  const messageRef = createRef(null)

  const conditionsFields = (collection = []) => collection.some(element => submitError?.includes(element))

  const {
    error: messageError,
    onChange: handleMessageChange,
    value: messageValue
  } = useInput({
    changeCallback: updatedValue => onUpdate({ key: 'message', value: updatedValue }),
    errorCallbacks: [
      checkInputErrorRequired('Este campo es obligatorio. Debes completar esta información para poder continuar.'),
      checkInputErrorMaxLength(300)
    ],
    errorDependencies: [submitError],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => message
  })

  const messageErrorText = useCallback(() => {
    if (messageError !== '') return messageError
    if (duplicatedError) return 'No puedes ingresar dos veces la misma pregunta.'
  }, [duplicatedError, messageError])

  useEffect(() => {
    if (conditionsFields(['ADDITIONAL_INFO_UNSET_ERROR', 'WILL_BE_PUBLIC_UNSET_ERROR'])) return

    if (
      conditionsFields([
        'QUESTIONS_UNSET_ERROR',
        'QUESTION_EMPTY_MESSAGE_ERROR',
        'QUESTION_MAX_LENGTH_ERROR',
        'DUPLICATED_QUESTION_ERROR'
      ]) &&
      messageRef.current.getAttribute('aria-invalid') === 'true'
    ) {
      return messageRef.current?.focus()
    }
  }, [messageRef, submitError])

  return (
    <Question removable={removable}>
      <Input
        disabled={isDisabled}
        error={messageErrorText()}
        info="Ingresa las preguntas específicas que quieres que los proveedores respondan."
        inputRef={messageRef}
        label="Pregunta"
        maxCount={300}
        onChange={handleMessageChange}
        required
        value={messageValue}
      />

      {removable && (
        <Button disabled={isDisabled} onClick={onRemove} padding="0" variant="outlined">
          Borrar
        </Button>
      )}
    </Question>
  )
}

QuestionItem.defaultProps = {
  isDisabled: false,
  submitError: undefined
}

QuestionItem.propTypes = {
  duplicatedError: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  submitError: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default memo(QuestionItem)

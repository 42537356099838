import React, { memo } from 'react'
import { CheckBox, CheckBoxSkeleton, Input, Select, Typography } from '@chilecompra/react-kit/components'
import PropTypes from 'prop-types'
import ProtectByProfile from '../../components/Security/ProtectByProfile'
import { ORDER_OPTIONS, USER_TYPES } from '../../configs/settings/constants'
import { useAuthProvider } from '../AuthProvider/AuthProvider.hook'
import { Accordion, AccordionDetails, AccordionSummary, Container, ExpandIcon } from './RequestFilters.styles'

/**
 * The RequestFilters's mobile.
 */
const MobileRequestFilters = props => {
  const { isDisabled, isItems, isLoading, isParticipated, order, orgBuyer } = props

  const { isPrivate } = useAuthProvider()

  return (
    <Accordion elevation={0}>
      <AccordionSummary aria-controls="filtros-avanzados" expandIcon={<ExpandIcon />}>
        <Typography fontWeight="bold" variant="h4">
          Filtros avanzados
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Select
          disabled={isDisabled}
          label={order.label}
          loading={isLoading}
          onChange={order.handleChange}
          options={ORDER_OPTIONS}
          value={order.value}
        />

        <ProtectByProfile rule={userType => userType === USER_TYPES.SELLER || !isPrivate}>
          <Typography fontWeight="bold" margin="16px 0 32px" variant="h4">
            Filtros destacados
          </Typography>

          <Input
            disabled={isDisabled}
            error={orgBuyer.error}
            label={orgBuyer.label}
            loading={isLoading}
            onBlur={orgBuyer.handleSearchByOrgBuyer}
            onChange={orgBuyer.handleChange}
            value={orgBuyer.value}
          />
        </ProtectByProfile>

        <ProtectByProfile rule={userType => userType === USER_TYPES.SELLER}>
          {isLoading && (
            <Container>
              <CheckBoxSkeleton margin="16px 0" rows={2} />

              <CheckBoxSkeleton rows={2} />
            </Container>
          )}

          {!isLoading && (
            <Container alignItems="flex-start" direction="column">
              <CheckBox
                checked={isParticipated.value}
                disabled={isDisabled}
                label={isParticipated.label}
                margin="16px 0"
                onChange={isParticipated.handleChange}
              />

              <CheckBox
                checked={isItems.value}
                disabled={isDisabled}
                label={isItems.label}
                onChange={isItems.handleChange}
              />
            </Container>
          )}
        </ProtectByProfile>
      </AccordionDetails>
    </Accordion>
  )
}

MobileRequestFilters.defaultProps = {
  isDisabled: false,
  isLoading: false
}

MobileRequestFilters.propTypes = {
  isDisabled: PropTypes.bool,
  isItems: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired
  }).isRequired,
  isLoading: PropTypes.bool,
  isParticipated: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired
  }).isRequired,
  order: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  orgBuyer: PropTypes.shape({
    error: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSearchByOrgBuyer: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired
}

export default memo(MobileRequestFilters)

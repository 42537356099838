import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkInputErrorMaxLength,
  formatCurrency,
  useCheckBox,
  useInput,
  useQuery,
  useSelect,
  useViewport
} from '@chilecompra/react-kit'
import { Skeleton, Typography } from '@chilecompra/react-kit/components'
import { INITIAL_FILTERS_VALUES } from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { formatOnlyLettersNumbersAndSomeSymbols } from '../../modules/utils/formatters'
import { updateQuery } from '../../modules/utils/searches'
import { onGetRequestsThunk } from '../RequestList/RequestList.actions'
import { useAuthProvider } from './../AuthProvider/AuthProvider.hook'
import DesktopRequestFilters from './RequestFilters.desktop'
import MobileRequestFilters from './RequestFilters.mobile'
import { Container } from './RequestFilters.styles'

/**
 * The RequestFilters's container.
 */
const RequestFilters = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()
  const { size } = useViewport()

  const {
    error,
    filters: { dateFrom, dateTo, isItems, isParticipated, order, orgBuyer },
    isDownloading,
    loading,
    searching,
    searchingByGeneral,
    summaryCount
  } = useSelector(state => state.requestList)

  const generalFilters = { ...INITIAL_FILTERS_VALUES, ...Object.fromEntries(query.entries()), dateFrom, dateTo }

  const { onChange: handleOrderChange } = useSelect({
    changeCallback: updatedValue => {
      if (updatedValue !== '') {
        const filters = { ...generalFilters, order: updatedValue }

        dispatch(onGetRequestsThunk({ filters, searchingByGeneral: true }))
        updateQuery({ filters, query })
        navigate(
          { pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() },
          { replace: true }
        )
      }
    },
    overwriteCallback: () => order
  })

  const {
    error: orgBuyerError,
    onChange: handleOrgBuyerChange,
    value: orgBuyerValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(200)],
    formatCallbacks: [formatOnlyLettersNumbersAndSomeSymbols],
    overwriteCallback: () => orgBuyer
  })

  const { onChange: handleIsParticipatedChange, value: isParticipatedValue } = useCheckBox({
    changeCallback: updatedValue => {
      const filters = { ...generalFilters, isParticipated: updatedValue }

      dispatch(onGetRequestsThunk({ filters, searchingByGeneral: true }))
      updateQuery({ filters, query })
      navigate(
        { pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() },
        { replace: true }
      )
    },
    overwriteCallback: () => isParticipated
  })

  const { onChange: handleIsItemsChange, value: isItemsValue } = useCheckBox({
    changeCallback: updatedValue => {
      const filters = { ...generalFilters, isItems: updatedValue }

      dispatch(onGetRequestsThunk({ filters, searchingByGeneral: true }))
      updateQuery({ filters, query })
      navigate(
        { pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() },
        { replace: true }
      )
    },
    overwriteCallback: () => isItems
  })

  const handleSearchByOrgBuyer = useCallback(() => {
    if (generalFilters.orgBuyer === orgBuyerValue) return

    const filters = { ...generalFilters, orgBuyer: orgBuyerValue }

    dispatch(onGetRequestsThunk({ filters, searchingByGeneral: true }))
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }, [generalFilters, orgBuyerValue, query])

  const filtersRestProps = {
    isItems: {
      handleChange: handleIsItemsChange,
      label: 'Ver solamente consultas en mis rubros.',
      value: isItemsValue
    },
    isParticipated: {
      handleChange: handleIsParticipatedChange,
      label: 'Ver solamente consultas en las que he participado.',
      value: isParticipatedValue
    },
    order: { handleChange: handleOrderChange, label: 'Ordenar por', value: order },
    orgBuyer: {
      error: orgBuyerError,
      handleChange: handleOrgBuyerChange,
      handleSearchByOrgBuyer,
      label: 'Organismo comprador',
      value: orgBuyerValue
    }
  }

  return (
    <Container direction="column" padding="16px 0">
      <Typography fontWeight="bold" margin="0 0 32px" variant="h4">
        {(loading || searching || searchingByGeneral) && <Skeleton />}

        {!error &&
          !loading &&
          !searching &&
          !searchingByGeneral &&
          summaryCount > 0 &&
          `Existen ${formatCurrency(summaryCount)} resultados para tu búsqueda.`}

        {!loading &&
          !searching &&
          !searchingByGeneral &&
          (summaryCount === 0 || error) &&
          'No existen resultados para tu búsqueda'}
      </Typography>

      {size.isDownToSmall && (
        <MobileRequestFilters
          isDisabled={isDownloading || searching || searchingByGeneral}
          isLoading={loading}
          {...filtersRestProps}
        />
      )}

      {size.isUpToMedium && (
        <DesktopRequestFilters
          isDisabled={isDownloading || searching || searchingByGeneral}
          isLoading={loading}
          {...filtersRestProps}
        />
      )}
    </Container>
  )
}

export default RequestFilters

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce, useSecondOrderCallback, useQuery, useViewport } from '@chilecompra/react-kit'
import { Button, Typography } from '@chilecompra/react-kit/components'
import { CREATE_INNOVATION_SECTION_OPTIONS } from '../../configs/settings/constants'
import routes from '../../configs/settings/routes'
import { updateQuery } from '../../modules/utils/searches'
import { onShowFeedbackConfirmDialog } from '../RequestFeedbackConfirmDialog/RequestFeedbackConfirmDialog.actions'
import { onShowQuestionsLibraryDialog } from '../RequestQuestionsLibraryDialog/RequestQuestionsLibraryDialog.actions'
import { useAuthProvider } from './../AuthProvider/AuthProvider.hook'
import {
  onAddQuestionInnovationSuccess,
  onAddQuestionMultipleChoiceSuccess,
  onInitialQuestionsMultipleChoice,
  onRemoveQuestionInnovationSuccess,
  onRemoveQuestionMultipleChoiceSuccess,
  onResetQuestionsMultipleChoice,
  onUpdateCreateInnovationFormValue,
  onUpdateQuestionInnovationValueThunk,
  onUpdateQuestionMultipleChoiceValueThunk,
  onValidateCreateInnovationFormThunk
} from './RequestCreateInnovationForm.actions'
import SectionsForm from './RequestCreateInnovationForm.sections'
import { Container, Item, WrapperGrid } from './RequestCreateInnovationForm.styles'

/**
 * The RequestCreateInnovationForm's container.
 */
const RequestCreateInnovationForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const { isPrivate } = useAuthProvider()
  const { size } = useViewport()

  const {
    antecedents,
    descriptionProblem,
    historyProblem,
    isInvestigated,
    loading,
    locationProblem,
    peopleAffected,
    questions,
    submitError,
    willBeRequiredConcept
  } = useSelector(state => state.requestCreateInnovationForm)
  const { filters } = useSelector(state => state.requestList)
  const { otherReason, reason } = useSelector(state => state.requestReasonDialog)

  const handleAddQuestionInnovation = useCallback(() => dispatch(onAddQuestionInnovationSuccess()), [])
  const handleGoBackStepOne = () => navigate({ pathname: routes.create }, { replace: true })
  const handleShowQuestionsLibraryDialog = () => dispatch(onShowQuestionsLibraryDialog())

  const handleAddQuestionMultipleChoice = useSecondOrderCallback((_, id) => {
    dispatch(onAddQuestionMultipleChoiceSuccess({ id }))
  })

  const handleInitialQuestionsMultipleChoice = useSecondOrderCallback(id => {
    dispatch(onInitialQuestionsMultipleChoice({ id }))
  })

  const handleRemoveQuestionInnovation = useSecondOrderCallback((_, id) => {
    dispatch(onRemoveQuestionInnovationSuccess({ id }))
  })

  const handleRemoveQuestionMultipleChoice = useSecondOrderCallback((idOption, id) => {
    dispatch(onRemoveQuestionMultipleChoiceSuccess({ id, idOption }))
  })

  const handleResetQuestionsMultipleChoice = useSecondOrderCallback(id => {
    dispatch(onResetQuestionsMultipleChoice({ id }))
  })

  const handleUpdateQuestionInnovation = useSecondOrderCallback((entry, id) => {
    dispatch(onUpdateQuestionInnovationValueThunk({ ...entry, id }))
  })

  const handleUpdateQuestionMultipleChoiceValue = useSecondOrderCallback(({ entryOption, idOption }, id) => {
    dispatch(onUpdateQuestionMultipleChoiceValueThunk({ entryOption, id, idOption }))
  })

  const handleUpdateCreateInnovationFormValue = useCallback(entry => {
    dispatch(onUpdateCreateInnovationFormValue({ ...entry }))
  }, [])

  const handleGoHomeWithParams = () => {
    updateQuery({ filters, query })
    navigate({ pathname: isPrivate ? routes.search : routes.searchPublic, search: query.toString() }, { replace: true })
  }

  const handleValidateCreateForm = () => {
    dispatch(
      onValidateCreateInnovationFormThunk({
        showFeedbackConfirmDialogCallbak: () => dispatch(onShowFeedbackConfirmDialog())
      })
    )
  }

  useEffectOnce(() => {
    if (!otherReason && !reason) return handleGoHomeWithParams()
  })

  if (!otherReason && !reason) return

  return (
    <Container padding="16px 0" spacing={4}>
      <Item md={9} xs={12}>
        <Typography fontWeight="bold" variant="h3">
          Problema a solucionar
        </Typography>
      </Item>

      <Item xs={12}>
        {CREATE_INNOVATION_SECTION_OPTIONS.map(section => (
          <WrapperGrid key={section.name} padding="32px 0">
            <Item>
              <Typography fontWeight="bold" margin="0 0 8px" variant="body1">
                {section.title}
              </Typography>

              <Typography variant="subtitle">{section.description}</Typography>
            </Item>

            <SectionsForm
              antecedents={antecedents}
              descriptionProblem={descriptionProblem}
              historyProblem={historyProblem}
              isDisabled={loading}
              isInvestigated={isInvestigated}
              locationProblem={locationProblem}
              onAddQuestion={handleAddQuestionInnovation}
              onAddQuestionMultipleChoice={handleAddQuestionMultipleChoice}
              onInitialQuestionsMultipleChoice={handleInitialQuestionsMultipleChoice}
              onRemoveQuestion={handleRemoveQuestionInnovation}
              onRemoveQuestionMultipleChoice={handleRemoveQuestionMultipleChoice}
              onResetQuestionsMultipleChoice={handleResetQuestionsMultipleChoice}
              onShowQuestionsLibraryDialog={handleShowQuestionsLibraryDialog}
              onUpdate={handleUpdateCreateInnovationFormValue}
              onUpdateQuestion={handleUpdateQuestionInnovation}
              onUpdateQuestionMultipleChoiceValue={handleUpdateQuestionMultipleChoiceValue}
              peopleAffected={peopleAffected}
              questions={questions}
              sectionName={section.name}
              submitError={submitError?.reason}
              willBeRequiredConcept={willBeRequiredConcept}
            />
          </WrapperGrid>
        ))}
      </Item>

      <Item md={9} xs={12}>
        <Container borderTop justifyContent={size.isTiny ? 'center' : 'space-between'} padding="32px 0">
          <Button
            disabled={loading}
            margin={size.isTiny ? '0 0 16px' : undefined}
            onClick={handleGoBackStepOne}
            variant="outlined"
          >
            Volver
          </Button>

          <Button color="primary" disabled={loading} onClick={handleValidateCreateForm} variant="contained">
            Publicar Consulta al Mercado
          </Button>
        </Container>
      </Item>
    </Container>
  )
}

export default RequestCreateInnovationForm

import { CLOSE_HISTORY_DIOALOG, SHOW_HISTORY_DIALOG } from './RequestHistory.actions'

const requestHistoryState = { showDialog: false }

/**
 * The RequestHistory's reducer.
 */
const requestHistoryReducer = (state = requestHistoryState, { type }) => {
  switch (type) {
    case CLOSE_HISTORY_DIOALOG: {
      return { ...state, showDialog: false }
    }
    case SHOW_HISTORY_DIALOG: {
      return { ...state, showDialog: true }
    }
    default: {
      return state
    }
  }
}

export default requestHistoryReducer
